import { Directive, Input } from '@angular/core';
import { ArchiveFolder } from '../../../../../ngxSpring/api.model';
import { ApiService } from '../../../../../ngxSpring/api.service';
import { FolderService } from '../../../bookmark/folder.service';
import { Subscription } from 'rxjs';
import { UserService } from '../../../user/user.service';

@Directive()
export abstract class FolderUpdate {
  @Input()
  folder: ArchiveFolder;

  folders: ArchiveFolder[];

  subscription;

  abstract load();

  constructor(
    public api: ApiService,
    public folderService: FolderService,
    public userService: UserService,
  ) {
    this.subscription = new Subscription();
    this.subscription.add(
      this.folderService.folderRemoved.subscribe((f) => {
        this.folders?.removeIf((it) => it.id == f.id);
      }),
    );
    this.subscription.add(
      this.folderService.folderUpdated.subscribe((f) => {
        if (!this.folder) {
          if (!f) {
            this.load();
          }
          return;
        }
        if (f && f.length) {
          if (f.find((it) => it.id === this.folder.id)) {
            this.load();
          }
          return;
        }
        if (f && f.id === this.folder.id) {
          if (f.name) this.folder.name = f.name;
          this.folder.membershipShared = f.membershipShared;
          this.load();
        }
      }),
    );
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }
}
