import { Component, Input, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'lb-day-log-list',
  templateUrl: './day-log-list.component.html',
  styleUrls: ['./day-log-list.component.sass'],
})
export class DayLogListComponent implements OnInit {
  sortByDay: {};
  dateLabels: string[];
  now;

  @Input()
  itemTemplate;

  @Input()
  itemDesc: boolean;

  @Input()
  dateField = 'createdAt';

  @Input()
  set logs(data: any[]) {
    if (!data) {
      return;
    }
    this.sortByDay = {};
    data.forEach((it) => {
      const dayString = this.datePipe.transform(
        it[this.dateField],
        'yyyy-MM-dd',
      );
      if (!this.sortByDay[dayString]) {
        this.sortByDay[dayString] = [];
      }
      this.sortByDay[dayString].push(it);
    });
    this.dateLabels = Object.keys(this.sortByDay).sort((a, b) =>
      b.localeCompare(a),
    );
    this.dateLabels.forEach((it) => {
      this.sortByDay[it].sort(
        (a, b) => Number(a[this.dateField]) - Number(b[this.dateField]),
      );
    });
  }

  constructor(private datePipe: DatePipe) {}

  ngOnInit(): void {
    this.now = new Date();
    this.now.setHours(0, 0, 0, 0);
  }

  dayLabel(dateLabel: string) {
    if (!dateLabel) {
      return;
    }
    const ago = this.ago(dateLabel);
    if (ago <= 0) {
      return '오늘';
    }
    if (ago > 3) {
      return `${dateLabel.split('-')[0]}년 ${Number(
        dateLabel.split('-')[1],
      )}월 ${Number(dateLabel.split('-')[2])}일`;
    }
    return `${ago}일 전`;
  }

  ago(dateLabel) {
    const split = dateLabel.split('-');
    const msInDay = 24 * 60 * 60 * 1000;
    return (
      (+this.now - +new Date(split[0], Number(split[1]) - 1, split[2])) /
      msInDay
    );
  }
}
