import { Component, OnInit } from '@angular/core';
import { DialogOrBottomSheetProto } from '../../../alert/alert.service';
import { UserToken } from '../../../../../ngxSpring/api.model';
import { UserService } from '../../user.service';
import { Router } from '@angular/router';
import { TrackService } from '../../../track/track.service';
import { DeviceService } from '../../../core/device.service';

@Component({
  selector: 'lb-device-remove',
  templateUrl: './device-remove.component.html',
  styleUrls: ['./device-remove.component.sass'],
})
export class DeviceRemoveComponent
  extends DialogOrBottomSheetProto<UserToken[]>
  implements OnInit
{
  ngOnInit(): void {
    this.injector.get(TrackService).sendEvent('view_certificate');
  }

  async authorize($event: UserToken) {
    const cert = await this.injector
      .get(UserService)
      .smsCertPromise($event.userId, Number($event.createdAt));
    this.injector
      .get(DeviceService)
      .deviceId()
      .then((v) => {
        this.api.smsVerify
          .removeDeviceAndLogin(cert.code, cert.phone, v, $event.id)
          .subscribe(() => {
            this.injector
              .get(Router)
              .navigateByUrl('/')
              .then(() => {
                this.alert.pop(
                  '해제가 완료되었습니다. 현재 이용중인 ' +
                    (this.data[0].deviceType == 'NORMAL'
                      ? 'PC/웹브라우저'
                      : '모바일 기기') +
                    '로 엘박스를 이용하실 수 있습니다.',
                );
                this.injector.get(UserService).updateFromServer();
                this.injector
                  .get(TrackService)
                  .sendEvent('view_certificate_complete');
                this.close();
              });
          });
      });
  }
}
