<img class="full-width" [src]="data.message" />
<div class="title alert-dialog">
  <div
    [innerHTML]="data.description | safeHtml"
    *ngIf="data.description"
    class="desc mt-0"
  ></div>
</div>
<div class="buttons">
  <button class="cancel" trackId="19d7"  (click)="close(null)" *ngIf="data.cancel != false">
    {{ data.cancel || '취소' }}
  </button>
  <button class="blue" trackId="c4ff"  (click)="close(true)">{{ data.ok || '확인' }}</button>
</div>
