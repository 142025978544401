<div class="outer with-label">
  <div class="wrapper" fxLayout="row" #wrapper>
    <div class="input" fxFlex="auto">
      <input
        [matAutocomplete]="auto"
        placeholder="판사명을 입력해주세요"
        #input
        [value]="judge.query || ''"
        #trigger="matAutocompleteTrigger"
        (keyup)="judge.find(input.value)"
        (keyup.enter)="judge.load(input.value, trigger)"
      />
    </div>

    <button
      class="search"
      trackId="5206"
      (click)="judge.load(input.value, trigger)"
    >
      <span class="icon2 n-83"></span>
    </button>
  </div>
  <mat-autocomplete
    [panelWidth]="
      wrapper.offsetWidth - 16 < 525 ? 525 : wrapper.offsetWidth - 16
    "
    class="ml--24 mt-24"
    #auto="matAutocomplete"
    (optionSelected)="judge.moveTo($event.option.value)"
    [displayWith]="name.bind(this)"
  >
    <mat-option
      class="judge"
      *ngFor="let judge of judge.judgeOptions"
      [value]="judge"
    >
      <div fxLayout="row" fxLayoutAlign="start center">
        <span class="icon2 n-82 h-4 mr-12"></span>
        <div>
          <div
            class="vertical-middle"
            [routerLink]="judge | judgeLink"
            trackId="882f"
            (click)="$event.stopPropagation()"
          >
            {{ judge.name }}
          </div>
          <lb-info-desc [judge]="judge"></lb-info-desc>
        </div>
      </div>
    </mat-option>
  </mat-autocomplete>
</div>
