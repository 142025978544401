import {
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { FlexModule } from '@angular/flex-layout';
import { ShowWhenSizeDirective } from '../show-when-size.directive';

@Component({
  selector: 'lb-upload-area',
  templateUrl: './upload-area.component.html',
  styleUrls: ['./upload-area.component.sass'],
  standalone: true,
  imports: [FlexModule, ShowWhenSizeDirective],
})
export class UploadAreaComponent {
  @Output()
  filesChange = new EventEmitter();

  @HostBinding('class.dragover')
  get hostClass() {
    return this.onDragOver;
  }

  @Output()
  uploaded = new EventEmitter();

  @ViewChild('file', { static: false })
  fileRef: ElementRef;

  onDragOver;

  @Output()
  onDragOverChange = new EventEmitter();

  @Input()
  fileType = '판례 PDF파일';

  @Input()
  accept;

  @Input()
  multiple = true;

  dragover(e) {
    this.onDragOver = true;
    this.onDragOverChange.emit(true);
    this.preventAndStop(e);
  }

  dragleave(e) {
    this.onDragOver = false;
    this.onDragOverChange.emit(false);
    this.preventAndStop(e);
  }

  drop(e) {
    this.preventAndStop(e);
    const files: FileList = e.dataTransfer.files;
    this.filesChange.emit(files);
    this.onDragOver = false;
  }

  private preventAndStop(e: Event) {
    e.stopPropagation();
    e.preventDefault();
  }
}
