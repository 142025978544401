import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'requestStates',
})
export class RequestStatesPipe implements PipeTransform {
  transform(value: unknown, ...args: unknown[]): unknown {
    switch (value) {
      case 'Requested':
      case 'Waiting':
        return { name: '입수진행중' };
      case 'OnRemoveProcess':
        return { name: '입수진행중(비실명 작업중)' };
      case 'Get':
        return { name: '등록완료', class: 'green' };
      case 'Undecided':
        return { name: '입수불가(미확정)', class: 'red' };
      case 'CounterClaim':
        return { name: '입수불가(반소)', class: 'red' };
      case 'UnAble':
        return { name: '입수불가(검찰송부)', class: 'red' };
      case 'Delayed':
        return { name: '입수지연', class: 'red' };
      case 'Merged':
        return { name: '입수불가(병합)', class: 'red' };
      case 'Limited':
        return { name: '입수불가(열람제한)', class: 'red' };
      case 'WrongCaseNo':
        return { name: '입수불가(부존재)', class: 'red' };
      case 'Cancel':
        return { name: '입수불가(소취하)', class: 'red' };
      case 'Wasted':
        return { name: '입수불가(폐기)', class: 'red' };
      case 'Unreadable':
        return { name: '입수불가(판독불가)', class: 'red' };
    }
  }
}
