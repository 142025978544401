import { Component, Input } from '@angular/core';
import { SnsLoginResult } from 'ngxSpring/api.model';
import { UserService } from '../../user/user.service';
import { AlertService } from 'src/app/alert/alert.service';
import { Router } from '@angular/router';
import { existDesc, navigateToFromOr } from '../../core/util';
import { SnsLoginService } from './sns-login.service';
import { LocalStorageService } from '../../core/local-storage.service';
import { LoginRecommendComponent } from '../../user/dialogs/login-recommend/login-recommend.component';

@Component({
  selector: 'lb-sns-login',
  templateUrl: './sns-login.component.html',
  styleUrls: ['./sns-login.component.sass'],
})
export class SnsLoginComponent {
  @Input()
  beforeLoginLogout;

  /** @deprecated */
  @Input()
  popover = '';

  /** template 상에서 문구를 분기하기 위한 prop */
  @Input()
  type: string;

  @Input()
  only;

  @Input()
  mustExist;

  @Input()
  snsEmail;

  constructor(
    private userService: UserService,
    private alert: AlertService,
    private router: Router,
    private snsLogin: SnsLoginService,
    private localStorage: LocalStorageService,
  ) {}

  loginWithSnsResult(u: SnsLoginResult, token: any, type: string) {
    if (!u.success) {
      u.snsAuthList.removeIf((it) => it.type == type);
      if (u.email || u.snsAuthList.length) {
        this.alert
          .confirm(
            '알림',
            '로그인하기',
            existDesc(
              '가입한 계정이 있습니다.',
              u.snsAuthList.length ? u.snsAuthList[0].type : null,
              u.email,
              null,
              u.snsAuthList[0]?.email,
            ),
          )
          .then((v) => {
            if (v) {
              this.alert.open(
                LoginRecommendComponent,
                { ...u, snsRecommend: true },
                { width: '450px' },
              );
              return;
            }
          });
        return;
      }
      this.alert
        .confirm(
          '미가입 회원',
          null,
          '미가입 정보 입니다. 회원가입 하시겠습니까?',
        )
        .then((v) => {
          if (!v) return;
          this.localStorage.setItem('snsToken', token);
          this.router.navigateByUrl(`/register?sns=${type}`);
        });
      return;
    }
    navigateToFromOr(this.router);
    this.alert.close();
    this.userService.updateUser(u.user);
    this.alert.pop('로그인 되었습니다.');
  }

  kakaoStart() {
    if (this.beforeLoginLogout) {
      void this.userService.logoutOnly();
    }
    void this.snsLogin.kakao().then((v) => {
      if (this.mustExist && !v.result.user) {
        this.alert.alert(this.notMatchedAccount('카카오'));
        return;
      }
      this.loginWithSnsResult(v.result, v.token, 'KAKAO');
    });
  }

  naverStart() {
    if (this.beforeLoginLogout) {
      void this.userService.logoutOnly();
    }
    void this.snsLogin.naver().then((v) => {
      if (this.mustExist && !v.result.user) {
        this.alert.alert(this.notMatchedAccount('네이버'));
        return;
      }
      this.loginWithSnsResult(v.result, v.token, 'NAVER');
    });
  }

  googleStart() {
    if (this.beforeLoginLogout) {
      void this.userService.logoutOnly();
    }
    void this.snsLogin.google().then((v) => {
      if (this.mustExist && !v.result.user) {
        this.alert.alert(this.notMatchedAccount('구글'));
        return;
      }
      this.loginWithSnsResult(v.result, v.token, 'GOOGLE');
    });
  }

  private notMatchedAccount(name: string) {
    const tail = this.snsEmail ? `[${this.snsEmail}]` : '엘박스에 연동된 다른';
    return `현재 ${name}에 로그인되어있는 계정은 엘박스와 연동된 계정이 아닙니다.<br>
            <div class="font-14 mt-12">${name}에 접속하여 ${tail} 계정으로 로그인하신 후, 다시 '${name}로 로그인하기' 버튼을 클릭해주세요.</div>`;
  }
}
