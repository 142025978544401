import { Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'lb-statute-badge',
  templateUrl: './statute-badge.component.html',
  styleUrls: ['./statute-badge.component.sass'],
})
export class StatuteBadgeComponent {
  @HostBinding('attr.class')
  @Input()
  state;

  names = {
    current: '현행',
    close: '폐지',
    history: '연혁',
    expect: '예정',
  };
}
