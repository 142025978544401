import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'lb-date-time',
  templateUrl: './date-time.component.html',
  styleUrls: ['./date-time.component.sass'],
})
export class DateTimeComponent {
  @Input()
  min;

  @Input()
  value;

  @Output()
  valueChange = new EventEmitter();

  @Input()
  type: 'datetime-local' | 'date' = 'datetime-local';

  get max() {
    if (this.type == 'datetime-local') return '2100-12-31T00:00';
    return '2100-12-31';
  }

  constructor(private datePipe: DatePipe) {}

  date(value: any) {
    if (!value) {
      return;
    }
    if (this.type == 'date') {
      try {
        return this.datePipe.transform(new Date(value), 'yyyy-MM-dd');
      } catch (e) {
        return;
      }
    }
    try {
      return this.datePipe.transform(new Date(value), 'yyyy-MM-ddTHH:mm:ss');
    } catch (e) {
      return;
    }
  }

  changed($event) {
    const bits = $event.split(/\D/);
    const date =
      this.type == 'date'
        ? new Date(bits[0], --bits[1], bits[2])
        : new Date(bits[0], --bits[1], bits[2], bits[3], bits[4]);
    this.valueChange.emit(date);
  }
}
