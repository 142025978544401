import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'newLine',
})
export class NewLinePipe implements PipeTransform {
  transform(value, ...args) {
    if (!value) return value;
    if (!args[0])
      return value
        .replace(/\</g, '&lt;')
        .replace(/\>/g, '&gt;')
        .replace(/\n/g, '<q>↵</q><br>')
        .replace(/ /g, '<span class="space"> </span>');
    return value
      .replace(/\n/g, '<q>↵</q><br>')
      .replace(/ /g, '<span class="space"> </span>');
  }
}
