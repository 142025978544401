import { getCaseNoRegex } from './util';
import { CategoryType } from '../search/search-result/categories';
import { josa } from './josa.util';

const block = '[^/ "]+';
const quoteBlock = '[^/"]+';
const blockWithoutDash = '[^/ "-]+';

function blockOrQuote(name) {
  return [`\@${name} \\"(${quoteBlock})\\"`, `\@${name} (${block})`];
}

export interface SearchFunction {
  hide?;
  order: number;
  name?;
  token?;
  type;
  description?;
  completion?;
  regex;
  highlightLimit?;
  highlightOffset?;
  preserveKeyword?;
  supports?: CategoryType[];

  isMatch(key: string, value: string, commands: SearchFunction[]): boolean;

  resultDescription(
    value: string[],
    last: boolean,
    sameOrderLast: boolean,
  ): string;
}

function defaultDescription(name: string) {
  return (value, last, sameOrderLast) => {
    if (last) return `${name}에 ${joinWithJosa(value, '을를')} 포함한`;
    if (sameOrderLast)
      return `${name}에 ${joinWithJosa(value, '을를')} 포함하고`;
    return `${name}에 ${joinWithJosa(value)}, `;
  };
}

export const commands: SearchFunction[] = [
  {
    order: 5,
    supports: ['case'],
    name: '@사건명',
    type: 'case',
    completion: '@사건명 ',
    description:
      '<span class="left-block">사건명에서 검색</span> <span class="right"><span class="blue">@사건명 검색어 <span class="or">또는</span> <span class="sentence">"문장"</span></span></span> <span class="ex">예) @사건명 건물명도</span>',
    regex: blockOrQuote('사건명'),
    isMatch(key: string): boolean {
      const min = Math.min(key.length, this.completion.length);
      return this.completion.substring(0, min) === key.substring(0, min);
    },
    resultDescription: defaultDescription('사건명'),
  },
  {
    order: 5,
    supports: ['case'],
    name: '@주문',
    type: 'order',
    description:
      '<span class="left-block">주문에서 검색</span> <span class="right"><span class="blue">@주문 검색어 <span class="or">또는</span> <span class="sentence">"문장"</span></span></span><span class="ex">예) @주문 기각</span>',
    completion: '@주문 ',
    regex: blockOrQuote('주문'),
    isMatch(key: string): boolean {
      const min = Math.min(key.length, this.completion.length);
      return this.completion.substring(0, min) === key.substring(0, min);
    },
    resultDescription: defaultDescription('주문'),
  },
  {
    order: 6,
    supports: ['case'],
    name: '@법조문',
    type: 'law',
    completion: '@법조문 ',
    description:
      '<span class="left-block">법조문에서 검색</span> <span class="right"><span class="blue">@법조문 법령명조문번호</span></span></span> <span class="ex">예) @법조문 민법406</span>',
    regex: blockOrQuote('법조문'),
    isMatch(key: string): boolean {
      const min = Math.min(key.length, this.completion.length);
      return this.completion.substring(0, min) === key.substring(0, min);
    },
    resultDescription: (value, last) => {
      if (last) return `${joinWithJosa(value, '을를')} 인용한`;
      return `${joinWithJosa(value, '을를')} 인용하고`;
    },
  },
  {
    order: 5,
    supports: ['case'],
    name: '@법무법인',
    type: 'lawfirm',
    description:
      '<span class="left-block">법무법인 검색</span> <span class="right"><span class="blue">@법무법인 검색어</span></span><span class="ex">예) @법무법인 율촌</span>',
    completion: '@법무법인 ',
    regex: blockOrQuote('법무법인').concat(blockOrQuote('로펌')),
    isMatch(key: string): boolean {
      const min = Math.min(key.length, this.completion.length);
      return this.completion.substring(0, min) === key.substring(0, min);
    },
    resultDescription: defaultDescription('법무법인'),
  },
  {
    order: 5,
    supports: ['case'],
    name: '@당사자명',
    type: 'parties',
    description:
      '<span class="left-block">당사자명 검색</span> <span class="right"><span class="blue">@당사자명 검색어 <span class="or">또는</span> <span class="sentence">"문장"</span></span></span><span class="ex">예) @당사자명 공정거래위원회</span>',
    completion: '@당사자명 ',
    regex: blockOrQuote('당사자명'),
    isMatch(key: string): boolean {
      const min = Math.min(key.length, this.completion.length);
      return this.completion.substring(0, min) === key.substring(0, min);
    },
    resultDescription: defaultDescription('당사자명'),
  },
  {
    order: 5,
    supports: ['case'],
    name: '@사건부호',
    type: 'caseChar',
    description:
      '<span class="left-block">사건부호 검색</span> <span class="right"><span class="blue">@사건부호 검색어</span></span><span class="ex">예) @사건부호 카합</span>',
    completion: '@사건부호 ',
    regex: blockOrQuote('사건부호'),
    isMatch(key: string): boolean {
      const min = Math.min(key.length, this.completion.length);
      return this.completion.substring(0, min) === key.substring(0, min);
    },
    resultDescription: defaultDescription('사건부호'),
  },

  {
    order: 5,
    supports: ['case'],
    name: '@판결요지',
    type: 'summary',
    completion: '@판결요지 ',
    description:
      '<span class="left-block">판시사항/판결요지에서 검색</span> <span class="right"><span class="blue">@판결요지 검색어 <span class="or">또는</span> <span class="sentence">"문장"</span></span></span><span class="ex">예) @판결요지 토지수용</span>',
    regex: blockOrQuote('판결요지'),
    isMatch(key: string): boolean {
      const min = Math.min(key.length, this.completion.length);
      return this.completion.substring(0, min) === key.substring(0, min);
    },
    resultDescription: defaultDescription('판결요지/판시사항'),
  },
  {
    order: 5,
    supports: ['case'],
    name: '@청구취지',
    type: 'claim',
    completion: '@청구취지 ',
    description:
      '<span class="left-block">청구취지에서 검색</span> <span class="right"><span class="blue">@청구취지 검색어 <span class="or">또는</span> <span class="sentence">"문장"</span></span></span><span class="ex">예) @청구취지 지급하라</span>',
    regex: blockOrQuote('청구취지'),
    isMatch(key: string): boolean {
      const min = Math.min(key.length, this.completion.length);
      return this.completion.substring(0, min) === key.substring(0, min);
    },
    resultDescription: defaultDescription('청구취지'),
  },
  {
    order: 5,
    supports: ['case'],
    name: '@신청취지',
    type: 'requestClaim',
    completion: '@신청취지 ',
    description:
      '<span class="left-block">신청취지에서 검색</span> <span class="right"><span class="blue">@신청취지 검색어 <span class="or">또는</span> <span class="sentence">"문장"</span></span></span> <span class="ex">예) @신청취지 인도하라</span>',
    regex: blockOrQuote('신청취지'),
    isMatch(key: string): boolean {
      const min = Math.min(key.length, this.completion.length);
      return this.completion.substring(0, min) === key.substring(0, min);
    },
    resultDescription: defaultDescription('신청취지'),
  },
  {
    order: 2,
    name: '"완전일치"',
    type: 'exact',
    completion: '"',
    token: '"',
    description:
      '<span class="left-block">검색어 완전 일치</span> <span class="right"><span class="blue">"검색어" <span class="or">또는</span> <span class="sentence">"문장"</span></span></span><span class="ex">예) "항소를 기각한다"</span>',
    regex: [`\"([^/"]+)\"`],
    isMatch(key: string, value, commands): boolean {
      if (commands.find((it) => value.endsWith(it.completion))) return false;
      const min = Math.min(key.length, this.completion.length);
      return this.completion.substring(0, min) === key.substring(0, min);
    },
    resultDescription: (value, last) => {
      if (last)
        return `${joinWithJosa(
          value,
          '이가',
          '<span class="search-term">"',
          '"</span>',
        )} 정확하게 일치한`;
      return `${joinWithJosa(
        value,
        '이가',
        '<span class="search-term">"',
        '"</span>',
      )} 정확하게 일치하고`;
    },
  },
  {
    order: 2,
    hide: true,
    supports: ['case'],
    type: 'caseno',
    regex: [getCaseNoRegex()],
    isMatch(): boolean {
      return false;
    },
    resultDescription: (value, last) => {
      if (last)
        return `사건번호 또는 연관된 사건번호가 ${joinWithJosa(
          value,
          '와과',
          '<span class="search-term">',
          '</span>',
        )} 일치한`;
      return `사건번호 또는 연관된 사건번호가 ${joinWithJosa(
        value,
        '와과',
        '<span class="search-term">',
        '</span>',
      )} 일치하고`;
    },
  },

  {
    order: 10,
    name: '-제외어',
    type: 'exclude',
    description:
      '<span class="left-block">제외 검색어 적용</span> <span class="right"><span class="blue">검색어&nbsp;&nbsp;&nbsp; -단어</span></span> <span class="ex">예) 소유권 -양도</span>',
    completion: ' -',
    token: '-',
    preserveKeyword: true,
    regex: [` \-(${blockWithoutDash})`],
    highlightOffset: 1,
    isMatch(key: string, value, commands): boolean {
      if (commands.find((it) => value.endsWith(it.completion))) return false;
      const token = value.replace('-', '').trim();
      if (!token || token == '"') {
        return false;
      }
      return new RegExp(this.regex[0]).test(key);
    },
    resultDescription: (value, last) => {
      if (last) return `${joinWithJosa(value, '을를')} 제외한`;
      return `${joinWithJosa(value, '을를')} 제외하고`;
    },
  },
  // {
  //     type: 'court',
  //     completion: '@법원 ',
  //     description: '<span class="left-block">법원명에서 검색<span class="ex">예) @법원 대법원</span></span> <span class="right"><b>@법원</b> 검색어 <span class="or">또는</span> <span class=sentence>"문장"<<//span>',
  //     regex: blockOrQuote('법원'),
  //     isMatch(key: string): boolean {
  //         const min = Math.min(key.length, this.completion.length);
  //         return this.completion.substring(0, min) === key.substring(0, min);
  //     }
  // },
  // {
  //     type: 'interval',
  //     completion: '/',
  //     description: '<b class="function interval">/n [검색어 검색어]</b> <span>두 검색어는 n개 단어 이내에 등장 해야함</span>',
  //     regex: [`\/(\\d ${block} ${block})`],
  // },
];

export function joinWithJosa(
  value: string[],
  arg1?: string,
  prefix = '<span class="search-term">',
  suffix = '</span>',
) {
  return (
    value.map((it) => prefix + it + suffix) +
    (arg1 ? josa[arg1](value.last()) : '')
  );
}
