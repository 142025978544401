import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'removeReserveTags',
})
export class RemoveReserveTagsPipe implements PipeTransform {
  transform(value: string): string {
    return value
      .replace(/굵게\((.+)\)/g, '$1')
      .replace(/아래첨자\((.+)\)/g, '$1')
      .replace(/위첨자\((.+)\)/g, '$1')
      .replace(/강조점\((.+)\)/g, '$1')
      .replace(/취소선\((.+)\)/g, '$1')
      .replace(/밑줄\((.+)\)/g, '$1')

      .replace(/굵게\{(.+)\}/g, '$1')
      .replace(/아래첨자\{(.+)\}/g, '$1')
      .replace(/위첨자\{(.+)\}/g, '$1')
      .replace(/강조점\{(.+)\}/g, '$1')
      .replace(/취소선\{(.+)\}/g, '$1')
      .replace(/밑줄\{(.+)\}/g, '$1')

      .replace(/굵게\[(.+)\]/g, '$1')
      .replace(/아래첨자\[(.+)\]/g, '$1')
      .replace(/위첨자\[(.+)\]/g, '$1')
      .replace(/강조점\[(.+)\]/g, '$1')
      .replace(/취소선\[(.+)\]/g, '$1')
      .replace(/밑줄\[(.+)\]/g, '$1')

      .replace(/굵게\(/g, '')
      .replace(/아래첨자\(/g, '')
      .replace(/위첨자\(/g, '')
      .replace(/강조점\(/g, '')
      .replace(/취소선\(/g, '')
      .replace(/밑줄\(/g, '')

      .replace(/굵게\{/g, '')
      .replace(/아래첨자\{/g, '')
      .replace(/위첨자\{/g, '')
      .replace(/강조점\{/g, '')
      .replace(/취소선\{/g, '')
      .replace(/밑줄\{/g, '')

      .replace(/굵게\[/g, '')
      .replace(/아래첨자\[/g, '')
      .replace(/위첨자\[/g, '')
      .replace(/강조점\[/g, '')
      .replace(/취소선\[/g, '')
      .replace(/밑줄\[/g, '');
  }
}
