<div class="empty" *ngIf="!dateLabels?.length">내역이 없습니다.</div>
<div *ngFor="let dateLabel of dateLabels; let i = index" class="daybox">
  <div class="day" *ngIf="dayLabel(dateLabel) !== dayLabel(dateLabels[i - 1])">
    {{ dayLabel(dateLabel) }}
  </div>
  <ul class="logs">
    <li *ngFor="let record of sortByDay[dateLabel] | orderBy: 'id':itemDesc">
      <ng-container
        *ngTemplateOutlet="itemTemplate; context: { record: record }"
      >
      </ng-container>
    </li>
  </ul>
</div>
