import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'optionName',
})
export class OptionNamePipe implements PipeTransform {
  transform(value, name?) {
    switch (value) {
      case '1':
      case '3':
      case '5':
        return `최근 ${value}년`;
      case 'RECENT_DESC':
        return '선고일순';
      case 'CITED_DESC':
        return '인용순';
      case 'VIEWS_DESC':
        return '조회수순';
      case 'UPLOADED_DESC':
        return '입수일순';
      case 'EFFECT_DESC':
        return '시행일순';
      case 'PROCLAMATION_DESC':
        return '공포일순';
      case 'DATE_DESC':
        return '발행일순';
      default:
        return name || '관련도순';
    }
  }
}
