<ng-container *showItSizes="{ max: 959 }">
  <div
    fxLayout="row"
    fxLayoutAlign="start center"
    class="header relative-position"
    style="z-index: 1"
  >
    <div trackId="ee60"  (click)="back()"><span class="icon3 n-33 invert mt--4"></span></div>
    <div fxFlex="auto"></div>
    <ng-content></ng-content>
  </div>
  <div *ngIf="name" class="name">{{ name }}</div>
</ng-container>
