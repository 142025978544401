import { Component, OnInit } from '@angular/core';
import { DialogOrBottomSheetProto } from '../../alert/alert.service';
import { UserService } from '../../user/user.service';

export const lqaDistricts = {
  '서울·경기권':
    '서울 의정부 남양주 고양 인천 부천 수원 성남 여주 평택 안산 안양'.split(
      ' ',
    ),
  강원권: '춘천 강릉 원주 속초 영월'.split(' '),
  충청권: '대전 홍성 공주 논산 서산 천안 청주 충주 제천 영동'.split(' '),
  경상권:
    '대구 안동 경주 포항 김천 상주 의성 영덕 부산 울산 창원 마산 통영 밀양 거창 진주'.split(
      ' ',
    ),
  전라권: '광주 목포 장흥 순천 해남 전주 군산 정읍 남원'.split(' '),
  제주: ['제주'],
};

@Component({
  selector: 'lb-lqa-district-setting',
  templateUrl: './lqa-district-setting.component.html',
  styleUrls: ['./lqa-district-setting.component.sass'],
})
export class LqaDistrictSettingComponent
  extends DialogOrBottomSheetProto<any>
  implements OnInit
{
  ngOnInit(): void {
    this.value = JSON.parse(
      JSON.stringify(
        this.injector.get(UserService).lboxUser?.lboxUserData?.lqaDistricts ||
          [],
      ),
    );
  }

  value = [];

  districts = lqaDistricts;

  toggle(district: string) {
    if (this.includes(district)) {
      this.value.remove(district);
    } else {
      this.value.push(district);
    }
  }

  includes(district: string) {
    return this.value.includes(district);
  }

  toggleAll(district: string) {
    if (this.allSelected(district)) {
      this.value.removeAll(
        this.districts[district].filter((it) => this.value.includes(it)),
      );
      return;
    }
    this.value.pushAll(
      this.districts[district].filter((it) => !this.value.includes(it)),
    );
  }

  allSelected(district: string) {
    return !this.districts[district].find((it) => !this.value.includes(it));
  }

  save() {
    if (!this.value.length) {
      this.alert.pop('1개 이상의 관심지역을 선택해주세요');
      return;
    }
    this.api.lboxUser.updateForLqaDistricts(this.value).subscribe((v) => {
      this.alert.pop('관심 지역이 업데이트 되었습니다.');
      this.injector.get(UserService).lboxUser = v;
      this.close();
    });
  }
}
