import { NgModule } from '@angular/core';
import { HttpAuthInterceptor } from './http-auth-interceptor';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpProcessInterceptor } from './http-process.interceptor';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { AlertModule } from '../alert/alert.module';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { LoadingBarComponent } from './loading-bar/loading-bar.component';
import { MatSelectModule } from '@angular/material/select';
import { FormsModule } from '@angular/forms';
import { CommonModule, DecimalPipe } from '@angular/common';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { TrackModule } from '../track/track.module';
import { MatButtonModule } from '@angular/material/button';
import { HttpWorkInterceptor } from './http-work-interceptor';
import { LottieModule } from 'ngx-lottie';
import { CountdownModule } from 'ngx-countdown';
import { NgxMaskModule } from 'ngx-mask';
import { TrackEditComponent } from './track-edit/track-edit.component';
import { SmsVerifyComponent } from './sms-verify/sms-verify.component';
import { LinkDirectivesModule } from './link-directives/link-directives.module';
import { LawyerVerifyByUploadComponent } from './lawyer-verify-by-upload/lawyer-verify-by-upload.component';
import { LawyerVerifyDialogV2Component } from './lawyer-verify-dialog-v2/lawyer-verify-dialog-v2.component';
import { MatCheckboxModule } from '@angular/material/checkbox';

export function playerFactory() {
  return import(/* webpackChunkName: 'lottie-web' */ 'lottie-web');
}

@NgModule({
  imports: [
    AlertModule,
    MatAutocompleteModule,
    MatCheckboxModule,
    MatSelectModule,
    FormsModule,
    CommonModule,
    TrackModule,
    MatButtonModule,
    LottieModule.forRoot({ player: playerFactory }),
    CountdownModule,
    NgxMaskModule,
    LinkDirectivesModule,
  ],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'ko-KR' },
    { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { float: 'never' } },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpProcessInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpAuthInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpWorkInterceptor,
      multi: true,
    },
    DecimalPipe,
    LawyerVerifyByUploadComponent,
  ],
  declarations: [
    LoadingBarComponent,
    TrackEditComponent,
    SmsVerifyComponent,
    LawyerVerifyDialogV2Component,
  ],
  exports: [LoadingBarComponent, TrackEditComponent],
})
export class CoreModule {}
