/* tslint:disable */
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ApiHttp} from '../api.http';

import {CalendarMemo} from '../api.model';

@Injectable()
export class CalendarMemoController {
  constructor(private http: ApiHttp) {
  }

  deleteCalendarMemo(id: number): Observable<void> {
    return this.http.delete<void>('/api/calendarMemo', {id: id});
  }

  getCalendarMemoList(id: number): Observable<CalendarMemo[]> {
    return this.http.get<CalendarMemo[]>('/api/calendarMemo/list', {id: id});
  }

  saveCalendarMemo(calendarMemo: CalendarMemo): Observable<CalendarMemo> {
    return this.http.post<CalendarMemo>('/api/calendarMemo', calendarMemo);
  }
}
