/* tslint:disable */
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ApiHttp} from '../api.http';

import {CaseNameGroup, DeleteResult} from '../api.model';

@Injectable()
export class CaseNameGroupController {
  constructor(private http: ApiHttp) {
  }

  delete(body: CaseNameGroup): Observable<DeleteResult> {
    return this.http.post<DeleteResult>('/api/caseNameGroup/delete', body);
  }

  getAll(): Observable<CaseNameGroup[]> {
    return this.http.get<CaseNameGroup[]>('/api/caseNameGroup');
  }

  save(body: CaseNameGroup): Observable<CaseNameGroup> {
    return this.http.post<CaseNameGroup>('/api/caseNameGroup', body);
  }
}
