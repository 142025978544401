/* tslint:disable */
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ApiHttp} from '../api.http';

import {} from '../api.model';

@Injectable()
export class EmailVerifyController {
  constructor(private http: ApiHttp) {
  }

  passwordReset(email: string, code: string, password: string): Observable<void> {
    return this.http.post<void>('/api/emailVerify/password/reset', null, {email: email, code: code, password: password});
  }

  passwordResetEmailRequest(email: string, work?: boolean): Observable<void> {
    return this.http.post<void>('/api/emailVerify/password/reset/requestEmail', null, {email: email, work: work});
  }

  requestCodeEmailLbox(email: string): Observable<void> {
    return this.http.post<void>('/api/emailVerify/requestCodeEmail/lbox', null, {email: email});
  }

  requestCodeEmailLworks(email: string): Observable<void> {
    return this.http.post<void>('/api/emailVerify/requestCodeEmail/lworks', null, {email: email});
  }
}
