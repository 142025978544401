import { NgModule } from '@angular/core';
import { RouterModule, Routes, UrlSegment } from '@angular/router';
import { NotFoundComponent } from './not-found/not-found.component';
import { ZendeskLoaderGuard } from './core/zendesk-loader-guard.service';
import { RecaptchaComponent } from './recaptcha/recaptcha/recaptcha.component';
import {
  LoginAndRoleRequiredGuard,
  LoginRequiredGuard,
} from './core/login-required-guard.service';
import { ManagerGuard } from './organization/manager.guard';
import { RedirectHomeGuard } from './substitute/redirect-home.guard';
import { RedirectStatuteGuard } from './redirect-statute.guard';
import { KakaoGuard } from './core/kakao-guard.service';

const routes: Routes = [
  {
    path: '',
    canActivateChild: [KakaoGuard],
    children: [
      {
        path: '',
        canActivate: [RedirectHomeGuard],
        component: RedirectHomeGuard,
      },
      {
        path: 'v2',
        canActivate: [RedirectHomeGuard],
        component: RedirectHomeGuard,
      },
      {
        path: 'statute/:id/:date',
        canActivate: [RedirectStatuteGuard],
        component: RedirectStatuteGuard,
      },
      {
        path: 'not_found',
        component: NotFoundComponent,
        data: {
          meta: {
            title: 'LBOX - 판례 검색은 엘박스에서!',
            description:
              '국내 최대 법률 데이터베이스 엘박스에서 원하는 판결문을 자유롭게 검색해보세요.',
          },
        },
      },
      {
        path: 'recaptcha',
        component: RecaptchaComponent,
      },
      {
        path: 'recaptcha/robot',
        component: RecaptchaComponent,
      },
      {
        path: 'statute',
        loadChildren: () =>
          import('./statute/statute.module').then((mod) => mod.StatuteModule),
      },
      {
        path: 'commentary',
        loadChildren: () =>
          import('./commentary/commentary.module').then(
            (mod) => mod.CommentaryModule,
          ),
        data: { tag: '법령 페이지' },
      },
      {
        path: 'event',
        loadChildren: () =>
          import('./event/event.module').then((mod) => mod.EventModule),
      },
      {
        path: 'lqa',
        loadChildren: () =>
          import('./lqa/lqa.module').then((mod) => mod.LqaModule),
      },
      {
        path: 'bookmark',
        loadChildren: () =>
          import('./bookmark/bookmark.module').then(
            (mod) => mod.BookmarkModule,
          ),
      },
      {
        path: 'user',
        loadChildren: () =>
          import('./user/user.module').then((mod) => mod.UserModule),
        canActivate: [ZendeskLoaderGuard],
        data: {
          meta: {
            title: 'LBOX - 판례 검색은 엘박스에서!',
            description:
              '국내 최대 법률 데이터베이스 엘박스에서 원하는 판결문을 자유롭게 검색해보세요.',
          },
        },
      },
      {
        path: 'rule',
        loadChildren: () =>
          import('./rule/rule.module').then((mod) => mod.RuleModule),
        canActivate: [ZendeskLoaderGuard],
        data: {
          meta: {
            title: 'LBOX - 판례 검색은 엘박스에서!',
            description:
              '국내 최대 법률 데이터베이스 엘박스에서 원하는 판결문을 자유롭게 검색해보세요.',
          },
        },
      },
      {
        path: 'register',
        loadChildren: () =>
          import('./register/register.module').then(
            (mod) => mod.RegisterModule,
          ),
        canActivate: [ZendeskLoaderGuard],
        data: {
          meta: {
            title: 'LBOX - 판례 검색은 엘박스에서!',
            description:
              '국내 최대 법률 데이터베이스 엘박스에서 원하는 판결문을 자유롭게 검색해보세요.',
          },
        },
      },
      {
        path: 'substitute',
        loadChildren: () =>
          import('./substitute/substitute.module').then(
            (mod) => mod.SubstituteModule,
          ),
      },
      {
        path: 'org',
        loadChildren: () =>
          import('./organization/organization.module').then(
            (mod) => mod.OrganizationModule,
          ),
        canActivate: [ZendeskLoaderGuard, LoginRequiredGuard, ManagerGuard],
      },
      {
        path: 'admin',
        loadChildren: () =>
          import('./admin/admin.module').then((mod) => mod.AdminModule),
        canActivate: [LoginAndRoleRequiredGuard],
      },
      {
        path: 'work/admin',
        loadChildren: () =>
          import('./admin/admin.module').then((mod) => mod.AdminWorkModule),
        canActivate: [LoginAndRoleRequiredGuard],
      },
      {
        path: 'judge',
        loadChildren: () =>
          import('./judge/judge.module').then((mod) => mod.JudgeModule),
      },
      {
        path: 'judge-edit',
        loadChildren: () =>
          import('./judge-edit/judge-edit.module').then(
            (mod) => mod.JudgeEditModule,
          ),
        canActivate: [LoginAndRoleRequiredGuard],
      },
      {
        path: 'reference',
        loadChildren: () =>
          import('./reference/reference.module').then(
            (mod) => mod.ReferenceModule,
          ),
        canActivate: [ZendeskLoaderGuard],
      },
      {
        path: '',
        matcher: (url: UrlSegment[]) => {
          if (!url.length) {
            return { consumed: url };
          }
          if (url.length == 1 || url.length == 2) {
            const path = url[0].path;
            if (path == 'search') {
              return { consumed: [] };
            }
          }
          return null;
        },
        loadChildren: () =>
          import('./search/search.module').then((mod) => mod.SearchModule),
        canActivate: [ZendeskLoaderGuard],
      },
      {
        path: 'product/payment',
        loadChildren: () =>
          import('./lbox-product/lbox-product.module').then(
            (mod) => mod.LboxProductModule,
          ),
        canActivate: [ZendeskLoaderGuard],
      },
      {
        path: '',
        loadChildren: () =>
          import('./case/case.module').then((mod) => mod.CaseModule),
      },
      {
        path: '**',
        redirectTo: 'not_found',
      },
    ],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      onSameUrlNavigation: 'reload',
      initialNavigation: 'enabledBlocking',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
