import {
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { AlertService } from '../../alert/alert.service';
import {
  checkIsFileImageType,
  checkIsFileSmallerThan,
} from '../../user/dialogs/law-school-authorize/util';
import { WarningLawSchoolAuthorizationComponent } from 'src/app/warning-law-school-authorization/warning-law-school-authorization.component';

@Component({
  selector: 'lb-upload-area-for-law-school-authorization',
  templateUrl: './upload-area-for-law-school-authorization.component.html',
  styleUrls: ['./upload-area-for-law-school-authorization.component.sass'],
})
export class UploadAreaForLawSchoolAuthorizationComponent {
  constructor(private alertService: AlertService) {}

  @HostBinding('class.dragover')
  get hostClass() {
    return this.isDraggingOver;
  }

  @ViewChild('file', { static: false })
  fileRef: ElementRef;

  /** 업로드 허용 확장자 목록 */
  @Input()
  accept: string | undefined;

  /** 파일 다중 업로드 가능 여부 */
  @Input()
  multiple = true;

  @Output()
  filesChange = new EventEmitter();

  @Output()
  uploaded = new EventEmitter();

  @Output()
  onDragOverChange = new EventEmitter();

  /** 드래그해서 호버한 상태 */
  isDraggingOver = false;
  /** 드래그한 파일들 */
  draggedFileList: FileList | null = null;

  private preventAndStop(e: Event) {
    e.preventDefault();
    e.stopPropagation();
  }

  async checkWarning() {
    return await this.alertService.open(
      WarningLawSchoolAuthorizationComponent,
      { fileRef: this.fileRef, draggedFileList: this.draggedFileList },
      { width: '392px' },
      { id: 'warning-law-school-authorize' },
      true,
    );
  }

  handleClick() {
    void this.checkWarning();
  }

  handleDragOver(e: DragEvent) {
    this.preventAndStop(e);

    this.isDraggingOver = true;
    this.onDragOverChange.emit(true);
  }

  handleDragLeave(e: DragEvent) {
    this.preventAndStop(e);

    this.isDraggingOver = false;
    this.onDragOverChange.emit(false);
  }

  async handleDrop(e: DragEvent) {
    this.preventAndStop(e);

    // checkWarning 모달이 켰다 꺼지면 이벤트 객체가 사라져 있어서
    // 모달을 켜기 전에 드래그한 파일 목록을 미리 draggedFileList 멤버 변수에 저장함
    this.draggedFileList = e.dataTransfer.files;

    if (!Array.from(this.draggedFileList).every(checkIsFileImageType)) {
      this.alertService.pop('이미지 형식이 아닌 파일은 업로드 할 수 없습니다');
      this.draggedFileList = null;
      this.isDraggingOver = false;
      return;
    }

    if (
      !Array.from(this.draggedFileList).every((file) =>
        checkIsFileSmallerThan(file, 500),
      )
    ) {
      void this.alertService.alert(
        '첨부할 수 있는 파일의 최대 용량은 500MB 입니다.',
      );
      this.draggedFileList = null;
      this.isDraggingOver = false;
      return;
    }

    const isWarningChecked = await this.checkWarning();
    if (!isWarningChecked) {
      return;
    }

    this.filesChange.emit({
      fileList: this.draggedFileList,
      eventType: 'drag',
    });

    this.isDraggingOver = false;
  }
}
