<div class="title alert-dialog">
  <h5 class="mb-12">관심지역 설정</h5>
  <div class="desc">
    관심지역의 질문자로부터 답변을 요청 받으실 수 있으며, 요청이 접수되면 카카오톡 알림을 받으실 수 있습니다. <br />
    관심지역은 알림 설정 페이지에서 변경 하실 수 있습니다.
  </div>
</div>
<div
  class="districts"
  *ngFor="
    let district of [
      '서울·경기권',
      '강원권',
      '충청권',
      '경상권',
      '전라권',
      '제주'
    ]
  "
>
  <h5>{{ district }}</h5>
  <div
    class="district"
    [class.selected]="allSelected(district)"
    *ngIf="districts[district].length > 1"
    trackId="a9de"  (click)="toggleAll(district)"
  >
    {{ district }} 전체
  </div>
  <div
    class="district"
    [class.selected]="includes(v)"
    *ngFor="let v of districts[district]"
    trackId="6654"  (click)="toggle(v)"
  >
    {{ v }}
  </div>
</div>
<div class="buttons">
  <button
    class="blue"
    [class.disabled]="!value?.length"
    trackId="9956"  (click)="save()"
    style="pointer-events: initial"
  >
    설정하기
  </button>
</div>
