import {
  AfterViewInit,
  Component,
  Directive,
  HostListener,
  Inject,
  Injectable,
  Injector,
  OnDestroy,
  OnInit,
  Optional,
  PLATFORM_ID,
  ViewChild,
} from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
} from '@angular/material/dialog';
import {
  MatSnackBar,
  MAT_SNACK_BAR_DATA,
  MatSnackBarConfig,
} from '@angular/material/snack-bar';
import {
  MAT_BOTTOM_SHEET_DATA,
  MatBottomSheet,
  MatBottomSheetRef,
} from '@angular/material/bottom-sheet';
import { ApiService } from '../../../ngxSpring/api.service';
import { DOCUMENT, isPlatformServer } from '@angular/common';
import { josa } from '../core/josa.util';
import { later } from '../core/util';
import { LocalStorageService } from '../core/local-storage.service';
import { firstValueFrom } from 'rxjs';
import { UserpilotService } from '../shared/userpilot.service';
import { TrackingService } from '../core/tracking.service';
import { Router } from '@angular/router';
import { LboxComponentsService } from 'src/app/shared/lbox-components.service';

export class PromptParameter {
  placeholder?: string;
  name?;
  value?;
  permitEmpty?;
  action?: { name; action };
  validator?;
  readonly?;
  type?;
  style?;
  errorMessage?;
}

@Injectable()
export class AlertService {
  constructor(
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    public bottomSheet: MatBottomSheet,
    @Inject(PLATFORM_ID) private platform,
  ) {}

  select(options: any[], title, description?, placeholder?, ok?, cancel?) {
    return this.open(SelectComponent, {
      options,
      title,
      ok,
      description,
      cancel,
      placeholder,
    });
  }

  // 페이지 중앙에 확인 버튼과 함께 제목/내용 표시되는 알림
  alert(
    message: any,
    ok?,
    description?,
    conf?,
    dialogConf?,
    dialogWhenBottomSheetOpened?,
  ) {
    if (!message) {
      return;
    }
    if (!description) {
      description = message;
      message = '알림';
    }
    return this.open(
      AlertComponent,
      { message, ok, description, cancel: false },
      conf,
      dialogConf || { maxWidth: '450px' },
      dialogWhenBottomSheetOpened,
    );
  }

  imageAlert(image: any, description?, ok?, conf?, cancel: any = false) {
    return this.open(
      ImageAlertComponent,
      {
        message: image,
        ok,
        description,
        cancel: cancel,
      },
      conf,
      { maxWidth: '450px' },
    );
  }

  confirm(
    message: any,
    ok?,
    description?,
    cancel?,
    conf?,
    dialogConf?,
    trackInfo?,
  ) {
    if (!message) {
      return;
    }
    return this.open(
      AlertComponent,
      { message, ok, description, cancel, trackInfo },
      conf,
      { maxWidth: '450px', ...dialogConf },
    );
  }

  deleteConfirm(message: any, ok?, description?, cancel?) {
    if (!message) {
      return;
    }
    return this.open(
      DeleteAlertComponent,
      { message, ok, description, cancel },
      null,
      { maxWidth: '450px' },
    );
  }

  // 페이지 하단에서 반투명 검정색 상자에 흰 글씨로 잠시동안 표시되는 알림
  pop(message, duration?, action?, conf?) {
    if (!message) {
      return;
    }
    if (isPlatformServer(this.platform)) {
      return;
    }
    return this.snackBar
      .open(message, action, {
        duration: duration || 3000,
        verticalPosition: this.bottomSheet._openedBottomSheetRef ? 'top' : null,
        ...conf,
      })
      .onAction();
  }

  // 커스텀 컴포넌트를 팝업으로 띄우는 함수
  popFromComponent<D>(component, conf?: MatSnackBarConfig<D>) {
    if (isPlatformServer(this.platform)) {
      return;
    }

    return this.snackBar
      .openFromComponent(component, {
        verticalPosition: this.bottomSheet._openedBottomSheetRef ? 'top' : null,
        duration: conf?.duration || 3000,
        panelClass: 'v2-snackbar-custom-class',
        ...conf,
      })
      .onAction()
      .subscribe(() => {
        this.snackBar.dismiss();
      });
  }

  dismissSnackbar() {
    this.snackBar?.dismiss();
  }

  // 페이지 상단에서 노란 테두리의 말풍선 상자가 표시되는 알림
  arrowAlert(message) {
    this.dialog.open(ArrowAlertComponent, {
      backdropClass: 'transparent',
      width: '100%',
      panelClass: 'arrowBox',
      maxWidth: 'initial',
      data: message,
      position: { top: '0', left: '0', right: '0' },
    });
  }

  prompt(
    values: PromptParameter | PromptParameter[],
    title,
    okText?,
    cancelText?,
    description?,
    fn?: (v) => Promise<any>,
    dialogConf?,
    dialogWhenBottomSheetOpened?,
  ) {
    const v: any = !(values as any).length ? [values] : values;
    v.forEach((it) => {
      it.value = it.value || '';
    });
    return this.open(
      PromptComponent,
      {
        title,
        description,
        okText,
        cancelText,
        values: v,
        exe: fn,
        conf: dialogConf,
      },
      { disableClose: true },
      dialogConf,
      dialogWhenBottomSheetOpened,
    );
  }

  open(component, data?, conf?, dialogConf?, dialogWhenBottomSheetOpened?) {
    const config = { panelClass: 'no-padding', data: data };
    if (conf) {
      Object.keys(conf).forEach((it) => (config[it] = conf[it]));
    }
    if (window.innerWidth >= (conf?.breakPoint || 768)) {
      return firstValueFrom(
        this.dialog.open(component, { ...config, ...dialogConf }).afterClosed(),
      );
    }
    if (dialogWhenBottomSheetOpened && this.bottomSheet._openedBottomSheetRef) {
      return firstValueFrom(
        this.dialog
          .open(component, { ...config, ...dialogConf, maxWidth: '100vw' })
          .afterClosed(),
      );
    }
    return firstValueFrom(
      this.bottomSheet.open(component, config).afterDismissed(),
    );
  }

  async checkOpen(url: string) {
    const w = window.open(url);
    if (w) {
      return w;
    }
    const v = await this.confirm(
      '팝업이 차단되어 있습니다. <br> 팝업을 허용해주세요.',
      '현재창에서 열기',
    );
    if (v) {
      location.href = url;
      return window;
    }
    return w;
  }

  close() {
    this.dialog.closeAll();
    this.bottomSheet.dismiss();
  }

  /** 우측 상단에 표시되는 제안 알림 */
  suggest(
    description,
    ok?,
    cancel?,
    transparentType?,
    conf?,
    withoutCheck?,
    later?,
  ) {
    if (this.dialog.openDialogs.length) {
      return;
    }
    if (this.bottomSheet._openedBottomSheetRef) {
      return;
    }
    return this.open(
      SuggestAlert,
      { ok, description, cancel, transparentType, withoutCheck, later },
      { disableClose: true, ...conf },
      {
        maxWidth: '410px',
        hasBackdrop: false,
        position: {
          right: '20px',
          top: '70px',
        },
      },
    );
  }

  /** 하단 표시되는 제안 */
  bottomSuggest({
    description,
    ok,
    cancel = false,
    conf,
  }: {
    description?: string;
    ok?: string;
    cancel?: string | false;
    conf?: MatDialogConfig;
  }) {
    if (this.dialog.openDialogs.length) {
      return;
    }
    if (this.bottomSheet._openedBottomSheetRef) {
      return;
    }
    return this.open(
      BottomSuggestAlert,
      { ok, description, cancel },
      { disableClose: true, ...conf },
      {
        maxWidth: '730px',
        hasBackdrop: false,
        position: {
          bottom: '32px',
        },
      },
    );
  }
}

@Directive({ selector: 'lb-dialog-or-bottom-sheet-proto' })
export class DialogOrBottomSheetProto<T> {
  public readonly ref: MatDialogRef<any> | MatBottomSheetRef;
  public data: T;

  public constructor(
    @Optional() ref1: MatDialogRef<any>,
    @Optional() ref2: MatBottomSheetRef,
    @Optional() @Inject(MAT_DIALOG_DATA) data1?: T,
    @Optional() @Inject(MAT_BOTTOM_SHEET_DATA) data2?: T,
    protected api?: ApiService,
    protected userpilotService?: UserpilotService,
    public alert?: AlertService,
    public injector?: Injector,
    protected trackingService?: TrackingService,
    protected router?: Router,
  ) {
    this.ref = ref1 || ref2;
    this.data = data1 || data2;
  }

  close(v?) {
    if (this.ref instanceof MatDialogRef) {
      this.ref.close(v);
    }
    if (this.ref instanceof MatBottomSheetRef) {
      this.ref.dismiss(v);
    }
  }

  addEventOnAfterClosed(callback: VoidFunction) {
    if (this.ref instanceof MatDialogRef) {
      this.ref.afterClosed().subscribe(() => callback());
    }
  }
}

@Component({
  selector: 'lb-prompt',
  templateUrl: './prompt/prompt.component.html',
  styleUrls: ['./prompt/prompt.component.sass'],
})
export class PromptComponent
  extends DialogOrBottomSheetProto<{
    title;
    description;
    okText;
    cancelText;
    values: PromptParameter[];
    exe: (v) => Promise<any>;
    conf;
  }>
  implements AfterViewInit, OnDestroy
{
  error: boolean;

  get dataValues() {
    return this.data.values.filter((it) => it.type != 'tip');
  }

  async ok() {
    const empty = this.dataValues.find((v) => !v.value && !v.permitEmpty);
    if (empty) {
      if (empty.name || empty.placeholder)
        this.alert.pop(
          `${empty.name || empty.placeholder}${josa['을를'](
            empty.name || empty.placeholder,
          )} 입력해주세요.`,
        );
      else this.alert.pop(`값을 입력해주세요.`);
      return;
    }
    const values = this.dataValues.map((it) => it.value);
    if (this.data.exe && !(await this.data.exe(values))) {
      return;
    }
    if (values.length == 1) {
      this.close(values[0]);
      return;
    }
    this.close(values);
  }

  @ViewChild('input')
  input;

  get isDisabled() {
    return this.dataValues.find((it) => {
      if (it.validator) {
        return !it.validator(it.value);
      }
      if (!it.permitEmpty) {
        return !it.value;
      }
    });
  }

  @HostListener('keydown', ['$event'])
  async enter($event) {
    if ($event.key === 'Enter') {
      $event.preventDefault();
      $event.stopPropagation();
      await this.ok();
    }
  }

  ngAfterViewInit(): void {
    this.input?.nativeElement?.focus();
    document.scrollingElement.classList.add('overflow-hidden');
  }

  ngOnDestroy(): void {
    document.scrollingElement.classList.remove('overflow-hidden');
  }

  hasError(d: PromptParameter) {
    return d.validator && d.value && !d.validator(d.value);
  }
}

@Component({
  selector: 'lb-alert',
  templateUrl: './alert/alert.component.html',
  styleUrls: ['./alert/alert.component.sass'],
})
export class AlertComponent extends DialogOrBottomSheetProto<{
  message;
  ok;
  description;
  cancel;
  trackInfo;
}> {
  @HostListener('keydown', ['$event'])
  enter($event) {
    if ($event.key === 'Enter') {
      $event.preventDefault();
      $event.stopPropagation();
      this.close(true);
    }
  }

  get cancelButtonTrackId(): string {
    return this.data?.trackInfo?.cancelButtonTrackId ?? '';
  }

  get okButtonTrackId(): string {
    return this.data?.trackInfo?.okButtonTrackId ?? '';
  }
}

@Component({
  selector: 'lb-image-alert',
  templateUrl: './image-alert/alert.component.html',
  styleUrls: ['./image-alert/alert.component.sass'],
})
export class ImageAlertComponent extends AlertComponent {}

@Component({
  selector: 'lb-delete-alert',
  templateUrl: './delete-alert/delete-alert.component.html',
  styleUrls: ['./delete-alert/delete-alert.component.sass'],
})
export class DeleteAlertComponent extends DialogOrBottomSheetProto<{
  message;
  ok;
  description;
  cancel;
}> implements OnInit {
  ngOnInit(): void {
    if (this.data.message === '결제 취소') {
      this.injector.get(LboxComponentsService).importAgreedPlanPricingAlert()
    }
  }
}

@Component({
  selector: 'lb-select',
  templateUrl: './select/select.component.html',
  styleUrls: ['./select/select.component.sass'],
})
export class SelectComponent extends DialogOrBottomSheetProto<{
  cancel;
  options: any[];
  title;
  description;
  ok;
  alert;
  placeholder;
}> {
  option;

  @HostListener('keydown', ['$event'])
  enter($event) {
    if ($event.key === 'Enter') {
      $event.preventDefault();
      $event.stopPropagation();
      this.close(this.option);
    }
  }

  select(option: any) {
    if (!option) {
      this.alert.pop(this.data.alert || '옵션을 선택해주세요.');
      return;
    }
    if (option && option.etc && !option.value) {
      this.alert.pop(this.data.alert || '사유를 입력해주세요.');
      return;
    }
    this.close(option);
  }
}

@Component({
  selector: 'lb-arrow-alert',
  templateUrl: './arrow-alert/arrow-alert.component.html',
  styleUrls: ['./arrow-alert/arrow-alert.component.sass'],
})
export class ArrowAlertComponent {
  constructor(
    private ref: MatDialogRef<ArrowAlertComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
  ) {}

  close() {
    this.ref.close();
  }
}

@Component({
  selector: 'lb-suggest-alert',
  templateUrl: './suggest/suggest.component.html',
  styleUrls: ['./suggest/suggest.component.sass'],
})
export class SuggestAlert
  extends DialogOrBottomSheetProto<{
    message;
    ok;
    description;
    cancel;
    transparentType;
    withoutCheck;
    later;
  }>
  implements OnInit, OnDestroy, AfterViewInit
{
  later = this.data.later ?? later;

  ngOnInit(): void {
    this.injector.get(DOCUMENT).body.classList.add('hide-suggestion');
  }

  ngOnDestroy(): void {
    this.injector.get(DOCUMENT).body.classList.remove('hide-suggestion');
  }

  ngAfterViewInit(): void {
    this.injector
      .get(DOCUMENT)
      .querySelector('.cdk-global-scrollblock')
      ?.classList.remove('cdk-global-scrollblock');
  }
}

@Component({
  selector: 'lb-bottom-suggest-alert',
  templateUrl: './bottom-suggest/bottom-suggest.component.html',
  styleUrls: ['./bottom-suggest/bottom-suggest.component.sass'],
})
export class BottomSuggestAlert extends DialogOrBottomSheetProto<{
  ok;
  description;
  cancel;
}> {}

@Component({
  selector: 'lb-tutiral',
  templateUrl: './tutiral/tutiral.component.html',
  styleUrls: ['./tutiral/tutiral.component.sass'],
})
export class TutiralComponent extends DialogOrBottomSheetProto<any> {
  get nowStep() {
    return this.data.steps[this.index];
  }

  index = 0;

  get steps() {
    return this.data.steps.filter((it) => !it.cover);
  }

  next() {
    this.index++;
  }

  prev() {
    this.index--;
  }

  done() {
    this.close();
    this.injector.get(LocalStorageService).setItem(this.data.key, 'true');
  }
}

interface CustomSnackBarData {
  type: 'success' | 'error';
  message: string;
}

@Component({
  templateUrl: './v2-snackbar/v2-snackbar.component.html',
  styleUrls: ['./v2-snackbar/v2-snackbar.component.sass'],
})
export class V2SnackbarComponent {
  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: CustomSnackBarData) {}
}
