import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'lb-report-tooltip',
  templateUrl: './report-tooltip.component.html',
  styleUrls: ['./report-tooltip.component.sass'],
})
export class ReportTooltipComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
