<div fxLayout fxLayoutAlign="space-between center" class="mb-36">
  <h4 *showItSizes="{ min: 960 }">{{ label || '전체 기간' }}</h4>
  <div class="sort" *showItSizes="{ min: 960 }">
    {{ tag }} 날짜
    <button mat-icon-button trackId="8abf" (click)="sorted(); desc = !desc">
      <span
        class="icon3 n-67"
        style="margin: 0 auto"
        [ngClass]="{ desc: desc }"
      ></span>
    </button>
  </div>
  <div *showItSizes="{ max: 959 }"></div>
  <div
    class="select-option-box"
    style="width: 115px"
    *showItSizes="{ max: 959 }"
  >
    <mat-select [(ngModel)]="desc" (valueChange)="sorted()">
      <mat-option [value]="false">{{ tag }} 날짜 순</mat-option>
      <mat-option [value]="true">{{ tag }} 날짜 역순</mat-option>
    </mat-select>
  </div>
</div>
<div class="empty" *ngIf="!dateLabels?.length">{{ ifEmpty }}</div>
<div *ngFor="let dateLabel of dateLabels; let i = index" class="daybox">
  <div
    class="month"
    *ngIf="
      !labelIncludes('월') &&
      monthLabel(dateLabel) != monthLabel(dateLabels[i - 1])
    "
  >
    {{ monthLabel(dateLabel) }}
  </div>
  <div class="day" *ngIf="!labelIncludes('일')">
    {{ dayLabel(dateLabel) }}
  </div>
  <div class="records">
    <div
      *ngFor="let record of sortByDay[dateLabel]"
      class="record"
      fxLayout="row"
      fxLayout.lt-sm="column"
      [attr.data-case-id]="record.caseId"
    >
      <span class="time" fxFlex="none">{{
        record.date | date: 'hh:mm a'
      }}</span>
      <span class="keyword" fxFlex="none" [matTooltip]="record.data.searchKeyword" matTooltipClass="v2-tooltip">
        {{ record.data.searchKeyword }}
      </span>
      <span class="link" fxLayout="row" fxFlex="auto">
        <a
          class="record-title"
          trackId="retitle"
          [caseLink]="record.caseId"
          [innerHTML]="record.title || record.caseId | keywordBold: keyword"
        ></a>
        <span *ngIf="record.archiveCase">
          <a
            class="icon3 n-80 ml-6 mr-2"
            trackId="8dd0"
            [routerLink]="
              '/bookmark/' +
              (record.archiveCase?.folderId ? record.archiveCase?.folderId : '')
            "
          ></a>
        </span>
      </span>
      <div fxFlex="none" class="ml-8">
        <ng-container *ngTemplateOutlet="template; context: { item: record }">
        </ng-container>
      </div>
    </div>
  </div>
</div>
