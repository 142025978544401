<div class="title">
  <h5>{{ data.title || '가입한 계정으로 로그인' }}</h5>
</div>
<div class="input-wrapper">
  <lb-sns-login
    [snsEmail]="sns.email"
    [beforeLoginLogout]="true"
    [mustExist]="true"
    *ngFor="let sns of data.snsAuthList"
    type="로그인"
    [only]="sns.type"
  ></lb-sns-login>
  <div *ngIf="data.email">
    <input
      placeholder="이메일"
      class="mb-16"
      readonly
      [(ngModel)]="data.email"
    />
    <input placeholder="패스워드" type="password" [(ngModel)]="data.password" />
    <div
      class="reset-password"
      trackId="9c9c"
      *ngIf="data.certificate"
      (click)="passwordResetWithToken()"
    >
      비밀번호 재설정
    </div>
  </div>
</div>
<div class="buttons mt-24">
  <button class="cancel" trackId="557c" (click)="close()">취소</button>
  <button class="blue" *ngIf="data.email" trackId="bda2" (click)="login()">
    로그인
  </button>
</div>
