<div class="title alert-dialog pb-0">
  <div
    [innerHTML]="data.description | safeHtml"
    *ngIf="data.description"
    class="desc mt-0"
  ></div>
  <mat-checkbox class="mt-32" *ngIf="!data.withoutCheck"
    >{{ later }}일 동안 보지 않기
  </mat-checkbox>
</div>
<div class="buttons" *ngIf="!data.transparentType">
  <button
    class="cancel"
    trackId="5a32"
    (click)="close(null)"
    *ngIf="data.cancel != false"
    [innerHTML]="data.cancel || '취소'"
  ></button>
  <button
    class="blue"
    trackId="c277"
    (click)="close(true)"
    [innerHTML]="data.ok || '확인'"
  ></button>
</div>
<div class="transparent-type" *ngIf="data.transparentType">
  <button
    trackId="9237"
    (click)="close(true)"
    [innerHTML]="data.ok || '확인'"
  ></button>
  <button
    class="cancel"
    trackId="bc95"
    (click)="close(null)"
    *ngIf="data.cancel != false"
    [innerHTML]="data.cancel || '취소'"
  ></button>
</div>
