/* tslint:disable */
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ApiHttp} from '../api.http';

import {CaseDetailReference, DBpiaSearchQuery, DBpiaSearchResult, Dbpia, KciSearchQuery, KciSearchResult, Reference} from '../api.model';

@Injectable()
export class ReferenceController {
  constructor(private http: ApiHttp) {
  }

  caseDetailReference(keyword: string): Observable<CaseDetailReference> {
    return this.http.get<CaseDetailReference>('/api/reference/caseDetailReference', {keyword: keyword});
  }

  getDbpia(node_id: string): Observable<Dbpia> {
    return this.http.get<Dbpia>('/api/reference/dbpia', {node_id: node_id});
  }

  searchDBpia(searchQuery: DBpiaSearchQuery): Observable<DBpiaSearchResult> {
    return this.http.post<DBpiaSearchResult>('/api/reference/dbpia/search', searchQuery);
  }

  searchKci(searchQuery: KciSearchQuery): Observable<KciSearchResult> {
    return this.http.post<KciSearchResult>('/api/reference/kci/search', searchQuery);
  }

  searchNaver(keyword: string): Observable<Reference[]> {
    return this.http.get<Reference[]>('/api/reference/naver/search', {keyword: keyword});
  }
}
