import type { JobDetails } from '../../../ngxSpring/api.model';

const JOB_KOR = {
  법조인: '법조인',
  법률전문직: '법률 전문직',
  법률사무직: '법률 사무직',
  공무원: '공무원',
  법학전문대학원생: '법학전문대학원생',
  기타: '기타(직접입력)',
} as const;

export const JOB_CODE = {
  LEGAL_PROFESSIONAL: {
    JOB_1010: {
      label: '변호사',
      value: 'JOB_1010',
    },
    JOB_1020: {
      label: '판사',
      value: 'JOB_1020',
    },
    JOB_1030: {
      label: '검사',
      value: 'JOB_1030',
    },
    JOB_1040: {
      label: '재판연구원',
      value: 'JOB_1040',
    },
    JOB_1050: {
      label: '군법무관',
      value: 'JOB_1050',
    },
    JOB_1060: {
      label: '공익법무관',
      value: 'JOB_1060',
    },
  },
  LEGAL_SPECIALIST: {
    JOB_2010: {
      label: '손해사정사',
      value: 'JOB_2010',
    },
    JOB_2020: {
      label: '공인노무사',
      value: 'JOB_2020',
    },
    JOB_2030: {
      label: '관세사',
      value: 'JOB_2030',
    },
    JOB_2040: {
      label: '변리사',
      value: 'JOB_2040',
    },
    JOB_2050: {
      label: '법무사',
      value: 'JOB_2050',
    },
    JOB_2060: {
      label: '세무사',
      value: 'JOB_2060',
    },
    JOB_2070: {
      label: '행정사',
      value: 'JOB_2070',
    },
    JOB_2080: {
      label: '공인회계사',
      value: 'JOB_2080',
    },
    JOB_2090: {
      label: '감정평가사',
      value: 'JOB_2090',
    },
  },
  LEGAL_OFFICER: {
    JOB_3030: {
      label: '법률사무원(법무법인 등 근무자)',
      value: 'JOB_3030',
    },
    JOB_3010: {
      label: '손해사정사 보조인',
      value: 'JOB_3010',
    },
    JOB_3020: {
      label: '기업 사내법무팀 임직원',
      value: 'JOB_3020',
    },
    JOB_3040: {
      label: '그 외 법률 관련 사무 종사자',
      value: 'JOB_3040',
    },
  },
  LAW_SCHOOL_STUDENT: {
    JOB_4010: {
      label: '법학전문대학원생',
      value: 'JOB_4010',
    },
  },
  CIVIL_SERVANT: {
    JOB_5010: {
      label: '공무원',
      value: 'JOB_5010',
    },
  },
  OTHER: {
    JOB_9999: {
      label: '기타(직접입력)',
      value: 'JOB_9999',
    },
  },
};

export const convertJobDetailsToLogProperty = (jobDetails: JobDetails) => {
  if (jobDetails.category === 'OTHER') {
    return {
      job: JOB_KOR.기타,
    };
  }

  if (jobDetails.category === 'CIVIL_SERVANT') {
    return {
      job: JOB_KOR.공무원,
    };
  }

  if (jobDetails.category === 'LAW_SCHOOL_STUDENT') {
    return {
      job: JOB_KOR.법학전문대학원생,
    };
  }

  if (jobDetails.category === 'LEGAL_OFFICER') {
    return {
      job: JOB_CODE.LEGAL_OFFICER[jobDetails.jobCode].label,
    };
  }

  if (jobDetails.category === 'LEGAL_SPECIALIST') {
    return {
      job: JOB_CODE.LEGAL_SPECIALIST[jobDetails.jobCode].label,
    };
  }

  if (jobDetails.jobCode === 'JOB_1010') {
    return {
      job: JOB_CODE.LEGAL_PROFESSIONAL[jobDetails.jobCode].label,
    };
  }

  return {
    job: JOB_CODE.LEGAL_PROFESSIONAL[jobDetails.jobCode].label,
  };
};
