/* tslint:disable */
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ApiHttp} from '../api.http';

import {Company, CompanyContract, CompanyInfo, CompanyPlanUser} from '../api.model';

@Injectable()
export class CompanyManagerController {
  constructor(private http: ApiHttp) {
  }

  deleteCompanyPlanUser(idList: any[]): Observable<void> {
    return this.http.delete<void>('/api/company/manager', {idList: idList});
  }

  getCompanyInfo(): Observable<CompanyInfo> {
    return this.http.get<CompanyInfo>('/api/company/manager');
  }

  makeLink(id: number): Observable<CompanyContract> {
    return this.http.post<CompanyContract>('/api/company/manager/code', null, {id: id});
  }

  registerByLink(code: string): Observable<string> {
    return this.http.post<string>('/api/company/manager/registerByLink', null, {code: code}, 'text');
  }

  saveCompanyPlanUser(companyPlanUser: CompanyPlanUser): Observable<CompanyPlanUser> {
    return this.http.post<CompanyPlanUser>('/api/company/manager', companyPlanUser);
  }

  saveCompanyPlanUserList(companyPlanUsers: any[], contractId: number): Observable<CompanyPlanUser[]> {
    return this.http.post<CompanyPlanUser[]>('/api/company/manager/list/v2', companyPlanUsers, { contractId });
  }

  updateSetting(useRestrict?: boolean, domain?: string): Observable<Company> {
    return this.http.post<Company>('/api/company/manager/updateSetting', null, {useRestrict: useRestrict, domain: domain});
  }
}
