import { EventEmitter, Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { ApiService } from '../../../ngxSpring/api.service';
import { AlertService } from '../alert/alert.service';
import { TrackService } from '../track/track.service';
import { ArchiveCaseMoveDialogComponent } from '../shared/archive-case-move-dialog/archive-case-move-dialog.component';
import { ArchiveFolder } from '../../../ngxSpring/api.model';
import { CopyService } from '../core/copy.service';
import { Router } from '@angular/router';
import { isPlatformServer } from '@angular/common';
import { UserService } from '../user/user.service';
import { firstValueFrom } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class FolderService {
  folderUpdated = new EventEmitter();
  folderRemoved = new EventEmitter();
  selectionChange = new EventEmitter();
  folderUnlinked = new EventEmitter();
  rootSelected = false;
  folder;
  sharedFolderIdList;
  history;

  constructor(
    private api: ApiService,
    private alert: AlertService,
    private ga: TrackService,
    private copyService: CopyService,
    private router: Router,
    private userService: UserService,
    @Inject(PLATFORM_ID) private platform,
  ) {
    if (isPlatformServer(this.platform)) {
      return;
    }
    api.archiveFolder.sharedIdList().subscribe((v) => {
      this.sharedFolderIdList = v;
    });
  }

  async updateName(folder) {
    const v = await this.alert.prompt(
      { placeholder: '폴더명', value: folder.name },
      '이름 변경',
      '변경하기',
    );
    if (!v) {
      return;
    }
    await firstValueFrom(this.api.archiveFolder.updateFolder(folder.id, v));
    folder.name = v;
    this.alert.pop('폴더이름이 변경되었습니다.');
    this.folderUpdated.emit(folder);
  }

  deleteFolder(folder, parent) {
    return new Promise((r) => {
      void this.alert
        .deleteConfirm(
          '폴더 삭제',
          `삭제하기`,
          `삭제된 폴더/파일은 복구가 불가능합니다. <br>그래도 삭제하시겠습니까?`,
        )
        .then((v) => {
          if (!v) {
            return;
          }
          this.api.archiveFolder
            .deleteArchiveFolder(folder.id)
            .subscribe(() => {
              this.folderUpdated.emit(parent);
              this.alert.pop('삭제되었습니다.');
              r(true);
            });
        });
    });
  }

  async selectFolder(now: ArchiveFolder) {
    const folders = await firstValueFrom(
      this.api.archiveFolder.getArchiveFolderList(null, true),
    );
    return await this.alert.open(ArchiveCaseMoveDialogComponent, {
      folders: folders.filter((it) => it.id && it.id != now.id),
      folder: now,
    });
  }

  shareInPlan(folder: ArchiveFolder) {
    if (folder.membershipShared) {
      this.copyLink(folder);
      return;
    }
    this.api.archiveFolder.shareInPlan(folder.id).subscribe((v) => {
      folder.membershipShared = v;
      this.folderUpdated.emit(folder);
      this.sharedFolderIdList.push(folder?.id);
      this.copyLink(folder);
    });
  }

  shareOffInPlan(folder: ArchiveFolder) {
    void this.alert
      .deleteConfirm(
        '공유 해제',
        `해제하기`,
        `공유를 해제하면 공유받은 분들은 더 이상 폴더를 열람할 수 없습니다. 공유를 해제하시겠습니까?`,
      )
      .then((v) => {
        if (!v) return;
        this.api.archiveFolder.shareInPlan(folder.id).subscribe((v) => {
          folder.membershipShared = v;
          this.sharedFolderIdList.remove(folder.id);
          this.folderUpdated.emit(folder);
          this.alert.pop('공유가 해제되었습니다.');
          this.copyLink(folder);
        });
      });
  }

  copyLink(folder: ArchiveFolder) {
    this.copyService.copy(
      location.origin + '/bookmark/' + folder.id,
      '공유링크가 복사되었습니다.',
      '공유링크를 복사해서 사용하세요.',
    );
  }

  unlinkFolder(folder: ArchiveFolder) {
    void this.alert
      .deleteConfirm(
        '연결 해제',
        `해제`,
        `${folder.shareUserName}님께서 공유하신 폴더 연결을 해제하시겠습니까??`,
      )
      .then((v) => {
        if (!v) {
          return;
        }
        this.api.sharedFolder.deleteSharedFolder(folder.id).subscribe(() => {
          this.folderUnlinked.emit(folder);
          this.alert.pop('연결이 해제되었습니다.');
          void this.router.navigateByUrl('/bookmark');
        });
      });
  }

  isSharedFolder(folder: ArchiveFolder) {
    if (!this.userService.isCompanyPlan) return false;
    if (this.userService.user?.id != folder.userId) return true;
    if (folder.membershipShared) return true;
    if (
      folder.parents.find((id) => {
        return this.sharedFolderIdList.includes(id);
      })
    )
      return true;
    return false;
  }

  isSharedRootFolder(folder: ArchiveFolder) {
    return !folder.parents.find((id) => {
      return this.sharedFolderIdList.includes(id);
    });
  }

  /**
   * 비즈니스 플랜 기능이지만, 비영리단체에서는 사용하지 못함
   * @returns 판례보관함 폴더 공유하기 기능 표시 여부
   */
  showsFolderShareButton() {
    return !this.userService.isPublicDomain && this.userService.isCompanyPlan;
  }
}
