/* tslint:disable */
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ApiHttp} from '../api.http';

import {} from '../api.model';

@Injectable()
export class EmailController {
  constructor(private http: ApiHttp) {
  }

}
