export const sessionStorageKeys = {
  NON_PROFIT_ORG_INFO: 'nonProfitOrgInfo',
};

export const localStorageKeys = {
  TOP_BANNER_NOT_SHOWN_UNTIL: 'topBannerNotShownUntil',
  SHOULD_DISPLAY_LAW_SCHOOL_AUTHORIZATION:
    'shouldDisplayLawSchoolAuthorization',
  CURRENT_PAGE_PATH: 'currentPagePath',
  PREV_PAGE_PATH: 'prevPagePath',
  CASE_CALENDAR_HIDE_END_CASE_TOGGLE: 'caseCalendarHideEndCaseToggle',
};

export const LAW_SCHOOL_VERIFICATION_CHANNEL = {
  요금제_페이지: '요금제 페이지',
  마이페이지: '마이페이지',
  회원가입_페이지: '회원가입 페이지',
  판례_상세_페이지: '판례 상세 페이지',
  임시_노출: '(임시) 로스쿨 인증 업데이트로 계정별 1회 강제 노출',
};
