/* tslint:disable */

export interface IamportCertificate {
  birth: number
  birthday: string
  carrier: string
  certified: boolean
  certified_at: number
  foreigner: boolean
  gender: string
  imp_uid: string
  merchant_uid: string
  name: string
  origin: string
  pg_provider: string
  pg_tid: string
  phone: string
  unique_in_site: string
  unique_key: string
}

export interface Certificate {
  certificate: IamportCertificate
  exists: boolean
  email: string
  snsAuthList: SnsAuth[]
}

export interface IamportPayment extends CreateTable {
  user: User
  cardName: string
  cardNumber: string
}

export interface LboxProduct extends IdTable {
  name: string
  amount: number
  paid: IamportPaymentLog
}

export interface UploadMyCaseParams {
  filename: string
  size: number
  contentType: string
}

export interface UploadParams {
  path: string
  size: number
  contentType: string
  ext: string
  src: string
}

export interface CompanyPlanInquiry {
  name: string
  company: string
  email: string
  content: string
}

export interface Holiday {
  dateKind: number
  dateName: string
  locdate: string
  holiday: boolean
}

export interface CaseAndRelations {
  case: Case
  relations: CaseRelations
  raw: boolean
  saved: boolean
  highlight: HighlightObject
  stats: string[]
  limitType: LimitType
  involve: boolean
}

export interface CaseInfo {
  caseId: string
  title: string
  itemOrder: number
  caseState: CaseState
  date: Date
  data: any
}

export interface CaseRelations {
  doc_id: string
  quote_list: DocIdAndResult[]
  disposed_list: DisposeAndDisposed[]
  disposed_by: string
  fairtrade_list: FairTradeDocument[]
  caseref_list: DocIdAndResult[]
  splited_list: DocIdAndResult[]
  confirmed: string
  lower_list: DocIdAndResult[]
}

export interface SearchResultInBookmark {
  cases: ArchiveCaseInfo[]
  count: number
}

export interface CaseStats {
  id: string
  date: Date
  first: number
  second: number
  third: number
  ccourt: number
  total_count: number
  total_added_count: number
}

export interface Judge {
  role: string
  serial: string
  name: string
}

export interface JudgeList {
  list: JudgeWithCaseList[]
  count: number
}

export interface ReferentialSearch {
  referencedSearchQuery: SearchQuery
  innerSearchQuery: SearchQuery
}

export interface RelativeSearch {
  query: string
  fromDocIdList: string[]
  searchQuery: SearchQuery
}

export interface NewsResult {
  newsList: NewsSummary[]
  count: number
  correctedQuery: string
  aggregations: any
  hasResult: boolean
  correctQuery: boolean
}

export interface ReferenceResult {
  referenceList: Reference[]
  count: number
  correctedQuery: string
  aggregations: any
  hasResult: boolean
  correctQuery: boolean
}

export interface SearchInDocIdList {
  docIdList: string[]
  searchQuery: SearchQuery
}

export interface SearchQuery {
  query: string
  page: number
  size: number
  caseType: string
  casename: string
  courtType: string
  trialType: string
  court: string
  data: string
  range: string
  result: string
  reference: string
  orderBy: OrderType
  queryElements: QueryElement[]
  preventCorrection: boolean
}

export interface SearchResult {
  caseList: CaseSummary[]
  count: number
  correctedQuery: string
  aggregations: any
  hasResult: boolean
  correctQuery: boolean
}

export interface SearchResultAndRelation {
  result: SearchResult
  relations: CaseRelations[]
}

export interface SearchResultAndRelationForMap {
  caseList: CaseSummary[]
  result: SearchResult
  relations: CaseRelations[]
}

export interface SearchMapParams {
  preventCorrection: boolean
  queryElements: QueryElement[]
  query: string
  // optional
  caseType: string | null
  data: string | null
  orderBy: OrderType | null
  page: number | null
  range: string | null
  result: string | null
  size: number | null
  trialType: string | null
}

export interface SearchMapCase {
  id: string
  courtType: string
  announce_date: Date
  score: number
  size: number
  top?: number
  left?: number
}

export interface SearchMapRelationsParams {
  docId: string
  searchQuery: SearchMapParams
}

export type SearchMapRelationsResponse = {
  case: CaseSummary
  relationType: SearchMapRelationType
}[]

export interface SearchMapRelatedCase extends CaseSummary {
  relationType: SearchMapRelationType
  line: any
  top?: number
  left?: number
  size?: number
}

export type SearchMapRelationType = 'LOWER' | 'QUOTE' | 'REFERENCE'

export interface TopHighlight {
  doc_id: string
  highlights: { [index: string]: any }
}

export interface ArchiveCase extends CreateTable {
  userId: number
  caseId: string
  folderId: number
  itemOrder: number
  copied: boolean
}

export interface ArchiveCaseInfo {
  id: number
  caseId: string
  title: string
  createdAt: Date
  order: number
  folder: ArchiveFolder
  searched: CaseSummary
  caseState: CaseState
  count: DocIdAndCount
  folderId: number
}

export interface ArchiveFolder extends CreateTable {
  contains: boolean | null;
  copied: boolean;
  count: number;
  folderCount: number;
  itemOrder: number | null;
  membershipShared: boolean;
  name: string;
  parent: number | null;
  parents: number[];
  shareUserName: string | null;
  userId: number;
  watchLater?: boolean;
}

export interface CaseNameGroup {
  id: string
  group_name: string
  casename_list: string[]
}

export interface CommentarySearchQuery {
  page: number
  size: number
  query: string
  queryElements: QueryElement[]
  commentaryTitle: string
  orderBy: ReferenceOrderType
  preventCorrection: boolean
}

export interface CommentarySearchResult {
  results: CommentarySummary[]
  count: number
  correctedQuery: string
  hasResult: boolean
  correctQuery: boolean
}

export interface HighlightObject {
  memos: MyHighlight[]
  sections: MyHighlight[][]
  fields: { [index: string]: MyHighlight[] }
}

export interface LinkData {
  colors: string[]
  includeMemo: boolean
  docId: string
  userId: number
}

export interface MyHighlight extends CreateUpdateTable {
  indexKey: string
  userId: number
  docId: string
  span: number[]
  className: string
  type: string
  text: string
  memo: string
  memoAt: Date
  title: string
  userName: string
}

export interface NewsSearchQuery {
  page: number
  size: number
  query: string
  queryElements: QueryElement[]
  originTypes: NewsOriginType[]
  orderBy: OrderType
}

export interface NewsSearchResult {
  results: NewsSummary[]
  count: number
  correctedQuery: string
  hasResult: boolean
  correctQuery: boolean
}

export interface ReadCase extends CreateTable {
  userId: number
  caseId: string
  shared: boolean
  printed: boolean
  highlighted: boolean
  caseState: CaseState
  memo: boolean
  title: string
}

export interface Dbpia {
  id: string
  node_id: string
  abstracts: { [index: string]: any }
  authors: DBpiaAuthor[]
  epage: string
  issue: { [index: string]: any }
  keywords: string[]
  main_title: { [index: string]: any }
  page_cnt: string
  price: string
  publication: { [index: string]: any }
  publisher: { [index: string]: any }
  spage: string
  sub_title: { [index: string]: any }
  toc: string
}

export interface Reference {
  title: string
  sub: string
  date: string
  link: string
  cases: Case[]
  hasPdf: boolean
}

export interface CaseDetailReference {
  dbpia: DBpiaSummary[]
  kci: KciSummary[]
  length: number
}

export interface DBpiaSearchQuery {
  page: number
  size: number
  query: string
  queryElements: QueryElement[]
  range: string
  orderBy: ReferenceOrderType
  preventCorrection: boolean
}

export interface DBpiaSearchResult {
  results: DBpiaSummary[]
  count: number
  correctedQuery: string
  hasResult: boolean
  correctQuery: boolean
}

export interface KciSearchQuery {
  page: number
  size: number
  query: string
  queryElements: QueryElement[]
  range: string
  orderBy: ReferenceOrderType
  preventCorrection: boolean
}

export interface KciSearchResult {
  results: KciSummary[]
  count: number
  correctedQuery: string
  hasResult: boolean
  correctQuery: boolean
}

export interface CaseReport extends CreateUpdateTable {
  userId: number
  caseId: string
  response: string
  state: CaseReportState
  updateUserId: number
  userName: string
  userEmail: string
}

export interface RequestCase extends CreateUpdateTable {
  userId: number
  timeTakenInMillisecond: number
  userName: string
  userEmail: string
  paid: boolean
  requestCaseId: string
  court: string
  state: RequestCaseState
  memo: string
  account: string
  docId: string
  hide: boolean
  ocrAlarmed: boolean
  updateUserId: number
}

export interface RequestCountStats {
  timestamp: Date
  date: string
  timeToGet: number
  allCount: number
  getCount: number
}

export interface RequestRecord extends CreateTable {
  ip: string
  docId: string
}

export interface Statute {
  id: string
  statute_fullname: string
  statute_name: string
  statute_abbrv: string
  statute_id: string
  effective_date: Date
  mst: string
  proclamation_date: Date
  proclamation_num: string
  bubjong: string
  revised_type: string
  jomun: { [index: string]: Jomun }
  buchic: { [index: string]: any }
  images: { [index: string]: any }
}

export interface StatuteSearchQuery {
  page: number
  size: number
  query: string
  queryElements: QueryElement[]
  searchType: StatuteSearchType
  bubjongString: string
  onlyCurrent: boolean
  range: string
  orderBy: StatuteOrderType
  preventCorrection: boolean
  bubjongTypes: BubjongType[]
}

export interface StatuteSearchResult {
  results: StatuteSummary[]
  count: number
  correctedQuery: string
  hasResult: boolean
  correctQuery: boolean
}

export interface CaseCount extends IdTable {
  realCount: number
  label: string
  dateLabel: string
  lbox: number
  casenote: number
  lawnb: number
  lboxRecent: number
  casenoteRecent: number
  lawnbRecent: number
  companies: string[]
}

export interface ClientTracking {
  clientId: string
  userId: number
  url: string
  event: any
  pageId: string
  eventId: string
  prevPageId: string
  prevEventId: string
  id: string
  date: Date
  userIp: string
}

export interface CopyLog {
  userId: number
  url: string
  content: string
  docId: string
  data: string
  id: string
  date: Date
}

export interface TrackId extends IdTable {
  trackId: string
  name: string
  data: { [index: string]: any }
  done: boolean
}

export interface LqaQuestion extends CreateUpdateTable {
  matchedList: MatchedLawyer[]
  matchedLawyer: MatchedLawyer
  userId: number
  email: string
  region: string
  regionCategory: string
  title: string
  background: string
  question1: string
  question2: string
  question3: string
  memo: string
  end: boolean
  files: FileDetail[]
}

export interface LboxUserAndMatched {
  lboxUser: LboxUser
  matchedCount: number
  matchedAt: Date
  involveCases: AttorneyCaseCandidateRelation[]
}

export interface LqaProfile {
  name: string
  lqaData: any
  authorizeInfo: string
  involves: AttorneyCaseCandidateRelation[]
  lqaOpenCases: boolean
}

export interface MatchedLawyer extends CreateUpdateTable {
  readReplyIndex: number
  newSection: ReadSection
  user: User
  userAssociation: string
  questionId: number
  lawyerId: number
  matchedReadAt: Date
  acceptedAt: Date
  rejectedAt: Date
  rejectAuto: boolean
  rejectAutoAlarmSent: boolean
  rejectReason: string
  additionalRequest: string
  additionalRequestAt: Date
  additionalRequestReadAt: Date
  additionalReplyId: number
  additionalReply: MatchedRecordReply
  background: string
  answer1: string
  answer2: string
  answer3: string
  answerWarnReadAt: Date
  answeredAt: Date
  answerRemainTimeAlarmSent: boolean
  answerReadAt: Date
  afterAnswerNoticeOrReply: boolean
  lawyerReplyCount: number
  lawyerReadReplyCount: number
  userReadReplyCount: number
  userReplyCount: number
  reviewScore: number
  reviewAt: Date
  review: string
  pointAdded: boolean
  additionalNotification: boolean
  thanksAt: Date
}

export interface MatchedRecordReply extends CreateTable {
  matchedLawyerRecordId: number
  writerId: number
  body: string
  readAt: Date
  newItem: boolean
  files: FileDetail[]
}

export interface PayInfo {
  freeTrialExtends: boolean
  plan: UserHasPlan
  payment: PricePlanPayment
  paymentType: PaymentType
  discountPolicy: 'MOU' | null
}

export interface Company extends CreateUpdateTable {
  name: string
  idNumber: string
  address: string
  managerName: string
  managerPhone: string
  managerEmail: string
  billManagerName: string
  billManagerPhone: string
  billManagerEmail: string
  useRestrict: boolean
  domain: string
}

export interface CompanyInfo {
  company: Company
  contracts: CompanyContractInfo[]
}

export interface CompanyContract extends IdTable {
  code: string
  receipt: string
  history: UserAdds[]
  startAt: Date
  endAt: Date
  size: number
  price: number
  name: string
  complete: boolean
  companyId: number
  memo: string
  files: string[]
  discountAmount: number
  discountRate: number
}

export interface CompanyPlanUser extends CreateUpdateTable {
  contract: CompanyContract
  email: string
  name: string
  code: string
  memo: string
  selected: boolean
  userExists: boolean
}

export interface LawSchoolAuthorization extends CreateUpdateTable {
  extend: boolean
  rejectReason: string
  user: User
  lboxUser: LboxUser
  userId: number
  files: string[]
  authorized: boolean
  authorizedAt: Date
  authorizeUser: number
  userHasPlan: UserHasPlan
}

export interface PricePlanPayment extends CreateTable {
  cardLimit: boolean
  planId: number
  userId: number
  user: User
  plan: UserHasPlan
  paid: IamportPaymentLog
  cancelable: boolean
  price: number
  point: number
  cancelled: boolean
  startDate: Date
  extendedDate: Date
  expiredAt: Date
  nextPayDate: Date
  originalPrice: number
  discountPrice: number
}

export interface RefundData {
  refundPoint: number
  cardName: string
  cardNumber: string
  amount: any
}

export interface UserHasPlan extends IdTable {
  companyPlan: string
  released: boolean
  memberShipReleaseTryCount: number
  originDate: Date
  referrerCode: string
  payment: PricePlanPayment
  paymentFailed: boolean
  failedChecked: boolean
  failMessage: string
  version: number
  userId: number
  plan: PricePlan
  startDate: Date
  originExpiredDate: Date
  expiredAt: Date
  nextPayDate: Date
  enabled: boolean
  cancelEnables: Date[]
  enables: Date[]
  uniqueKey: string
  used: string
  promotionType: PromotionType
  cancelable: boolean
  freeDays: number
  expired: boolean
  planName: string
}

export interface AttorneyProfile {
  id: string
  user_id: number
  name: string
  serial: string
  case_list: string[]
  basic_info: BasicInfo[]
  career: Career[]
  education: Education[]
  updated_date: Date
  bar_type: string
}

export interface JudgeEditCase extends CreateTable {
  court: string
  caseNo: string
  docId: string
  judges: string
  judge_found: string
  announce_date: Date
}

export interface JudgeProfile {
  id: string
  court: string
  branch: string
  name: string
  serial: string
  basic_info: BasicInfo[]
  career: Career[]
  court_list: Court[]
  education: Education[]
  role: string
  joins_id: number
  updated_date: Date
  bar_type: string
  court_type: string
  retired: boolean
}

export interface OpponentData {
  name: string
  association: string
  work: string
  phone: string
  email: string
}

export interface Substitute extends CreateUpdateTable {
  date: Date
  location: string
  type: SubstituteType
  user: User
  candidate: User
  candidatesAt: Date
  madeAt: Date
  state: SubstituteState
  data: { [index: string]: any }
  storage: string
  court: string
}

export interface RegisteredUserStatistics {
  registeredDate: Date
  count: number
}

export interface User extends CreateTable, Tokenize {
  withdrawAt: Date
  snsEmail: string
  snsType: SnsAuthType
  email: string
  role: UserRole
  password: string
  name: string
  lboxUserData: boolean
  loggedAt: Date
  data: { [index: string]: any }
  permissions: LboxPermission[]
}

export interface PasswordInfo {
  code: string
  newPassword: string
}

export interface UserRegisterInfo {
  user: User
  job: string[]
  cardnum: string
  inputnum: string
  snsType: SnsAuthType
  snsToken: string
}

export interface UserAlarm extends CreateUpdateTable {
  userId: number
  link: string
  alarmSubjectId: string
  linkText: string
  message: string
  readAt: Date
}

export interface CaseManage extends CreateUpdateTable {
  parent: number
  hasMemo: boolean
  jobIndex: number
  lastAlarmDate: Date
  userId: number
  court: string
  caseNo: string
  requestName: string
  events: CaseEvents
  auctionCase: AuctionCase
  sentEvents: string[]
  done: boolean
  name: string
  auction: boolean
  nextEvent: CaseEvent
  progressList: CaseEvent[]
  casename: string
}

export interface CaseManageData extends CreateUpdateTable {
  caseManage: CaseManage
  name: string
}

export interface GoogleCalendarPermission extends IdTable {
  userId: number
  accessToken: string
  expiresAt: Date
  refreshToken: string
  tokenRevoked: boolean
  googleEventIdMap: { [index: string]: string }
  googleCalendarId: string
  valid: boolean
}

export interface CalendarMemo extends CreateUpdateTable {
  caseManageId: number
  text: string
}

export interface AttorneyCaseCandidateRelation {
  id: string
  doc_id: string
  serial: string
  court: string
  lawfirm: string
  announce_date: number
  side: string
  title: string
}

export interface UserInvolveCase extends CreateTable {
  userId: number
  docId: string
  involved: boolean
}

export interface LawSubscribe extends CreateTable {
  userId: number
  lastCheckedAt: Date
  rawQuery: string
  hasNew: boolean
  searchQuery: SearchQuery
  summary: string
}

interface LegalProfessional {
  category: 'LEGAL_PROFESSIONAL'
  jobCode: 'JOB_1010' | 'JOB_1020' | 'JOB_1030' | 'JOB_1040' | 'JOB_1050' | 'JOB_1060'
  testName: '사법시험' | '변호사시험' | '군법무관 임용시험' 
  testCount: number
  party?: '강원지방변호사회' | '경기북부지방변호사회' | '경기중앙지방변호사회' | '경남지방변호사회' | '광주지방변호사회' | '대구지방변호사회' | '대전지방변호사회' | '부산지방변호사회' | '서울지방변호사회' | '울산지방변호사회' | '인천지방변호사회' | '전북지방변호사회' | '제주지방변호사회' | '충북지방변호사회' | '없음'
}

interface LegalSpecialist {
  category: 'LEGAL_SPECIALIST'
  jobCode: 'JOB_2010' | 'JOB_2020' | 'JOB_2030' | 'JOB_2040' | 'JOB_2050' | 'JOB_2060' | 'JOB_2070' | 'JOB_2080' | 'JOB_2090'
  acquireYear: number
}

interface LegalOfficer {
  category: 'LEGAL_OFFICER'
  jobCode: 'JOB_3030' | 'JOB_3010' | 'JOB_3020' | 'JOB_3040'
}

interface LawSchoolStudent {
  category: 'LAW_SCHOOL_STUDENT'
  jobCode: 'JOB_4010'
  university: '강원대학교'| 
  '건국대학교'|
  '경북대학교'| 
  '경희대학교'|
  '고려대학교'|
  '동아대학교'|
  '부산대학교'|
  '서강대학교'|
  '서울대학교'|
  '서울시립대학교'|
  '성균관대학교'|
  '아주대학교'|
  '연세대학교'|
  '영남대학교'|
  '원광대학교'|
  '이화여자대학교'|
  '인하대학교'|
  '전남대학교'|
  '전북대학교'|
  '제주대학교'|
  '중앙대학교'|
  '충남대학교'|
  '충북대학교'|
  '한국외국어대학교'|
  '한양대학교'
  admissionYear: number
  expectedGraduateYear: number
}

interface CivilServant {
  category: 'CIVIL_SERVANT'
  jobCode: 'JOB_5010'
  acquireYear: number
}

interface Other {
  category: 'OTHER'
  jobCode: 'JOB_9999'
  description: string
}

export type JobDetails = LegalProfessional | LegalSpecialist | LegalOfficer | LawSchoolStudent | CivilServant | Other


export interface LboxUser extends CreateUpdateTable {
  lawyerVerifiedAt: Date;
  newLawyerTarget: Date;
  pointAutoUse: boolean;
  user: User;
  memo: string;
  phone: string;
  uniqueKey: string;
  gender: string;
  birthday: string;
  userId: number;
  point: number;
  lqaOpenCases: boolean;
  substitute: boolean;
  substituteKakao: boolean;
  substituteBroswer: boolean;
  marketing: boolean;
  marketingAgreementUpdatedAt: string;
  marketingEmail: boolean;
  marketingKakao: boolean;
  marketingBroswer: boolean;
  marketingAppPush: boolean;
  caseDate: boolean;
  caseDateEmail: boolean;
  caseDateKakao: boolean;
  caseDateBroswer: boolean;
  caseDateAppPush: boolean;
  caseUpdate: boolean;
  caseUpdateEmail: boolean;
  caseUpdateKakao: boolean;
  caseUpdateBroswer: boolean;
  caseUpdateAppPush: boolean;
  subscribe: boolean;
  subscribeCaseEmail: boolean;
  subscribeCaseKakao: boolean;
  subscribeCaseBroswer: boolean;
  subscribeCaseAppPush: boolean;
  requestCase: boolean;
  requestCaseEmail: boolean;
  requestCaseKakao: boolean;
  requestCaseBroswer: boolean;
  requestCaseAppPush: boolean;
  myCaseRegister: boolean;
  myCaseRegisterEmail: boolean;
  myCaseRegisterKakao: boolean;
  myCaseRegisterBroswer: boolean;
  myCaseRegisterAppPush: boolean;
  reportSpecific: boolean;
  reportSpecificKakao: boolean;
  reportSpecificBroswer: boolean;
  reportSpecificAppPush: boolean;
  lqa: boolean;
  lqaKakao: boolean;
  lqaBroswer: boolean;
  lqaNewMapping: boolean;
  lqaNewMappingKakao: boolean;
  lqaNewMappingBroswer: boolean;
  job: string[];
  jobDetails: JobDetails;
  substituteDistricts: string[];
  lboxUserData: LboxUserData;
  substituteInfoRegisterAt: Date;
  lqaLawyerPermission: boolean;
  lawyerAuthorized: boolean;
  lawyerPassInfo: string;
  substitutePermission: boolean;
  lawyerPassYear: number;
  association: string;
  lawyer: boolean;
  lawyerVerified: boolean; // 변호사 자격증 인증 여부. 직업은 변호사가 아닐 수 있음.
}

export interface LqaData {
  work: string
  phones: string[]
  workAddressDetail: string
  workAddressObject: any
  licenseYear: string
  workAddress: string
  workEmail: string
  careers: RangeData[]
  schools: RangeData[]
}

export interface LawyerVerify extends IdTable {
  rejectReason: string
  user: User
  lboxUser: LboxUser
  userId: number
  files: string[]
  authorized: boolean
  authorizedAt: Date
  authorizeUser: number
  verifyType: VerifyType
}

export interface MyCaseRegister extends CreateTable {
  reason: string
  nameHide: boolean
  anonymizationDoneAt: Date
  rejectReason: string
  rejectedAt: Date
  approvedAt: Date
  fileName: string
  filePath: string
  docId: string
  userId: number
  state: MyCaseRegisterState
}

export interface PointLog extends CreateTable {
  userId: number
  pointRemain: number
  type: PointLogType
  data: string
  user: User
  dpoint: number
}

export interface UserProperties {
  userId: number
  userNthMembership: number
  userTotalPayment: number
}

export interface SnsAuth extends IdTable {
  user: User
  type: SnsAuthType
  idString: string
  email: string
}

export interface SnsLoginResult {
  user: User
  success: boolean
  email: string
  snsAuthList: SnsAuth[]
}

export interface UserToken extends CreateTable {
  deviceId: string
  userId: number
  deviceType: DeviceType
  devicePlatform: DevicePlatform
  lastCheckedAt: Date
  deviceName: string
  browser: string
  userAgent: string
  deviceToken: boolean
  resolvedDeviceId: string
}

export interface SmsVerify extends CreateTable {
  phone: string
  code: string
  verifiedAt: Date
}

export interface DeleteResult {
  deletedCount: number
}

export interface S3Signature extends Serializable {
  acl: string
  key: string
  'Content-Type': string
  Policy: string
  'X-Amz-Algorithm': string
  'X-Amz-Credential': string
  'X-Amz-Date': string
  'x-amz-meta-uuid': string
  'X-Amz-Signature': string
  Host: string
}

export interface Iterable<T> {}

export interface Page<T> extends Slice<T> {
  totalPages: number
  totalElements: number
}

export interface PageImpl<T> extends Chunk<T>, Page<T> {}

export interface ResponseEntity<T> extends HttpEntity<T> {
  statusCodeValue: number
  statusCode: HttpStatus
}

export interface StreamingResponseBody {}

export interface CreateTable extends IdTable {
  createdAt: Date
}

export interface IamportPaymentLog extends CreateUpdateTable {
  failed: boolean
  error: string
  refundResponse: { [index: string]: any }
  param: { [index: string]: any }
  response: { [index: string]: any }
  data: { [index: string]: string }
  userId: number
}

export interface IdTable {
  id: number
}

export interface Case {
  id: string
  doc_id_list: string[]
  doc_id: string
  announce_date: Date
  case: string
  casename_list: string[]
  caseno_list: string[]
  casetype: CaseType
  court: string
  footnote: { [index: string]: string }
  images: { [index: string]: string }
  tables: { [index: string]: string }
  issue: string
  judges: string
  judi: string
  origin: string
  reference: string
  summary: string
  sentence_type: string
  tree_id: ObjectId
  order: string
  conclusion: string
  result: string
  s3_urls: string[]
  updated_date: Date
  gjae: string
  footnote_caseref: { [index: string]: CaseRef[] }
  commentary_list: Commentary[]
  level: number
  en_banc: boolean
  noise_list: any
  restricted: boolean
  uploaded_date: Date
  deidentified_targets: { [index: string]: string }
  from_readable_pdf: boolean
  casename_groups: string[]
  evidence_list: string[]
  record_list: string[]
  separate_page_list: string[]
  facts: CaseIndexList
  limited: boolean
  section_list: Section[]
  casename: string
  title: string
  _panel: boolean
  issueName: string
  summaryName: string
  factType: string
}

export interface DocIdAndResult {
  doc_id: string
  result: string
  announce_date: string
  level: number
  disposed_by: any
  link: string
  revised_list: string[]
}

export interface DisposeAndDisposed {
  dispose: string
  disposed: string[]
}

export interface FairTradeDocument {
  doc_id: string
  caseno: string
  s3_url: string
}

export interface JudgeWithCaseList {
  caseList: string[]
  citedCount: number
  judge: JudgeProfile
}

export interface NewsSummary {
  serial: string
  title: string
  origin: NewsOriginType
  cases: Case[]
  court: string
  content: string
  time: string
  originName: string
  link: string
}

export interface QueryElement {
  type: QueryType
  value: string
}

export interface CaseSummary {
  id: string
  title: string
  sub_title: string
  result: string
  case_result: string
  content: string
  order: string
  relief: string
  reliefName: string
  disposed: boolean
  disposedRef: boolean
  score: number
  similarity: number
  section_index: number
  sent_indexes: number[]
  start_char_index: number
  courtType: string
  announce_date: Date
  origin: string
  quoted_count: number
  view_count: number
  judgeCitedCount: number
  confirmed_info: { [index: string]: any }
  read: boolean
  matchedQueries: string[]
  states: string[]
}

export interface DocIdAndCount {
  docId: string
  memoCount: number
  highlightCount: number
}

export interface CommentarySummary {
  commentaryTitle: string
  version: string
  source: string
  authorName: string
  part: string
  chapter: string
  section: string
  title: string
  snippet: string
  cleanedCommentaryTitle: string
  commentaryPath: string
}

export interface CreateUpdateTable extends CreateTable {
  updatedAt: Date
}

export interface DBpiaAuthor {
  roll: string
  name: string
}

export interface DBpiaSummary {
  node_id: string
  main_title: string
  sub_title: string
  publisher_name: string
  publication_name: string
  issue_name: string
  issue_date: string
  authors: DBpiaAuthor[]
  page_count: string
  snippet: string
}

export interface KciSummary {
  url: string
  main_title: string
  publisher_name: string
  publication_name: string
  issue_date: string
  authors: KciAuthor[]
  page_count: string
  snippet: string
  hasPdf: boolean
}

export interface Jomun {
  key: string
  title: string
  content: string
  hang: { [index: string]: Hang }
}

export interface StatuteSummary {
  name: string
  proclamation_date: string
  proclamation_num: string
  effective_date: string
  revised_type: string
  bubjong: string
  jomuns: JomunSummary[]
  fullname: string
  state: StatuteState
  _current: boolean
}

export interface FileDetail {
  path: string
  name: string
}

export interface CompanyContractInfo {
  contract: CompanyContract
  users: CompanyPlanUser[]
}

export interface UserAdds {
  date: Date
  size: number
}

export interface BasicInfo {
  subject: string
  content: string
}

export interface Career {
  career: string
  from: Date
  year: string
  to: Date
}

export interface Education {
  year: string
  institution: string
}

export interface Court {
  court: string
  from: Date
  to: Date
  year: string
  allowed_courts: string
}

export interface Tokenize {}

export interface CaseEvents {
  id: string
  progress_list: CaseEvent[]
  info_list: EventInfo[]
  updated_date: Date
  court: string
  caseno: string
  name: string
  casename: string
  case_result: string
  errmsg: string
  confirmation_date: string
  reception_date: Date
  merged_or_splited_case_list: CaseManageInfo[]
  uplo_case_list: CaseManageInfo[]
  related_case_list: CaseManageInfo[]
  etc_info_tables: any[]
  balance_tables: any[]
  nextEvent: CaseEvent
  _criminal: boolean
  comingDate: Date
}

export interface AuctionCase {
  id: string
  caseno: string
  court: string
  announce_date?: Date 
  appeal_table: RowAndColumns
  case_result: '기각' | '미종국' | '배당종결' | '취하'
  casename: string
  claim_amount: string
  date_table: RowAndColumns
  document_table: RowAndColumns
  etc_info_tables: RowAndColumns[]
  info_list: { [index: string]: any }[]
  involved_table: RowAndColumns
  item_tables: { [index: string]: any }[][]
  name: string
  reception_date: Date
  send_table: RowAndColumns
  updated_date: Date
  errmsg: string
  dateTable: RowAndColumns
}

export interface CaseEvent {
  type: string
  date: Date
  result: string
  content: string
  location: string
  forCalendar: boolean
  scheduleModified: boolean
  has_time: boolean
  manageId: number
}

export interface LboxUserData {
  lqaData: LqaData;
  association: string;
  passNo: string;
  passDue: string;
  lqaEnable: boolean;
  lqaDistricts: string[];
  work: string;
  lqaClientTargetUntil: boolean;
}

export interface RangeData {
  startYear: string
  endYear: string
  name: string
}

export interface Serializable {}

export interface Sort extends Streamable<Order>, Serializable {
  sorted: boolean
  unsorted: boolean
}

export interface Pageable {
  offset: number
  sort: Sort
  paged: boolean
  unpaged: boolean
  pageNumber: number
  pageSize: number
}

export interface ObjectId extends Comparable<ObjectId>, Serializable {
  timestamp: number
  counter: number
  time: number
  date: Date
  machineIdentifier: number
  processIdentifier: number
  timeSecond: number
}

export interface CaseRef {
  type: string
  text: string
  span: number[]
  value: CaseRefSummary
  source: string
}

export interface Commentary {
  title: string
  source: string
  doc_num: string
  page_span: number[]
  published_date: Date
  pdf_url: string
}

export interface CaseIndexList {
  casenames: CaseIndex[]
}

export interface Section {
  title: string
  text: string
  caseref_list: CaseRef[]
  statuteref_list: StatuteRef[]
  lawfirm_list: any[]
  attorney_list: any[]
  text_properties: { [index: string]: any[] }
  type: string
  checked: boolean
  index: number
  refList: any[]
}

export interface KciAuthor {
  institution: string
  name: string
}

export interface Hang {
  key: string
  content: string
  ho: { [index: string]: Ho }
  statuteref_list: any
}

export interface JomunSummary {
  title: string
  content: string
}

export interface EventInfo {
  title: string
  text: string
}

export interface CaseManageInfo {
  type: string
  caseno: string
  court: string
  date: string
}

export interface RowAndColumns {
  title: string
  columns: string[]
  rows: { [index: string]: string }[]
}

export interface Slice<T> extends Streamable<T> {
  number: number
  size: number
  content: T[]
  sort: Sort
  last: boolean
  first: boolean
  pageable: Pageable
  numberOfElements: number
}

export interface Chunk<T> extends Slice<T>, Serializable {}

export interface HttpEntity<T> {
  headers: { [index: string]: any }
  body: T
}

export interface CaseRefSummary {
  court: string
  announce_date: string
  caseno: string
}

export interface CaseIndex {
  casename: string
  sent_indexes: number[]
}

export interface StatuteRef {
  type: string
  text: string
  span: number[]
  value: StatuteData
}

export interface Ho {
  key: string
  content: string
  mok: { [index: string]: Mok }
  statuteref_list: any
}

export interface Streamable<T> extends Iterable<T>, Supplier<Stream<T>> {
  empty: boolean
}

export interface Order extends Serializable {
  direction: Direction
  property: string
  ignoreCase: boolean
  nullHandling: NullHandling
  descending: boolean
  ascending: boolean
}

export interface Comparable<T> {}

export interface StatuteData {
  statute_id: string
  jonum: string
  statute_name: string
}

export interface Mok {
  key: string
  content: string
  statuteref_list: any
}

export interface Supplier<T> {}

export interface Stream<T> extends BaseStream<T, Stream<T>> {}

export interface BaseStream<T, S> extends AutoCloseable {
  parallel: boolean
}

export interface AutoCloseable {}

export interface UserSettingsOption {
  state: 'ON' | 'OFF'
}

export interface NonProfitOrganizationInfo {
  name: string
  securityLevel: 'UPLOAD_AND_INPUT_DISABLE'
}

export interface VerifyLawyerRequest {
  licenseInfo?: {
    registrationNumber: string,
    issueNumber: string
  },
  lawyerInfo?: {
    testType: string,
    sequence: string,
    association: string,
  }
}

export interface UserBenefitApiResponse {
  numberOfCasesViewed: number
  numberOfRegisteredCaseManages: number
  numberOfCommentariesViewed: number
}

export type OrderType =
  | 'RECENT_DESC'
  | 'SCORE_DESC'
  | 'UPLOADED_DESC'
  | 'CITED_DESC'
  | 'VIEWS_DESC'

export type InteractType = 'COPY_URL' | 'HIGHLIGHT' | 'PRINT' | 'MEMO'

export type CaseReportState = 'Requested' | 'Modifying' | 'Done' | 'Unreadable'

export type RequestCaseState =
  | 'Requested'
  | 'Undecided'
  | 'Limited'
  | 'WrongCaseNo'
  | 'OnRemoveProcess'
  | 'Get'
  | 'Wasted'
  | 'Waiting'
  | 'Unreadable'
  | 'CounterClaim'
  | 'UnAble'
  | 'Merged'
  | 'Delayed'
  | 'Cancel'

export type PricePlan = 'NONE' | 'LAW_SCHOOL' | 'MEMBERSHIP'

export type PromotionType = 'Extend' | 'Discount'

export type SubstituteQueryType = 'NOT_DONE' | 'MY' | 'CANDIDATE'

export type SubstituteType = 'RECORD_COPY' | 'SUBSTITUTE'

export type AlarmMethodType = 'Email' | 'Kakao' | 'AppPush'

export type AlarmType =
  | 'marketing'
  | 'caseDate'
  | 'caseUpdate'
  | 'subscribeCase'
  | 'requestCase'
  | 'myCaseRegister'
  | 'substitute'
  | 'reportSpecific'
  | 'lqa'
  | 'lqaNewMapping'

export type MyCaseRegisterState =
  | 'Upload'
  | 'Anonymization'
  | 'AnonymizationDone'
  | 'Registered'
  | 'RejectByUser'
  | 'RejectByLbox'
  | 'RejectByLboxRegistering'
  | 'RejectByLboxExist'

export type PointLogType =
  | 'MY_CASE_REGISTER'
  | 'MEMBERSHIP_REFUND'
  | 'MEMBERSHIP'
  | 'REFERRER'
  | 'INVITER'
  | 'ADMIN'
  | 'NEW_LAWYER_CONGRATS'

export type PointQueryType = 'ALL' | 'PLUS' | 'MINUS'

export type SnsAuthType = 'KAKAO' | 'NAVER' | 'GOOGLE'

export type LimitType = 'NOT_LOGGED' | 'FREE_PLAN'

export type ReferenceOrderType = 'SCORE_DESC' | 'DATE_DESC'

export type NewsOriginType = 'legaltimes' | 'lawtimes' | 'joongang'

export type StatuteSearchType = 'NAME' | 'JOMUN'

export type StatuteOrderType =
  | 'SCORE_DESC'
  | 'EFFECT_DESC'
  | 'PROCLAMATION_DESC'

export type BubjongType = 'LAW' | 'PRECEDENT' | 'PRIME_MINISTER' | 'ETC'

export type ReadSection =
  | 'additionalRequest'
  | 'reply'
  | 'additionalRequestAnswer'
  | 'answer'

export type PaymentType = 'PAY_NOW' | 'EXTEND' | 'FREE_TRIAL'

export type SubstituteState = 'WAITING' | 'APPLY' | 'MADE' | 'DELETED'

export type UserRole = 'Admin' | 'Alba' | 'Skilled' | 'Trainee'

export type LboxPermission = 'Admin' | 'CaseEdit' | 'ExpressEdit'

export type VerifyType = 'FROM_10000_EVENT'

export type DeviceType = 'NORMAL' | 'MOBILE' | 'TABLET'

export type DevicePlatform = 'IOS' | 'ANDROID' | 'UNKNOWN'

export type HttpStatus =
  | 'CONTINUE'
  | 'SWITCHING_PROTOCOLS'
  | 'PROCESSING'
  | 'CHECKPOINT'
  | 'OK'
  | 'CREATED'
  | 'ACCEPTED'
  | 'NON_AUTHORITATIVE_INFORMATION'
  | 'NO_CONTENT'
  | 'RESET_CONTENT'
  | 'PARTIAL_CONTENT'
  | 'MULTI_STATUS'
  | 'ALREADY_REPORTED'
  | 'IM_USED'
  | 'MULTIPLE_CHOICES'
  | 'MOVED_PERMANENTLY'
  | 'FOUND'
  | 'MOVED_TEMPORARILY'
  | 'SEE_OTHER'
  | 'NOT_MODIFIED'
  | 'USE_PROXY'
  | 'TEMPORARY_REDIRECT'
  | 'PERMANENT_REDIRECT'
  | 'BAD_REQUEST'
  | 'UNAUTHORIZED'
  | 'PAYMENT_REQUIRED'
  | 'FORBIDDEN'
  | 'NOT_FOUND'
  | 'METHOD_NOT_ALLOWED'
  | 'NOT_ACCEPTABLE'
  | 'PROXY_AUTHENTICATION_REQUIRED'
  | 'REQUEST_TIMEOUT'
  | 'CONFLICT'
  | 'GONE'
  | 'LENGTH_REQUIRED'
  | 'PRECONDITION_FAILED'
  | 'PAYLOAD_TOO_LARGE'
  | 'REQUEST_ENTITY_TOO_LARGE'
  | 'URI_TOO_LONG'
  | 'REQUEST_URI_TOO_LONG'
  | 'UNSUPPORTED_MEDIA_TYPE'
  | 'REQUESTED_RANGE_NOT_SATISFIABLE'
  | 'EXPECTATION_FAILED'
  | 'I_AM_A_TEAPOT'
  | 'INSUFFICIENT_SPACE_ON_RESOURCE'
  | 'METHOD_FAILURE'
  | 'DESTINATION_LOCKED'
  | 'UNPROCESSABLE_ENTITY'
  | 'LOCKED'
  | 'FAILED_DEPENDENCY'
  | 'TOO_EARLY'
  | 'UPGRADE_REQUIRED'
  | 'PRECONDITION_REQUIRED'
  | 'TOO_MANY_REQUESTS'
  | 'REQUEST_HEADER_FIELDS_TOO_LARGE'
  | 'UNAVAILABLE_FOR_LEGAL_REASONS'
  | 'INTERNAL_SERVER_ERROR'
  | 'NOT_IMPLEMENTED'
  | 'BAD_GATEWAY'
  | 'SERVICE_UNAVAILABLE'
  | 'GATEWAY_TIMEOUT'
  | 'HTTP_VERSION_NOT_SUPPORTED'
  | 'VARIANT_ALSO_NEGOTIATES'
  | 'INSUFFICIENT_STORAGE'
  | 'LOOP_DETECTED'
  | 'BANDWIDTH_LIMIT_EXCEEDED'
  | 'NOT_EXTENDED'
  | 'NETWORK_AUTHENTICATION_REQUIRED'

export type CaseType =
  | 'civil'
  | 'criminal'
  | 'patent'
  | 'etc'
  | 'administration'
  | 'tax'
  | 'household'
  | 'ccourt'
  | 'old'

export type QueryType =
  | 'and'
  | 'or'
  | 'exclude'
  | 'order'
  | 'claim'
  | 'case'
  | 'interval'
  | 'exact'
  | 'court'
  | 'caseChar'
  | 'lawfirm'
  | 'requestClaim'
  | 'excludeCourtType'
  | 'law'
  | 'summary'
  | 'parties'
  | 'caseno'

export type StatuteState = 'current' | 'close' | 'history' | 'expect'

export type Direction = 'ASC' | 'DESC'

export type NullHandling = 'NATIVE' | 'NULLS_FIRST' | 'NULLS_LAST'

export type CaseState = 'IMAGE' | 'NORMAL'
