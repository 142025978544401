/* tslint:disable */
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ApiHttp} from '../api.http';

import {ResponseEntity, S3Signature, StreamingResponseBody, UploadMyCaseParams, UploadParams} from '../api.model';

@Injectable()
export class UploadFileController {
  constructor(private http: ApiHttp) {
  }

  caseUploadSignature(params: UploadMyCaseParams): Observable<S3Signature> {
    return this.http.post<S3Signature>('/api/upload/myCase', params);
  }

  downloadCaseImage(key: string): Observable<ResponseEntity<StreamingResponseBody>> {
    return this.http.get<ResponseEntity<StreamingResponseBody>>('/api/uploadcase-image', {key: key});
  }

  downloadFile(path: string): Observable<ResponseEntity<StreamingResponseBody>> {
    return this.http.get<ResponseEntity<StreamingResponseBody>>('/api/upload', {path: path});
  }

  jsonSave(fileName: string, fileContent: string): Observable<void> {
    return this.http.post<void>('/api/upload/pdf', fileContent, {fileName: fileName});
  }

  lawSchoolUploadSignature(params: UploadMyCaseParams): Observable<S3Signature> {
    return this.http.post<S3Signature>('/api/upload/lawSchool', params);
  }

  lawyerUploadSignature(params: UploadMyCaseParams): Observable<S3Signature> {
    return this.http.post<S3Signature>('/api/upload/lawyer', params);
  }

  lqaUploadSignature(params: UploadMyCaseParams): Observable<S3Signature> {
    return this.http.post<S3Signature>('/api/upload/lqa', params);
  }

  s3Signature(params: UploadParams): Observable<S3Signature> {
    return this.http.post<S3Signature>('/api/upload', params);
  }
}
