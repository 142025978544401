import { Component, Input } from '@angular/core';

@Component({
  selector: 'lb-confirm-info-tip',
  templateUrl: './confirm-info-tip.component.html',
  styleUrls: ['./confirm-info-tip.component.sass'],
})
export class ConfirmInfoTipComponent {
  @Input()
  info;

  @Input()
  caseId;
}
