/** MIME 타입이 jpg, jpeg, png 이미지인지 체크 */
export function checkIsFileImageType(file: File) {
  const ALLOWED_TYPES = ['image/jpeg', 'image/jpg', 'image/png'];
  return ALLOWED_TYPES.includes(file.type);
}

/** 파일 크기가 "N" MB 이하인지 체크 */
export function checkIsFileSmallerThan(file: File, mb: number) {
  const FILE_SIZE_LIMIT_MB = mb * 1024 * 1024;
  return file.size < FILE_SIZE_LIMIT_MB;
}
