import { Directive, HostBinding, HostListener, Input } from '@angular/core';
import { UserService } from '../../user/user.service';
import { AlertService } from '../../alert/alert.service';
import { Router } from '@angular/router';
import { ReadService } from '../../core/read.service';

@Directive({
  selector: '[newsLink]',
})
export class NewsLinkDirective {
  @Input()
  newsLink;

  constructor(
    private user: UserService,
    private alert: AlertService,
    private router: Router,
    private read: ReadService,
  ) {}

  @HostListener('click', ['$event'])
  click($event) {
    $event.stopPropagation();
    $event.preventDefault();
    this.newsPage();
  }

  @HostBinding('attr.href')
  get href() {
    return this.newsLink;
  }

  @HostBinding('class.read')
  get readClass() {
    if (!this.newsLink) return;
    return this.read.isRead(this.href);
  }

  newsPage() {
    const url = new URL(this.href);
    this.alert.checkOpen(url.toString());
  }
}
