import {
  Directive,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Renderer2,
} from '@angular/core';
import { TrackService } from './track.service';
import { AlertService } from '../alert/alert.service';
import { josa } from '../core/josa.util';
import tippy from 'tippy.js';

@Directive({
  selector: '[trackClass]',
})
export class TrackProto {
  @Input()
  trackData: any;

  get trackKey() {
    return '';
  }

  get trackName() {
    return this.trackService.trackName(this.trackKey);
  }

  get data() {
    const data =
      typeof this.trackData === 'function' ? this.trackData() : this.trackData;
    return { ...this.trackService.trackData(this.trackKey), ...data };
  }

  constructor(
    protected el: ElementRef,
    @Optional() private value: TrackValueDirective,
    @Optional() protected trackPrefix: TrackPrefixDirective,
    protected trackService: TrackService,
    protected alert: AlertService,
    protected renderer: Renderer2,
  ) {}

  protected sendEvent(trackKey: string = '') {
    this.trackService.sendEvent(
      trackKey ? this.trackService.trackName(trackKey) : this.trackName,
      this.value?.trackValue,
      this.data,
    );
  }
}

@Directive({
  selector: '[trackEnter]',
})
export class TrackEnterDirective
  extends TrackProto
  implements OnInit, OnDestroy
{
  @Input()
  trackEnter;

  get trackKey() {
    return (this.trackPrefix?.trackPrefix || '') + this.trackEnter;
  }

  listener;

  ngOnDestroy(): void {
    this.listener?.();
  }

  ngOnInit(): void {
    if (this.trackName) {
      this.listener = this.renderer.listen(
        this.el.nativeElement,
        'keydown.enter',
        (e) => {
          if (!e.isComposing) {
            this.sendEvent();
          }
        },
      );
    }
  }
}

@Directive({
  selector: '[trackId]',
})
export class TrackDirective extends TrackProto implements OnInit, OnDestroy {
  @Input()
  trackId: string;

  get trackKey() {
    return (this.trackPrefix?.trackPrefix || '') + this.trackId;
  }

  listener;

  ngOnDestroy(): void {
    this.listener?.();
  }

  ngOnInit(): void {
    if (this.trackName) {
      this.listener = this.renderer.listen(this.el.nativeElement, 'click', () =>
        this.sendEvent(),
      );
    }

    if (!localStorage.getItem('amplitudeEditMode')) return;
    this.developMode();
  }

  private developMode() {
    this.trackService.showTrack.subscribe((name) => {
      if (name != this.trackName) {
        this['tip']?.hide();
      }
      if (name && name != this.trackName) return;
      this.makeTip(!name || name == this.trackName);
    });
    this.el.nativeElement.addEventListener('mouseover', () => {
      this.trackService.nowTrackKey = this.trackId;
    });
    this.el.nativeElement.addEventListener('contextmenu', (e) => {
      e.preventDefault();
      e.stopPropagation();
      this.alert
        .prompt(
          [
            {
              name: '분석이름',
              value: this.trackName,
              permitEmpty: true,
            },
            {
              name: '데이터',
              value: this.trackService.trackData(this.trackKey)
                ? JSON.stringify(this.trackService.trackData(this.trackKey))
                : '',
              permitEmpty: true,
            },
          ],
          this.trackKey,
        )
        .then((v) => {
          if (!v) return;
          if (!v[0]) {
            this.trackService.api.trackId
              .deleteId(this.trackKey, this.trackName)
              .subscribe(() => {
                delete this.trackService.trackMap[this.trackKey];
                this.makeTip();
              });
            return;
          }
          let data;
          try {
            data = JSON.parse(v[1]);
          } catch (e) {
            this.alert.pop('파싱 실패');
          }
          this.trackService.api.trackId
            .saveTrackId(this.trackKey, v[0], data)
            .subscribe(() => {
              this.trackService.trackMap[this.trackKey] = {
                name: v[0],
                data: data,
              };
              this.makeTip();
              this.alert.pop(v[0] + josa['으로'](v[0]) + ' 설정됨');
            });
        });
    });
  }

  private makeTip(showOnCreate = false) {
    this.el.nativeElement.classList.remove('track-active');
    if (!this.trackName) return;
    let content = `<b>${this.trackName}</b>`;
    if (this.data)
      content += `<br>${Object.keys(this.data)
        .map((it) => it + ':' + this.data[it])
        .join('\n')}`;
    this['tip']?.destroy();
    this['tip'] = tippy(this.el.nativeElement, {
      showOnCreate:
        showOnCreate && this.el.nativeElement.getClientRects()?.length,
      allowHTML: true,
      content: content,
      appendTo: document.body,
      popperOptions: {
        strategy: 'fixed',
      },
    });
    this.trackService.showTracks.push(this.trackName);
    this.el.nativeElement.classList.add('track-active');
  }
}

@Directive({
  selector: '[trackValue]',
})
export class TrackValueDirective {
  @Input()
  trackValue;
}

@Directive({
  selector: '[trackPrefix]',
})
export class TrackPrefixDirective {
  @Input()
  trackPrefix;
}
