export const ZENDESK_SCRIPT_SRC =
  'https://static.zdassets.com/ekr/snippet.js?key=23b4e125-bbd3-4802-961c-192470919b10';

export const ZENDESK_CHAT_IFRAME_SELECTOR = 'iframe[id="launcher"]';

export const showZendeskChatWidget = () => {
  const zendeskIframeElement = window.document.querySelector<HTMLIFrameElement>(
    ZENDESK_CHAT_IFRAME_SELECTOR,
  );
  if (zendeskIframeElement) {
    zendeskIframeElement.style.visibility = 'visible';
  }
};

export const hideZendeskChatWidget = () => {
  const zendeskIframeElement = window.document.querySelector<HTMLIFrameElement>(
    ZENDESK_CHAT_IFRAME_SELECTOR,
  );
  if (zendeskIframeElement) {
    zendeskIframeElement.style.visibility = 'hidden';
  }
};
