import { Directive, HostListener } from '@angular/core';

declare var zendesk;

@Directive({
  selector: '[zendesk]',
})
export class ZenDeskDirective {
  @HostListener('click')
  openZendesk() {
    zendesk();
  }
}
