import { Component, OnInit, ViewChild } from '@angular/core';
import { ScrapMenuComponent } from './scrap-menu.component';
import { ArchiveFolder } from '../../../../../ngxSpring/api.model';

@Component({
  selector: 'lb-scrap-menu-folder-button',
  templateUrl: './scrap-menu-folder-button.component.html',
  styleUrls: ['./scrap-menu.component.sass'],
})
export class ScrapMenuFolderButtonComponent
  extends ScrapMenuComponent
  implements OnInit
{
  @ViewChild('trigger')
  trigger;
  sharedFolders: ArchiveFolder[];
  recentCases;

  get needLabel() {
    return (
      (this.folders?.length ? 1 : 0) +
        (this.sharedFolders?.length ? 1 : 0) +
        (this.recentCases?.length ? 1 : 0) >
      1
    );
  }

  open() {
    this.trigger.openMenu();
  }

  ngOnInit() {
    this.load();
    this.api.sharedFolder.getSharedFolderList().subscribe((v) => {
      this.sharedFolders = v;
    });
    this.api.readCase.getRecentList().subscribe((v) => {
      this.recentCases = v;
    });
  }
}
