<h5 trackId="c084" [lbNewTabLink]="reference.link">
  {{ reference.title | replace: '\\<[!\\w\/]+?\\>':'':'ig' | maxLength: 39 }}
</h5>
<div>
  {{
    reference.sub
      | replace: '\\<[!\\w\/]+?\\>':'':'ig'
      | maxLength: 39
      | preventNull
  }}
  <span class="date" *ngIf="reference.date | preventNull"
    ><span *ngIf="reference.sub"> | </span>
    {{ reference.date | preventNull }}</span
  >
</div>
