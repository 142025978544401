import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TrackingService } from 'src/app/core/tracking.service';
import { CaseSummary } from '../../../../ngxSpring/api.model';
import { SearchService } from '../../search/search.service';

@Component({
  selector: 'lb-search-result-case',
  templateUrl: './search-result-case.component.html',
  styleUrls: ['./search-result-case.component.sass'],
})
export class SearchResultCaseComponent implements OnInit {
  @Input()
  newTabOnly;

  @Input()
  suffix = '회 인용됨';

  @Input()
  index;

  @Input()
  withoutExclude = true;

  @Input()
  case: CaseSummary;

  @Input()
  matPopupId: string;

  @Output()
  pageRouted = new EventEmitter();
  omissions;
  className = { disposedRef: 'ref' };
  name = {
    disposedRef: '주의',
    disposed: '폐기',
    snippet: '미등록',
  };
  tips = {
    snippet:
      '<span class="dispose-badge snippet">미등록</span>무료 등록요청 후 열람 가능한 판례입니다.',
    disposed: "<span class='dispose-badge'>폐기</span>변경된 판례",
    disposedRef:
      "<span class='dispose-badge ref'>주의</span>변경된 판례를 참조한 판례",
  };
  searchSessionId;

  @Input()
  page;

  get rank() {
    const CASES_PER_PAGE = 10
    return (this.page * CASES_PER_PAGE) + (this.index + 1)
  }

  get caseTitleClickTrackData() {
    const baseTrackData = {
      doc_id: this.case.id,
      rank: this.rank,
      search_session_id: this.searchSessionId
    }
    return this.matPopupId ? { ...baseTrackData, popup_page_id: this.matPopupId } : baseTrackData 
  }

  get stateTips() {
    if (!this.case || !this.case.states || !this.case.states.length) return;
    return `<div class='mt-4'>${this.case.states
      .map((it) => `<div class='mb-4'>${this.tips[it]}</div>`)
      .join('')}</div>`;
  }

  constructor(public search: SearchService, public trackingService: TrackingService) {}

  onClickOtherCaseLink(casePathname: string) {
    const [court, id] = casePathname?.split('-') ?? ['', '']

    this.trackingService.tracking('click_case', {
      funnel_root_id: this.trackingService.getCasePDPFunnelRootId(),
      isLeafEvent: true,
      click_url: `${window.location.origin}/case/${court}/${id}?q=${this.search.searchQuery.pureQuery}`,
      ...this.caseTitleClickTrackData,
    })
  }

  ngOnInit(): void {
    this.omissions = this.search
      .extractQuery(this.search.correctedQuery || this.search.searchQuery.query)
      .keyword.split(' ')
      .filter((it) => it)
      .filter(
        (it) =>
          this.case.matchedQueries &&
          !this.case.matchedQueries.find((q) => q == it),
      )
      .distinct()
      .filter((it) => !this.case.content.match(it));

    this.searchSessionId = localStorage?.getItem('search_session_id') ?? ''
  }

  mustInclude(omission: any) {
    this.search.searchWithKeyword(
      this.search.searchQuery.query.replace(omission, `"${omission}"`),
    );
  }
}
