<div fxLayout="row" class="wrapper">
  <div class="left">
    <div class="title" [class.read]="case.read || (case.id | isRead)">
      <ng-container *showItSizes="{ min: 768 }">
        <span class="disposes" *ngIf="case.states?.length">
          <span
            class="dispose-badge mr-4"
            *ngFor="let state of case.states"
            (mouseenter)="tip.show()"
            (mouseleave)="tip.hide()"
            [ngClass]="className[state] || state"
            >{{ name[state] }}</span
          >
          <span
            class="icon3 small h-4 n-11 mr-6"
            #tip="tip"
            [lbTip]="stateTips"
          ></span>
        </span>
      </ng-container>
      <a
        class="link"
        (click)="
          onClickOtherCaseLink(case.id);
          $event.preventDefault();
          $event.stopPropagation();
          case.read = true;
        "
        [caseLink]="case.id"
        [query]="search.searchQuery.pureQuery"
        (moved)="pageRouted.emit()"
        [href]="'/' + case.id"
        target="_blank"
        [title]="case.title"
      >
        <ng-container *showItSizes="{ max: 767 }">
          <span class="disposes mb-4" *ngIf="case.states?.length">
            <span
              class="dispose-badge mr-4"
              *ngFor="let state of case.states"
              (mouseenter)="tip.show()"
              (mouseleave)="tip.hide()"
              [ngClass]="className[state] || state"
              >{{ name[state] }}</span
            >
            <span
              class="icon3 small h-4 n-11 mr-6"
              #tip="tip"
              [lbTip]="stateTips"
            ></span>
          </span>
        </ng-container>
        {{ case.title | ellipsis: 59 }}</a
      >
    </div>
    <p [innerHTML]="case.content | removeReserveTags"></p>
  </div>
</div>
<div class="omissions" *ngIf="omissions?.length && !withoutExclude">
  누락된 검색어:
  <span class="omission" *ngFor="let omission of omissions; let last = last">{{
    omission
  }}</span>
  <span *ngIf="omissions.length < 4" class="omission-div"></span
  ><br *ngIf="omissions.length >= 4" />
  다음 검색어를 반드시 포함:
  <span
    class="include inline-block"
    [class.mt-4]="omissions.length >= 4"
    *ngFor="let omission of omissions; let last = last"
    trackId="d24e"
    (click)="mustInclude(omission)"
    >{{ omission }}</span
  >
</div>
<div
  class="right mt-6 mt-12"
  fxLayout="row"
  fxLayoutAlign="start center"
  fxLayout.lt-md="column"
  fxLayoutAlign.lt-md="start start"
>
  <div>
    <span
      *ngIf="case.order"
      class="order"
      [lbTip]="tip"
      [class.gray]="!case.result"
    >
      <span
        class="icon3 n-103 mr-4"
        *ngIf="
          case.confirmed_info?.confirmed === true &&
          case.confirmed_info?.confirmed_case?.doc_id == case.id
        "
      ></span>
      <span class="vertical-middle">{{ case.result || '주문' }}</span>
      <div #tip class="mt--6">
        <lb-confirm-info-tip
          [caseId]="case.id"
          [info]="case.confirmed_info"
        ></lb-confirm-info-tip>
        <div class="tip-div"></div>
        <div [innerHTML]="case.order | newLine"></div>
      </div>
    </span>
    <span
      *ngIf="case.relief"
      class="order gray"
      [lbTip]="case.relief | newLine"
    >
      {{ case.reliefName }}
    </span>
  </div>
  <span fxFlex="auto"></span>
  <div class="color-mid-gray font-14 mb-4">
    <div *showItSizes="{ max: 960 }" class="mt-12"></div>
    {{ case.view_count || 0 }}회 조회 ·
    {{ case.judgeCitedCount || case.quoted_count || 0 }}{{ suffix }}
  </div>
</div>
