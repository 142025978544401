<div>
  <div class="slider top">
    <div class="slide-track top">
      <img src="/assets/home/nameplate_1.png" alt="로펌/기업 네임플레이트 슬라이딩 이미지 1" class="sliding-image" />
      <img src="/assets/home/nameplate_1.png" alt="로펌/기업 네임플레이트 슬라이딩 이미지 1" class="sliding-image" />
      <img src="/assets/home/nameplate_1.png" alt="로펌/기업 네임플레이트 슬라이딩 이미지 1" class="sliding-image" />
    </div>
  </div>
  <div class="slider bottom">
    <div class="slide-track bottom">
      <img src="/assets/home/nameplate_2.png" alt="로펌/기업 네임플레이트 슬라이딩 이미지 2" class="sliding-image" />
      <img src="/assets/home/nameplate_2.png" alt="로펌/기업 네임플레이트 슬라이딩 이미지 2" class="sliding-image" />
      <img src="/assets/home/nameplate_2.png" alt="로펌/기업 네임플레이트 슬라이딩 이미지 2" class="sliding-image" />
    </div>
  </div>
</div>