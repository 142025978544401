import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpHeaders,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { NEVER, Observable, of, throwError } from 'rxjs';
import { AlertService, V2SnackbarComponent } from '../alert/alert.service';
import { Router } from '@angular/router';
import { AlarmService } from './alarm.service';
import { isPlatformServer } from '@angular/common';
import { deleteAllCookies, loginUrl } from './util';
import { PricePlanComponent } from '../alert/price-plan/price-plan.component';
import { DeviceRemoveComponent } from '../user/dialogs/device-remove/device-remove.component';
import { LocalStorageService } from './local-storage.service';
import { log } from '../../log';
import { TrackingService } from './tracking.service';

const messages = {
  EmailExists: '이미 가입한 이메일입니다.',
  AlreadyExistsNick: '사용 중인 닉네임입니다.',
  AlreadyExistsUniqueKey: '이미 등록된 정보입니다.',
};

@Injectable()
export class HttpProcessInterceptor implements HttpInterceptor {
  constructor(
    private alert: AlertService,
    private router: Router,
    private alarmService: AlarmService,
    @Inject(PLATFORM_ID) private platform,
    private localStorage: LocalStorageService,
    private trackingService: TrackingService,
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    if (isPlatformServer(this.platform)) {
      return next.handle(req).pipe(
        catchError((err) => {
          return of(err);
        }),
      );
    }
    return next
      .handle(req)
      .pipe(
        map((e) => {
          if (e instanceof HttpResponse) {
            this.processHeaders(e.headers);
          }
          return e;
        }),
      )
      .pipe(
        catchError((err) => {
          this.processHeaders(err.headers);
          if (err.status === 409) {
            /**
             * @NOTE 기존에는 409 응답이 오면 무조건 기기등록 해제 팝업을 띄웠으나, API 에러 응답 방식이 변경되면서 기기등록 관련 에러도 409 응답을 내려줄 수 있다.
             * 따라서 상태코드가 409 이면서, 에러 응답이 특정 형식일 때에만 기기등록 해제 팝업을 띄운다.
             */
            if (
              Array.isArray(err.error) &&
              err.error.length > 0 &&
              Boolean(err.error[0].userId)
            ) {
              void this.alert.open(DeviceRemoveComponent, err.error, {
                width: '560px',
              });
              return NEVER;
            }
          }
          if (err.status === 400) {
            if (document.querySelector('.alert-dialog')) this.alert.close();

            if (err.error instanceof Blob) {
              err.error = err.error.text().then((text) => {
                this.processError(text);
              });
              return throwError(err.error);
            }
            this.processError(err.error);
          }
          return throwError(err.error);
        }),
      );
  }

  private processError(error: any) {
    if (error === 'token_removed') {
      deleteAllCookies();
      this.localStorage.clear();
      void this.alert
        .alert('로그아웃', null, '기기 등록 해제되어 로그아웃 됩니다.')
        .then(() => {
          location.reload();
        });
      return NEVER;
    }
    if (error === 'token_error') {
      deleteAllCookies();
      this.localStorage.clear();
      location.reload();
      return NEVER;
    }
    if (error === 'login_required') {
      this.alert.pop('로그인이 필요한 서비스입니다.');
      void this.router.navigateByUrl(loginUrl());
      return NEVER;
    }
    if (error === 'lboxuser_required') {
      void this.router.navigateByUrl('/register/verify');
      return NEVER;
    }
    if (error === 'case_limit_exceeded') {
      void this.router.navigateByUrl(`/recaptcha?from=${location.href}`);
      return NEVER;
    }
    if (error === 'not_found' || error === '') {
      void this.router.navigateByUrl('/not_found');
      return NEVER;
    }
    if (error && error.startsWith('PRICEPLAN_')) {
      void this.alert
        .open(PricePlanComponent, error.replace('PRICEPLAN_', ''))
        .then((v) => {
          if (v) {
            void this.router.navigateByUrl('/user/pricing');
            return;
          }
          if (location.pathname == '/') {
            void this.router.navigateByUrl('/');
          }
        });
      return NEVER;
    }
    const message = messages[error] || error;

    /** [PROD-99] 변호사 인증 실패 시 팝업 뜨지 않도록 변경 */
    if (message.split('_')[0] === '신분증 인증 실패') {
      return NEVER;
    }

    if (/[가-힣]/g.test(message)) {
      if (this.alert.bottomSheet._openedBottomSheetRef) {
        this.alert.popFromComponent(V2SnackbarComponent, {
          data: {
            type: 'error',
            message: message.split('_')[0],
          },
        });
      } else if (message.match('_')) {
        void this.alert.alert(
          message.split('_')[0],
          null,
          message.split('_')[1],
        );
      } else {
        void this.alert.alert(message);
      }
      return NEVER;
    } else {
      log(message);
    }
  }

  private processHeaders(headers: HttpHeaders) {
    if (!headers) return;
    if (headers.get('alarmRefresh')) {
      this.alarmService.refreshAlarm();
    }
    if (headers.get('redirect')) {
      void this.router.navigateByUrl(headers.get('redirect') || '/');
    }
  }
}
