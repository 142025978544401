import { Component } from '@angular/core';
import { PasswordResetDialogComponent } from '../password-reset-dialog/password-reset-dialog.component';

@Component({
  selector: 'lb-password-reset-with-token',
  templateUrl: './password-reset-with-token.component.html',
  styleUrls: ['./password-reset-with-token.component.sass'],
})
export class PasswordResetWithTokenComponent extends PasswordResetDialogComponent {
  passwordRedefine() {
    const error = this.passwordRedefineForm.error();
    if (error) {
      this.alert.alert(error);
      return;
    }
    this.api.user
      .updatePasswordWithToken({
        newPassword: this.passwordRedefineForm.getFormValue('password'),
        code: this.data.token,
      })
      .subscribe(() => {
        this.alert.pop('비밀번호가 변경되었습니다.');
        this.close();
      });
  }
}
