<div>
  <button
    *ngIf="!only || only === 'KAKAO'"
    class="bordered"
    fxLayout="row"
    fxLayoutAlign="center center"
    trackId="2f47"
    (click)="kakaoStart()"
  >
    <img src="/assets/kakao.png" />
    <div fxFlex="auto" class="text-center color-black">
      <b>카카오톡</b>으로 {{ type }}하기
    </div>
  </button>
  <button
    *ngIf="!only || only === 'NAVER'"
    class="bordered"
    fxLayout="row"
    fxLayoutAlign="center center"
    trackId="69b2"
    (click)="naverStart()"
  >
    <img src="/assets/naver.png" />
    <div fxFlex="auto" class="text-center color-black">
      <b>네이버</b>로 {{ type }}하기
    </div>
  </button>
  <button
    *ngIf="!only || only === 'GOOGLE'"
    class="bordered"
    fxLayout="row"
    fxLayoutAlign="center center"
    trackId="e4b0"
    (click)="googleStart()"
  >
    <img src="/assets/google.png" />
    <div fxFlex="auto" class="text-center color-black">
      <b>구글</b>로 {{ type }}하기
    </div>
  </button>
</div>
