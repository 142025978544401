<div class="image">
  <img [src]="nowStep.image" [class.pc]="nowStep.mobileImage" />
  <img
    [src]="nowStep.mobileImage"
    style="width: 100%"
    *ngIf="nowStep.mobileImage"
    class="mobile"
  />
</div>
<div class="pd-32">
  <div fxLayout="row" fxLayout.lt-md="column">
    <div>
      <div class="title">
        {{ nowStep.title }}
      </div>
      <div class="description" [innerHTML]="nowStep.description"></div>
    </div>
    <div fxFlex="auto"></div>
    <div *ngIf="!nowStep.cover">
      <div class="steps">
        <div
          trackId="945f"
          (click)="index = data.steps.indexOf(step)"
          *ngFor="let step of steps; let i = index"
          class="step"
          [class.active]="index == data.steps.indexOf(step)"
        >
          <span>
            {{ i + 1 }}
          </span>
        </div>
      </div>
    </div>
  </div>

  <div class="text-right">
    <button class="mr-8" trackId="51a5" (click)="prev()" *ngIf="index > 0">
      이전
    </button>
    <button
      class="active"
      trackId="fa24"
      (click)="next()"
      *ngIf="index < this.data.steps.length - 1"
    >
      다음
    </button>
    <button
      class="active"
      trackId="8e7d"
      (click)="done()"
      *ngIf="index >= this.data.steps.length - 1"
    >
      완료
    </button>
  </div>
</div>
