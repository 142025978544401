/* tslint:disable */
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ApiHttp} from '../api.http';

import {JudgeEditCase, Page} from '../api.model';

@Injectable()
export class JudgeEditCaseController {
  constructor(private http: ApiHttp) {
  }

  getJudgeEditCaseList(page?: number, size?: number, order?: string, query?: string, start?: number, end?: number): Observable<Page<JudgeEditCase>> {
    return this.http.get<Page<JudgeEditCase>>('/api/judgeEditCase/list', {page: page, size: size, order: order, query: query, start: start, end: end});
  }

  updateCaseJudge(query: string): Observable<void> {
    return this.http.post<void>('/api/judgeEditCase/updateCaseJudge', query);
  }

  updateJudgeEditCaseList(): Observable<void> {
    return this.http.post<void>('/api/judgeEditCase/updateJudgeEditCaseList', null);
  }
}
