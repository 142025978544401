import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AlertService } from '../../alert/alert.service';
import { AccountRemoveDialogComponent } from '../../user/dialogs/account-remove-dialog/account-remove-dialog.component';
import type { ContentType } from 'src/app/shared/personal-information-collect-and-usage-info-table/personal-information-collect-and-usage-info-table';

@Component({
  selector: 'lb-agree-rules',
  templateUrl: './agree-rules.component.html',
  styleUrls: ['./agree-rules.component.sass'],
})
export class AgreeRulesComponent {
  @Input()
  noName;

  @Input()
  contentType: ContentType;

  get jobName() {
    switch (this.contentType) {
      case 'lawyer':
        return '변호사';
      case 'lawSchoolStudent':
        return '로스쿨 학생';
      default:
        return null;
    }
  }

  get requireAgreed() {
    return this.agrees.filter((it, index) => it || index == 2).length == 4;
  }

  get marketingAgree() {
    return this.agrees[2];
  }

  get agreeAll() {
    return this.agrees.filter((it) => it)?.length == 4;
  }

  set agreeAll(v) {
    if (v) {
      this.agrees = [true, true, true, true];
    } else {
      this.agrees = [false, false, false, false];
    }
  }

  agrees = [false, false, false, false];

  @Output()
  done = new EventEmitter();

  @Input()
  registered;
  expand;
  promoExpand;
  privateExpand;

  constructor(private alert: AlertService) {}

  async next() {
    if (!this.agrees[0] || !this.agrees[1]) {
      await this.alert.alert('필수 약관에 동의해주세요.');
      return;
    }
    this.done.emit(this.agrees[2]);
  }

  async withdraw() {
    await this.alert.open(AccountRemoveDialogComponent);
  }
}
