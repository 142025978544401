import { Component, TemplateRef } from '@angular/core';
import { AlarmService } from '../../core/alarm.service';
import { UserService } from '../../user/user.service';
import { AlarmComponent } from './alarm.component';
import { AlertService } from '../../alert/alert.service';

@Component({
  selector: 'lb-alarm-mobile',
  templateUrl: './alarm-mobile.component.html',
  styleUrls: ['./alarm.component.sass'],
})
export class AlarmMobileComponent extends AlarmComponent {
  constructor(
    alarmService: AlarmService,
    user: UserService,
    private alert: AlertService,
  ) {
    super(alarmService, user);
  }
  open(popoverContent: TemplateRef<any>) {
    this.alert.open(popoverContent);
  }
}
