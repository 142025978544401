<div id="panel-root" class="component-root react-component"></div>
<div id="refer-to-provision-right-panel-root" class="component-root react-component"></div>
<div id="following-case-right-panel-root" class="component-root react-component"></div>
<div id="ai-relative-case-right-panel-root" class="component-root react-component"></div>
<div id="search-by-lawfirm-case-right-panel-root" class="component-root react-component"></div>
<div id="unsubscribe-standard-plan-modal-root" class="component-root react-component"></div>
<div id="modal-root" class="component-root react-component"></div>
<div id="toast-root" class="component-root react-component"></div>
<div id="tooltip-root" class="component-root react-component"></div>
<div id="drawer-root" class="component-root react-component"></div>
<div id="overlay-root" class="component-root react-component"></div>
<div id="popover-root" class="component-root react-component"></div>
<div id="pop-up-root" class="component-root react-component"></div>


<mat-drawer-container>
  <mat-drawer-content>
    <lb-loading-bar></lb-loading-bar>
    <router-outlet></router-outlet>
  </mat-drawer-content>
  <mat-drawer mode="over" position="end" class="fixed-drawer">
    <lb-header-drawer></lb-header-drawer>
  </mat-drawer>
  <mat-drawer *ngIf="edit" style="width: 240px" mode="side" position="start" [opened]="true">
    <lb-track-edit></lb-track-edit>
  </mat-drawer>
</mat-drawer-container>
