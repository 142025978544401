<div class="element mt-12 pb-12" [class.no-border]="true">
  <div class="wrap-menu">
    <span class="font-16 font-weight-500">
      <ng-container *ngIf="userService.user?.name">
        {{ userService.user?.name }}님</ng-container
      >
    </span>
    <span
      class="icon3 n-9 h-3 float-right mt-14"
      trackId="061d"
      (click)="close()"
    ></span>
  </div>
</div>
<ng-container *ngIf="userService.logged">
  <ng-container *ngFor="let menu of userService._menuList">
    <div class="menu-name" *ngIf="!menu.name">
      {{ menu }}
    </div>
    <ng-container *ngIf="menu.name">
      <a 
        mat-menu-item
        (click)="close()"
        trackId="26b4"
        [trackData]="{ type: menu.logType || menu.name }"
        [routerLink]="menu.path">
        <div class="wrap-menu">
          {{ menu.name }}
          <span
            *ngIf="menu.sub"
            class="float-right"
            [innerHTML]="menu.sub"
          ></span>
        </div>
      </a>
    </ng-container>
  </ng-container>
  <div class="menu-name">기타</div>
  <a
    mat-menu-item
    href="https://lbox.notion.site/LBox-3e2919133fe44ad59c8d9b651e0def7c"
    target="_blank"
    trackId="26b4"
    [trackData]="{ type: '이용 가이드' }"
    (click)="close()"
    >이용 가이드</a
  >
  <a
    *ngIf="!userService.isRestrictedUser"
    mat-menu-item
    zendesk
    trackId="26b4"
    [trackData]="{ type: '고객센터' }"
    (click)="close()"
    >고객센터</a
  >
  <a
    mat-menu-item
    trackId="26b4"
    [trackData]="{ type: '이벤트' }"
    routerLink="/event"
    (click)="close()"
    >이벤트</a
  >
  <a
    class="logout pb-0 pt-0"
    mat-menu-item
    trackId="26b4"
    [trackData]="{ type: '로그아웃' }"
    (click)="userService.logout(); close()"
    >로그아웃</a
  >
  <div style="height: 60px"></div>
</ng-container>

<div *ngIf="!userService.logged">
  <a 
    mat-menu-item
    trackId="26b4"
    [trackData]="{ type: '로그인' }"
    [queryParams]="{ from: href }"
    routerLink="/user/login"
    (click)="close()"
    >로그인</a
  >
  <a 
    mat-menu-item
    trackId="26b4"
    [trackData]="{ type: '회원가입' }"
    routerLink="/user/register"
    (click)="close()"
    >회원가입</a
  >

  <div class="menu-name">기타</div>
  <a
    mat-menu-item
    trackId="26b4"
    [trackData]="{ type: '이용 가이드' }"
    href="https://lbox.notion.site/LBox-3e2919133fe44ad59c8d9b651e0def7c"
    target="_blank"
    (click)="close()"
    >이용 가이드</a
  >
  <a 
    mat-menu-item
    zendesk
    trackId="26b4"
    [trackData]="{ type: '고객센터' }"
    (click)="close()">고객센터</a>
</div>
