import { Pipe, PipeTransform } from '@angular/core';
import { josa } from '../core/josa.util';

@Pipe({
  name: 'josa',
})
export class JosaPipe implements PipeTransform {
  transform(value: string, ...args: any[]): string {
    if (args[1]) return josa[args[0]](value);
    return value + josa[args[0]](value);
  }
}
