/* tslint:disable */
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ApiHttp} from '../api.http';

import {CaseCount, Page} from '../api.model';

@Injectable()
export class CaseCountController {
  constructor(private http: ApiHttp) {
  }

  deleteCaseCount(id: number): Observable<void> {
    return this.http.delete<void>('/api/caseCount', {id: id});
  }

  getCaseCountList(page?: number, size?: number): Observable<Page<CaseCount>> {
    return this.http.get<Page<CaseCount>>('/api/caseCount/list', {page: page, size: size});
  }

  saveCaseCount(caseCount: CaseCount): Observable<CaseCount> {
    return this.http.post<CaseCount>('/api/caseCount', caseCount);
  }
}
