import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { V2_PAGE_PATH } from 'src/consts';
import { UrlService } from './shared/url.service';

@Injectable({
  providedIn: 'root',
})
export class RedirectStatuteGuard implements CanActivate {
  constructor(private urlService: UrlService) {}

  canActivate(route: ActivatedRouteSnapshot):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    const { id, date } = route.params
    const { jomun } = route.queryParams

    if (date && jomun) {
      window.location.href = `${this.urlService.getAuthority()}${V2_PAGE_PATH.root}/statute/${id}?targetDate=${new Date(date).getTime()}#${jomun}`;

      return false;
    }

    if (jomun) {
      window.location.href = `${this.urlService.getAuthority()}${V2_PAGE_PATH.root}/statute/${id}#${jomun}`;

      return false;
    }

    if (date) {
      window.location.href = `${this.urlService.getAuthority()}${V2_PAGE_PATH.root}/statute/${id}?targetDate=${new Date(date).getTime()}`;

      return false
    }

    window.location.href = `${this.urlService.getAuthority()}${V2_PAGE_PATH.root}/statute/${id}`;

    return false;
  }
}
