import { Component, Input, OnInit } from '@angular/core';
import { YoutubeEmbedComponent } from '../../search/search/youtube-embed/youtube-embed.component';
import { AlertService } from '../../alert/alert.service';
import { CaseCount } from '../../../../ngxSpring/api.model';
import { ApiService } from '../../../../ngxSpring/api.service';

@Component({
  selector: 'lb-lbox-select-why',
  templateUrl: './lbox-select-why.component.html',
  styleUrls: ['./lbox-select-why.component.sass'],
})
export class LboxSelectWhyComponent implements OnInit {
  constructor(private alert: AlertService, private api: ApiService) {}

  features = [
    {
      title: 'LBox만의 차별화된 검색 기능을 이용해보세요',
      cards: [
        {
          name: 'Ai 유사판례 검색',
          description:
            '원하는 쟁점을 다룬 판례를 찾으셨나요? \n' +
            '유사판례 검색 기능을 사용하여, 수십 만 건의 판례 중 \n' +
            '유사한 판례를 빠르게 찾아보세요.',
          image: '/assets/features/similaritysearch.png',
          link: 'https://youtube.com/embed//vCLwGbDleZ8',
        },
        {
          name: '빠른 검색 명령어 및 필터',
          description:
            '빠른 검색 명령어를 이용하여 특정 조건을 만족하는 판례를 빠르고 쉽게 찾아보세요.',
          image: '/assets/features/quicksearchcommand.png',
          link: 'https://youtube.com/embed/Sl4RPAvgwwE',
        },
      ],
    },
    {
      title: '업무 효율을 극대화 시켜주는 알림을 받아보세요',
      cards: [
        {
          name: '사건 일정관리',
          description:
            '사건정보를 등록하고 기일과 진행경과에 대한 알림을 \n' +
            '카카오톡으로 빠짐없이 받아보세요.',
          image: '/assets/features/casecalendar.png',
          link: 'https://www.youtube.com/embed/Hw6FCsbdVC0',
        },
        {
          name: '미등록 판례 요청',
          description:
            '찾으시는 판례가 없나요? \n' +
            '판례등록을 요청하고 1~ 2일 안에 원하는 판례를 \n' +
            '받아보세요.',
          image: '/assets/features/requestcase.png',
          link: 'https://youtube.com/embed/Q_s4WRAersM',
        },
        {
          name: '검색조건 알림',
          description:
            '신규 판례 중 놓치는 판례가 있을까봐 불안하신가요? \n' +
            '자주 검색하는 조건을 설정해두고 새로운 판례가 등록될 때마다 알림을 받아보세요.',
          image: '/assets/features/subscribecase.png',
          link: 'https://youtube.com/embed/AbHqLih3y3A',
        },
      ],
    },
    {
      title: '판례를 보다 효율적으로 활용해보세요',
      cards: [
        {
          name: '판례보관함',
          description: '판례를 보관하고 언제 어디서든 간편하게 확인해보세요.',
          image: '/assets/features/casebox.png',
          link: 'https://youtube.com/embed/K0J621WEmgo',
        },
        {
          name: '하이라이트 표시/메모 남기기',
          description: '중요한 내용에 하이라이트를 표시하고 메모를 남겨보세요.',
          image: '/assets/features/highlight&note.png',
          link: 'https://youtube.com/embed/0um2OYlHHl8?start=27',
        },
        {
          name: '하이라이트 포함 인쇄/공유',
          description:
            '전자소송에 판례를 제출하거나 동료 변호사에게 판례를 공유할 때, 하이라이트 포함 기능을 이용해보세요.',
          image: '/assets/features/highlightprint.png',
          link: 'https://youtube.com/embed/0um2OYlHHl8?start=103',
        },
      ],
    },
  ];

  openYoutube(link: string) {
    this.alert.dialog.open(YoutubeEmbedComponent, {
      panelClass: 'transparent',
      maxWidth: '100vw',
      data: link,
    });
  }

  @Input()
  values: CaseCount;

  round(number: number) {
    return Math.round(number);
  }

  ngOnInit(): void {
    if (!this.values) {
      this.api.caseCount.getCaseCountList(0, 1).subscribe((list) => {
        this.values = list.content[0];
      });
    }
  }
}
