<div class="title">
  <h5>판결문 보관함에 저장</h5>
</div>
<div class="divider"></div>
<div class="scroller">
  <div class="line-button placeholder" *ngIf="!parents.length">
    폴더가 없습니다.
  </div>
  <lb-select-folder
    *ngFor="let folder of parents"
    [folder]="folder"
  ></lb-select-folder>
</div>
<div class="divider"></div>
<div class="line-button mt-12" trackId="4187" (click)="saveNewFolder()">
  <span class="icon icon-plus"></span> 새 폴더에 저장
</div>
<div class="buttons">
  <button class="cancel" (click)="trackClickCaseButtonSaveCancel(); close()">취소</button>
  <button class="blue" (click)="archive()">저장하기</button>
</div>
