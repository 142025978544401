import { Component } from '@angular/core';
import { DialogOrBottomSheetProto } from '../../alert/alert.service';

@Component({
  selector: 'lb-sms-verify',
  templateUrl: './sms-verify.component.html',
  styleUrls: ['./sms-verify.component.sass'],
})
export class SmsVerifyComponent extends DialogOrBottomSheetProto<any> {
  code;

  verify() {
    this.api.smsVerify.verify(this.code, this.data).subscribe((v) => {
      this.close(v);
    });
  }
}
