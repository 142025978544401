<div fxLayout="row" fxLayoutAlign="center center">
  <span *ngIf="!pageMinusAble()" class="mr-32 fake-arrow-btn"></span>
  <span *ngIf="pageMinusAble()" (click)="pageStartMinus()" class="mr-32 arrow-btn">
    <span class="icon2 n-34 h-4 reverse pagination-reverse-icon"></span>
  </span>
  
  <ul fxLayout="row" fxLayoutAlign="center center">
    <li
      fxFlex="auto"
      *ngFor="let p of pages | slice: pageStart:pageStart + pageMax"
      [class.active]="pageValue === p"
      (click)="setPage(p || 1)"
    >
      {{ p }}
    </li>
  </ul>

  <span *ngIf="!pagePlusAble()" class="ml-32 fake-arrow-btn"></span>
  <span *ngIf="pagePlusAble()" (click)="pageStartPlus()" class="ml-32 arrow-btn">
    <span class="icon2 n-34 h-4 pagination-icon"></span>
  </span>
</div>
