/* tslint:disable */
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ApiHttp} from '../api.http';

import {LboxUserAndMatched, LqaProfile, LqaQuestion, MatchedLawyer, MatchedRecordReply, ResponseEntity, StreamingResponseBody} from '../api.model';

@Injectable()
export class MatchedLawyerController {
  constructor(private http: ApiHttp) {
  }

  accept(id: number): Observable<MatchedLawyer> {
    return this.http.post<MatchedLawyer>('/api/matchedLawyer/accept', null, {id: id});
  }

  additionalRequest(id: number, request: string): Observable<MatchedLawyer> {
    return this.http.post<MatchedLawyer>('/api/matchedLawyer/additionalRequest', request, {id: id});
  }

  additionalRequestAnswer(id: number, answer: MatchedRecordReply): Observable<MatchedRecordReply> {
    return this.http.post<MatchedRecordReply>('/api/matchedLawyer/additionalRequest/answer', answer, {id: id});
  }

  answer(matchedLawyer: MatchedLawyer): Observable<MatchedLawyer> {
    return this.http.post<MatchedLawyer>('/api/matchedLawyer/answer', matchedLawyer);
  }

  candidates(region: string, itemId: number): Observable<LboxUserAndMatched[]> {
    return this.http.post<LboxUserAndMatched[]>('/api/matchedLawyer/candidates', null, {region: region, itemId: itemId});
  }

  downloadFile(path: string): Observable<ResponseEntity<StreamingResponseBody>> {
    return this.http.get<ResponseEntity<StreamingResponseBody>>('/api/matchedLawyer/file', {path: path});
  }

  getCaseSize(userId: number): Observable<number> {
    return this.http.get<number>('/api/matchedLawyer/getCaseSize', {userId: userId});
  }

  getLastReply(id: number, fromLawyer: boolean): Observable<MatchedRecordReply> {
    return this.http.get<MatchedRecordReply>('/api/matchedLawyer/reply/last', {id: id, fromLawyer: fromLawyer});
  }

  getLawyerLqaQuestionList(): Observable<LqaQuestion[]> {
    return this.http.get<LqaQuestion[]>('/api/matchedLawyer/list/lawyer');
  }

  getLqaProfile(userId: number): Observable<LqaProfile> {
    return this.http.get<LqaProfile>('/api/matchedLawyer/getLqaProfile', {userId: userId});
  }

  getMatchedQuestionByLawyer(id: number): Observable<LqaQuestion> {
    return this.http.get<LqaQuestion>('/api/matchedLawyer', {id: id});
  }

  getMatchedRecordReplyList(id: number, fromLawyer: boolean): Observable<MatchedRecordReply[]> {
    return this.http.get<MatchedRecordReply[]>('/api/matchedLawyer/reply/list', {id: id, fromLawyer: fromLawyer});
  }

  getMatchedRecordReplyListAdmin(id: number): Observable<MatchedRecordReply[]> {
    return this.http.get<MatchedRecordReply[]>('/api/matchedLawyer/reply/list/admin', {id: id});
  }

  makeMapping(lqaQuestionId: number, lawyerId: number): Observable<MatchedLawyer> {
    return this.http.post<MatchedLawyer>('/api/matchedLawyer/makeMapping', null, {lqaQuestionId: lqaQuestionId, lawyerId: lawyerId});
  }

  newCount(): Observable<number> {
    return this.http.get<number>('/api/matchedLawyer/newCount');
  }

  pointAdded(id: number): Observable<MatchedLawyer> {
    return this.http.post<MatchedLawyer>('/api/matchedLawyer/pointAdded', null, {id: id});
  }

  readAnswerWarn(id: number): Observable<MatchedLawyer> {
    return this.http.post<MatchedLawyer>('/api/matchedLawyer/readAnswerWarn', null, {id: id});
  }

  reject(id: number, reason: string): Observable<MatchedLawyer> {
    return this.http.post<MatchedLawyer>('/api/matchedLawyer/reject', null, {id: id, reason: reason});
  }

  reply(id: number, fromLawyer: boolean, reply: MatchedRecordReply): Observable<MatchedRecordReply> {
    return this.http.post<MatchedRecordReply>('/api/matchedLawyer/reply', reply, {id: id, fromLawyer: fromLawyer});
  }

  review(id: number, reviewScore: number, review?: string): Observable<MatchedLawyer> {
    return this.http.post<MatchedLawyer>('/api/matchedLawyer/review', null, {id: id, reviewScore: reviewScore, review: review});
  }

  tempSaveAnswer(matchedLawyer: MatchedLawyer): Observable<MatchedLawyer> {
    return this.http.post<MatchedLawyer>('/api/matchedLawyer/tempSaveAnswer', matchedLawyer);
  }

  thanks(id: number): Observable<MatchedLawyer> {
    return this.http.post<MatchedLawyer>('/api/matchedLawyer/thanks', null, {id: id});
  }
}
