import { AWS_LAMBDA_CASE_PDF_VERSION, AWS_LAMBDA_QUOTATION_PDF_VERSION } from './common'
import type { Environment } from './type'

export const environment: Environment = {
	production: false,
	AWS_LAMBDA_CASE_PDF_VERSION,
	AWS_LAMBDA_QUOTATION_PDF_VERSION,
	E2E_CI: false,
	DATADOG_CLIENT_TOKEN: 'pub4072ab5f11a7375301814c45aa312ac4',
	DATADOG_SERVICE: 'lbox-client-nextjs-browser',
	UNLEASH_URL: 'https://unleash.lbox.kr/api/frontend',
	UNLEASH_CLIENT_KEY: '*:development.2beb90310537ab231f46ade22414be5920b9167f3d2d3f21fb87644d',
}
