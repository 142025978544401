import {
  HttpClient,
  HttpEvent,
  HttpEventType,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Observable, forkJoin, map, mergeMap } from 'rxjs';
import {
  LawSchoolAuthorization,
  S3Signature,
  UploadMyCaseParams,
} from '../../../../../ngxSpring/api.model';
import { TrackingService } from '../../../core/tracking.service';
import {
  FileObject,
  FileUploadListComponent,
} from '../../../shared/file-upload-list/file-upload-list.component';
import { checkIsFileImageType, checkIsFileSmallerThan } from './util';

@Component({
  selector: 'lb-law-school-authorize',
  templateUrl: './law-school-authorize.component.html',
  styleUrls: ['./law-school-authorize.component.sass'],
})
export class LawSchoolAuthorizeComponent
  extends FileUploadListComponent
  implements OnInit
{
  trackingService = this.injector.get(TrackingService);

  data: any;

  /** 개인정보 수집 및 이용 동의 여부 */
  isTermAgreed = false;
  /** 개인정보 수집 및 이용 동의 아코디언 열기/닫기 */
  showsTermsDetail = false;

  getChannel() {
    return this.data?.channel ?? '채널 정보 없음';
  }

  ngOnInit(): void {
    void this.trackingService.tracking('view_lawschoolauth', {
      channel: this.getChannel(),
    });
  }

  get isConfirmButtonActive() {
    return this.files.length > 0 && this.isTermAgreed;
  }

  updateFiles({
    fileList,
    eventType,
  }: {
    fileList?: FileList;
    eventType: 'click' | 'drag';
  }) {
    if (!fileList) {
      return;
    }

    if (eventType === 'click') {
      if (!Array.from(fileList).every(checkIsFileImageType)) {
        this.alert.pop('이미지 형식이 아닌 파일은 업로드 할 수 없습니다');
        return;
      }

      if (
        !Array.from(fileList).every((file) => checkIsFileSmallerThan(file, 500))
      ) {
        void this.alert.alert(
          '첨부할 수 있는 파일의 최대 용량은 500MB 입니다.',
          null,
          null,
          null,
          null,
          true, // dialogWhenBottomSheetOpened // TODO: Heedo - 파라미터 객체로 리팩토링
        );
        return;
      }
    }

    const fileObjects: FileObject[] = Array.from(fileList).map((file: File) => {
      return {
        file,
        progressSize: 0,
        done: false,
        error: null,
        path: 'noPath',
      };
    });

    const apiCalls: Observable<FileObject>[] = fileObjects
      .filter((fileObject) => !fileObject.error)
      .map((fileObject) =>
        this.getS3Signature(fileObject.file).pipe(
          map((signature) => ({ fileObject, signature })),
          mergeMap(({ fileObject, signature }) =>
            this.callWithS3Signature(fileObject.file, signature).pipe(
              map((httpEvent) => ({ fileObject, signature, httpEvent })),
            ),
          ),
          map(({ fileObject, signature, httpEvent }) =>
            this.updateFileObject(fileObject, signature, httpEvent),
          ),
        ),
      );

    forkJoin(apiCalls).subscribe((fileObjects) =>
      this.files.pushAll(fileObjects),
    );
  }

  getS3Signature(file: File): Observable<S3Signature> {
    return this.api.uploadFile.lawSchoolUploadSignature({
      filename: file.name,
      size: file.size,
    } as UploadMyCaseParams);
  }

  callWithS3Signature(file: File, signature: S3Signature) {
    const formData: FormData = new FormData();
    Object.keys(signature)
      .filter((v) => v !== 'Host')
      .forEach((key) => {
        formData.append(key, signature[key]);
      });
    formData.append('file', file);
    const req = new HttpRequest('POST', `https://${signature.Host}`, formData, {
      reportProgress: true,
    });
    return this.injector.get(HttpClient).request(req);
  }

  updateFileObject(
    fileObject: FileObject,
    signature: S3Signature,
    httpEvent: HttpEvent<any>,
  ) {
    if (httpEvent.type === HttpEventType.UploadProgress) {
      fileObject.progressSize = httpEvent.loaded;
    } else if (httpEvent instanceof HttpResponse) {
      fileObject.progressSize = fileObject.file.size;
      fileObject.done = true;
      fileObject.path = signature.key;
    }
    return fileObject;
  }

  handleClickCloseButton() {
    this.close();
  }

  handleClickTermsCheckbox() {
    this.isTermAgreed = !this.isTermAgreed;
  }

  handleClickIconCaretUp() {
    this.showsTermsDetail = !this.showsTermsDetail;
  }

  handleClickConfirmButton() {
    void this.trackingService.tracking('click_lawschoolauth_cta', {
      channel: this.getChannel(),
    });

    this.api.lawSchoolAuthorization
      .saveLawSchoolAuthorization({
        files: this.validFiles.map((it) => it.path),
      } as LawSchoolAuthorization)
      .subscribe((v) => {
        this.close(v);
        if (!this.data.uploadOnly) {
          void this.alert
            .alert(
              '로스쿨 플랜 신청 완료',
              null,
              '승인 후 엘박스의 다양한 서비스를 무료로 이용할 수 있습니다. 승인절차는 1~2 영업일이 소요되며, 완료시 카카오톡 또는 \n' +
                '이메일로 알림을 드립니다.',
            )
            .then();
        }
      });
  }
}
