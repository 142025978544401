import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { OrgService } from './org.service';
import { ApiService } from '../../../ngxSpring/api.service';
import { AlertService } from '../alert/alert.service';
import { isPlatformServer } from '@angular/common';
import { TrackingService } from '../core/tracking.service';

@Injectable({
  providedIn: 'root',
})
export class ManagerGuard implements CanActivate {
  constructor(
    private org: OrgService,
    private api: ApiService,
    private alert: AlertService,
    private router: Router,
    @Inject(PLATFORM_ID) private platform,
    private trackingService: TrackingService,
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    if (isPlatformServer(this.platform)) {
      return true;
    }
    return new Promise((r) => {
      this.org.loadCompany().then((company) => {
        if (!company) {
          this.trackingService.tracking('view_org_unregisteredPopup', {});
          this.alert
            .alert('관리자 권한이 없습니다.<br> 관리자 계정으로 접속해주세요.')
            .then(() => {
              r(this.router.parseUrl('/'));
            });
          return;
        }
        r(true);
      });
    });
  }
}
