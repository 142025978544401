import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'ellipsis',
})
export class EllipsisPipe implements PipeTransform {
  transform(value: string, limit = 25, ellipsis = '...', reverse = false) {
    if (reverse) {
      return value.length > limit
        ? ellipsis + value.substring(value.length - limit, value.length)
        : value;
    }
    return value.length > limit ? value.substring(0, limit) + ellipsis : value;
  }
}
