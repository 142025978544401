import { Injectable } from '@angular/core';
import { ClipboardService } from 'ngx-clipboard';
import { AlertService } from '../alert/alert.service';
import { isSafari } from './util';

@Injectable({
  providedIn: 'root',
})
export class CopyService {
  constructor(private clip: ClipboardService, private alert: AlertService) {}

  copy(
    value: string,
    message: string = '클립보드에 복사되었습니다.',
    placeholder = '복사해서 사용하세요.',
  ) {
    if (isSafari()) {
      this.alert.prompt({ placeholder, value }, placeholder);
      setTimeout(() => {
        (document.querySelector('lb-prompt input') as any).select();
      });
      return;
    }
    this.clip.copy(value);
    this.alert.pop(message);
  }
}
