export const districtAndCourts = {
  서울: [
    '대법원',
    '서울행정법원',
    '서울고등법원',
    '서울중앙지방법원',
    '서울동부지방법원',
    '서울남부지방법원',
    '서울북부지방법원',
    '서울민사지방법원',
    '서울서부지방법원',
    '서울가정법원',
    '서울회생법원',
  ],
  제주: ['제주지방법원', '제주지방법원 서귀포시법원', '광주고등법원 (제주)'],
  전주: [
    '전주지방법원',
    '전주지방법원 군산지원',
    '전주지방법원 정읍지원',
    '전주지방법원 남원지원',
    '전주지방법원 진안군법원',
    '전주지방법원 김제시법원',
    '전주지방법원 무주군법원',
    '전주지방법원 임실군법원',
    '전주지방법원 군산지원 익산시법원',
    '전주지방법원 정읍지원 부안군법원',
    '전주지방법원 정읍지원 고창군법원',
    '전주지방법원 남원지원 장수군법원',
    '전주지방법원 남원지원 순창군법원',
    '광주고등법원 (전주)',
  ],
  광주: [
    '광주가정법원',
    '광주고등법원',
    '광주지방법원',
    '광주지방법원 목포지원',
    '광주지방법원 장흥지원',
    '광주지방법원 순천지원',
    '광주지방법원 해남지원',
    '광주지방법원 곡성군법원',
    '광주지방법원 영광군법원',
    '광주지방법원 나주시법원',
    '광주지방법원 장성군법원',
    '광주지방법원 화순군법원',
    '광주지방법원 담양군법원',
    '광주지방법원 목포지원 함평군법원',
    '광주지방법원 목포지원 영암군법원',
    '광주지방법원 목포지원 무안군법원',
    '광주지방법원 장흥지원 강진군법원',
    '광주지방법원 순천지원 보성군법원',
    '광주지방법원 순천지원 고흥군법원',
    '광주지방법원 순천지원 여수시법원',
    '광주지방법원 순천지원 구례군법원',
    '광주지방법원 순천지원 광양시법원',
    '광주지방법원 해남지원 완도군법원',
    '광주지방법원 해남지원 진도군법원',
  ],
  창원: [
    '창원지방법원',
    '창원지방법원 진주지원',
    '창원지방법원 통영지원',
    '창원지방법원 밀양지원',
    '창원지방법원 거창지원',
    '창원지방법원 마산지원',
    '창원지방법원 마산지원 함안군법원',
    '창원지방법원 창원남부시법원',
    '창원지방법원 김해시법원',
    '창원지방법원 마산지원 의령군법원',
    '창원지방법원 진주지원 하동군법원',
    '창원지방법원 진주지원 사천시법원',
    '창원지방법원 진주지원 남해군법원',
    '창원지방법원 진주지원 산청군법원',
    '창원지방법원 통영지원 거제시법원',
    '창원지방법원 통영지원 고성군법원',
    '창원지방법원 밀양지원 창녕군법원',
    '창원지방법원 거창지원 합천군법원',
    '창원지방법원 거창지원 함양군법원',
    '부산고등법원 (창원)',
  ],
  대구: [
    '대구가정법원',
    '대구고등법원',
    '대구지방법원',
    '대구지방법원 안동지원',
    '대구지방법원 경주지원',
    '대구지방법원 김천지원',
    '대구지방법원 상주지원',
    '대구지방법원 의성지원',
    '대구지방법원 영덕지원',
    '대구지방법원 포항지원',
    '대구지방법원 서부지원',
    '대구지방법원 청도군법원',
    '대구지방법원 영천시법원',
    '대구지방법원 칠곡군법원',
    '대구지방법원 서부지원 성주군법원',
    '대구지방법원 경산시법원',
    '대구지방법원 서부지원 고령군법원',
    '대구지방법원 안동지원 영주시법원',
    '대구지방법원 안동지원 봉화군법원',
    '대구지방법원 김천지원 구미시법원',
    '대구지방법원 상주지원 예천군법원',
    '대구지방법원 상주지원 문경시법원',
    '대구지방법원 의성지원 청송군법원',
    '대구지방법원 의성지원 군위군법원',
    '대구지방법원 영덕지원 울진군법원',
    '대구지방법원 영덕지원 영양군법원',
  ],
  대전: [
    '대전가정법원',
    '대전고등법원',
    '대전지방법원',
    '대전지방법원 홍성지원',
    '대전지방법원 논산지원',
    '대전지방법원 천안지원',
    '대전지방법원 공주지원',
    '대전지방법원 서산지원',
    '대전지방법원 세종특별자치시법원',
    '대전지방법원 금산군법원',
    '대전지방법원 홍성지원 서천군법원',
    '대전지방법원 홍성지원 보령시법원',
    '대전지방법원 홍성지원 예산군법원',
    '대전지방법원 논산지원 부여군법원',
    '대전지방법원 천안지원 아산시법원',
    '대전지방법원 공주지원 청양군법원',
    '대전지방법원 서산지원 태안군법원',
    '대전지방법원 서산지원 당진시법원',
    '특허법원',
  ],
  청주: [
    '청주지방법원',
    '청주지방법원 충주지원',
    '청주지방법원 제천지원',
    '청주지방법원 영동지원',
    '청주지방법원 보은군법원',
    '청주지방법원 괴산군법원',
    '청주지방법원 진천군법원',
    '청주지방법원 충주지원 음성군법원',
    '청주지방법원 제천지원 단양군법원',
    '청주지방법원 영동지원 옥천군법원',
    '대전고등법원 (청주)',
  ],
  춘천: [
    '춘천지방법원',
    '춘천지방법원 강릉지원',
    '춘천지방법원 원주지원',
    '춘천지방법원 속초지원',
    '춘천지방법원 영월지원',
    '춘천지방법원 인제군법원',
    '춘천지방법원 홍천군법원',
    '춘천지방법원 양구군법원',
    '춘천지방법원 화천군법원',
    '춘천지방법원 강릉지원 삼척시법원',
    '춘천지방법원 강릉지원 동해시법원',
    '춘천지방법원 원주지원 횡성군법원',
    '춘천지방법원 속초지원 고성군법원',
    '춘천지방법원 속초지원 양양군법원',
    '춘천지방법원 영월지원 정선군법원',
    '춘천지방법원 영월지원 태백시법원',
    '춘천지방법원 영월지원 평창군법원',
    '서울고등법원 (춘천)',
  ],
  수원: [
    '수원가정법원',
    '수원고등법원',
    '수원지방법원',
    '수원지방법원 성남지원',
    '수원지방법원 여주지원',
    '수원지방법원 평택지원',
    '수원지방법원 안양지원',
    '수원지방법원 평택지원 안성시법원',
    '수원지방법원 용인시법원',
    '수원지방법원 오산시법원',
    '수원지방법원 안산지원 광명시법원',
    '수원지방법원 안산지원',
    '수원지방법원 성남지원 광주시법원',
    '수원지방법원 여주지원 양평군법원',
    '수원지방법원 여주지원 이천시법원',
  ],
  인천: [
    '인천가정법원',
    '인천지방법원',
    '인천지방법원 부천지원',
    '인천지방법원 강화군법원',
    '인천지방법원 부천지원 김포시법원',
    '서울고등법원 (인천)',
  ],
  의정부: [
    '의정부지방법원',
    '의정부지방법원 고양지원 파주시법원',
    '의정부지방법원 포천시법원',
    '의정부지방법원 남양주지원 가평군법원',
    '의정부지방법원 남양주지원',
    '의정부지방법원 연천군법원',
    '의정부지방법원 철원군법원',
    '의정부지방법원 고양지원',
    '의정부지방법원 동두천시법원',
  ],
  부산: [
    '부산가정법원',
    '부산고등법원',
    '부산지방법원',
    '부산지방법원 동부지원',
    '부산지방법원 서부지원',
  ],
  울산: ['울산지방법원', '울산지방법원 양산시법원', '부산고등법원 (울산)'],
};

export const districtAndPlaces = {
  서울: [
    '서울고등검찰청',
    '서울중앙지방검찰청',
    '서울동부지방검찰청',
    '서울남부지방검찰청',
    '서울북부지방검찰청',
    '서울서부지방검찰청',
  ],
  의정부: ['의정부지방검찰청', '의정부지방검찰청 고양지청'],
  인천: ['인천지방검찰청', '인천지방검찰청 부천지청'],
  춘천: [
    '춘천지방검찰청',
    '춘천지방검찰청 강릉지청',
    '춘천지방검찰청 원주지청',
    '춘천지방검찰청 속초지청',
    '춘천지방검찰청 영월지청',
  ],
  수원: [
    '수원고등검찰청',
    '수원지방검찰청',
    '수원지방검찰청 성남지청',
    '수원지방검찰청 여주지청',
    '수원지방검찰청 평택지청',
    '수원지방검찰청 안산지청',
    '수원지방검찰청 안양지청',
  ],
  대전: [
    '대전고등검찰청',
    '대전지방검찰청',
    '대전지방검찰청 홍성지청',
    '대전지방검찰청 공주지청',
    '대전지방검찰청 논산지청',
    '대전지방검찰청 서산지청',
    '대전지방검찰청 천안지청',
  ],
  청주: [
    '청주지방검찰청',
    '청주지방검찰청 충주지청',
    '청주지방검찰청 제천지청',
    '청주지방검찰청 영동지청',
  ],
  대구: [
    '대구고등검찰청',
    '대구지방검찰청',
    '대구지방검찰청 안동지청',
    '대구지방검찰청 경주지청',
    '대구지방검찰청 김천지청',
    '대구지방검찰청 상주지청',
    '대구지방검찰청 의성지청',
    '대구지방검찰청 영덕지청',
    '대구지방검찰청 포항지청',
    '대구지방검찰청 서부지청',
  ],
  부산: [
    '부산고등검찰청',
    '부산지방검찰청',
    '부산지방검찰청 동부지청',
    '부산지방검찰청 서부지청',
  ],
  창원: [
    '창원지방검찰청',
    '창원지방검찰청 진주지청',
    '창원지방검찰청 통영지청',
    '창원지방검찰청 밀양지청',
    '창원지방검찰청 거창지청',
    '창원지방검찰청 마산지청',
  ],
  울산: ['울산지방검찰청'],
  광주: [
    '광주고등검찰청',
    '광주지방검찰청',
    '광주지방검찰청 목포지청',
    '광주지방검찰청 장흥지청',
    '광주지방검찰청 순천지청',
    '광주지방검찰청 해남지청',
  ],
  전주: [
    '전주지방검찰청',
    '전주지방검찰청 군산지청',
    '전주지방검찰청 정읍지청',
    '전주지방검찰청 남원지청',
  ],
  제주: ['제주지방검찰청'],
};

export const places = Object.keys(districtAndPlaces)
  .map((it) => districtAndPlaces[it])
  .reduce((a, b) => a.concat(b), []);
export const districts = [
  '서울',
  '제주',
  '전주',
  '광주',
  '창원',
  '대구',
  '대전',
  '청주',
  '춘천',
  '수원',
  '인천',
  '의정부',
  '부산',
  '울산',
].sort((a, b) => a.localeCompare(b));
export const associations = [
  '미정',
  '서울지방변호사회',
  '경기북부지방변호사회',
  '인천지방변호사회',
  '경기중앙지방변호사회',
  '강원지방변호사회',
  '충북지방변호사회',
  '대전지방변호사회',
  '대구지방변호사회',
  '부산지방변호사회',
  '울산지방변호사회',
  '경남지방변호사회',
  '광주지방변호사회',
  '전북지방변호사회',
  '제주지방변호사회',
];
