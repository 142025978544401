import { Inject, Injectable, PLATFORM_ID } from '@angular/core'
import { isPlatformServer } from '@angular/common'

declare var userpilot

@Injectable({ providedIn: 'root' })
export class UserpilotService {
  constructor(@Inject(PLATFORM_ID) private platform) {
    if (isPlatformServer(this.platform)) {
      return
    }
  }

  get instance() {
    if (typeof userpilot === 'undefined') {
      return null
    }
    return userpilot
  }
}
