import {
  AfterViewInit,
  Component,
  HostListener,
  Inject,
  OnDestroy,
  OnInit,
  PLATFORM_ID,
  ViewChild,
} from '@angular/core';
import { DOCUMENT, isPlatformBrowser, isPlatformServer } from '@angular/common';
import { UserService } from './user/user.service';
import { MatDrawer } from '@angular/material/sidenav';
import { NavigationEnd, Router } from '@angular/router';
import { UserpilotService } from '../app/shared/userpilot.service';
import { localStorageKeys } from './user/constants';
import { LocalStorageService } from './core/local-storage.service';
import { Subscription } from 'rxjs';
import { TrackingService } from './core/tracking.service';
import { first } from 'rxjs/operators';

import { PAGE_PATHNAME_WITHOUT_REACT_COMPONENT } from './constants';
import { datadogLogs } from '@datadog/browser-logs';
import { LboxComponentsService } from 'src/app/shared/lbox-components.service';
import { FeatureFlagService } from 'src/app/feature-flag/feature-flag.service';
import { ApiService } from 'ngxSpring/api.service';

@Component({
  selector: 'lb-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.sass'],
})
export class AppComponent implements OnInit, AfterViewInit, OnDestroy {
  trackPreviousUrlSubscription: Subscription = null;

  constructor(
    @Inject(PLATFORM_ID) private platform,
    public userService: UserService,
    @Inject(DOCUMENT) protected document: Document,
    private router: Router,
    private localStorageService: LocalStorageService,
    private trackingService: TrackingService,
    private lboxComponentsService: LboxComponentsService,
    private featureFlagService: FeatureFlagService,
    private apiService: ApiService,
  ) {
    userService.menuOpen.subscribe((v) => {
      if (v) {
        void this.drawer.open();
      } else {
        void this.drawer.close();
      }
    });

    if (isPlatformBrowser(this.platform)) {
      this.trackPreviousUrlSubscription = this.trackPreviousUrl();
    }
  }

  @ViewChild(MatDrawer)
  drawer: MatDrawer;
  edit;

  @HostListener('window:resize')
  setVH() {
    this.document.documentElement.style.setProperty(
      '--vh',
      `${window.innerHeight * 0.01}px`,
    );
  }

  @HostListener('window:popstate')
  onPopState() {
    location.reload();
  }

  ngOnInit(): void {
    /**
     * @description
     * feature flag 초기화 메서드 주석 제거 후 사용.
     */
    // this.featureFlagService.initFeatureFlag();

    this.userService.userUpdated.pipe(first()).subscribe(() => {
      this.lboxComponentsService.importHeader({
        isLoggedIn: this.userService.logged,
        isJobLawyer: this.userService.isLawyer,
        name: this.userService.user?.name ?? '',
      });
    });

    this.lboxComponentsService.importPlanPricingPopUp();

    if (isPlatformServer(this.platform)) {
      return;
    }
    this.setVH();
    this.edit = localStorage.getItem('amplitudeEditMode');
  }

  ngAfterViewInit(): void {
    this.drawer.openedStart.subscribe(() => {
      this.document.scrollingElement.classList.add('overflow-hidden');
    });
    this.drawer.openedChange.subscribe((v) => {
      if (!v)
        this.document.scrollingElement.classList.remove('overflow-hidden');
    });

    if (
      PAGE_PATHNAME_WITHOUT_REACT_COMPONENT.includes(window.location.pathname)
    ) {
      return;
    }

    if (isPlatformServer(this.platform)) {
      return;
    }

    import('@lbox-kr/components')
      .then(({ myCaseUploadModal }) => {
        myCaseUploadModal.mount('react-root');
        window.addEventListener(
          'my-case-upload-modal-event',
          (event: CustomEvent<{ eventType: string }>) => {
            void this.trackingService.tracking(event.detail.eventType, {});
          },
        );
        window.addEventListener(
          'my-case-upload-modal-error',
          ({
            detail,
          }: CustomEvent<{
            message: string;
            context: Record<string, unknown>;
            error: Error;
          }>) => {
            datadogLogs.logger.error(
              detail.message,
              detail.context,
              detail.error,
            );
          },
        );
      })
      .catch((e) => {
        console.error('cannot mount myCaseUploadModal', e);
      });
  }

  ngOnDestroy(): void {
    if (isPlatformBrowser(this.platform)) {
      this.trackPreviousUrlSubscription.unsubscribe();
    }
  }

  /**
   * 이전 url 을 LocalStorage 에 저장해두고 추적하는 메서드
   */
  trackPreviousUrl() {
    return this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        const prevPagePath = this.localStorageService.getItem(
          localStorageKeys.CURRENT_PAGE_PATH,
        );

        this.localStorageService.setItem(
          localStorageKeys.PREV_PAGE_PATH,
          prevPagePath,
        );

        this.localStorageService.setItem(
          localStorageKeys.CURRENT_PAGE_PATH,
          event.urlAfterRedirects,
        );

        if (event.urlAfterRedirects === '/not_found') {
          this.trackNotFoundPage();
        }
      }
    });
  }

  /**
   * 404 page 진입 시 Amplitude 이벤트를 쏜다
   */
  trackNotFoundPage() {
    const previousUrl = this.localStorageService.getItem(
      localStorageKeys.PREV_PAGE_PATH,
    );

    void this.trackingService.tracking('view_error', {
      error_code: 404,
      error_page: previousUrl,
    });
  }
}
