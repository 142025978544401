import { Component } from '@angular/core';
import { UserService } from '../../user/user.service';

@Component({
  selector: 'lb-header-drawer',
  templateUrl: './header-drawer.component.html',
  styleUrls: ['./header-drawer.component.sass'],
})
export class HeaderDrawerComponent {
  constructor(public userService: UserService) {}

  get href() {
    return window.location.href;
  }

  close() {
    this.userService.menuOpen.emit(false);
  }
}
