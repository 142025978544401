/* tslint:disable */
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ApiHttp} from '../api.http';

import {LboxUser, Page, PointLog, PointLogType, PointQueryType} from '../api.model';

@Injectable()
export class PointLogController {
  constructor(private http: ApiHttp) {
  }

  addPoint(userId: number, amount: number, reason?: string): Observable<LboxUser> {
    return this.http.post<LboxUser>('/api/pointLog/addPoint', null, {userId: userId, amount: amount, reason: reason});
  }

  getAllPointLogList(page?: number, size?: number, from?: number, to?: number, type?: PointLogType, keyword?: string): Observable<Page<PointLog>> {
    return this.http.get<Page<PointLog>>('/api/pointLog/list/admin', {page: page, size: size, from: from, to: to, type: type, keyword: keyword});
  }

  getDayList(from: number, to: number): Observable<string[]> {
    return this.http.get<string[]>('/api/pointLog/dayList', {from: from, to: to});
  }

  getMonthList(): Observable<string[]> {
    return this.http.get<string[]>('/api/pointLog/monthList');
  }

  getPointLogList(page?: number, size?: number, from?: number, to?: number, type?: PointQueryType): Observable<Page<PointLog>> {
    return this.http.get<Page<PointLog>>('/api/pointLog/list', {page: page, size: size, from: from, to: to, type: type});
  }
}
