<lb-popover
  #popover
  [popoverContent]="popoverContent"
  trackId="e948"
  (click)="alarmService.read(); alarmService.refreshAlarm()"
  xPosition="before"
>
  <ng-content></ng-content>
</lb-popover>
<ng-template #popoverContent>
  <lb-alarm-board [popover]="popover"></lb-alarm-board>
</ng-template>
