import tippy, { hideAll, inlinePositioning } from 'tippy.js';
import {
  Directive,
  ElementRef,
  Inject,
  Input,
  OnDestroy,
  PLATFORM_ID,
} from '@angular/core';
import { isPlatformServer } from '@angular/common';

@Directive({
  exportAs: 'tip',
  selector: '[lbTip]',
})
export class TipDirective implements OnDestroy {
  tip;

  @Input()
  public set lbTip(tip) {
    if (isPlatformServer(this.platform)) {
      return;
    }
    if (!tip) {
      if (this.tipInstance) this.ngOnDestroy();
      return;
    }
    if (this.tipInstance) {
      this.tipInstance.setContent(tip.tip || tip);
      return;
    }
    const trigger = window.innerWidth >= 768 ? 'mouseenter focus' : 'click';
    this.tipInstance = tippy(this.el.nativeElement, {
      // duration: [0, 90000000],
      zIndex: tip.zIndex,
      showOnCreate: tip.showOnCreate,
      theme: tip.theme,
      animation: tip.animation,
      content: tip.tip || tip,
      allowHTML: true,
      interactive: true,
      delay: tip.delay,
      hideOnClick: tip.hideOnClick,
      trigger: tip.trigger || trigger,
      onHide: tip.onHide,
      onCreate: tip.onCreate,
      onDestroy: tip.onDestroy,
      placement: tip.placement,
      offset: tip.offset,
      onShow: () => {
        hideAll();
      },
      appendTo: tip.appendTo
        ? document.querySelector(tip.appendTo)
        : document.body,
      plugins: [inlinePositioning],
      inlinePositioning: true,
      popperOptions: {
        strategy: tip.position || 'fixed',
      },
    });
  }

  tipInstance;

  constructor(private el: ElementRef, @Inject(PLATFORM_ID) private platform) {}

  ngOnDestroy(): void {
    if (this.tipInstance) {
      this.tipInstance.destroy();
      this.tipInstance = null;
    }
  }

  hide() {
    this.tipInstance?.hide();
  }

  show() {
    this.tipInstance?.show();
  }

  showThen() {
    return new Promise<void>((r) => {
      this.show();
      const onHide = this.tipInstance.props.onHide;
      this.tipInstance.setProps({
        onHide: () => {
          r();
          this.tipInstance.setProps({ onHide });
        },
      });
    });
  }
}
