/* tslint:disable */
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ApiHttp} from '../api.http';

import {Company, Page} from '../api.model';

@Injectable()
export class CompanyController {
  constructor(private http: ApiHttp) {
  }

  deleteCompany(id: number): Observable<void> {
    return this.http.delete<void>('/api/company', {id: id});
  }

  getCompanyList(page?: number, size?: number, keyword?: string): Observable<Page<Company>> {
    return this.http.get<Page<Company>>('/api/company/list', {page: page, size: size, keyword: keyword});
  }

  saveCompany(company: Company): Observable<Company> {
    return this.http.post<Company>('/api/company', company);
  }
}
