/* tslint:disable */
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ApiHttp} from '../api.http';

import {JudgeProfile, Page} from '../api.model';

@Injectable()
export class JudgeProfileController {
  constructor(private http: ApiHttp) {
  }

  getJudgeProfile(name: string, serial: string): Observable<JudgeProfile> {
    return this.http.get<JudgeProfile>('/api/judge/detail', {name: name, serial: serial});
  }

  getJudgeProfileList(page?: number, size?: number, name?: string, serial?: string): Observable<Page<JudgeProfile>> {
    return this.http.get<Page<JudgeProfile>>('/api/judge/list', {page: page, size: size, name: name, serial: serial});
  }

  getJudgeProfileSameNameList(name?: string, serial?: string): Observable<JudgeProfile[]> {
    return this.http.get<JudgeProfile[]>('/api/judge/sameName', {name: name, serial: serial});
  }

  save(judgeProfile: JudgeProfile): Observable<JudgeProfile> {
    return this.http.post<JudgeProfile>('/api/judge/save', judgeProfile);
  }

  updateJudgeProfile(judgeProfile: JudgeProfile): Observable<JudgeProfile> {
    return this.http.post<JudgeProfile>('/api/judge/update', judgeProfile);
  }
}
