<div class="verify-container">
  <div class="verify-header">
    <div class="verify-title">
      <h4>첨부 시 주의 사항을 확인하셨나요?</h4>
    </div>
  </div>

  <div class="verify-body">
    <ul class="verify-list">
      <li>이름, 학교, 학과, 입학연도 확인이 어렵거나 유효하지 않은 학생증은 반려될 수 있습니다.</li>
      <li>체크카드 겸용 학생증인 경우 카드번호와 유효기간이 보이지 않도록 마스킹 처리 후 첨부해주세요.
        마스킹 처리를 하지 않은 경우 즉시 파기 되며 요청이 반려됩니다.</li>
    </ul>
  </div>

  <div class="verify-footer">
    <button class="verify-button-confirm" (click)="handleClickConfirmButton()">확인</button>
  </div>
</div>
