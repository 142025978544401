import { Component, HostBinding } from '@angular/core';
import { DialogOrBottomSheetProto } from '../../../alert/alert.service';
import { UserService } from '../../user.service';
import { isIOS } from '../../../core/util';

@Component({
  selector: 'lb-select-cases',
  templateUrl: './select-cases.component.html',
  styleUrls: ['./select-cases.component.sass'],
})
export class SelectCasesComponent extends DialogOrBottomSheetProto<any> {
  user = this.injector.get(UserService);

  get selected() {
    return this.user?.candidateCases.find((it) => it.type);
  }

  async selectDone() {
    if (
      !this.user.authorized &&
      !(await this.user.authorizeLawyer({ channel: '수행사건 매칭' }))
    )
      return;
    const items = this.user.candidateCases.filter((it) => it.type);
    this.api.userInvolveCase
      .marking(
        items.map((it) => {
          return { docId: it.doc_id, type: it.type };
        }),
      )
      .subscribe(() => {
        this.user.candidateCases.removeAll(items);
        this.close();
        if (this.data) {
          this.alert.pop('수행여부가 표시되었습니다.');
          return;
        }
        this.user.afterMark();
      });
  }

  @HostBinding('style.height')
  get height() {
    if (isIOS()) return 'calc(100vh - 128px)';
  }
}
