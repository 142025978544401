import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { DialogOrBottomSheetProto } from '../../../alert/alert.service';
import { UserService } from '../../user.service';
import { passwordValidators } from '../../../core/validators';
import { PasswordResetWithTokenComponent } from '../password-reset-with-token/password-reset-with-token.component';

@Component({
  selector: 'lb-login-recommend',
  templateUrl: './login-recommend.component.html',
  styleUrls: ['./login-recommend.component.sass'],
})
export class LoginRecommendComponent extends DialogOrBottomSheetProto<any> {
  userService: UserService = this.injector.get(UserService);

  async login() {
    const error = passwordValidators.find((it: any, index) => {
      if (index % 2 == 0) return it({ value: this.data.password });
    });
    if (error) {
      this.alert.pop(
        passwordValidators[passwordValidators.indexOf(error) + 1] as any,
      );
      return;
    }
    this.api.user
      .login(this.data, await this.userService.deviceService.deviceId())
      .subscribe((u) => {
        this.userService.updateUser(u);
        this.alert.pop('로그인 되었습니다.');
        if (this.data.isLwork) this.injector.get(Router).navigateByUrl('/work');
        else this.injector.get(Router).navigateByUrl('/');
        this.close();
        if (this.data.snsRecommend) {
          this.alert
            .confirm(
              '알림',
              null,
              `<b>${u.email}</b> 로 로그인되었습니다.<br>위 이메일과 SNS 계정을 연결 하시겠습니까?`,
            )
            .then((v) => {
              if (v)
                this.injector.get(Router).navigateByUrl('/user/myPage/sns');
            });
        }
      });
  }

  passwordResetWithToken() {
    this.alert.open(PasswordResetWithTokenComponent, {
      token: this.data.certificate.imp_uid,
      isLwork: this.data.isLwork,
    });
  }
}
