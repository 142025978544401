import { Component } from '@angular/core';
import { DialogOrBottomSheetProto } from '../alert.service';
import { UserService } from '../../user/user.service';

@Component({
  selector: 'lb-price-plan',
  templateUrl: './price-plan.component.html',
  styleUrls: ['./price-plan.component.sass'],
})
export class PricePlanComponent extends DialogOrBottomSheetProto<any> {
  user = this.injector.get(UserService);
}
