import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FooterComponent } from './footer/footer.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HeaderButtonsComponent } from './header-buttons/header-buttons.component';
import { MatMenuModule } from '@angular/material/menu';
import { CaseSearchComponent } from '../core/case-search/case-search.component';
import { ErrForComponent } from './err-for/err-for.component';
import { MatInputModule } from '@angular/material/input';
import { RouterModule } from '@angular/router';
import { ReplacePipe } from './pipes/replace.pipe';
import { SplitPipe } from './pipes/split.pipe';
import { FilePathPipe } from './pipes/file-path.pipe';
import { ContentLoaderComponent } from './content-loader/content-loader.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { LogoAndFooterFormComponent } from './logo-and-footer-form/logo-and-footer-form.component';
import { MaxLengthPipe } from './pipes/max-length.pipe';
import { TipDirective } from './tip.directive';
import { TrackModule } from '../track/track.module';
import { PopoverComponent } from './popover/popover.component';
import { MomentModule } from 'ngx-moment';
import * as moment from 'moment';
import ko from '@angular/common/locales/ko';
import { AlarmComponent } from './alarm/alarm.component';
import { CheckboxComponent } from './checkbox/checkbox.component';
import { DragScrollDirective } from './drag-scroll.directive';
import { SearchResultCaseComponent } from './search-result-case/search-result-case.component';
import { HeaderAndFooterTemplateComponent } from './header-and-footer-template/header-and-footer-template.component';
import { AutosizeDirective } from './autosize.directive';
import { MatChipsModule } from '@angular/material/chips';
import { TextInputHighlightModule } from 'angular-text-input-highlight';
import { CourtInputComponent } from './court-input/court-input.component';
import { MatButtonModule } from '@angular/material/button';
import { NewLinePipe } from './pipes/new-line.pipe';
import { RegisterPopoverComponent } from './register-popover/register-popover.component';
import { SnsLoginComponent } from './sns-login/sns-login.component';
import { ScrapMenuComponent } from './header-buttons/scrap-menu/scrap-menu.component';
import { ScrapMenuFolderButtonComponent } from './header-buttons/scrap-menu/scrap-menu-folder-button.component';
import { ClickOutsideModule } from 'ng-click-outside';
import { PaginationComponent } from './pagination/pagination.component';
import { PageablePaginationComponent } from './pageable-pagination/pageable-pagination.component';
import { ReferenceSummaryComponent } from './reference-summary/reference-summary.component';
import { PreventNullPipe } from './pipes/prevent-null.pipe';
import { CaseSearchOpenerComponent } from './case-search-opener/case-search-opener.component';
import { FlexModule } from '@angular/flex-layout';
import { CaseSearchCompactComponent } from './case-search-compact/case-search-compact.component';
import { DialogCaseSearchComponent } from './dialog-case-search/dialog-case-search.component';
import { TemplateDialogComponent } from './template-dialog/template-dialog.component';
import { EnumValuesPipe } from './pipes/enum-values.pipe';
import { NgInitDirective } from './ng-init.directive';
import { LawSelectComponent } from './search-with-law/law-select/law-select.component';
import { SearchWithLawComponent } from './search-with-law/search-with-law.component';
import { EllipsisPipe } from './pipes/ellipsis.pipe';
import { SuggestionComponent } from './suggestion/suggestion.component';
import { RemoveReserveTagsPipe } from './pipes/remove-reserve-tags.pipe';
import { InputModule } from '../alert/input/input.module';
import { OptionNamePipe } from './pipes/option-name.pipe';
import { KeywordBoldPipe } from './pipes/keyword-bold.pipe';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { SuggestionService } from './suggestion/suggestion.service';
import { DialogOrderComponent } from './dialog-order/dialog-order.component';
import { MatSelectModule } from '@angular/material/select';
import { UploadAreaComponent } from './upload-area/upload-area.component';
import { FileUploadListComponent } from './file-upload-list/file-upload-list.component';
import { MyCaseTipComponent } from './file-upload-list/my-case-tip/my-case-tip.component';
import { MyCaseTipDialogComponent } from './file-upload-list/my-case-tip/my-case-tip-dialog/my-case-tip-dialog.component';
import { ShowWhenSizeDirective } from './show-when-size.directive';
import { ShowWhenMobileDirective } from './show-when-mobile.directive';
import { MatRadioModule } from '@angular/material/radio';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { FilterByPipe } from './filter-by.pipe';
import { SelectFolderComponent } from './select-folder/select-folder.component';
import { CaseLinkDirective } from './case-link.directive';
import {
  MenuOrBottomSheetDirective,
  OpenerDirective,
} from './menu-or-bottom-sheet.directive';
import { BottomSheetTemplateComponent } from './bottom-sheet-template/bottom-sheet-template.component';
import { CaseInteractPanelComponent } from './case-interact-panel/case-interact-panel.component';
import { DayLogListComponent } from './day-log-list/day-log-list.component';
import { OrderModule } from 'ngx-order-pipe';
import { JosaPipe } from './josa.pipe';
import { RequestStatesPipe } from '../user/request-states.pipe';
import { CaseTypePipe } from '../case/case-type.pipe';
import { SearchResultNewsComponent } from './search-result-news/search-result-news.component';
import { JudgeSearchComponent } from './judge-search/judge-search.component';
import { DateTimeComponent } from './date-time/date-time.component';
import { ScrollHandleComponent } from './scroll-handle/scroll-handle.component';
import { UserSuggestionComponent } from './user-suggestion/user-suggestion.component';
import { DoCaseHelpDirective } from './do-case-help.directive';
import { UserRecommendsComponent } from './user-recommends/user-recommends.component';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { MyCaseRegisterEventAlertDirective } from './my-case-register-event-alert.directive';
import { AlarmBoardComponent } from './alarm/alarm-board/alarm-board.component';
import { AlarmMobileComponent } from './alarm/alarm-mobile.component';
import { LboxSelectWhyComponent } from './lbox-select-why/lbox-select-why.component';
import { MatTabsModule } from '@angular/material/tabs';
import { MainChartComponent } from './main-chart/main-chart.component';
import { HeaderWithCompactSearchComponent } from './header/header-with-compact-search.component';
import { LinkDirectivesModule } from '../core/link-directives/link-directives.module';
import { HeaderModuleModule } from '../core/header-drawer/header-module.module';
import { LboxServicesComponent } from './header-buttons/lbox-services/lbox-services.component';
import { ArchiveDialogComponent } from './archive-dialog/archive-dialog.component';
import { MobileHeaderWithBackButtonComponent } from './mobile-header-with-back-button/mobile-header-with-back-button.component';
import { ArchiveCaseMoveDialogComponent } from './archive-case-move-dialog/archive-case-move-dialog.component';
import { CaseDailyListComponent } from './case-daily-list/case-daily-list.component';
import { LqaSelectYearComponent } from '../lqa/lqa-select-year/lqa-select-year.component';
import { ConfirmInfoTipComponent } from './search-result-case/confirm-info-tip/confirm-info-tip.component';
import { LqaProfileUpdateComponent } from './lqa-profile-update/lqa-profile-update.component';
import { NgxMaskModule } from 'ngx-mask';
import { LawSchoolAuthorizeComponent } from '../user/dialogs/law-school-authorize/law-school-authorize.component';
import { SelectCasesComponent } from '../user/dialogs/select-cases/select-cases.component';
import { StatuteBadgeComponent } from './statute-badge/statute-badge.component';
import { StatuteLinkDirective } from './statute-link.directive';
import { IsReadPipe } from './is-read.pipe';
import { DateUndefinedPipe } from './date-undefined.pipe';
import { ChangeTypeComponent } from '../user/dialogs/change-type/change-type.component';
import { LboxUserTypeComponent } from '../register/lbox-user-type/lbox-user-type.component';
import { InputForEtcComponent } from '../register/input-for-etc/input-for-etc.component';
import { NewsLinkDirective } from './search-result-news/news-link.directive';
import { InputTrimDirective } from './trim.directive';
import { LawyerAssosiationInputComponent } from './lawyer-assosiation-input/lawyer-assosiation-input.component';
import { IsInViewport } from './is-in-viewport.directive';
import { UserpilotService } from './userpilot.service';
import { PersonalInformationCollectAndUsageInfoTable } from './personal-information-collect-and-usage-info-table/personal-information-collect-and-usage-info-table';
import { CompanyLogoSliderComponent } from './company-logo-slider/company-logo-slider.component';
import { UrlService } from './url.service';
import { UploadAreaForLawSchoolAuthorizationComponent } from './upload-area-for-law-school-authorization/upload-area-for-law-school-authorization.component';
import { WarningLawSchoolAuthorizationComponent } from '../warning-law-school-authorization/warning-law-school-authorization.component';
import { MountMonitorDirective } from './mount-monitor.directive';
import { MatTooltipModule } from '@angular/material/tooltip';

@NgModule({
  declarations: [
    NewsLinkDirective,
    ChangeTypeComponent,
    LawSchoolAuthorizeComponent,
    SelectCasesComponent,
    CaseDailyListComponent,
    ArchiveCaseMoveDialogComponent,
    MainChartComponent,
    RequestStatesPipe,
    TemplateDialogComponent,
    CaseInteractPanelComponent,
    ErrForComponent,
    FooterComponent,
    ReplacePipe,
    SplitPipe,
    TipDirective,
    SearchResultCaseComponent,
    HeaderButtonsComponent,
    CaseSearchOpenerComponent,
    FilePathPipe,
    ContentLoaderComponent,
    LogoAndFooterFormComponent,
    MaxLengthPipe,
    PopoverComponent,
    AlarmComponent,
    AlarmMobileComponent,
    CheckboxComponent,
    DragScrollDirective,
    HeaderAndFooterTemplateComponent,
    AutosizeDirective,
    CourtInputComponent,
    DialogOrderComponent,
    NewLinePipe,
    SnsLoginComponent,
    SearchResultNewsComponent,
    RegisterPopoverComponent,
    ReferenceSummaryComponent,
    ScrapMenuFolderButtonComponent,
    PaginationComponent,
    PageablePaginationComponent,
    ScrapMenuComponent,
    SelectFolderComponent,
    PreventNullPipe,
    CaseSearchCompactComponent,
    DialogCaseSearchComponent,
    EnumValuesPipe,
    NgInitDirective,
    CaseTypePipe,
    SearchWithLawComponent,
    LawSelectComponent,
    EllipsisPipe,
    DayLogListComponent,
    SuggestionComponent,
    RemoveReserveTagsPipe,
    OptionNamePipe,
    KeywordBoldPipe,
    FileUploadListComponent,
    MyCaseTipComponent,
    MyCaseTipDialogComponent,
    ShowWhenMobileDirective,
    FilterByPipe,
    CaseLinkDirective,
    MenuOrBottomSheetDirective,
    OpenerDirective,
    BottomSheetTemplateComponent,
    JosaPipe,
    JudgeSearchComponent,
    DateTimeComponent,
    ScrollHandleComponent,
    UserSuggestionComponent,
    DoCaseHelpDirective,
    UserRecommendsComponent,
    MyCaseRegisterEventAlertDirective,
    AlarmBoardComponent,
    LboxSelectWhyComponent,
    HeaderWithCompactSearchComponent,
    LboxServicesComponent,
    ArchiveDialogComponent,
    MobileHeaderWithBackButtonComponent,
    LqaSelectYearComponent,
    ConfirmInfoTipComponent,
    LqaProfileUpdateComponent,
    StatuteBadgeComponent,
    StatuteLinkDirective,
    IsReadPipe,
    DateUndefinedPipe,
    LboxUserTypeComponent,
    InputForEtcComponent,
    InputTrimDirective,
    LawyerAssosiationInputComponent,
    IsInViewport,
    PersonalInformationCollectAndUsageInfoTable,
    CompanyLogoSliderComponent,
    UploadAreaForLawSchoolAuthorizationComponent,
    WarningLawSchoolAuthorizationComponent,
    MountMonitorDirective,
  ],
  exports: [
    LboxUserTypeComponent,
    InputForEtcComponent,
    ArchiveDialogComponent,
    HeaderModuleModule,
    RequestStatesPipe,
    CaseInteractPanelComponent,
    SearchResultNewsComponent,
    TemplateDialogComponent,
    InputModule,
    OptionNamePipe,
    DialogOrderComponent,
    PaginationComponent,
    SelectFolderComponent,
    SearchResultCaseComponent,
    SnsLoginComponent,
    MatMenuModule,
    CommonModule,
    DayLogListComponent,
    TipDirective,
    ReferenceSummaryComponent,
    ReplacePipe,
    SplitPipe,
    ErrForComponent,
    ReactiveFormsModule,
    FormsModule,
    FooterComponent,
    CaseSearchOpenerComponent,
    MaxLengthPipe,
    HeaderButtonsComponent,
    CaseSearchComponent,
    FilePathPipe,
    ContentLoaderComponent,
    LogoAndFooterFormComponent,
    TrackModule,
    FlexModule,
    PopoverComponent,
    CheckboxComponent,
    DragScrollDirective,
    HeaderAndFooterTemplateComponent,
    AutosizeDirective,
    CourtInputComponent,
    NewLinePipe,
    PageablePaginationComponent,
    PreventNullPipe,
    CaseSearchCompactComponent,
    OrderModule,
    CaseTypePipe,
    DialogCaseSearchComponent,
    ClickOutsideModule,
    EnumValuesPipe,
    NgInitDirective,
    SearchWithLawComponent,
    LawSelectComponent,
    EllipsisPipe,
    SuggestionComponent,
    KeywordBoldPipe,
    RemoveReserveTagsPipe,
    MyCaseTipComponent,
    ShowWhenSizeDirective,
    FilterByPipe,
    CaseLinkDirective,
    MenuOrBottomSheetDirective,
    OpenerDirective,
    ShowWhenMobileDirective,
    JosaPipe,
    JudgeSearchComponent,
    DateTimeComponent,
    ScrollHandleComponent,
    UserSuggestionComponent,
    DoCaseHelpDirective,
    UserRecommendsComponent,
    MyCaseRegisterEventAlertDirective,
    MatRadioModule,
    LboxSelectWhyComponent,
    HeaderWithCompactSearchComponent,
    LinkDirectivesModule,
    MobileHeaderWithBackButtonComponent,
    CaseDailyListComponent,
    LqaSelectYearComponent,
    StatuteBadgeComponent,
    StatuteLinkDirective,
    IsReadPipe,
    DateUndefinedPipe,
    InputTrimDirective,
    LawyerAssosiationInputComponent,
    IsInViewport,
    PersonalInformationCollectAndUsageInfoTable,
    CompanyLogoSliderComponent,
    MountMonitorDirective,
  ],
  imports: [
    ShowWhenSizeDirective,
    UploadAreaComponent,
    InputModule,
    OrderModule,
    ClickOutsideModule,
    TrackModule,
    MatMenuModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    RouterModule,
    MatAutocompleteModule,
    MomentModule,
    MatChipsModule,
    TextInputHighlightModule,
    MatButtonModule,
    FlexModule,
    MatCheckboxModule,
    MatSelectModule,
    MatRadioModule,
    PerfectScrollbarModule,
    CarouselModule,
    MatTabsModule,
    HeaderModuleModule,
    LinkDirectivesModule,
    NgxMaskModule,
    MatTooltipModule,
  ],
  providers: [NewLinePipe, SuggestionService, UserpilotService, UrlService],
})
export class SharedModule {}

moment.locale('ko');
