<span
  trackId="83a8"
  [matMenuTriggerFor]="folderContents"
  #trigger="matMenuTrigger"
></span>
<a
  trackOFF
  (click)="
    $event.preventDefault();
    $event.stopPropagation();
    hasItems ? trigger.openMenu() : ''
  "
>
  <ng-content></ng-content>
</a>
<mat-menu
  class="mt-24 folderMenu"
  #folderContents="matMenu"
  [hasBackdrop]="false"
  (clickOutside)="trigger ? trigger.closeMenu() : ''"
>
  <a
    mat-menu-item
    *ngIf="recentCases?.length"
    trackId="7f98"
    routerLink="/bookmark/history"
    [matMenuTriggerFor]="history"
    >열어본 판례</a
  >
  <mat-menu #history>
    <a
      mat-menu-item
      *ngFor="let c of recentCases"
      trackId="opencases"
      [caseLink]="c.caseId"
      >{{ c.title }}</a
    >
  </mat-menu>
  <div class="label" *ngIf="needLabel && folders?.length">판례보관함</div>
  <lb-scrap-menu
    *ngFor="let folder of folders; let last = last"
    #menu
    [folder]="folder"
  >
    <a
      mat-menu-item
      trackId="2a40"
      [routerLink]="folderLink(folder)"
      [matMenuTriggerFor]="menu.menu"
      [class.not-last]="!last"
      *ngIf="menu.hasItems; else elseBlock"
    >
      <span
        class="icon3 n-80 scrap"
        [class.n-95]="folderService.isSharedFolder(folder)"
      ></span>
      <span class="vertical-middle">
        {{ folder?.name || '판례보관함' }}
      </span>
    </a>
    <ng-template #elseBlock>
      <a
        mat-menu-item
        [class.not-last]="!last"
        trackId="c4aa"
        [routerLink]="folderLink(folder)"
        ><span
          class="icon3 n-80 scrap"
          [class.n-95]="folderService.isSharedFolder(folder)"
        ></span>
        <span class="vertical-middle"> {{ folder?.name || '판례보관함' }}</span>
      </a>
    </ng-template>
  </lb-scrap-menu>
  <div class="space" *ngIf="folders?.length && cases?.length"></div>
  <a
    class="case"
    [class.last]="last"
    trackId="b736"
    [lbNewTabLink]="'/' + c.caseId"
    mat-menu-item
    *ngFor="let c of cases; let last = last"
  >
    <span>{{ c.title }}</span>
  </a>
  <div class="label" *ngIf="needLabel && sharedFolders?.length">
    공유받은 판례보관함
  </div>
  <lb-scrap-menu
    *ngFor="let folder of sharedFolders; let last = last"
    #menu
    [folder]="folder"
  >
    <a
      mat-menu-item
      trackId="dfc7"
      [routerLink]="folderLink(folder)"
      [matMenuTriggerFor]="menu.menu"
      [class.not-last]="!last"
      *ngIf="menu.hasItems; else elseBlock"
    >
      <span
        class="icon3 n-80 scrap"
        [class.n-95]="folderService.isSharedFolder(folder)"
      ></span>
      <span class="vertical-middle"> {{ folder?.name || '판례보관함' }}</span>
    </a>
    <ng-template #elseBlock>
      <a
        mat-menu-item
        [class.not-last]="!last"
        trackId="cebc"
        [routerLink]="folderLink(folder)"
        ><span
          class="icon3 n-80 scrap"
          [class.n-95]="folderService.isSharedFolder(folder)"
        ></span>
        <span class="vertical-middle"> {{ folder?.name || '판례보관함' }}</span>
      </a>
    </ng-template>
  </lb-scrap-menu>
</mat-menu>
