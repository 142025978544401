/* tslint:disable */
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ApiHttp} from '../api.http';

import type { 
  CaseAndRelations, 
  MyCaseRegister, 
  MyCaseRegisterState, 
  Page 
} from '../api.model';

@Injectable()
export class MyCaseRegisterController {
  constructor(private http: ApiHttp) {
  }

  approve(idList: any[]): Observable<number> {
    return this.http.post<number>('/api/myCaseRegister/approve', idList);
  }

  approvalPage(myCaseId: string): Observable<CaseAndRelations> {
    return this.http.get<CaseAndRelations>('/api/myCaseRegister/approval-page', { id: myCaseId })
  }

  checkName(name?: string): Observable<boolean> {
    return this.http.get<boolean>('/api/myCaseRegister/checkName', {name: name});
  }

  forceDone(id: number): Observable<boolean> {
    return this.http.post<boolean>('/api/myCaseRegister/forceDone', null, {id: id});
  }

  getMyCaseRegisterList(page?: number, size?: number, keyword?: string, state?: MyCaseRegisterState): Observable<Page<MyCaseRegister>> {
    return this.http.get<Page<MyCaseRegister>>('/api/myCaseRegister/list', {page: page, size: size, keyword: keyword, state: state});
  }

  getMyMyCaseRegisterList(states: any[], page?: number, size?: number): Observable<Page<MyCaseRegister>> {
    return this.http.post<Page<MyCaseRegister>>('/api/myCaseRegister/list/my', states, {page: page, size: size});
  }

  reject(idList: any[], reason: string): Observable<void> {
    return this.http.post<void>('/api/myCaseRegister/reject', idList, {reason: reason});
  }

  rejectDeidentify(id: number, reason: string, docId?: string): Observable<void> {
    return this.http.post<void>('/api/myCaseRegister/rejectDeIdentify', id, {reason: reason, docId: docId});
  }

  reviewDone(id: number, reason?: string): Observable<void> {
    return this.http.post<void>('/api/myCaseRegister/reviewDone', null, {id: id, reason: reason});
  }

  saveMyCaseRegister(myCaseRegister: MyCaseRegister, queryParams?: { ignoreNameCheck: boolean }): Observable<MyCaseRegister> {
    return this.http.post<MyCaseRegister>('/api/myCaseRegister/saveAdmin', myCaseRegister, queryParams);
  }

  saveMyCaseRegisters(myCaseRegisters: any[]): Observable<MyCaseRegister[]> {
    return this.http.post<MyCaseRegister[]>('/api/myCaseRegister', myCaseRegisters);
  }

  startDeIdentify(id: number, url: string, docId: string): Observable<void> {
    return this.http.get<void>('/api/myCaseRegister/startDeIdentify', {id: id, url: url, docId: docId});
  }

  uploaderName(docId: string): Observable<string> {
    return this.http.get<string>('/api/myCaseRegister/uploaderName', {docId: docId}, 'text');
  }
}
