/* tslint:disable */
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ApiHttp} from '../api.http';

import {Holiday} from '../api.model';

@Injectable()
export class HolidayListController {
  constructor(private http: ApiHttp) {
  }

  getHolidayListList(yyyyMM: string): Observable<Holiday[]> {
    return this.http.get<Holiday[]>('/api/holidayList/list', {yyyyMM: yyyyMM});
  }
}
