import { Injectable, NgZone } from '@angular/core';
import { SnsLoginResult } from '../../../../ngxSpring/api.model';
import { ApiService } from '../../../../ngxSpring/api.service';
import { UserService } from '../../user/user.service';
import { AlertService } from '../../alert/alert.service';
import { ScriptService } from 'ngx-script-loader';
import { isInAppBrowser, isIOS, isWhale } from '../../core/util';
import { firstValueFrom } from 'rxjs';
import { datadogLogs } from '@datadog/browser-logs';

declare const Kakao;
declare const gapi;

@Injectable({
  providedIn: 'root',
})
export class SnsLoginService {
  constructor(
    private api: ApiService,
    private userService: UserService,
    private alert: AlertService,
    private scriptService: ScriptService,
    private zone: NgZone,
  ) {}

  async kakao(): Promise<{ result: SnsLoginResult; token: string }> {
    await firstValueFrom(
      this.scriptService.loadScript(
        'https://developers.kakao.com/sdk/js/kakao.min.js',
      ),
    );
    if (!Kakao.isInitialized()) {
      Kakao.init('2a86049af115b0d62adc79bb3e5382c7');
    }

    const s = await this.kakaoLogin();

    const u = await firstValueFrom(
      this.api.snsAuth.startWithSns(
        s.access_token,
        'KAKAO',
        await this.userService.deviceService.deviceId(),
      ),
    );
    return { result: u, token: s.access_token };
  }

  kakaoLogin() {
    return new Promise<any>((r) => {
      Kakao.Auth.login({
        success: (s) => {
          r(s);
        },
      } as any);
    });
  }

  async naver(): Promise<{ result: SnsLoginResult; token: string }> {
    const token = await this.naverLogin();
    const u = await firstValueFrom(
      this.api.snsAuth.startWithSns(
        token,
        'NAVER',
        await this.userService.deviceService.deviceId(),
      ),
    );
    return { result: u, token: token };
  }

  naverLogin() {
    return new Promise<string>((r) => {
      const naverClientId = 'MZSBX8B4wTenvH7NkWGB';

      const url = `redirect_uri=${location.origin}/user/login/naver`;
      datadogLogs.logger.log('네이버 oauth - redirect 주소', { url });

      void this.alert.checkOpen(
        `https://nid.naver.com/oauth2.0/authorize?client_id=${naverClientId}&response_type=token&redirect_uri=${location.origin}/user/login/naver`,
      );
      window.naverCallback = (token) => {
        this.zone.run(() => {
          r(token);
        });
      };
    });
  }

  googleLogin() {
    return new Promise<string>((r) => {
      void firstValueFrom(
        this.scriptService.loadScript(
          'https://apis.google.com/js/platform.js?onload=init',
        ),
      ).then(() => {
        if (isInAppBrowser() || isWhale()) {
          this.alert.arrowAlert(
            '구글 소셜 로그인은 Chrome, Safari, Firefox, Opera, Edge 브라우저에서 이용하실 수 있습니다.',
          );
          return;
        }
        gapi.load('auth2', () => {
          gapi.auth2.authorize(
            {
              client_id:
                '475096476016-4sttu6hphdhsmp44jjc082d5ssh718i1.apps.googleusercontent.com',
              scope: 'email profile openid',
              response_type: 'id_token permission',
            },
            (response) => {
              this.zone.run(() => {
                if (response.error) {
                  console.error(response.error);
                  return;
                }
                const idToken = response.id_token;
                r(idToken);
              });
            },
          );
        });
      });
    });
  }

  async google(): Promise<{ result: SnsLoginResult; token: string }> {
    const idToken = await this.googleLogin();
    const u = await firstValueFrom(
      this.api.snsAuth.startWithSns(
        idToken,
        'GOOGLE',
        await this.userService.deviceService.deviceId(),
      ),
    );
    return { result: u, token: idToken };
  }
}
