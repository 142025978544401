import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { UserService } from '../user/user.service';
import { AlertService } from '../alert/alert.service';
import { isPlatformServer } from '@angular/common';
import { getLBoxNextClientURL, loginUrl } from './util';

@Injectable({
  providedIn: 'root',
})
export class LoginAndRoleRequiredGuard implements CanActivate {
  constructor(
    private user: UserService,
    private alert: AlertService,
    private router: Router,
    @Inject(PLATFORM_ID) private platform,
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    if (isPlatformServer(this.platform)) {
      return true;
    }
    if (this.user.user) {
      return true;
    }
    return new Promise((r) => {
      this.user.ensureUserLoaded().then((u) => {
        if (u && u.role) {
          r(true);
          return;
        }
        this.alert.pop('로그인이 필요한 서비스입니다.');
        r(this.router.parseUrl(loginUrl()));
      });
    });
  }
}

@Injectable({
  providedIn: 'root',
})
export class LoginRequiredGuard implements CanActivate {
  constructor(
    private user: UserService,
    private alert: AlertService,
    private router: Router,
    @Inject(PLATFORM_ID) private platform,
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    if (isPlatformServer(this.platform)) {
      return true;
    }
    return new Promise((r) => {
      this.user.ensureUserLoaded().then((u) => {
        if (u) {
          r(true);
          return;
        }
        this.alert.pop('로그인이 필요한 서비스입니다.');
        window.location.assign(
          getLBoxNextClientURL() +
            `/login?from=${encodeURIComponent(location.href)}`,
        );
      });
    });
  }
}

@Injectable({
  providedIn: 'root',
})
export class LawyerOnlyGuard implements CanActivate {
  constructor(
    private user: UserService,
    private alert: AlertService,
    private router: Router,
    @Inject(PLATFORM_ID) private platform,
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    if (isPlatformServer(this.platform)) {
      return true;
    }
    return new Promise((r) => {
      this.user.ensureUserLoaded().then((u) => {
        if (u) {
          r(this.user.isLawyer);
          return;
        }
        this.alert.pop('로그인이 필요한 서비스입니다.');
        r(this.router.parseUrl(loginUrl()));
      });
    });
  }
}
