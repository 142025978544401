<span *ngIf="menu">
  <ng-content></ng-content>
</span>
<mat-menu #folderContents="matMenu">
  <lb-scrap-menu
    *ngFor="let folder of folders; let last = last"
    [folder]="folder"
    #menu
  >
    <a
      mat-menu-item
      trackId="969c"
      [routerLink]="folderLink(folder)"
      [matMenuTriggerFor]="menu.menu"
      [class.last]="last"
      *ngIf="menu.hasItems; else elseBlock"
    >
      <span
        class="icon3 n-80 scrap"
        [class.n-95]="folderService.isSharedFolder(folder)"
      ></span>
      <span class="vertical-middle">
        {{ folder?.name || '판례보관함' }}
      </span>
    </a>
    <ng-template #elseBlock>
      <a
        mat-menu-item
        [class.last]="last"
        trackId="4f6c"
        [routerLink]="folderLink(folder)"
        ><span
          class="icon3 n-80 scrap"
          [class.n-95]="folderService.isSharedFolder(folder)"
        ></span>
        <span class="vertical-middle">
          {{ folder?.name || '판례보관함' }}
        </span>
      </a>
    </ng-template>
  </lb-scrap-menu>
  <div class="space" *ngIf="folders?.length && cases?.length"></div>
  <a
    class="case"
    trackId="5695"
    [lbNewTabLink]="'/' + c.caseId"
    mat-menu-item
    *ngFor="let c of cases; let last = last"
    [class.last]="last"
  >
    <span>{{ c.title }}</span>
  </a>
</mat-menu>
