import { Component } from '@angular/core';
import { UserService } from 'src/app/user/user.service';
import { LboxComponentsService } from '../lbox-components.service';

@Component({
  selector: 'lb-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.sass'],
})
export class FooterComponent {
  constructor(
    public userService: UserService,
    private lboxComponentsService: LboxComponentsService,
  ) {}

  ngOnInit(): void {
    this.lboxComponentsService.importFooter();
  }
}
