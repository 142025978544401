/* tslint:disable */
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ApiHttp} from '../api.http';

import {SmsVerify} from '../api.model';

@Injectable()
export class SmsVerifyController {
  constructor(private http: ApiHttp) {
  }

  removeDeviceAndLogin(code: string, phone: string, deviceId: string, tokenId: number): Observable<void> {
    return this.http.post<void>('/api/smsVerify/removeDeviceAndLogin', null, {code: code, phone: phone, deviceId: deviceId, tokenId: tokenId});
  }

  requestSms(uId: number, time: number): Observable<string> {
    return this.http.post<string>('/api/smsVerify/request', null, {uId: uId, time: time}, 'text');
  }

  verify(code: string, phone: string): Observable<SmsVerify> {
    return this.http.post<SmsVerify>('/api/smsVerify/verify', null, {code: code, phone: phone});
  }
}
