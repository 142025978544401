import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  courts,
  courtsForJudgeEdit,
  courtsForMyCase,
  courtsForMyCaseEnrollment,
} from '../../search/dialogs/case-request-dialog/courts';
import { places } from '../../substitute/districtsAndCourts';

@Component({
  selector: 'lb-court-input',
  templateUrl: './court-input.component.html',
  styleUrls: ['./court-input.component.sass'],
})
export class CourtInputComponent {
  @Input()
  type;

  @Input()
  court;

  @Output()
  courtChange = new EventEmitter();

  @Input()
  placeholder;

  @Input()
  strict;

  get filteredCourts() {
    switch (this.type) {
      case 'myCase':
        return courtsForMyCase.filter((it) => it.match(this.court));
      case 'places':
        return places.filter((it) => it.match(this.court));
      case 'judgeEditCase':
        return courtsForJudgeEdit.filter((it) => it.match(this.court));
      // 2023. 3. 2. LBK-1592
      // [어드민 > 내 판례등록(/admin/myCase) > 신청완료 > 등록하기 버튼 탭]에서만 값 필터링 적용 요청
      case 'myCaseEnrollment':
        return courtsForMyCaseEnrollment.filter((it) => it.match(this.court));
      default:
        return courts.filter((it) => it.match(this.court));
    }
  }

  emit(court) {
    if (!this.strict) {
      this.courtChange.emit(court);
      return;
    }
    if (this.filteredCourts.includes(court)) this.courtChange.emit(court);
  }
}
