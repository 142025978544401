/* tslint:disable */
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ApiHttp} from '../api.http';

import {ArchiveFolder, PageImpl} from '../api.model';

@Injectable()
export class ArchiveFolderController {
  constructor(private http: ApiHttp) {
  }

  copyFolder(folderId: number, userId: number): Observable<void> {
    return this.http.post<void>('/api/archiveFolder/copy', null, {folderId: folderId, userId: userId});
  }

  deleteArchiveFolder(id: number): Observable<void> {
    return this.http.delete<void>('/api/archiveFolder', {id: id});
  }

  getAllArchiveFolderList(query?: string, page?: number, size?: number): Observable<PageImpl<ArchiveFolder>> {
    return this.http.get<PageImpl<ArchiveFolder>>('/api/archiveFolder/list/all', {query: query, page: page, size: size});
  }

  getArchiveFolder(id: number): Observable<ArchiveFolder> {
    return this.http.get<ArchiveFolder>('/api/archiveFolder', {id: id});
  }

  getArchiveFolderList(parent?: number, all?: boolean, docId?: string): Observable<ArchiveFolder[]> {
    return this.http.get<ArchiveFolder[]>('/api/archiveFolder/list', {parent: parent, all: all, docId: docId});
  }

  getParentInfo(id: number): Observable<ArchiveFolder[]> {
    return this.http.post<ArchiveFolder[]>('/api/archiveFolder/getParentInfo', null, {id: id});
  }

  moveFolder(id: number, folderId: number): Observable<void> {
    return this.http.post<void>('/api/archiveFolder/moveFolder', null, {id: id, folderId: folderId});
  }

  saveArchiveFolder(archiveFolder: ArchiveFolder): Observable<ArchiveFolder> {
    return this.http.post<ArchiveFolder>('/api/archiveFolder', archiveFolder);
  }

  searchInFolder(query: string, parent?: number): Observable<ArchiveFolder[]> {
    return this.http.get<ArchiveFolder[]>('/api/archiveFolder/search/in', {parent: parent, query: query});
  }

  shareInPlan(id: number): Observable<boolean> {
    return this.http.post<boolean>('/api/archiveFolder/shareInPlan', null, {id: id});
  }

  sharedIdList(): Observable<number[]> {
    return this.http.get<number[]>('/api/archiveFolder/sharedIdList');
  }

  updateFolder(folderId: number, name: string): Observable<ArchiveFolder> {
    return this.http.post<ArchiveFolder>('/api/archiveFolder/update', null, {folderId: folderId, name: name});
  }

  updateOrders(orders: any[]): Observable<void> {
    return this.http.post<void>('/api/archiveFolder/updateOrders', orders);
  }
}
