import { Directive, HostBinding, HostListener, Input } from '@angular/core';
import { UserService } from '../../../user/user.service';
import { AlertService } from '../../../alert/alert.service';
import { Router } from '@angular/router';
import { ReadService } from '../../../core/read.service';
import { CommentarySummary } from '../../../../../ngxSpring/api.model';
import { ApiService } from '../../../../../ngxSpring/api.service';

@Directive({
  selector: '[commentaryLink]',
})
export class CommentaryLinkDirective {
  @Input()
  commentary: CommentarySummary;

  @Input()
  commentaryLink: string;

  constructor(
    private user: UserService,
    private alert: AlertService,
    private router: Router,
    private read: ReadService,
    private api: ApiService,
  ) {}

  @HostListener('click', ['$event'])
  click($event) {
    $event.stopPropagation();
    $event.preventDefault();
    this.commentaryPage();
  }

  @HostBinding('attr.href')
  get href() {
    return `/commentary/${this.commentaryLink}`;
  }

  @HostBinding('class.read')
  get readClass() {
    if (!this.commentary) return;
    return this.read.isRead(this.href);
  }

  commentaryPage() {
    let openInThis;
    try {
      openInThis = JSON.parse(this.user.getItem('setting'))?.openInThisWindow;
    } catch (e) {}
    const path = this.href;
    this.read.read(path);
    this.api.commentary
      .commentaryMembership(this.commentaryLink)
      .subscribe(() => {
        if (!openInThis && window.innerWidth >= 768) {
          const url = new URL(location.origin + path);
          if (this.commentary?.part) {
            url.searchParams.append('part', this.commentary.part);
          }
          if (this.commentary?.section) {
            url.searchParams.append('section', this.commentary.section);
          }
          if (this.commentary?.chapter) {
            url.searchParams.append('chapter', this.commentary.chapter);
          }
          if (this.commentary?.title) {
            url.searchParams.append('title', this.commentary.title);
          }
          this.alert.checkOpen(url.toString());
        } else {
          return this.router.navigate([path], {
            queryParams: {
              part: this.commentary?.part,
              chapter: this.commentary?.chapter,
              section: this.commentary?.section,
              title: this.commentary?.title,
            },
          });
        }
        return;
      });
  }
}
