import { Component } from '@angular/core';
import { LoaderService } from '../../core/loader.service';
import { DialogOrBottomSheetProto } from '../../alert/alert.service';
import { UserService } from '../../user/user.service';
import {
  LboxUser,
  LboxUserData,
  LqaData,
  RangeData,
} from '../../../../ngxSpring/api.model';
import { ScriptService } from 'ngx-script-loader';
import { LqaMyCasesRegisterComponent } from '../../user/lqa-profile/lqa-my-cases-register/lqa-my-cases-register.component';

declare var daum;

@Component({
  selector: 'lb-lqa-lawyer-register',
  templateUrl: './lqa-profile-update.component.html',
  styleUrls: ['./lqa-profile-update.component.sass'],
})
export class LqaProfileUpdateComponent extends DialogOrBottomSheetProto<any> {
  user = this.injector.get(UserService);
  lboxUserData: LboxUserData;
  lboxUser: LboxUser;
  touched;

  hasPhoneValue() {
    return this.lboxUserData?.lqaData?.phones?.find((it) => it);
  }

  get dataFilled() {
    return (
      this.check &&
      (this.lboxUserData.lqaData.licenseYear || !this.data || this.data == 1) &&
      this.lboxUserData.lqaData.work &&
      this.lboxUserData.lqaData.workAddress &&
      this.hasPhoneValue() &&
      this.lboxUserData.lqaData.workEmail &&
      this.lboxUserData.lqaData.workEmail.match('@') &&
      this.hasStartEndValue(this.lboxUserData.lqaData.careers) &&
      this.hasStartEndValue(this.lboxUserData.lqaData.schools)
    );
  }

  index(index: number) {
    return index;
  }

  ngOnInit(): void {
    this.injector.get(LoaderService).load = false;
    this.lboxUser = JSON.parse(JSON.stringify(this.user.lboxUser));
    this.lboxUserData = this.lboxUser.lboxUserData as any;
    this.check = !!this.data;
    if (!this.lboxUserData.lqaData)
      this.lboxUserData.lqaData = {
        workAddress: '',
        phones: [''],
        schools: [],
        careers: [],
      } as LqaData;
    if (!this.lboxUserData.lqaData.schools?.length)
      this.lboxUserData.lqaData.schools.unshift({ name: '' } as RangeData);
    if (!this.lboxUserData.lqaData.careers?.length)
      this.lboxUserData.lqaData.careers.unshift({ name: '' } as RangeData);
  }

  address() {
    this.injector
      .get(ScriptService)
      .loadScript(
        '//t1.daumcdn.net/mapjsapi/bundle/postcode/prod/postcode.v2.js',
      )
      .subscribe(() => {
        new daum.Postcode({
          oncomplete: (data) => {
            this.lboxUserData.lqaData.workAddress = data.address;
            this.lboxUserData.lqaData.workAddressDetail = '';
            this.lboxUserData.lqaData.workAddressObject = data;
            setTimeout(() => {
              (document.querySelector('#workAddressDetail') as any).focus();
            });
          },
        }).open();
      });
  }

  register() {
    if (!this.dataFilled) {
      this.touched = true;
      this.scrollToError();
      return;
    }

    this.api.lboxUser.updateForLqa(this.lqaData()).subscribe((v) => {
      this.user.lboxUser = v;
      this.close(true);
      this.user.setLqaDistrict().then(() => {
        this.alert
          .alert(
            '법률 Q&A 프로필 등록완료',
            '완료',
            '프로필 등록이 완료되었습니다.<br />질문자로부터 답변 요청이 접수되면 카카오톡 알림을 받으실 수 있습니다.',
          )
          .then(() => {
            this.alert.open(
              LqaMyCasesRegisterComponent,
              {
                title: '수행사건 추가',
                description:
                  '‘엘박스 법률 Q&A’는 질문자의 답변 요청 내용과 엘박스에 등록된 판례 사이의 유사도를 기반으로 진행됩니다. <br />',
              },
              {
                panelClass: ['word-break-keep-all', 'no-padding'],
                width: '448px',
              },
            )
          });
      });
    });
  }

  update() {
    if (!this.dataFilled) {
      this.touched = true;
      this.scrollToError();
      return;
    }
    this.api.lboxUser.updateForLqa(this.lqaData()).subscribe((v) => {
      this.user.lboxUser = v;
      this.close(true);
    });
  }

  private scrollToError() {
    setTimeout(() => {
      document
        .querySelector('.errored')
        .scrollIntoView({ behavior: 'smooth', block: 'center' });
    });
  }

  private lqaData() {
    const data = JSON.parse(JSON.stringify(this.lboxUserData.lqaData));
    data.careers = this.lboxUserData.lqaData.careers.filter((it) => it.name);
    data.schools = this.lboxUserData.lqaData.schools.filter((it) => it.name);
    return data;
  }

  pattern = {
    N: { pattern: new RegExp('[0-9-]') },
  };
  check;

  private hasStartEndValue(values) {
    return !values.find(
      (it) =>
        (it.endYear && !it.startYear) ||
        (!it.endYear && it.endYear !== 0 && it.startYear),
    );
  }

  changeType() {
    this.user.changeType(true).then(() => {
      this.lboxUser.job = this.user.lboxUser.job;
      this.lboxUser.lawyerPassInfo = this.user.lboxUser.lawyerPassInfo;
    });
  }

  rangeData() {
    return {} as RangeData;
  }
}
