import { Directive, HostBinding, HostListener, Input } from '@angular/core';
import { UserService } from '../user/user.service';
import { AlertService } from '../alert/alert.service';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { ReadService } from '../core/read.service';
import { TrackingService } from 'src/app/core/tracking.service';

@Directive({
  selector: '[statuteLink]',
})
export class StatuteLinkDirective {
  @Input()
  statuteLink;

  @Input()
  openInThis;

  @Input()
  jomun;

  constructor(
    private user: UserService,
    private alert: AlertService,
    private router: Router,
    private read: ReadService,
    private trackingService: TrackingService,
  ) {}

  @HostListener('click', ['$event'])
  click($event) {
    $event.stopPropagation();
    $event.preventDefault();

    this.trackingService.tracking('click_case_referredstatute', {
      name: this.jomun,
      funnel_root_id: this.trackingService.getCasePDPFunnelRootId(),
      click_url: `${window.location.host}/v2${this.href}`,
      isLeafEvent: true,
    });
    
    this.statutePage();
  }

  @HostBinding('attr.href')
  get href() {
    if (!this.statuteLink) return;
    return `/statute/${
      this.statuteLink.name || this.statuteLink.statute_name
    }/${moment(this.statuteLink.effective_date).format('yyyy-M-D')}`;
  }

  @HostBinding('class.read')
  get readClass() {
    if (!this.statuteLink) return;
    return this.read.isRead(this.href);
  }

  statutePage() {
    let openInThis;
    try {
      openInThis = JSON.parse(this.user.getItem('setting'))?.openInThisWindow;
    } catch (e) {}
    const path = this.href;
    this.read.read(path);
    if (!(openInThis || this.openInThis) && window.innerWidth >= 768) {
      const url = new URL(location.origin + path);
      if (this.jomun) {
        url.searchParams.append('jomun', this.jomun);
      }
      this.alert.checkOpen(url.toString());
    } else {
      return this.router.navigate([path], {
        queryParams: { jomun: this.jomun },
      });
    }
    return;
  }
}
