/* tslint:disable */
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ApiHttp} from '../api.http';

import {Page, PricePlanPayment, RefundData} from '../api.model';

@Injectable()
export class PricePlanPaymentController {
  constructor(private http: ApiHttp) {
  }

  cancelPayment(id: number): Observable<RefundData> {
    return this.http.post<RefundData>('/api/pricePlanPayment/cancel', null, {id: id});
  }

  deletePricePlanPayment(id: number): Observable<void> {
    return this.http.delete<void>('/api/pricePlanPayment', {id: id});
  }

  getAllPricePlanPaymentList(page?: number, size?: number, keyword?: string): Observable<Page<PricePlanPayment>> {
    return this.http.get<Page<PricePlanPayment>>('/api/pricePlanPayment/list/admin', {page: page, size: size, keyword: keyword});
  }

  getDayList(from: number, to: number): Observable<string[]> {
    return this.http.get<string[]>('/api/pricePlanPayment/dayList', {from: from, to: to});
  }

  getMonthList(): Observable<string[]> {
    return this.http.get<string[]>('/api/pricePlanPayment/monthList');
  }

  getPricePlanPayment(id: number): Observable<PricePlanPayment> {
    return this.http.get<PricePlanPayment>('/api/pricePlanPayment', {id: id});
  }

  getPricePlanPaymentList(page?: number, size?: number, from?: number, to?: number): Observable<Page<PricePlanPayment>> {
    return this.http.get<Page<PricePlanPayment>>('/api/pricePlanPayment/list', {page: page, size: size, from: from, to: to});
  }

  savePricePlanPayment(pricePlanPayment: PricePlanPayment): Observable<PricePlanPayment> {
    return this.http.post<PricePlanPayment>('/api/pricePlanPayment', pricePlanPayment);
  }
}
