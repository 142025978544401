/* tslint:disable */
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ApiHttp} from '../api.http';

import {SnsAuth, SnsAuthType, SnsLoginResult, User} from '../api.model';

@Injectable()
export class SnsAuthController {
  constructor(private http: ApiHttp) {
  }

  getMySnsList(): Observable<SnsAuth[]> {
    return this.http.get<SnsAuth[]>('/api/snsAuth/getMySnsList');
  }

  removeSns(type: SnsAuthType): Observable<User> {
    return this.http.delete<User>('/api/snsAuth', {type: type});
  }

  startWithSns(token: string, type: SnsAuthType, deviceId: string): Observable<SnsLoginResult> {
    return this.http.post<SnsLoginResult>('/api/snsAuth/startWithSns', null, {token: token, type: type, deviceId: deviceId});
  }
}
