import { NgModule } from '@angular/core';
import {
  AlertComponent,
  AlertService,
  ArrowAlertComponent,
  DeleteAlertComponent,
  DialogOrBottomSheetProto,
  ImageAlertComponent,
  PromptComponent,
  SelectComponent,
  SuggestAlert,
  BottomSuggestAlert,
  TutiralComponent,
  V2SnackbarComponent,
} from './alert.service';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatDialogModule } from '@angular/material/dialog';
import { CommonModule } from '@angular/common';
import { FlexModule } from '@angular/flex-layout';
import { MatSelectModule } from '@angular/material/select';
import { FormsModule } from '@angular/forms';
import { InputModule } from './input/input.module';
import { PricePlanComponent } from './price-plan/price-plan.component';
import { SafeHtmlPipe } from './safe-html.pipe';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { TrackModule } from '../track/track.module';
import { SharedModule } from '../shared/shared.module';

@NgModule({
  declarations: [
    PromptComponent,
    AlertComponent,
    ImageAlertComponent,
    DialogOrBottomSheetProto,
    SelectComponent,
    DeleteAlertComponent,
    ArrowAlertComponent,
    PricePlanComponent,
    SafeHtmlPipe,
    SuggestAlert,
    BottomSuggestAlert,
    TutiralComponent,
    V2SnackbarComponent,
  ],
  imports: [
    MatSnackBarModule,
    MatDialogModule,
    CommonModule,
    FlexModule,
    MatSelectModule,
    FormsModule,
    InputModule,
    MatCheckboxModule,
    TrackModule,
    SharedModule,
  ],
  providers: [AlertService],
})
export class AlertModule {}
