/* tslint:disable */
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ApiHttp} from '../api.http';

import {IamportPayment} from '../api.model';

@Injectable()
export class IamportPaymentController {
  constructor(private http: ApiHttp) {
  }

  getCardInfoByUserId(id: number): Observable<IamportPayment> {
    return this.http.get<IamportPayment>('/api/iamportPayment/getCardInfoByUserId', {id: id});
  }

  iamportDetail(uid: string): Observable<Map<any, any>> {
    return this.http.get<Map<any, any>>('/api/iamportPayment/detail', {uid: uid});
  }

  registeredPayment(): Observable<IamportPayment> {
    return this.http.get<IamportPayment>('/api/iamportPayment');
  }

  saveBillingKey(cardNumber: string, expiry: string, birth: string, pw: string): Observable<IamportPayment> {
    return this.http.post<IamportPayment>('/api/iamportPayment/saveBillingKey', null, {cardNumber: cardNumber, expiry: expiry, birth: birth, pw: pw});
  }
}
