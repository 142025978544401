import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { datadogLogs } from '@datadog/browser-logs';

const CLIENT_TOKEN = environment.DATADOG_CLIENT_TOKEN;

if (environment.production) {
  enableProdMode();

  if (CLIENT_TOKEN && typeof window !== 'undefined') {
    datadogLogs.init({
      clientToken: CLIENT_TOKEN,
      site: 'datadoghq.com',
      forwardErrorsToLogs: true,
      forwardConsoleLogs: ['warn', 'error'],
      sessionSampleRate: 100,
      env: 'prod',
      service: 'lbox-client-angular-browser',
    });
  }
}

/**
 * Bootstrapping for angular module
 */
function bootstrap() {
  platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .catch((err) => console.error(err));
}

if (document.readyState === 'complete') {
  bootstrap();
} else {
  document.addEventListener('DOMContentLoaded', bootstrap);
}
