import { Component } from '@angular/core';
import { DialogOrBottomSheetProto } from '../../../alert/alert.service';
import { UserService } from '../../user.service';
import { day } from 'src/app/core/util';

@Component({
  selector: 'lb-account-remove-dialog',
  templateUrl: './account-remove-dialog.component.html',
  styleUrls: ['./account-remove-dialog.component.sass'],
})
export class AccountRemoveDialogComponent extends DialogOrBottomSheetProto<any> {
  reason;
  reasonDetail;
  password;
  state;
  user = this.injector.get(UserService);
  withdrawOk;

  statePassword() {
    if (!this.reason) {
      this.alert.pop('탈퇴 이유를 선택해주세요.');
      return;
    }
    if (this.reason === '기타' && !this.reasonDetail) {
      this.alert.pop('탈퇴 이유를 적어주세요.');
      return;
    }
    if (this.injector.get(UserService).user.password) {
      this.state = 'passwordConfirm';
      return;
    }
    this.state = 'confirm';
  }

  checkPassword() {
    if (!this.password) {
      this.alert.pop('비밀번호를 입력해주세요.');
      return;
    }
    this.api.user.checkPassword(this.password).subscribe((v) => {
      if (!v) {
        this.alert.pop('비밀번호가 다릅니다.');
        return;
      }
      this.state = 'confirm';
    });
  }

  withdraw() {
    if (!this.withdrawOk) {
      this.alert.pop('회원탈퇴 안내를 확인하고 동의해주세요.');
      return;
    }
    this.api.user
      .withdraw(this.reason, this.password, this.reasonDetail)
      .subscribe(() => {
        this.alert.pop('성공적으로 회원 탈퇴되었습니다.');
        this.close();
        void this.injector.get(UserService).logout();
      });
  }

  get planExpiredDate() {
    const { plan } = this.user;

    const isFree = !plan.payment;

    // NOTE: originExpiredDate는 timestamp 형식, 전체 타입을 변경하면 side effect가 생길 수 있어서 타입 단언으로 처리
    const originExpiredDate = plan.originExpiredDate as unknown as number;

    if (isFree) {
      return originExpiredDate - day;
    }

    return originExpiredDate;
  }
}
