/* tslint:disable */
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ApiHttp} from '../api.http';

import {LawSchoolAuthorization, Page} from '../api.model';

@Injectable()
export class LawSchoolAuthorizationController {
  constructor(private http: ApiHttp) {
  }

  authorize(id: number, expiredAt: number): Observable<void> {
    return this.http.post<void>('/api/lawSchoolAuthorization/authorize', null, {id: id, expiredAt: expiredAt});
  }

  authorizeGraduate(id: number, expiredAt: number): Observable<void> {
    return this.http.post<void>('/api/lawSchoolAuthorization/authorizeGraduate', null, {id: id, expiredAt: expiredAt});
  }

  getLawSchoolAuthorizationList(page?: number, size?: number, includeDone?: boolean): Observable<Page<LawSchoolAuthorization>> {
    return this.http.get<Page<LawSchoolAuthorization>>('/api/lawSchoolAuthorization/list', {page: page, size: size, includeDone: includeDone});
  }

  getMyAuthorization(): Observable<LawSchoolAuthorization> {
    return this.http.get<LawSchoolAuthorization>('/api/lawSchoolAuthorization');
  }

  reject(id: number, reason: string): Observable<void> {
    return this.http.post<void>('/api/lawSchoolAuthorization/reject', null, {id: id, reason: reason});
  }

  saveLawSchoolAuthorization(lawSchoolAuthorization: LawSchoolAuthorization): Observable<LawSchoolAuthorization> {
    return this.http.post<LawSchoolAuthorization>('/api/lawSchoolAuthorization', lawSchoolAuthorization);
  }
}
