import { Component, OnInit } from '@angular/core';
import { ArchiveFolderWithSelect } from '../archive-dialog/archive-dialog.component';
import { DialogOrBottomSheetProto } from '../../alert/alert.service';
import { ArchiveFolder } from '../../../../ngxSpring/api.model';
import { FolderService } from '../../bookmark/folder.service';

@Component({
  selector: 'lb-archive-case-move-dialog',
  templateUrl: './archive-case-move-dialog.component.html',
  styleUrls: ['./archive-case-move-dialog.component.sass'],
})
export class ArchiveCaseMoveDialogComponent
  extends DialogOrBottomSheetProto<{ folders; folder }>
  implements OnInit
{
  folders: ArchiveFolderWithSelect[];
  parents: ArchiveFolderWithSelect[] = [];
  caseId;
  folderService: FolderService;

  ngOnInit(): void {
    this.folderService = this.injector.get(FolderService);
    this.folderService.rootSelected = false;
    this.folders = this.data.folders;
    const children = [];
    this.folders
      .filter((it) => it.parent)
      .forEach((it) => {
        const parent = this.folders.find((parent) => parent.id === it.parent);
        if (parent) {
          if (!parent.folders) {
            parent.folders = [];
          }
          parent.folders.push(it);
          children.push(it);
        }
      });
    this.parents.pushAll(this.folders.filter((it) => !children.includes(it)));
  }

  select() {
    if (this.folderService.rootSelected) {
      this.close(null);
      return;
    }
    const folder: ArchiveFolder = getAllFolders(this.folders).find(
      (it) => it.select,
    );
    if (!folder) {
      this.alert.pop('폴더를 선택해주세요.');
      return;
    }
    if (folder.id === this.data.folder?.id) {
      this.alert.pop('다른 폴더를 선택해주세요.');
      return;
    }
    this.close(folder);

    function getAllFolders(folders: ArchiveFolderWithSelect[]) {
      return folders
        ?.concat(folders?.map((it) => getAllFolders(it.folders)).flatten())
        .filter((it) => it)
        .distinct((a, b) => a?.id == b?.id);
    }
  }

  selectRoot() {
    this.folderService.rootSelected = true;
    this.folderService.selectionChange.emit(null);
  }
}
