import { Component, OnInit } from '@angular/core';
import { VerifyLawyerRequest } from 'ngxSpring/api.model';
import { DialogOrBottomSheetProto } from 'src/app/alert/alert.service';
import { TrackingService } from '../tracking.service';
import { Router } from '@angular/router';

@Component({
  selector: 'lb-lawyer-verify-dialog-v2',
  templateUrl: './lawyer-verify-dialog-v2.component.html',
  styleUrls: ['./lawyer-verify-dialog-v2.component.sass'],
})
export class LawyerVerifyDialogV2Component
  extends DialogOrBottomSheetProto<{
    /** [변호사 회원 전환] 모달에서 넘어오는 정보 */
    lawyerInfo?: VerifyLawyerRequest['lawyerInfo'];
    /** 모달 호출한 채널 정보(Amplitude 이벤트 기록용) */
    channel?: string;
    /** 변호사 회원가입 2/2 페이지에서 호출한 경우에는 checkLicense API를 호출 */
    isOnUserLawyerComponent?: boolean;
    name?: string;
    birthday?: string;
  }>
  implements OnInit
{
  trackingService = this.injector.get(TrackingService);
  router = this.injector.get(Router);

  /** 대한변협 신분증 등록번호 */
  registerNo: string = '';
  /** 대한변협 신분증 발급번호 */
  licenseNo: string = '';
  /** 개인정보 수집 및 이용 동의 여부 */
  isTermAgreed: boolean = false;
  /** 아코디언 열기/닫기 */
  showsTermsDetail: boolean = false;
  /** 인증 실패한 경우, 등록/발급번호 변경 일어나기 전까지 실패 상태 표시 */
  isVerifyingFailed: boolean = false;
  /** 연속 클릭 방지 */
  isVerifying: boolean = false;

  ngOnInit(): void {
    this.trackingService.tracking('view_lawyerauth', {
      channel: this.data?.channel ?? '채널 정보 없음',
    });
  }

  // 우측 상단 [X] 버튼
  handleClickCloseButton() {
    this.close(false);
  }

  // 숫자 아닌 경우 입력 무효화
  filterNumbers(event: KeyboardEvent) {
    const inputCharacter = event.key;
    if (!/[0-9]/.test(inputCharacter)) {
      event.preventDefault();
    }
  }

  // 발급번호 수정할 경우, 인증 실패 상태 해제
  handleChangeRegisterNo() {
    this.isVerifyingFailed = false;
  }

  // 인증번호 수정할 경우, 인증 실패 상태 해제
  handleChangeLicenseNo() {
    this.isVerifyingFailed = false;
  }

  handleClickFAQButton() {
    this.trackingService.tracking('click_lawyerauth_faq', {
      channel: this.data?.channel ?? '채널 정보 없음',
    });
    window.open(
      'https://support.lbox.kr/hc/ko/articles/7528239282191',
      '_blank',
    );
  }

  // [개인정보 수집/이용 동의] 체크박스
  handleClickTermsCheckbox() {
    this.isTermAgreed = !this.isTermAgreed;
  }

  // [개인정보 수집/이용 동의] 자세히 보기 화살표 버튼
  handleClickIconCaretUp() {
    this.showsTermsDetail = !this.showsTermsDetail;
  }

  // [확인] 버튼
  handleClickConfirmButton() {
    if (!this.isConfirmButtonActive || this.isVerifying) {
      return;
    }

    this.isVerifying = true;

    this.trackingService.tracking('click_lawyerauth_cta', {
      channel: this.data?.channel ?? '채널 정보 없음',
    });

    // 1. 회원가입 페이지에서 [변호사 인증]을 진행하는 경우: checkLicense API 호출
    if (
      this.data?.isOnUserLawyerComponent &&
      this.data?.name &&
      this.data?.birthday
    ) {
      this.api.lboxUser
        .checkLicense(
          this.data.name,
          this.data.birthday,
          this.licenseNo,
          this.registerNo,
        )
        .subscribe({
          next: (success) => {
            if (success) {
              this.trackingService.tracking('complete_lawyerauth', {
                channel: this.data?.channel ?? '채널 정보 없음',
              });
              this.close({
                licenseNo: this.licenseNo,
                registerNo: this.registerNo,
              });
            } else {
              // 실패인 경우 에러 응답하지만 방어적 코드 추가
              this.isVerifying = false;
              this.isVerifyingFailed = true;
            }
          },
          error: () => {
            this.isVerifying = false;
            this.isVerifyingFailed = true;
          },
        });
    }

    // 2. 회원가입 페이지 아닌 곳에서 진행하는 경우
    if (!this.data?.isOnUserLawyerComponent) {
      if (this.data?.lawyerInfo === undefined) {
        // 2-1. 직접 [변호사 인증]을 진행하는 경우: verifyLawyer API 호출,
        const verifyLawyerRequest: VerifyLawyerRequest = {
          licenseInfo: {
            registrationNumber: String(this.registerNo),
            issueNumber: String(this.licenseNo),
          },
        };

        this.api.lboxUser.verifyLawyer(verifyLawyerRequest).subscribe({
          next: () => {
            this.trackingService.tracking('complete_lawyerauth', {
              channel: this.data?.channel ?? '채널 정보 없음',
            });
            this.close(true);
          },
          error: () => {
            this.isVerifying = false;
            this.isVerifyingFailed = true;
          },
        });
      } else {
        // 2-2. [변호사 회원 전환] 모달 다음 단계로 [변호사 인증]을 진행하는 경우: verifyLawyer API 호출, lawyerInfo를 전달받음
        const verifyLawyerRequestFromChangingJob: VerifyLawyerRequest = {
          licenseInfo: {
            registrationNumber: String(this.registerNo),
            issueNumber: String(this.licenseNo),
          },
          lawyerInfo: this.data.lawyerInfo,
        };

        this.api.lboxUser
          .verifyLawyer(verifyLawyerRequestFromChangingJob)
          .subscribe({
            next: () => {
              this.trackingService.tracking('complete_lawyerauth', {
                channel: this.data?.channel ?? '채널 정보 없음',
              });
              this.close(true);

              // 토스트 안내 후 다음 페이지로 넘어가기 전까지 유저 입력 방지
              document.addEventListener(
                'click',
                function (event) {
                  event.preventDefault();
                  event.stopPropagation();
                },
                true,
              );
              this.alert.pop('변호사 인증이 완료되었습니다.', 2000);
              setTimeout(() => {
                window.location.assign('/substitute');
              }, 2000);
            },
            error: () => {
              this.isVerifying = false;
              this.isVerifyingFailed = true;
            },
          });
      }
    }
  }

  // [다음에 하기] 버튼
  handleClickCancelButton() {
    this.close(false);
  }

  /**
   * 확인 버튼 활성화 여부.
   * 아래 4가지 조건을 모두 만족해야 함.
   * - 등록번호가 숫자로 입력
   * - 발급번호가 숫자로 입력
   * - 개인정보 수집 및 이용에 체크
   * - 인증 실패한 상태가 아닐 때
   */
  get isConfirmButtonActive() {
    const isNumberRegisterNo =
      !!this.registerNo && !isNaN(Number(this.registerNo));
    const isNumberLicenseNo =
      !!this.licenseNo && !isNaN(Number(this.licenseNo));

    return (
      isNumberRegisterNo &&
      isNumberLicenseNo &&
      this.isTermAgreed &&
      !this.isVerifyingFailed
    );
  }
}
