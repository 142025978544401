<div class="why">
  <h3>
    가장 많은 변호사들이<br *showItSizes="{ max: 959 }" />
    엘박스를 선택한 이유
  </h3>
  <div class="container">
    <mat-tab-group
      [disablePagination]="true"
      class="tab"
      mat-align-tabs="center"
    >
      <mat-tab
        *ngFor="
          let name of [
            '압도적인 판례 수',
            '혁신적인 검색',
            '다양한 업무 지원',
            '지식 관리 및 공유'
          ];
          let i = index
        "
      >
        <ng-template matTabLabel>
          <span trackId="aaaa">{{ name }}</span>
        </ng-template>
        <div class="tab-body" *ngIf="values" style="word-break: keep-all;">
          <ng-container *ngIf="!i">
            <div fxLayout="row" fxLayout.lt-md="column">
              <div class="chart-wrapper">
                <div>
                  <lb-main-chart
                    title="< 전체 판례 수 >"
                    [values]="[
                      round(values?.lawnb / 10000),
                      round(values?.casenote / 10000),
                      round(values?.lbox / 10000)
                    ]"
                    fxFlex="auto"
                  ></lb-main-chart>
                </div>
                <div class="color-gray font-12 mt-24" style="margin-left: 48px; margin-right: 24px; text-align: left;">
                  *타사 수치는 검색결과 및 홈페이지 자료에 근거함. [{{
                    values?.dateLabel
                  }} 측정 기준]<br />
                  <div class="desktop-only">
                    <br />
                    *내용 전체를 빠짐없이 확인할 수 있는 '전문 판례'를 기준으로 산출한 수치이며, 내용 중 일부만 확인 가능한
                    '부분 판례'는 제외함.
                  </div>
                </div>
              </div>
              <div
                fxFlex="auto"
                class="chart-side"
                fxLayout="column"
                fxLayoutAlign="center start"
                fxLayoutAlign.lt-md="center center"
              >
                <h5 class="chart-title">경쟁사를 압도하는 판례 수</h5>
                <div class="chart-desc">
                  이미 가장 많은 판례를 보유하고 있으며 압도적인 증가 속도로
                  매월 격차가 더욱 커지고 있습니다.
                </div>
              </div>
            </div>
            <div class="feature-divider"></div>

            <div fxLayout="row" fxLayout.lt-md="column">
              <div class="chart-wrapper">
                <div>
                  <lb-main-chart
                    title="< 최신 판례 수 >"
                    [values]="[
                      round(values?.lawnbRecent / 10000),
                      round(values?.casenoteRecent / 10000),
                      round(values?.lboxRecent / 10000)
                    ]"
                    fxFlex="auto"
                  ></lb-main-chart>
                </div>
                <div class="color-gray font-12 mt-24" style="margin-left: 48px; margin-right: 24px; text-align: left;">
                  *최근 5년 선고된 판례 수, 타사 수치는 검색결과 및 홈페이지
                  자료에 근거함. [{{ values?.dateLabel }} 측정 기준]
                </div>
              </div>
              <div
                fxFlex="auto"
                class="chart-side"
                fxLayout="column"
                fxLayoutAlign="center start"
                fxLayoutAlign.lt-md="center center"
              >
                <h5 class="chart-title">높은 최신 판례 비중</h5>
                <div class="chart-desc">
                  변호사 유저들이 요청한 실무적으로 가장 중요한 최신 판례가 매일
                  2,000건 이상 새롭게 등록됩니다.
                </div>
              </div>
            </div>
          </ng-container>
          <ng-container *ngIf="i">
            <ng-container
              *ngFor="let card of features[i - 1]?.cards; let last = last"
            >
              <div class="feature" fxLayout="row" fxLayout.lt-md="column">
                <div fxFlex="1/2">
                  <img
                    style="box-shadow: 1px 4px 16px -4px rgba(0, 0, 0, 0.1)"
                    [src]="card.image"
                  />
                </div>

                <div
                  fxFlex="1/2"
                  class="card"
                  fxLayout="column"
                  fxLayoutAlign="center start"
                  fxLayout.lt-md="center center"
                >
                  <div class="name" [innerHTML]="card.name"></div>
                  <div class="description" [innerHTML]="card.description"></div>
                  <div
                    *ngIf="!last"
                    class="button"
                    trackId="6097"
                    (click)="openYoutube(card.link)"
                  >
                    이용가이드 영상 보기
                  </div>
                </div>
              </div>
              <div class="feature-divider" *ngIf="!last"></div>
            </ng-container>
          </ng-container>
        </div>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>
