import { Component } from '@angular/core';
import { JudgesService } from '../../judge/judges.service';

@Component({
  selector: 'lb-judge-search',
  templateUrl: './judge-search.component.html',
  styleUrls: ['./judge-search.component.sass'],
})
export class JudgeSearchComponent {
  constructor(public judge: JudgesService) {}

  name(j) {
    return j ? j.name : '';
  }
}
