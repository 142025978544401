/* tslint:disable */
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ApiHttp} from '../api.http';

import {} from '../api.model';

@Injectable()
export class SearchLogController {
  constructor(private http: ApiHttp) {
  }

  getClientTrackingList(from: number, to: number): Observable<any[]> {
    return this.http.get<any[]>('/api/searchLog/logs', {from: from, to: to});
  }
}
