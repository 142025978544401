/* tslint:disable */
import {Injectable} from '@angular/core';
import {NEVER, Observable} from 'rxjs';
import {getCookie} from 'src/app/core/util';
import {ApiHttp} from '../api.http';

import {LboxUser, Page, PasswordInfo, RegisteredUserStatistics, User, UserProperties, UserRegisterInfo, UserSettingsOption} from '../api.model';

@Injectable()
export class UserController {
  lboxToken = null;
  constructor(private http: ApiHttp) {
    this.lboxToken = getCookie('lboxToken');
  }

  changePhone(uid: string): Observable<LboxUser> {
    return this.http.post<LboxUser>('/api/user/change/phone', null, {uid: uid});
  }

  checkPassword(password: string): Observable<boolean> {
    return this.http.post<boolean>('/api/user/checkPassword', null, {password: password});
  }

  detachSns(userId: number): Observable<void> {
    return this.http.post<void>('/api/user/detachSns/admin', null, {userId: userId});
  }

  existCheck(email: string): Observable<boolean> {
    return this.http.post<boolean>('/api/user/existCheck', null, {email: email});
  }

  getCurrentUser(): Observable<User> {
    return this.http.get<User>('/api/user/current');
  }

  getCurrentUserProperties(): Observable<UserProperties> {
    return this.http.get<UserProperties>('/api/user/current/properties');
  }

  getDailyRegisteredUserCount(fromDate?: string, toDate?: string): Observable<RegisteredUserStatistics[]> {
    return this.http.get<RegisteredUserStatistics[]>('/api/user/userCount/daily', {fromDate: fromDate, toDate: toDate});
  }

  getUserList(page?: number, size?: number, query?: string, roles?: any[]): Observable<Page<User>> {
    return this.http.post<Page<User>>('/api/user/list', roles, {page: page, size: size, query: query});
  }

  fetchUserEmail(uniqueKey: string): Observable<{ email: string }> {
    return this.http.get<{ email: string }>('/api/user/email', { uniqueKey })
  }

  login(user: User, deviceId?: string): Observable<User> {
    return this.http.post<User>('/api/user/login', user, {deviceId: deviceId});
  }

  logout(deviceId: string): Observable<void> {
    return this.http.post<void>('/api/user/logout', null, {deviceId: deviceId});
  }

  register(user: UserRegisterInfo, uid: string, deviceId: string, marketingAgree?: boolean): Observable<User> {
    return this.http.post<User>('/api/user/register/lbox', user, {uid: uid, deviceId: deviceId, marketingAgree: marketingAgree});
  }

  removeData(key: string): Observable<User> {
    return this.http.post<User>('/api/user/removeData', null, {key: key});
  }

  saveUser(user: User): Observable<User> {
    return this.http.post<User>('/api/user', user);
  }

  updateData(map: any): Observable<User> {
    return this.http.post<User>('/api/user/updateData', map);
  }

  updateEmail(email: string, code: string): Observable<User> {
    return this.http.post<User>('/api/user/updateEmail', null, {email: email, code: code});
  }

  updatePassword(info: PasswordInfo): Observable<User> {
    return this.http.post<User>('/api/user/update', info);
  }

  updatePasswordWithToken(info: PasswordInfo): Observable<User> {
    return this.http.post<User>('/api/user/update/token', info);
  }

  withdraw(reason: string, password?: string, opinion?: string): Observable<void> {
    return this.http.post<void>('/api/user/withdraw', null, {password: password, reason: reason, opinion: opinion});
  }

  withdrawAdmin(userId: number): Observable<void> {
    return this.http.post<void>('/api/user/withdraw/admin', null, {userId: userId});
  }

  getShowsSourceWhenCopyTextOption(): Observable<UserSettingsOption> {
    if (!this.lboxToken) {
      return NEVER;
    }
    return this.http.get<UserSettingsOption>('/api/users/my-settings/copy-with-title');
  }

  updateShowsSourceWhenCopyText(state: 'ON' | 'OFF') {
    if (!this.lboxToken) {
      return NEVER;
    }
    return this.http.patch<User>(`/api/users/my-settings/copy-with-title?state=${state}`);
  }

  /** 특이사항: 서버에서 비밀번호를 항상 "lbox123!"로 초기화한다. */
  resetPasswordAdmin(email: string): Observable<void> {
    return this.http.put<void>('/api/user/password/reset/admin', { email });
  }
}
