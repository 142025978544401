/* tslint:disable */
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ApiHttp} from '../api.http';

import {OpponentData, OrderType, Page, Substitute, SubstituteQueryType, SubstituteType} from '../api.model';

@Injectable()
export class SubstituteController {
  constructor(private http: ApiHttp) {
  }

  applyCandidate(id: number): Observable<Substitute> {
    return this.http.post<Substitute>('/api/substitute/applyCandidate', null, {id: id});
  }

  cancelApply(id: number): Observable<Substitute> {
    return this.http.post<Substitute>('/api/substitute/cancelApply', null, {id: id});
  }

  deleteSubstitute(id: number): Observable<Substitute> {
    return this.http.delete<Substitute>('/api/substitute', {id: id});
  }

  getSubstitute(id?: number): Observable<Substitute> {
    return this.http.get<Substitute>('/api/substitute/detail', {id: id});
  }

  getSubstituteList(districts: any[], page?: number, size?: number, type?: SubstituteType, from?: number, to?: number, orderBy?: OrderType, queryType?: SubstituteQueryType): Observable<Page<Substitute>> {
    return this.http.post<Page<Substitute>>('/api/substitute/list', districts, {page: page, size: size, type: type, from: from, to: to, orderBy: orderBy, queryType: queryType});
  }

  hasPermission(): Observable<boolean> {
    return this.http.get<boolean>('/api/substitute');
  }

  made(id: number): Observable<Substitute> {
    return this.http.post<Substitute>('/api/substitute/made', null, {id: id});
  }

  madeManual(id: number, opponentData: OpponentData): Observable<Substitute> {
    return this.http.post<Substitute>('/api/substitute/madeManual', opponentData, {id: id});
  }

  opponent(id: number): Observable<OpponentData> {
    return this.http.get<OpponentData>('/api/substitute/opponent', {id: id});
  }

  saveSubstitute(substitute: Substitute): Observable<Substitute> {
    return this.http.post<Substitute>('/api/substitute', substitute);
  }
}
