<input
  [ngModel]="value"
  (keyup)="valueChange.emit(input.value)"
  #input
  (ngModelChange)="valueChange.emit($event)"
  #ctrl="ngModel"
  [placeholder]="placeholder"
  [class.ng-invalid]="textError(value)"
/>
<mat-error class="font-12" *ngIf="ctrl.touched && textError(value)">{{
  textError(value)
}}</mat-error>
