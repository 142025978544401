<div class="title">
  <h5>법조문으로 검색</h5>
</div>
<div class="input-wrapper">
  <lb-law-select></lb-law-select>
</div>

<div class="buttons">
  <button class="cancel" trackId="325a" (click)="close()">취소</button>
  <button class="blue" trackId="99d2" (click)="ok()">검색</button>
</div>
