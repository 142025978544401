import { Directive, Input } from '@angular/core';

@Directive({
  selector: '[lbNgInit]',
})
export class NgInitDirective {
  @Input() lbNgInit;

  ngOnInit() {
    if (this.lbNgInit) {
      this.lbNgInit();
    }
  }
}
