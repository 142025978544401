<div class="outer">
  <div class="wrapper">
    <div class="input" mwlTextInputHighlightContainer>
      <textarea
        (focusin)="focusOn = true; updateFocus(); findCompletion(input.value)"
        (focusout)="focusOn = false; updateFocus()"
        mwlTextInputElement
        [placeholder]="placeholder || '검색어 입력'"
        [matAutocomplete]="auto"
        trackEnter="돋보기 클릭(엔터포함)"
        [(ngModel)]="query"
        #trigger="matAutocompleteTrigger"
        (keypress)="preventEnter($event)"
        (keyup.enter)="searchWithKeyword(); trigger.closePanel()"
        (keyup)="
          findCompletion(input.value, $event, true); preventSearch = false
        "
        #input
      >
      </textarea>
      <mwl-text-input-highlight
        [tags]="queryElements"
        [textInputElement]="input"
      >
      </mwl-text-input-highlight>
    </div>
    <!--서치다이얼로그 / 컴팩트 / 서치-->
    <mat-autocomplete
      panelWidth="476px"
      #auto="matAutocomplete"
      class="compact"
      [displayWith]="ifJudge.bind(this)"
      (optionSelected)="ifJudgeMove($event.option.value)"
      (opened)="autoOpen = true; updateFocus()"
      (closed)="autoOpen = false; updateFocus()"
    >
      <span class="label" *ngIf="functions?.length || find"
        ><span class="icon3 small n-94 h-3 mr-2"></span>빠른 검색 명령어</span
      >
      <mat-option [disabled]="true" class="points-none" *ngIf="find">
        <span class="fn-desc" [innerHTML]="find.description"></span>
      </mat-option>
      <div class="quick-options" *ngIf="functions.length">
        <mat-option
          class="quick-button"
          [value]="functionValue(el)"
          *ngFor="let el of functions"
          (onSelectionChange)="selectionChange(el, trigger, input.value)"
        >
          {{ el.name }}
        </mat-option>
      </div>
      <span class="label" *ngIf="searchService.historyService.history?.length"
        >최근 검색한 항목</span
      >
      <ng-container *ngFor="let el of searchService.historyService.history">
        <mat-option
          *ngIf="!el.name"
          [value]="el"
          (onSelectionChange)="searchService.searchWithKeyword(el)"
        >
          <span class="icon3 n-14 h-5 mr-12"></span>
          <span class="keyword" [innerHTML]="tagged(el)"></span>
          <span
            trackId="761d"
            (click)="
              searchService.historyService.removeHistory(el);
              $event.stopPropagation()
            "
            class="icon delete-icon"
          ></span>
        </mat-option>
        <!-- <mat-option *ngIf="el.name" class="judge" [value]="el">
          <div fxLayout="row" fxLayoutAlign="start center">
            <span class="icon3 n-82 h-4 mr-12"></span>
            <div>
              <div
                class="vertical-middle"
                [routerLink]="el | judgeLink"
                trackId="6deb"
                (click)="$event.stopPropagation()"
              >
                {{ el.name }}
              </div>
              <lb-info-desc [judge]="el"></lb-info-desc>
            </div>
            <span fxFlex="auto"></span>
            <span
              trackId="b3d6"
              (click)="
                searchService.historyService.removeHistory(el);
                $event.stopPropagation()
              "
              class="icon delete-icon"
            ></span>
          </div>
        </mat-option> -->
      </ng-container>
      <span class="label" *ngIf="completions?.length">{{
        completionName
      }}</span>
      <mat-option
        [value]="getCompletionValue(el)"
        *ngFor="let el of completions; let i = index"
        (onSelectionChange)="
          searchService.searchWithKeyword(getCompletionValue(el))
        "
      >
        <span>
          <span class="icon3 n-83 mr-12 mt-2"></span>
          <span class="keyword" [innerHTML]="tagged(el.view || el)"></span>
        </span>
      </mat-option>
      <span class="label" *ngIf="judge.judgeOptions?.length">판사</span>
      <mat-option
        class="judge"
        *ngFor="let judge of judge.judgeOptions | slice: 0:5"
        [value]="judge"
      >
        <div fxLayout="row" fxLayoutAlign="start center">
          <span class="icon3 n-82 h-4 mr-12"></span>
          <div>
            <div
              class="vertical-middle"
              [routerLink]="judge | judgeLink"
              trackId="b1bc"
              (click)="$event.stopPropagation()"
            >
              {{ judge.name }}
            </div>
            <lb-info-desc [judge]="judge"></lb-info-desc>
          </div>
        </div>
      </mat-option>
    </mat-autocomplete>
  </div>
  <button class="search" trackId="0439" (click)="ifFocusSearch(trigger)">
    <span class="icon2 n-83 ml--2 mt--2"></span>
  </button>
</div>
