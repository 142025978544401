import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'caseType',
})
export class CaseTypePipe implements PipeTransform {
  transform(value): string {
    switch (value) {
      case 'civil':
        return '민사';
      case 'criminal':
        return '형사';
      case 'patent':
        return '특허';
      case 'etc':
        return '기타';
      case 'administration':
        return '행정';
      case 'tax':
        return '세무';
      case 'household':
        return '가사';
      case 'ccourt':
        return '';
      case 'old':
        return '';
      case 'news':
        return '뉴스';
      case 'reference':
        return '참고문헌';
    }
  }
}
