export const josa = new josaUtil();

function josaUtil() {
  this['으로'] = function (word) {
    return checkLastChar(word, '(으)로', '로', '으로');
  };

  this['이가'] = function (word) {
    return checkLastChar(word, '(이)가', '가', '이');
  };

  this['은는'] = function (word) {
    return checkLastChar(word, '(은)는', '는', '은');
  };

  this['을를'] = function (word) {
    return checkLastChar(word, '(을)를', '를', '을');
  };

  this['와과'] = function (word) {
    return checkLastChar(word, '(와)과', '와', '과');
  };

  function checkLastChar(word, ifNotHangul, noJongSung, hasJongSung) {
    if (!word) {
      return '';
    }
    if (typeof word !== 'string') {
      word = word.toString();
    }
    const last = word.charAt(word.length - 1);
    if (/[013678]/.test(last)) {
      return hasJongSung;
    }
    if (/[2459]/.test(last)) {
      return noJongSung;
    }
    if (!is_hangul_char(last)) {
      return ifNotHangul;
    }
    if (tSound(last) === 'ᆧ') {
      return noJongSung;
    }
    return hasJongSung;
  }

  function tSound(a) {
    const r = (a.charCodeAt(0) - parseInt('0xac00', 16)) % 28;
    return String.fromCharCode(r + parseInt('0x11A8', 16) - 1);
  }

  function is_hangul_char(ch) {
    const c = ch.charCodeAt(0);
    if (0x1100 <= c && c <= 0x11ff) {
      return true;
    }
    if (0x3130 <= c && c <= 0x318f) {
      return true;
    }
    return !!(0xac00 <= c && c <= 0xd7a3);
  }
}
