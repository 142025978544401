<div (drop)="handleDrop($event)" trackId="cb74" (click)="handleClick()" (dragover)="handleDragOver($event)"
  (dragleave)="handleDragLeave($event)" class="upload-container">
  <div class="upload-inner-area">
    <img src="../assets/icon-file-arrow-up.png" />
    <div>
      <b class="drag">학생증 또는 재학증명서 사본 이미지 파일(JPG,PNG)</b>을<br />
      드래그하거나 이곳을 클릭해주세요.
    </div>
  </div>
</div>

<input #file hidden type="file" [accept]="accept"
  (change)="filesChange.emit({ fileList: file.files, eventType: 'click' })" [multiple]="multiple" />