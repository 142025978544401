import { EventEmitter, Injectable } from '@angular/core';
import { isMobileOrTablet } from '../core/util';
import { AlertService } from '../alert/alert.service';
import { HighlightTooltipComponent } from './components/case-buttons/highlight-tooltip/highlight-tooltip.component';
import { MemoTooltipComponent } from './components/case-buttons/memo-tooltip/memo-tooltip.component';
import { SearchTooltipComponent } from './components/case-buttons/search-tooltip/search-tooltip.component';
import { ReportTooltipComponent } from './components/case-buttons/report-tooltip/report-tooltip.component';
import { UserService } from '../user/user.service';
import { CaseAndRelations } from '../../../ngxSpring/api.model';
import { LocalStorageService } from '../core/local-storage.service';

import { copyTextOptionLocalStorageKey } from '../../consts';
import { TrackingService } from 'src/app/core/tracking.service';

export const caseTypes = [
  { name: '전체', value: null },
  { name: '민사', value: 'civil' },
  { name: '형사', value: 'criminal' },
  { name: '행정', value: 'administration' },
  { name: '가사', value: 'household' },
  { name: '특허', value: 'patent' },
  { name: '기타', value: 'etc' },
];

export function replaceTags(text) {
  return text
    .replace(/아래첨자\[([\d\D]+?)\]/g, '$1')
    .replace(/위첨자\[([\d\D]+?)\]/g, '$1')
    .replace(/강조점\[([\d\D]+?)\]/g, '$1')
    .replace(/취소선\[([\d\D]+?)\]/g, '$1')
    .replace(/밑줄\[([\d\D]+?)\]/g, '$1')
    .replace(/굵게\[([\d\D]+?)\]/g, '$1')
    .replace(/아래첨자\(([\d\D]+?)\)/g, '$1')
    .replace(/위첨자\(([\d\D]+?)\)/g, '$1')
    .replace(/강조점\(([\d\D]+?)\)/g, '$1')
    .replace(/취소선\(([\d\D]+?)\)/g, '$1')
    .replace(/밑줄\(([\d\D]+?)\)/g, '$1')
    .replace(/굵게\(([\d\D]+?)\)/g, '$1')
    .replace(/아래첨자\{([\d\D]+?)\}/g, '$1')
    .replace(/위첨자\{([\d\D]+?)\}/g, '$1')
    .replace(/강조점\{([\d\D]+?)\}/g, '$1')
    .replace(/취소선\{([\d\D]+?)\}/g, '$1')
    .replace(/밑줄\{([\d\D]+?)\}/g, '$1')
    .replace(/굵게\{([\d\D]+?)\}/g, '$1');
}

@Injectable()
export class CaseService {
  mode: 'highlight' | 'find' | 'search' | 'memo' | 'report' = null;
  data: CaseAndRelations;
  caseRefChange = new EventEmitter();
  colors;
  query;
  findQuery = '';
  findSize: number;
  findIndex;
  setting: {
    openInThisWindow;
    big;
    family;
    recommendHighlightOff;
    searchHighlightOn;
    includesHighlights: boolean;
  } = {
    openInThisWindow: null,
    big: null,
    family: null,
    recommendHighlightOff: null,
    searchHighlightOn: null,
    includesHighlights: false,
  };
  showHighlightInfo;
  highlights = { summary: [], issue: [], judges: [] };
  hasHighlight: ('yellow' | 'red' | 'green')[] = [];
  selectedContent;
  activeContent;
  showsSourceWhenCopyText = true; // [설정 > 텍스트 복사 시 출처 표시하기]

  imageUpdated = new EventEmitter();
  settingLoaded = new EventEmitter();

  highlightInfoUpdate() {
    this.hasHighlight = [
      this.hasColor('yellow'),
      this.hasColor('red'),
      this.hasColor('green'),
    ]
      .filter((it) => it)
      .map((it) => it.className);
  }

  hasColor(color) {
    return (
      this.data.case.section_list
        .map((it) =>
          it.refList.find(
            (it) => it.type === 'myHighlight' && it.className == color,
          ),
        )
        .filter((it) => it)[0] ||
      Object.keys(this.highlights)
        .map((it) => this.highlights[it])
        .flatten()
        .find((it) => it.className == color)
    );
  }

  hasHighlightColor(color) {
    return this.hasHighlight?.find((it) => it === color);
  }

  toggleColor(selectedColor: string) {
    if (this.colors.includes(selectedColor)) {
      this.colors = this.colors.filter((color) => color !== selectedColor)
    } else {
      this.colors = this.colors.concat(selectedColor)
    }
    localStorage.setItem('selectColors', JSON.stringify(this.colors));
  }

  get isYellowSelected() {
    return this.colors.includes('yellow')
  }

  get isGreenSelected() {
    return this.colors.includes('green')
  }

  get isRedSelected() {
    return this.colors.includes('red')
  }

  saveSetting() {
    this.user.setItem('setting', JSON.stringify(this.setting));
  }

  constructor(
    private alert: AlertService,
    private user: UserService,
    private localStorage: LocalStorageService,
    private trackingService: TrackingService,
  ) {
    const setting = this.user.getItem('setting');
    if (setting) {
      this.setting = JSON.parse(setting);
      this.settingLoaded.emit();
    }
    this.user.userUpdated.subscribe(() => {
      const setting = this.user.getItem('setting');
      if (setting) {
        this.setting = JSON.parse(setting);
        this.settingLoaded.emit();
      }
    });
    try {
      this.colors = JSON.parse(this.localStorage.getItem('selectColors'));
    } catch (e) {}
    if (!this.colors) this.colors = ['yellow', 'red', 'green'];
  }

  get enabledColors() {
    if (!this.colors) return;
    return this.colors.filter((it) => this.hasHighlightColor(it)).length;
  }

  async setMode(
    mode: null | 'highlight' | 'find' | 'search' | 'report' | 'memo' = null,
    raw = false,
  ) {
    this.findIndex = -1;
    this.findSize = 0;
    this.findQuery = '';
    
    if (this.mode === mode) {
      this.mode = null;
      return;
    }
    await this.user.loginCheck();
    switch (mode) {
      case 'find':
        this.trackingService.tracking('click_case_btn_textsearch', {
          funnel_root_id: this.trackingService.getCasePDPFunnelRootId(),
        });
        break;

      case 'search':
        this.trackingService.tracking('click_case_btn_similarsearch', {
          funnel_root_id: this.trackingService.getCasePDPFunnelRootId(),
        });

        if (isMobileOrTablet()) {
          this.alert.pop(
            '본문 텍스트를 블록지정하여 유사판례를 검색할 수 있습니다.',
          );
          return;
        }
        this.alert.snackBar.openFromComponent(SearchTooltipComponent, {
          duration: 6000,
        });
        break;

      case 'highlight':
        if (raw) {
          this.alert.pop('수정 중인 판례에는 하이라이트를 표시할 수 없습니다.');
          return;
        }
        if (isMobileOrTablet()) {
          this.alert.pop(
            '본문 텍스트를 블록지정하여 하이라이트 할 수 있습니다.',
          );
          return;
        }
        this.alert.snackBar.openFromComponent(HighlightTooltipComponent, {
          duration: 6000,
        });
        break;

      case 'memo':
        if (raw) {
          this.alert.pop('수정 중인 판례에는 메모를 남길 수 없습니다.');
          return;
        }
        if (isMobileOrTablet()) {
          this.alert.pop('본문 텍스트를 블록지정하여 메모를 남길 수 있습니다.');
          return;
        }
        this.alert.snackBar.openFromComponent(MemoTooltipComponent, {
          duration: 6000,
        });
        break;

      case 'report':
        this.trackingService.tracking('click_case_btn_errorreport', {
          funnel_root_id: this.trackingService.getCasePDPFunnelRootId(),
        });

        if (isMobileOrTablet()) {
          this.alert.pop(
            '본문 텍스트를 블록지정하여 오류를 신고할 수 있습니다.',
          );
          return;
        }
        this.alert.snackBar.openFromComponent(ReportTooltipComponent, {
          duration: 6000,
        });
        break;
    }

    this.mode = mode;
  }

  async find(value: string) {
    if (this.findQuery == value) {
      this.addIndex(1);
      return;
    }
    this.findReset();
    this.findQuery = value;
    if (value != this.findQuery) {
      this.findIndex = 0;
    }
    this.addIndex();
  }

  addIndex(number = 0) {
    this.caseRefChange.emit({ type: 'find' });
    setTimeout(() => {
      this.findIndex += number;
      const marks = document.querySelectorAll('mark');
      this.findSize = marks?.length;
      if (!this.findSize) {
        this.findIndex = -1;
      } else if (this.findIndex > this.findSize - 1) {
        this.findIndex = 0;
      } else if (this.findIndex < 0) {
        this.findIndex = 0;
      }
      if (this.findSize) {
        marks.forEach((it) => it.classList.remove('active'));
        marks[this.findIndex].classList.add('active');
        marks[this.findIndex].scrollIntoView({
          behavior: 'smooth',
          block: 'center',
          inline: 'nearest',
        });
      }

      this.trackingService.tracking('find_onpage', {
        type: 'find_onpage',
        funnel_root_id: this.trackingService.getCasePDPFunnelRootId(),
        properties: {
          text: this.findQuery,
          rank: this.findIndex + 1,
          hits_total: this.findSize,
        }
      })
    });
  }

  findReset() {
    if (this.mode != 'find') return;
    this.findQuery = '';
    this.caseRefChange.emit({ type: 'findReset' });
  }

  /**
   * [설정 > 텍스트 복사 시 출처 표시하기] 토글 후 저장
   */
  toggleShowsSourceWhenCopyText() {
    this.showsSourceWhenCopyText = !this.showsSourceWhenCopyText;
    this.localStorage.setBooleanItem(
      copyTextOptionLocalStorageKey,
      this.showsSourceWhenCopyText,
    );
    this.user.saveShowsSourceWhenCopyText(this.showsSourceWhenCopyText ? 'ON' : 'OFF');
  }

  /**
   * [설정 > 텍스트 복사 시 출처 표시하기] 조회
   */
  loadShowsSourceWhenCopyText() {
    const storedState = this.localStorage.getBooleanItem(
      copyTextOptionLocalStorageKey,
    );

    if (typeof storedState === 'boolean') {
      this.showsSourceWhenCopyText = storedState;
    } else {
      this.user.getShowsSourceWhenCopyTextOption().subscribe((option) => {
        this.showsSourceWhenCopyText = option.state === 'ON';
      });
    }
  }
}

export function getCaretCharacterOffsetWithin(element) {
  let caretOffset = 0;
  const doc = element?.ownerDocument || element?.document
  const win = doc?.defaultView || doc?.parentWindow
  let sel;
  if (typeof win.getSelection != 'undefined') {
    sel = win.getSelection();
    if (sel.rangeCount > 0) {
      const range = win.getSelection().getRangeAt(0);
      const preCaretRange = range.cloneRange();
      preCaretRange.selectNodeContents(element);
      preCaretRange.setEnd(range.endContainer, range.endOffset);
      caretOffset = preCaretRange.toString().length;
    }
  } else if ((sel = doc.selection) && sel.type != 'Control') {
    const textRange = sel.createRange();
    const preCaretTextRange = doc.body.createTextRange();
    preCaretTextRange.moveToElementText(element);
    preCaretTextRange.setEndPoint('EndToEnd', textRange);
    caretOffset = preCaretTextRange.text.length;
  }
  return caretOffset;
}
