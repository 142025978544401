import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'replace',
})
export class ReplacePipe implements PipeTransform {
  transform(value: string, ...args: string[]): string {
    return value.replace(
      new RegExp(args[0], args[2] !== undefined ? args[2] : 'g'),
      args[1],
    );
  }
}
