<div class="wrapper">
  <div class="box">
    <img
      src="/v2/lbox-fe-static/images/lbox/service/lbox-re-brand-logo.webp"
      alt="LBOX"
      width="100"
      height="37.5"
    />
    <h1 class="title">페이지를 찾을 수 없습니다</h1>
    <div class="description">
      찾으려는 페이지의 주소가 잘못 입력되었거나,
      <br />
      주소의 변경 혹은 삭제로 사용할 수 없습니다.
      <br />
      입력하신 페이지의 주소가 정확한지 다시 한번 확인해 주세요.
    </div>
    <div class="buttons">
      <a class="helpButton" zendesk>고객센터</a>
      <a class="homButton" href="/v2">홈으로</a>
    </div>
  </div>
</div>
