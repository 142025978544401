import {
  Directive,
  EmbeddedViewRef,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  PLATFORM_ID,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import { isPlatformServer, NgIfContext } from '@angular/common';
import { fromEvent } from 'rxjs';

@Directive({
  selector: '[showItSizes]',
  standalone: true,
})
export class ShowWhenSizeDirective implements OnInit, OnDestroy {
  @Input()
  showItSizes: { min?; max? };

  sub;

  private _context: NgIfContext = new NgIfContext();
  private readonly _thenTemplateRef: TemplateRef<NgIfContext> | null = null;
  private _thenViewRef: EmbeddedViewRef<NgIfContext> | null = null;

  values = { md: 960 };

  constructor(
    private _viewContainer: ViewContainerRef,
    templateRef: TemplateRef<NgIfContext>,
    @Inject(PLATFORM_ID) private platform,
  ) {
    this._thenTemplateRef = templateRef;
  }

  private _updateView() {
    if (this._context.$implicit) {
      if (!this._thenViewRef) {
        this._viewContainer.clear();
        if (this._thenTemplateRef) {
          this._thenViewRef = this._viewContainer.createEmbeddedView(
            this._thenTemplateRef,
            this._context,
          );
        }
      }
    } else {
      this._viewContainer.clear();
      this._thenViewRef = null;
    }
  }

  resizeEvent() {
    const innerWidth =
      isPlatformServer(this.platform) || typeof window == 'undefined'
        ? 1280
        : window?.innerWidth;
    let show = true;
    if (this.showItSizes.min) {
      show =
        show &&
        innerWidth >=
          (this.values[this.showItSizes.min] || this.showItSizes.min);
    }
    if (this.showItSizes.max) {
      show =
        show &&
        innerWidth <=
          (this.values[this.showItSizes.max] || this.showItSizes.max);
    }
    this._context.$implicit = this._context.ngIf = show;
    this._updateView();
  }

  ngOnInit(): void {
    if (isPlatformServer(this.platform)) {
      return;
    }
    this.resizeEvent();
    this.sub = fromEvent(window, 'resize').subscribe(() => {
      this.resizeEvent();
    });
  }

  ngOnDestroy(): void {
    this.sub?.unsubscribe();
  }
}
