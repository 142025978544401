import Inko from 'inko';

const errorQueries = [
  'tkrjs',
  'qngh',
  'qlrh',
  'gjsrk',
  'gjssk',
  'gjsek',
  'gjsfk',
  'gjsak',
  'gjsqk',
  'gjstk',
  'gjsdk',
  'wotla',
  'tkrjs',
  'qngh',
  'qlrh',
  'rkgkq',
  'rkeks',
  'rkth',
  'sk',
  'ek',
  'fk',
  'ak',
  'rm',
  'qk',
  'aj',
  'wk',
  'ck',
  'ckwjs',
  'fj',
  'zkgkq',
  'zkeks',
  'zkrhd',
  'zkeka',
  'zkaud',
  'zkqnf',
  'zkwh',
  'zkrn',
  'zkghkr',
  'zkduf',
  'zkdla',
  'zkwjd',
  'zkth',
  'zkrl',
  'xkrud',
  'xkco',
  'xkqo',
  'xkdls',
  'xkrl',
  'qlgkq',
  'qleks',
  'ghlgkq',
  'ghleks',
  'rksghlgkq',
  'rksghleks',
  'ghlghkr',
  'ghlrl',
  'gkgkq',
  'gkeks',
  'gkghkr',
  'gkaus',
  'gkrl',
  'roghl',
  'roghkr',
  'rorl',
  'rnrtmd',
  'rnrwl',
  'rhk',
  'cor',
  'wjdrk',
  'wjdaud',
  'wjdfk',
  'wjdak',
  'tkrjs',
  'qngh',
  'qlrh',
  'rhgkq',
  'rheks',
  'rhdir',
  'rhdirwjs',
  'rhwjd',
  'sh',
  'eh',
  'fh',
  'ah',
  'dh',
  'qh',
  'zh',
  'wh',
  'xh',
  'chwjr',
  'chqh',
  'chwo',
  'chtk',
  'chrl',
  'rkarh',
  'rkash',
  'rkaeh',
  'rkafh',
  'rkaah',
  'rkadh',
  'rkaxh',
  'rkach',
  'wjsrh',
  'wjssh',
  'wjseh',
  'wjsdh',
  'wjsch',
  'wjsfh',
  'wjsah',
  'qhrh',
  'qhsh',
  'qheh',
  'qhdh',
  'qhch',
  'qhfh',
  'qhah',
  'clrh',
  'clsh',
  'cleh',
  'cldh',
  'clch',
  'chcl',
  'clfh',
  'clah',
  'ehdrh',
  'ehdsh',
  'ehdeh',
  'ehddh',
  'ehdch',
  'vn',
  'zm',
  'xm',
  'vnch',
  'vnwlq',
  'qj',
  'tj',
  'dj',
  'wj',
  'qjwlq',
  'cj',
  'zj',
  'xj',
  'cjwlq',
  'ehdqj',
  'ehdtj',
  'ehddj',
  'ehdwj',
  'ehdqjwlq',
  'ehdcj',
  'ehdcjwlq',
  'ehdzj',
  'ehdxj',
  'tjd',
  'tjdfh',
  'tjdah',
  'tjdch',
  'tkrjs',
  'qngh',
  'qlrh',
  'dls',
  'dlsfk',
  'dlsak',
  'dlszk',
  'wjdrh',
  'wjdrl',
  'wjdfh',
  'wjdah',
  'emgkq',
  'emeks',
  'fm',
  'am',
  'qm',
  'tm',
  'dm',
  'sj',
  'cm',
  'wmgkq',
  'wmeks',
  'wmrl',
  'smgkq',
  'smeks',
  'gnrka',
  'ghaud',
  'rngkq',
  'rneks',
  'sn',
  'en',
  'fn',
  'an',
  'qn',
  'tk',
  'dk',
  'gj',
  'gn',
  'gm',
  'gl',
  'zkgj',
  'tn',
  'dn',
  'tngm',
  'wn',
  'cn',
  'zn',
  'wjdem',
  'wjdqm',
  'wjdtm',
  'wjdrhk',
  'wjdfj',
];

const inko = new Inko();

export function queryCorrection(query) {
  const match = query.match(/\d{1,10}([a-zA-Z]{1,10})\d{1,10}/);
  if (!(match && match[1] && errorQueries.includes(match[1]))) return query;
  return inko.en2ko(query);
}
