import { FormControl, FormGroup } from '@angular/forms';

export class ValidFormGroup extends FormGroup {
  controls;
  checked: any;

  constructor(param: { [key: string]: any[] }, values?, options?) {
    const controls = {} as any;
    Object.keys(param).forEach((key) => {
      controls[key] = new ValidFormControl(
        key,
        param[key],
        values ? values[key] : '',
      );
      controls[key].options = options && options[key] ? options[key] : {};
    });
    super(controls);
    this.controls = controls;
    this.checked = {};
  }

  public getFormValue(name) {
    return this.controls[name].value;
  }

  public setFormValue(name, value) {
    return this.controls[name].setValue(value);
  }

  public error() {
    const error = Object.keys(this.controls).find((it) => {
      return this.controls[it].message;
    });
    if (error) {
      return this.controls[error].message;
    }
  }

  public stopCheck(name) {
    this.checked[name] = true;
    this.controls[name].updateValueAndValidity({
      onlySelf: false,
      emitEvent: true,
    });
  }

  public startCheck(name) {
    this.checked[name] = false;
    this.controls[name].updateValueAndValidity({
      onlySelf: false,
      emitEvent: true,
    });
  }
}

export class ValidFormControl extends FormControl {
  options: { placeholder?; type?; template? };

  constructor(name, fn: any[], value?) {
    super(value ? value : '');
    this.validator = (c) => {
      if ((this.parent as ValidFormGroup).checked[name]) {
        return;
      }
      for (let i = 0; i < fn.length; i += 2) {
        if (fn[i](c)) {
          return { message: fn[i + 1] };
        }
      }
    };
  }

  get message() {
    for (const errorsKey in this.errors) {
      if (this.errors[errorsKey]) {
        return this.errors[errorsKey];
      }
    }
  }
}
