<input
  [placeholder]="placeholder || '법원명을 입력해주세요.'"
  [(ngModel)]="court"
  (ngModelChange)="emit(court)"
  [matAutocomplete]="auto"
/>

<mat-autocomplete class="no-top-margin" #auto="matAutocomplete">
  <mat-option *ngFor="let court of filteredCourts" [value]="court">
    <small>{{ court }}</small>
  </mat-option>
</mat-autocomplete>
