import { Component, Inject } from '@angular/core';
import {
  MAT_BOTTOM_SHEET_DATA,
  MatBottomSheetRef,
} from '@angular/material/bottom-sheet';

@Component({
  selector: 'lb-bottom-sheet-template',
  templateUrl: './bottom-sheet-template.component.html',
  styleUrls: ['./bottom-sheet-template.component.sass'],
})
export class BottomSheetTemplateComponent {
  constructor(
    @Inject(MAT_BOTTOM_SHEET_DATA) public template,
    public ref: MatBottomSheetRef,
  ) {}
}
