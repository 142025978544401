import { Component, OnInit } from '@angular/core';
import { ArchiveFolder } from '../../../../ngxSpring/api.model';
import { DialogOrBottomSheetProto } from '../../alert/alert.service';
import { FolderService } from '../../bookmark/folder.service';

export interface ArchiveFolderWithSelect extends ArchiveFolder {
  select;
  folders: ArchiveFolderWithSelect[];
}

@Component({
  selector: 'lb-archive-dialog',
  templateUrl: './archive-dialog.component.html',
  styleUrls: ['./archive-dialog.component.sass'],
})
export class ArchiveDialogComponent
  extends DialogOrBottomSheetProto<{
    folders;
    case;
    folderService: FolderService;
    data;
  }>
  implements OnInit
{
  folders: ArchiveFolderWithSelect[];
  parents: ArchiveFolderWithSelect[] = [];
  caseId;

  archive(folder?: ArchiveFolder) {
    this.trackingService.tracking('click_case_btn_save_confirm', { 
      name: folder?.name ?? '',
      funnel_root_id: this.trackingService.getCasePDPFunnelRootId(),
    })

    const folders: ArchiveFolder[] = folder
      ? [folder]
      : getAllFolders(this.folders).filter((it) => it.select);

    function getAllFolders(folders: ArchiveFolderWithSelect[]) {
      return folders
        ?.concat(folders?.map((it) => getAllFolders(it.folders)).flatten())
        .filter((it) => it)
        .distinct((a, b) => a.id == b.id);
    }

    this.api.archiveCase
      .saveArchiveCase(
        folders.map((it) => it.id),
        this.caseId,
      )
      .subscribe(() => {
        this.alert.pop(
          `${
            folders.length
              ? folders.map((it) => it.name).join(', ') +
                ' 폴더에 추가했습니다.'
              : '판결문 보관함에 저장했습니다.'
          }`,
        );
        this.data.folderService.folderUpdated.emit(folders);
        if (this.data.data) {
          this.data.data.saved = true;
        }
        this.close(true);
      });
  }

  saveNewFolder() {
    this.trackingService.tracking('click_case_btn_save_newfolder');

    this.alert
      .prompt(
        { placeholder: '새 폴더의 이름을 입력해주세요.' },
        '새 폴더에 저장',
        '저장하기',
      )
      .then((v) => {
        if (!v) {
          this.trackingService.tracking('click_case_btn_save_newfolder_cancel');
          return;
        }
        this.trackingService.tracking('click_case_btn_save_newfolder_save', { name: v });
        this.api.archiveFolder
          .saveArchiveFolder({ name: v } as ArchiveFolder)
          .subscribe((f) => {
            this.archive(f);
            this.data.folderService.folderUpdated.emit();
          });
      });
  }

  ngOnInit(): void {
    this.folders = this.data.folders;
    const children = [];
    this.folders
      .filter((it) => it.parent)
      .forEach((it) => {
        const parent = this.folders.find((parent) => parent.id === it.parent);
        if (parent) {
          if (!parent.folders) {
            parent.folders = [];
          }
          parent.folders.push(it);
          children.push(it);
        }
      });
    this.parents.pushAll(this.folders.filter((it) => !children.includes(it)));
    this.caseId = this.data.case.doc_id;
  }

  trackClickCaseButtonSaveCancel() {
    this.trackingService.tracking('click_case_btn_save_cancel');
  }
}
