import { Component, HostBinding, Input } from '@angular/core';
import { UserService } from '../../user/user.service';
import { animate, style, transition, trigger } from '@angular/animations';
import { AlarmService } from '../../core/alarm.service';
import { Router } from '@angular/router';
import { SearchService } from '../../search/search.service';
import { getLBoxNextClientURL } from '../../core/util';
import { TrackService } from 'src/app/track/track.service';
import { V2_PAGE_PATH } from '../../../consts';

@Component({
  selector: 'lb-header-buttons',
  templateUrl: './header-buttons.component.html',
  styleUrls: ['./header-buttons.component.sass'],
  animations: [
    trigger('enterAnimation', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('.2s 1s', style({ opacity: 1 })),
      ]),
    ]),
  ],
})
export class HeaderButtonsComponent {
  @Input()
  mobileSearch = false;

  @Input()
  withMobileAlarm = true;

  @Input()
  showSuggest;

  @Input()
  colorRevert = false;

  @Input()
  break = 768;

  @Input()
  folderBreak = this.break;

  @Input()
  membershipSuggestion = true;

  legalAnalyticsUrl = getLBoxNextClientURL() + V2_PAGE_PATH.legalAnalytics.caseAnalytics

  get home() {
    return location.pathname == '/';
  }

  get href() {
    return location.href;
  }

  get showsMobileAlarm() {
    return (
      this.userService.user && 
      !this.userService.isRestrictedUser && 
      this.withMobileAlarm && 
      !this.mobileSearch
    )
  }

  constructor(
    public userService: UserService,
    public alarmService: AlarmService,
    public trackService: TrackService,
    public router: Router,
    public search: SearchService,
  ) {}

  public sendClickGnbAnalyticsEvent() {
    this.trackService.sendEvent('click_gnb_analytics');
  }

  @HostBinding('class.logged')
  get logged() {
    return this.userService.logged;
  }

  public openUseGuideOnBlank() {
    this.trackService.sendEvent('click_gnb_guide');
    window.open('https://lbox.notion.site/LBox-3e2919133fe44ad59c8d9b651e0def7c', '_blank');
  }
}
