/* tslint:disable */
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ApiHttp} from '../api.http';

import {ArchiveFolder} from '../api.model';

@Injectable()
export class SharedFolderController {
  constructor(private http: ApiHttp) {
  }

  deleteSharedFolder(id: number): Observable<void> {
    return this.http.delete<void>('/api/sharedFolder', {id: id});
  }

  getSharedFolderList(): Observable<ArchiveFolder[]> {
    return this.http.get<ArchiveFolder[]>('/api/sharedFolder/list');
  }
}
