import { Component } from '@angular/core';
import {
  DialogOrBottomSheetProto,
  V2SnackbarComponent,
} from '../../../alert/alert.service';
import { UserService } from '../../user.service';

@Component({
  selector: 'lb-change-payment',
  templateUrl: './change-payment.component.html',
  styleUrls: ['./change-payment.component.sass'],
})
export class ChangePaymentComponent extends DialogOrBottomSheetProto<any> {
  changePayment(cardNumber: string, expiry: string, pw: string, birth: string) {
    this.api.iamportPayment
      .saveBillingKey(cardNumber, expiry, birth, pw)
      .subscribe((v) => {
        this.injector.get(UserService).payment = v;
        this.alert.popFromComponent(V2SnackbarComponent, {
          data: {
            type: 'success',
            message: '결제 수단이 변경되었습니다.',
          },
          verticalPosition: 'bottom',
        });
        this.close();
      });
  }
}
