<div class="title alert-dialog pb-0">
  <div
    [innerHTML]="data.description | safeHtml"
    *ngIf="data.description"
    class="desc mt-0"
  ></div>
</div>
<div class="buttons">
  <button
    class="cancel"
    (click)="close(null)"
    *ngIf="data.cancel != false"
    [innerHTML]="data.cancel || '취소'"
  ></button>
  <button
    class="blue"
    (click)="close(true)"
    [innerHTML]="data.ok || '확인'"
  ></button>
</div>
