import {
  Component,
  ElementRef,
  Inject,
  OnInit,
  Optional,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { SearchService } from '../../search/search.service';
import { ApiService } from '../../../../ngxSpring/api.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CaseSearchComponent } from '../case-search/case-search.component';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { TrackService } from '../../track/track.service';
import { AlertService } from '../../alert/alert.service';
import { JudgesService } from '../../judge/judges.service';
import { UserService } from '../../user/user.service';

@Component({
  selector: 'lb-search-dialog',
  templateUrl: './search-dialog.component.html',
  styleUrls: ['./search-dialog.component.sass'],
})
export class SearchDialogComponent
  extends CaseSearchComponent
  implements OnInit
{
  @ViewChild('input')
  elf: ElementRef;
  type = 1;

  constructor(
    searchService: SearchService,
    router: Router,
    api: ApiService,
    dialog: AlertService,
    public ref: MatDialogRef<SearchDialogComponent>,
    user: UserService,
    private bottomSheet: MatBottomSheet,
    @Optional() @Inject(MAT_DIALOG_DATA) private data,
    ga: TrackService,
    judge: JudgesService,
  ) {
    super(searchService, router, api, dialog, ga, user, judge);
    if (data?.tab) this.type = data?.tab;
  }

  handleKeyDownEnter(e: any): void { 
    if (!e.isComposing) {
      this.search()
    }
  }

  search() {
    this.searchService.searchWithKeyword(this.query).then(() => {
      this.ref.close();
    });
  }

  ngOnInit() {
    super.ngOnInit();
    this.findCompletion('');
    setTimeout(() => {
      this.input.nativeElement.click();
      const searchDialogElem = document.querySelector('.search-dialog')
      if (searchDialogElem) {
        searchDialogElem.scrollTop = 0
      }

      if (document.scrollingElement) {
        document.scrollingElement.scrollTop = 0
      }
    }, 300);
    if (this.data?.isFn) {
      this.searchService.searchWithLaw();
    }
  }

  change() {
    if (this.type == 2) {
      this.judge.find('');
    }
  }
}
