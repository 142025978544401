import { Component, Input } from '@angular/core';
import { NewsSummary } from '../../../../ngxSpring/api.model';
import { SearchService } from '../../search/search.service';

@Component({
  selector: 'lb-search-result-news',
  templateUrl: './search-result-news.component.html',
  styleUrls: ['./search-result-news.component.sass'],
})
export class SearchResultNewsComponent {
  @Input()
  news: NewsSummary;

  read;

  constructor(public search: SearchService) {}
}
