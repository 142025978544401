<div fxLayout="row" fxLayoutAlign="center center" class="select-bar">
  <span
    class="icon3 n-33 back invert"
    trackId="6d1c"
    (click)="ref.close()"
  ></span>
  <div fxFlex="auto" class="text-center">
    통합검색
  </div>
  <div class="icon-button" [class.disabled]="type == 2">
    <span
      class="icon3 n-3"
      trackId="bc61"
      (click)="showFunctions(input.value)"
    ></span>
  </div>
</div>

<ng-container *ngIf="type == 1">
  <div class="input-gray" fxLayout="row" fxLayoutAlign="center center">
    <div mwlTextInputHighlightContainer fxFlex="auto" class="mt-8">
      <textarea
        type="search"
        autofocus
        mwlTextInputElement
        (scroll)="setScroll()"
        [placeholder]="placeholder || '키워드를 검색해 보세요.'"
        trackEnter="e915"
        [(ngModel)]="query"
        (keypress)="preventEnter($event)"
        (keydown.enter)="handleKeyDownEnter($event)"
        (keyup)="findCompletion(input.value, null, true)"
        #input
      >
      </textarea>
      <mwl-text-input-highlight
        [tags]="queryElements"
        [textInputElement]="input"
      >
      </mwl-text-input-highlight>
    </div>
    <div class="icon-button ml-10">
      <span class="icon3 n-83" trackId="323f" (click)="search()"></span>
    </div>
  </div>

  <span class="label" *ngIf="functions?.length || find"
    ><span class="icon3 small n-94 h-3 mr-2"></span>빠른 검색 명령어</span
  >
  <div
    class="relative points-none"
    *ngIf="find"
    style="height: 74px; line-height: 74px"
  >
    <span class="fn-desc mobile" [innerHTML]="find.description"></span>
  </div>
  <div class="quick-options" *ngIf="functions.length">
    <div
      class="quick-button"
      trackId="5fec"
      (click)="query = functionValue(el); findCompletion(query); input.focus()"
      *ngFor="let el of functions"
    >
      {{ el.name }}
    </div>
  </div>
  <span class="label" *ngIf="searchService.historyService.history?.length"
    >최근 검색한 항목</span
  >
  <ng-container *ngFor="let el of searchService.historyService.history">
    <div
      class="relative"
      trackId="ecc6"
      (click)="query = el; search()"
      *ngIf="!el.name"
    >
      <span class="icon3 n-14 h-5 mr-12"></span>
      <span class="keyword" [innerHTML]="tagged(el)"></span>
      <span
        trackId="982f"
        (click)="
          searchService.historyService.removeHistory(el);
          $event.stopPropagation()
        "
        class="icon delete-icon mr-8"
      ></span>
    </div>
    <!-- <div class="option" *ngIf="el.name">
      <div
        fxLayout="row"
        fxLayoutAlign="start center"
        [routerLink]="el | judgeLink"
        trackId="eae0"
        (click)="ref.close()"
      >
        <span class="icon3 n-82 h-4 mr-12"></span>
        <div>
          <div class="vertical-middle font-14">{{ el.name }}</div>
          <lb-info-desc [judge]="el"></lb-info-desc>
        </div>
        <span fxFlex="auto"></span>
        <span
          trackId="d0ec"
          (click)="
            searchService.historyService.removeHistory(el);
            $event.stopPropagation()
          "
          class="icon delete-icon mr-8"
        ></span>
      </div>
    </div> -->
  </ng-container>
  <span class="label" *ngIf="completions?.length">{{ completionName }}</span>
  <div
    class="relative"
    trackId="35bd"
    (click)="query = getCompletionValue(el); search()"
    *ngFor="let el of completions; let i = index"
  >
    <span>
      <span class="icon3 n-83 mr-12"></span>
      <span class="keyword" [innerHTML]="tagged(el.view || el)"></span>
    </span>
  </div>
  <!-- <span class="label" *ngIf="judge.judgeOptions?.length">판사</span>
  <div class="option" *ngFor="let judge of judge.judgeOptions | slice: 0:5">
    <div
      fxLayout="row"
      fxLayoutAlign="start center"
      [routerLink]="judge | judgeLink"
      trackId="479a"
      (click)="ref.close()"
    >
      <span class="icon3 n-82 h-4 mr-12"></span>
      <div>
        <div class="vertical-middle">{{ judge.name }}</div>
        <lb-info-desc [judge]="judge"></lb-info-desc>
      </div>
    </div>
  </div> -->
</ng-container>

<!-- 법률분석 클로즈베타 서비스를 위해 type 2로 가는 로직을 제거함 -->
<ng-container class="search-box" *ngIf="type == 2">
  <div class="input-gray">
    <div mwlTextInputHighlightContainer fxFlex="auto" class="mt-8">
      <input
        autofocus
        placeholder="판사명을 입력하세요."
        #input
        [value]="judge.query || ''"
        (keyup)="judge.find(input.value)"
        (keyup.enter)="judge.load(input.value, null, true, ref)"
      />
    </div>
    <div class="icon-button">
      <span
        class="icon3 n-83"
        trackId="b83d"
        (click)="judge.load(input.value, null, true, ref)"
      ></span>
    </div>
  </div>
  <span class="label" *ngIf="judge.judgeOptions?.length">판사</span>
  <div class="option" *ngFor="let judge of judge.judgeOptions">
    <div
      fxLayout="row"
      fxLayoutAlign="start center"
      [routerLink]="judge | judgeLink"
      trackId="b1f3"
      (click)="ref.close()"
    >
      <span class="icon3 n-82 h-4 mr-12"></span>
      <div>
        <div class="vertical-middle">{{ judge.name }}</div>
        <lb-info-desc [judge]="judge"></lb-info-desc>
      </div>
    </div>
  </div>
</ng-container>
