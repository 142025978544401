import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { extractCaseNum, extractCourt } from './util';
import { RequestCase } from '../../../ngxSpring/api.model';
import { CaseRequestDialogComponent } from '../search/dialogs/case-request-dialog/case-request-dialog.component';
import { ApiService } from '../../../ngxSpring/api.service';
import { UserService } from '../user/user.service';
import { AlertService } from '../alert/alert.service';
import { Router } from '@angular/router';
import { TrackingService } from './tracking.service';

@Injectable({
  providedIn: 'root',
})
export class CaseDetailService {
  constructor(
    private api: ApiService,
    private user: UserService,
    private alert: AlertService,
    private router: Router,
    private trackingService: TrackingService,
  ) {}

  // 새창으로 열렸는지 여부 리턴
  async moveOrRequest(link: string, queryString?, newTabOnly?, caseData?) {
    let queryParams = this.getQueryParams(caseData);
    if (!queryParams) queryParams = {} as any;
    const v = await firstValueFrom(this.api.lbCase.isIdValid(link));
    if (v) {
      let openInThis;
      try {
        openInThis = JSON.parse(this.user.getItem('setting'))?.openInThisWindow;
      } catch (e) {}
      if (!openInThis && (window.innerWidth >= 768 || newTabOnly)) {
        const url = new URL(
          location.origin + '/case/' + link.replace('-', '/'),
        );
        Object.keys(queryParams).forEach((it) =>
          url.searchParams.append(it, queryParams[it]),
        );
        if (queryString) {
          url.searchParams.append('q', queryString);
        }
        this.alert.checkOpen(url.toString());
        return true;
      } else {
        if (queryString) {
          (queryParams as any).q = queryString;
        }
        this.router.navigate(['/' + link.replace('-', '/')], {
          queryParams,
        });
        return false;
      }
    }
    await this.user.loginCheck();
    this.api.requestCase
      .getRequestedRecord({
        requestCaseId: extractCaseNum(link),
        court: extractCourt(link),
      } as RequestCase)
      .subscribe((v) => {
        if (v) {
          this.alert.alert(
            '입수 요청 해주신 판례입니다. 최대한 빨리 입수하겠습니다.',
          );
          return;
        }
        this.trackingService.tracking('click_case_btn_unregisteredplz', {
          keyword: extractCaseNum(link),
          type: '판례디테일페이지',
        })
        this.alert.open(
          CaseRequestDialogComponent,
          {
            caseId: extractCaseNum(link),
            court: extractCourt(link),
          },
          { autoFocus: false },
        );
      });
  }

  private getQueryParams(caseData) {
    if (!(caseData && caseData.section_index && caseData.sent_indexes)) return;
    if (caseData.section_index == -1) return;
    return {
      section: caseData.section_index,
      sentences: caseData.sent_indexes,
    };
  }
}
