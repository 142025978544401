<div class="title">
  <h5 [innerHTML]="data.message"></h5>
</div>

<div
  *ngIf="data.description"
  class="description"
  [innerHTML]="data.description"
></div>

<div *ngIf="data.message === '결제 취소'" class="cancel-payment">
  <div id="agreed-plan-pricing-alert-root" class="component-root react-component"></div>
</div>

<div class="buttons">
  <button class="cancel-button" (click)="close(null)" *ngIf="data.cancel != false">
    {{ data.cancel || '취소' }}
  </button>
  <button class="delete-button" (click)="close(true)">
    {{ data.ok || '삭제하기' }}
  </button>
</div>
