import { Component } from '@angular/core';

/**
 * @deprecated
 * 사장된 코드인듯
 */
@Component({
  selector: 'lb-register-popover',
  templateUrl: './register-popover.component.html',
  styleUrls: ['./register-popover.component.sass'],
})
export class RegisterPopoverComponent {
  close;
}
