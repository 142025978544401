<!-- FileTrackOFF -->
<div class="title">
  <h4>{{ data.title }}</h4>
  <div
    class="mt-16"
    *ngIf="data.description"
    [innerHTML]="data.description"
  ></div>
</div>
<ng-container *ngFor="let d of data.values">
  <ng-container [ngSwitch]="d.type">
    <ng-container *ngSwitchCase="'tip'">
      <div class="font-14 text-center pt-16">
        <span
          class="cursor-pointer"
          [lbTip]="d.name"
          [innerHTML]="d.placeholder"
        ></span>
      </div>
    </ng-container>
    <ng-container *ngSwitchCase="'textarea'">
      <div class="el">
        <h5 *ngIf="d.name">{{ d.name }}</h5>
        <div class="input-wrapper">
          <textarea
            [ngStyle]="d.style"
            (keydown.enter)="$event.stopPropagation()"
            autosize
            [readonly]="d.readonly"
            [placeholder]="d.placeholder || d.name || ''"
            #input
            [(ngModel)]="d.value"
          ></textarea>
          <button
            *ngIf="d.action"
            class="action"
            (click)="d.action.action(d.value, d)"
          >
            {{ d.action.name }}
          </button>
        </div>
      </div>
    </ng-container>
    <ng-container *ngSwitchDefault>
      <div class="el">
        <h5 *ngIf="d.name">{{ d.name }}</h5>
        <div class="input-wrapper" [class.errored]="hasError(d)">
          <input
            [ngStyle]="d.style"
            [readonly]="d.readonly"
            [placeholder]="d.placeholder || d.name || ''"
            [(ngModel)]="d.value"
          />
          <button
            *ngIf="d.action"
            class="action"
            (click)="d.action.action(d.value, d)"
          >
            {{ d.action.name }}
          </button>
        </div>
        <div class="input-error" *ngIf="hasError(d)">
          {{ d.errorMessage }}
        </div>
      </div>
    </ng-container>
  </ng-container>
</ng-container>
<div class="buttons">
  <button class="cancel" (click)="close()">
    {{ data.cancelText || '취소' }}
  </button>
  <button
    class="blue"
    [class.disabled]="isDisabled"
    [ngClass]="data.conf?.okClass"
    (click)="ok()"
  >
    {{ data.okText || '확인' }}
  </button>
</div>
