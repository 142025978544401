<div *ngIf="!done">
  <div class="title">
    <h5>미등록 판례 요청</h5>
  </div>
  <div class="input-wrapper mb-12">
    <label class="mb-4"> 법원명 (필수) </label>
    <lb-court-input [(court)]="court"></lb-court-input>
  </div>
  <div class="input-wrapper mb-12">
    <label class="mb-4"> 사건번호 (필수) </label>
    <input [(ngModel)]="caseId" placeholder="사건번호를 입력해주세요." />
  </div>
  <div class="buttons">
    <button class="cancel" trackId="9fdd" (click)="close()">취소</button>
    <button class="blue" trackId="fb32" (click)="request()">등록요청</button>
  </div>
</div>
<div *ngIf="done">
  <div class="title">
    <div *ngIf="prevent">
      <h5>입수불가 판례</h5>
      <p [innerHTML]="prevent"></p>
    </div>
    <div *ngIf="!prevent">
      <h5>판례 등록 요청 완료</h5>
      <div [ngSwitch]="type" class="font-12">
        <div class="alert" *ngSwitchCase="0">
          <div class="mb-4">
            <span class="icon3 n-63 h-5 mr-8"></span
            ><span
              >가압류, 가처분 결정문은 법원별 제공시점에 따라 등록이 지연될 수
              있습니다.</span
            >
          </div>
          <div>
            <span class="icon3 n-63 h-5 mr-8"></span>보관기간이 지나
            폐기되었거나 열람제한 신청된 결정문은 등록이 불가합니다.
          </div>
        </div>
        <div class="alert" *ngSwitchCase="2">
          <div class="mb-4">
            <span class="icon3 n-63 h-5 mr-8"></span
            ><span
              >오래된 판례는 법원별 제공시점에 따라 등록이 지연될 수
              있습니다.</span
            >
          </div>
          <div>
            <span class="icon3 n-63 h-5 mr-8"></span>보관기간이 지나
            폐기되었거나 열람제한 신청된 판례는 등록이 불가합니다.
          </div>
        </div>
        <div class="alert" *ngSwitchDefault>
          <div class="mb-4">
            <span class="icon3 n-63 h-5 mr-8"></span
            ><span>영업일 기준 1~2일이 소요됩니다.</span>
          </div>
          <div>
            <span class="icon3 n-63 h-5 mr-8"></span>판결이 아직 확정되지
            않았거나 열람제한 신청된 판례는 등록이 불가합니다.
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="buttons mt--12">
    <button class="blue" trackId="abfd" (click)="close()">확인</button>
  </div>
</div>
