import { Component, OnInit } from '@angular/core';
import { TrackService } from '../../track/track.service';
import { ApiService } from '../../../../ngxSpring/api.service';
import { AlertService } from '../../alert/alert.service';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'lb-track-edit',
  templateUrl: './track-edit.component.html',
  styleUrls: ['./track-edit.component.sass'],
})
export class TrackEditComponent implements OnInit {
  constructor(
    public gaService: TrackService,
    private api: ApiService,
    private alert: AlertService,
  ) {}

  ids = [];
  notRegistered;
  viewOnly;
  keyword;

  toggleBody() {
    document.querySelector('html').classList.toggle('track-view');
    this.gaService.showTracks = [];
    this.gaService.showTrack.emit();
  }

  get remains() {
    if (!this.gaService.trackMap) return;
    return this.ids
      .filter((it) => !this.notRegistered || !it.key)
      .filter(
        (it) => !this.viewOnly || this.gaService.showTracks.includes(it.name),
      )
      .filter(
        (it) =>
          !this.keyword ||
          it.key?.match(this.keyword) ||
          it.name?.match(this.keyword),
      );
  }

  find(id: string) {
    if (!id) return;
    this.gaService.showTrack.emit(id);
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.loadNames();
    }, 1000);
  }

  private loadNames() {
    this.api.trackId.getTrackNames().subscribe((names) => {
      const nameAndKeys = Object.keys(this.gaService.trackMap).map((it) => {
        return { key: it, value: this.gaService.trackMap[it] };
      });
      return (this.ids = names.map((name) => {
        return {
          name: name,
          key: nameAndKeys.find((it) => it.value.name == name)?.key,
        };
      }));
    });
  }

  new() {
    this.alert.prompt({ type: 'textarea' }, '등록').then((v) => {
      if (!v) return;
      forkJoin(
        v
          .split('\n')
          .filter((it) => !this.ids.includes(it))
          .filter((it) => it)
          .map((it) => this.api.trackId.newTrack(it)),
      ).subscribe(() => {
        this.alert.pop('완료');
        this.loadNames();
      });
    });
  }
}
