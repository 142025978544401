import {
  Inject,
  Injectable,
  Injector,
  OnDestroy,
  PLATFORM_ID,
} from '@angular/core';
import { ApiService } from '../../../ngxSpring/api.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { Meta, Title } from '@angular/platform-browser';
import { firstValueFrom, Subscription } from 'rxjs';
import { LocalStorageService } from './local-storage.service';
import { isPlatformServer } from '@angular/common';

import { init, track } from '@amplitude/analytics-browser';
import { log } from '../../log';
import moment from 'moment';
import { uuid } from './util';
import { UserService } from '../user/user.service';
import { isProduction } from './util';
import { localStorageKeys } from '../user/constants';

@Injectable({
  providedIn: 'root',
})
export class TrackingService implements OnDestroy {
  pageId: string;
  prevPageId: string;
  prevEventId: string;
  prevEventName: string;
  pageName;
  pageValue;
  prevPageName;
  subscription: Subscription;
  userProperties;
  init;
  beForeInitCall = [];

  constructor(
    private api: ApiService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public meta: Meta,
    private title: Title,
    private localStorage: LocalStorageService,
    private injector: Injector,
    @Inject(PLATFORM_ID) private platform,
  ) {
    if (isPlatformServer(this.platform)) return;
    this.pageId = this.localStorage.getItem('pageId');
    this.prevEventId = this.localStorage.getItem('prevEventId');
    this.subscription = this.router.events
      .pipe(filter((e) => e instanceof NavigationEnd))
      .subscribe(() => {
        this.setPageId();
        this.prevPageName = this.pageName;
        this.pageName = null;
        this.pageValue = null;
        this.setPageNameAndMeta(this.activatedRoute);
        if (this.pageName) {
          void this.tracking(this.pageName, {
            prevPageName: this.prevPageName,
            ...this.pageValue,
          });
        }
      });

    let amplitudeApiKey = '1a70786f4f469f077e3bd90ac262cf83';
    if (location.host.startsWith('stage')) {
      amplitudeApiKey = 'ef75d6dce37051a8a8fc2d4f6f598210';
    }

    if (location.host.startsWith('dev')) {
      amplitudeApiKey = 'da6c9ab8d175b940b92dc5543db348a6';
    }

    void init(amplitudeApiKey, null, {
      minIdLength: 0,
      flushQueueSize: 100,
    }).promise.then(() => {
      this.init = true;
      this.beForeInitCall.forEach((it) => {
        void this.tracking(it.name, it.data).then();
      });
      this.beForeInitCall = [];
    });
  }

  private setPageId() {
    this.prevPageId = this.pageId;
    this.pageId = uuid();
    localStorage.setItem('pageId', this.pageId);
  }

  get userServiceInstance() {
    return this.injector.get(UserService);
  }

  get lboxUserId() {
    const lboxUserId = this.userServiceInstance?.lboxUser?.userId;
    return lboxUserId ? String(lboxUserId) : null;
  }

  direct(type: string, results: any) {
    /**
     * user data 가 아직 load 되기 전이라면,
     * user data update 이후에 다시 이 함수가 실행될 수 있도록 한다.
     */
    if (!this.userServiceInstance.userLoaded) {
      this.userServiceInstance.userUpdated.subscribe(() => {
        this.direct(type, results);
      });

      return;
    }

    const eventId = uuid();
    const data = {
      type,
      ...results,
      user_id: this.lboxUserId,
      search_session_id: localStorage.getItem('search_session_id'),
    };

    void firstValueFrom(
      this.api.clientTracking.saveClientTracking(
        location.href,
        this.pageId,
        eventId,
        this.prevEventId,
        this.prevPageId,
        data,
      ),
    ).then();

    log(type, {
      url: location.href,
      pageId: this.pageId,
      eventId,
      prevEventId: this.prevEventId,
      prevPageId: this.prevPageId,
      data,
    });
  }

  getCasePDPFunnelRootId() {
    return window.sessionStorage.getItem('case_pdp_funnel_root_id') || null;
  }

  async tracking(name, data: any = {}) {
    if (isPlatformServer(this.platform)) {
      return;
    }

    /**
     * user data 가 아직 load 되기 전이라면,
     * user data update 이후에 다시 이 함수가 실행될 수 있도록 한다.
     */
    if (!this.userServiceInstance.userLoaded) {
      this.userServiceInstance.userUpdated.subscribe(() => {
        void this.tracking(name, data);
      });

      return;
    }

    if (!this.pageId) {
      this.setPageId();
    }

    /**
     * @description
     * 외부에서 eventId 생성한 경우 외부 eventId를 사용 그렇지 않은 경우 uuid로 새롭게 생성
     */
    const eventId = data.externalGenerateEventId || uuid();

    if (data.externalGenerateEventId) {
      delete data.externalGenerateEventId;
    }

    localStorage.setItem('prevEventId', eventId);

    /**
     * @description
     * 1. isLeafEvent가 true인 경우, prev_leaf_id를 설정한다.
     * 2. data에 isLeafEvent 값이 적재되지 않도록 제거한다.
     */
    if (data.isLeafEvent) {
      localStorage.setItem('prevLeafId', eventId);

      delete data.isLeafEvent;
    }

    const eventInput = {
      user_id: this.lboxUserId,
      device_id: localStorage.getItem('deviceId'),
      event_type: name,
      event_properties: {
        previousEvent: this.prevEventName,
        timestamp: moment().format('YYYY-MM-DDTHH:mm:ss'),
        ...data,
        pageId: this.pageId,
        eventId: eventId,
        prevEventId: this.prevEventId,
        prevPageId: this.prevPageId,
        prevPagePath: this.localStorage.getItem(
          localStorageKeys.PREV_PAGE_PATH,
        ),
        currentPagePath: this.localStorage.getItem(
          localStorageKeys.CURRENT_PAGE_PATH,
        ),
      },
    };
    if (!this.init) {
      this.beForeInitCall.push({ name, data });
      return;
    }
    // Amplitude tracking logic
    const sendToAmplitude = !isProduction();
    if (eventInput.user_id || eventInput.device_id) {
      if (sendToAmplitude) {
        void track(eventInput).promise.then((result) => {
          if (result.code == 200) return;
        });
      }
    }

    // LBox server tracking logic
    await firstValueFrom(
      this.api.clientTracking.saveClientTracking(
        location.href,
        this.pageId,
        eventId,
        this.prevEventId,
        this.prevPageId,
        {
          ...data,
          type: eventInput.event_type,
          amplitude: eventInput,
          userAgent: window.navigator.userAgent,
        },
      ),
    ).then();

    log(name, eventInput);
    this.prevEventName = name;
    this.prevEventId = eventId;
  }

  private setPageNameAndMeta(route: ActivatedRoute): ActivatedRoute {
    while (route.firstChild) {
      route = route.firstChild;
      route.data.subscribe((v) => {
        if (v.name) {
          this.pageName = v.name;
          this.pageValue = v.pageValue;
        }
        if (v.meta) {
          this.updateMeta(v.meta.title, v.meta.description);
        }
      });
    }
    return route;
  }

  updateMeta(title?, description?) {
    if (title) {
      this.title.setTitle(title);
      this.meta.updateTag(
        { name: 'og:title', content: title },
        "name='og:title'",
      );
      this.meta.updateTag({ name: 'title', content: title }, "name='title'");
    }
    if (description) {
      this.meta.updateTag(
        { name: 'og:description', content: description },
        "name='og:description'",
      );
      this.meta.updateTag(
        { name: 'description', content: description },
        "name='description'",
      );
    }
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }
}
