import { Injectable } from '@angular/core';
import { ApiService } from '../../../ngxSpring/api.service';
import { Router } from '@angular/router';
import { AlertService } from '../alert/alert.service';
import { judgeLink } from '../shared/judge-link.pipe';

@Injectable({ providedIn: 'root' })
export class JudgesService {
  judgeOptions = [];
  judges = [];
  count;

  page = 0;
  query;

  load(keyword?, trigger?, route = true, ref?) {
    this.page = 0;
    this.query = keyword;
    this.api.judgeProfile
      .getJudgeProfileList(this.page, 20, this.query)
      .subscribe((v) => {
        this.judges = v.content;
        this.count = v.totalElements;
        if (route) {
          const results = v.content.filter((it) => it.name == keyword);
          if (!results.length) {
            this.alert.pop(
              '해당하는 판사가 없습니다. 정확한 성명을 입력해주세요.',
            );
            trigger?.openPanel();
            return;
          }
          if (results.length == 1) {
            window.open('/judge/' + keyword, '_blank');
            trigger?.closePanel();
            ref?.close();
            return;
          }
          trigger?.openPanel();
          this.alert.pop('동명이인이 있습니다. 목록에서 선택해주세요.');
        }
      });
  }

  constructor(
    private api: ApiService,
    private router: Router,
    private alert: AlertService,
  ) {
    this.load(null, null, false);
  }

  keyword;

  find(value: string) {
    if (!value) return;
    if (this.keyword == value) return;
    this.keyword = value;
    this.api.judgeProfile
      .getJudgeProfileList(this.page, 10, value)
      .subscribe((v) => {
        this.judgeOptions = v.content.distinct((a, b) => {
          return a.name == b.name && a.serial == b.serial;
        });
      });
  }

  moveTo(value: any) {
    window.open(judgeLink(value), '_blank');
  }

  loadMore() {
    if (this.count <= this.judges.length) return;
    this.page++;
    this.api.judgeProfile
      .getJudgeProfileList(this.page, 20, this.query)
      .subscribe((v) => {
        this.judges.pushAll(v.content);
      });
  }
}
