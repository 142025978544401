import { Component, OnInit, ViewChild } from '@angular/core';
import { DialogOrBottomSheetProto } from '../../alert/alert.service';
import { Observable } from 'rxjs';
import {
  S3Signature,
  UploadMyCaseParams,
} from '../../../../ngxSpring/api.model';
import {
  HttpClient,
  HttpEventType,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { UserService } from '../../user/user.service';
import { LocalStorageService } from '../../core/local-storage.service';

export interface FileObject {
  file: File;
  progressSize: number;
  done: boolean;
  error: string | null;
  path: string;
}

@Component({
  selector: 'lb-file-upload-list',
  templateUrl: './file-upload-list.component.html',
  styleUrls: ['./file-upload-list.component.sass'],
})
export class FileUploadListComponent
  extends DialogOrBottomSheetProto<{ fileList: FileList; popOnly }>
  implements OnInit
{
  @ViewChild('tipInstance')
  tipInstance;

  get nameHide(): boolean {
    return this._nameHide;
  }

  set nameHide(value: boolean) {
    if (value)
      this.injector.get(LocalStorageService).setItem('nameHide', 'true');
    else this.injector.get(LocalStorageService).removeItem('nameHide');
    this._nameHide = value;
  }

  phase = 0;
  name;
  files: FileObject[] = [];
  agree;
  private _nameHide = false;
  agree2;
  /** 내 판례등록 최종 작업 진행중 */
  isRegistering = false;

  get validFiles() {
    return this.files.filter((it) => !it.error);
  }

  get done() {
    return !this.validFiles.find((it) => !it.done);
  }

  get sum() {
    return this.validFiles.map((it) => it.file.size).sum();
  }

  get progressSum() {
    return this.validFiles.map((it) => it.progressSize).sum();
  }

  getSignature(file: File): Observable<S3Signature> {
    const ext = file.name.split('.').pop().toLowerCase();
    if (ext !== 'pdf') {
      this.alert.pop('PDF만 업로드 가능합니다.');
      return;
    }
    return this.api.uploadFile.caseUploadSignature({
      filename: file.name,
      size: file.size,
    } as UploadMyCaseParams);
  }

  ngOnInit(): void {
    this.fileUpload(this.data.fileList);
    this._nameHide = !!this.injector
      .get(LocalStorageService)
      .getItem('nameHide');
    this.name = this.injector.get(UserService)?.user?.name;
  }

  fileUpload(fileList) {
    if (!fileList || fileList?.length === 0) {
      return;
    }

    const files = Array.from(fileList).map((file: any) => {
      return {
        file: file,
        progress: 0,
        progressSize: 0,
        done: false,
        error: file.error,
        path: null,
      };
    });
    files
      .filter((it) => !it.error)
      .forEach((file) => {
        this.api.myCaseRegister.checkName(file.file.name).subscribe((v) => {
          if (v) {
            file.error =
              '업로드 실패: 이미 등록되었거나 등록 신청된 판례입니다.';
            return;
          }
          this.getSignature(file.file).subscribe((value) => {
            const formData: FormData = new FormData();
            Object.keys(value)
              .filter((v) => v !== 'Host')
              .forEach((key) => {
                formData.append(key, value[key]);
              });
            formData.append('file', file.file);
            const req = new HttpRequest(
              'POST',
              `https://${value.Host}`,
              formData,
              { reportProgress: true },
            );

            this.injector
              .get(HttpClient)
              .request(req)
              .subscribe((event) => {
                if (event.type === HttpEventType.UploadProgress) {
                  file.progressSize = event.loaded;
                } else if (event instanceof HttpResponse) {
                  file.progressSize = file.file.size;
                  file.done = true;
                  file.path = value.key;
                }
              });
          });
        });
      });
    this.files.pushAll(files);
  }

  user = this.injector.get(UserService);

  async registerAndClose() {
    if (
      !this.user.authorized &&
      !(await this.user.authorizeLawyer({ channel: '내 판례등록' }))
    )
      return;

    this.isRegistering = true;
    this.api.myCaseRegister
      .saveMyCaseRegisters(
        this.validFiles.map((it) => {
          return {
            fileName: it.file.name,
            filePath: it.path,
            nameHide: this._nameHide,
          };
        }),
      )
      .subscribe(() => {
        this.close(true);
        if (this.data.popOnly) {
          this.alert.pop('내 판례등록 신청완료');
          return;
        }
        void this.alert
          .alert(
            '내 판례등록 신청 완료',
            null,
            '모든 개인정보에 대한 익명화가 진행되며, 익명화가 완료되면 카카오톡/이메일로 알림을 드립니다.<br>' +
              '‘내 메뉴 > 내 판례등록’ 페이지에서 익명화된 판례를 확인 후,\n' +
              '승인해주시면 판례가 최종 등록됩니다.',
          )
          .then(() => {
            this.userpilotService.instance?.track(
              '내 판례등록 신청완료 팝업 확인 버튼 클릭',
            );
          });
      });
  }
}
