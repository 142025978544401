/* tslint:disable */
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ApiHttp} from '../api.http';

import {Page, UserAlarm} from '../api.model';

@Injectable()
export class UserAlarmController {
  constructor(private http: ApiHttp) {
  }

  getAllUserAlarmList(keyword?: string, page?: number, size?: number): Observable<Page<UserAlarm>> {
    return this.http.get<Page<UserAlarm>>('/api/userAlarm/list/all', {keyword: keyword, page: page, size: size});
  }

  getNewCount(): Observable<number> {
    return this.http.get<number>('/api/userAlarm/count');
  }

  getUserAlarmList(types: any[], page?: number, size?: number): Observable<Page<UserAlarm>> {
    return this.http.post<Page<UserAlarm>>('/api/userAlarm/list', types, {page: page, size: size});
  }

  readUserAlarm(): Observable<void> {
    return this.http.post<void>('/api/userAlarm/read', null);
  }

  saveUserAlarm(userAlarm: UserAlarm): Observable<UserAlarm> {
    return this.http.post<UserAlarm>('/api/userAlarm', userAlarm);
  }
}
