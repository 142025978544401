import { Component } from '@angular/core';
import { DialogOrBottomSheetProto } from '../../../alert/alert.service';
import { UserService } from '../../user.service';

@Component({
  selector: 'lb-lqa-my-cases-register',
  templateUrl: './lqa-my-cases-register.component.html',
  styleUrls: ['./lqa-my-cases-register.component.sass'],
})
export class LqaMyCasesRegisterComponent extends DialogOrBottomSheetProto<any> {
  user: UserService = this.injector.get(UserService);
}
