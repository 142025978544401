import { Injectable } from '@angular/core';
import { UserService } from '../user/user.service';

@Injectable({
  providedIn: 'root',
})
export class HistoryService {
  allHistory = [];
  history = [];

  get keywordHistory() {
    return this.history.filter((it) => !it.name);
  }

  constructor(private user: UserService) {
    try {
      this.allHistory = JSON.parse(this.user.getItem('history'));
    } catch (e) {
      this.allHistory = this.history = [];
    }
    this.user.userUpdated.subscribe(() => {
      try {
        this.allHistory = JSON.parse(this.user.getItem('history'));
      } catch (e) {
        this.allHistory = this.history = [];
      }
    });
  }

  addHistory(query: any) {
    if (!this.allHistory) {
      this.allHistory = [];
    }
    if (this.allHistory.includes(query)) {
      this.allHistory.remove(query);
    }
    this.removeJudge(query);
    this.allHistory.unshift(query);
    this.allHistory.splice(100);
    this.user.setItem('history', JSON.stringify(this.allHistory));
  }

  private removeJudge(query: any) {
    try {
      const judge = JSON.parse(query);
      const filter = this.allHistory.filter((it) => {
        try {
          const f = JSON.parse(it);
          return f.name == judge.name && f.serial == judge.serial;
        } catch (e) {
          return false;
        }
      });
      this.allHistory.removeAll(filter);
    } catch (e) {}
  }

  removeHistory(keyword: any) {
    if (!this.allHistory) {
      return;
    }
    this.removeJudge(JSON.stringify(keyword));
    this.allHistory.remove(keyword);
    this.history.remove(keyword);
    this.user.setItem('history', JSON.stringify(this.allHistory));
  }

  historyFiltering(value: string) {
    if (this.allHistory) {
      this.history = this.allHistory
        .map((it) => {
          if (it.startsWith && it.startsWith('{')) {
            try {
              return JSON.parse(it);
            } catch (e) {
              return '';
            }
          }
          return it;
        })
        .filter((it) => it)
        .filter(
          (it) =>
            (it && it.match && it.match(value)) ||
            (it.name && it.name.match(value)),
        )
        .slice(0, 5);
    }
  }
}
