<div class="title alert-dialog pb-0">
  <div class="head">
    {{
      data?.title ||
        '수행사건 추가'
    }}
  </div>
  <div class="desc mt-16 word-break-keep-all">
    <span *ngIf="data?.description" [innerHTML]="data?.description"></span>
    <ng-container *ngIf="user.candidateCases?.length">
      아래 두 가지 방법을 통해 변호사님께서 직접 수행하신 사건을 알려주세요.
    </ng-container>
    <ng-container *ngIf="!user.candidateCases?.length">
      내 판례등록을 통해 변호사님께서 직접 수행하신 사건을 알려주세요.
    </ng-container>
  </div>
</div>
<div class="transparent-type mt-24">
  <button trackId="dd47" (click)="user.myCaseRegister(); close()">
    내 판례 등록하기<span class="color-primary font-14"
      >(1건 당 5,000포인트 지급)</span
    >
  </button>
  <button
    *ngIf="user.candidateCases?.length"
    trackId="affd"
    (click)="user.selectCases(); close()"
    class="black"
  >
    기존 판례에 수행여부 표시하기
  </button>
  <button class="cancel" trackId="17ea" (click)="close(null)">나중에</button>
</div>
