/* tslint:disable */
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ApiHttp} from '../api.http';

import {
  CaseAndRelations,
  CaseRelations,
  Judge,
  JudgeList,
  NewsResult,
  ReferenceResult,
  ReferentialSearch,
  RelativeSearch,
  SearchInDocIdList,
  SearchMapCase,
  SearchMapParams,
  SearchMapRelatedCase,
  SearchMapRelationsParams,
  SearchMapRelationsResponse,
  SearchQuery,
  SearchResult,
  SearchResultAndRelation,
  SearchResultAndRelationForMap,
  SearchResultInBookmark,
  TopHighlight,
} from '../api.model'

@Injectable()
export class LbCaseController {
  constructor(private http: ApiHttp) {
  }

  casesByLawFirms(name: string, searchQuery?: SearchQuery, page?: number, size?: number): Observable<SearchResult> {
    return this.http.post<SearchResult>('/api/case/casesByLawFirms', searchQuery, {name: name, page: page, size: size});
  }

  completion(query: string, isLaywer: boolean): Observable<string[]> {
    return this.http.get<string[]>('/api/case/completion', {query: query, isLaywer: isLaywer});
  }

  get(id: string, searchKeyword?: string, key?: string, hasLimit?: boolean): Observable<CaseAndRelations> {
    return this.http.get<CaseAndRelations>('/api/case', {id, key, searchKeyword, hasLimit});
  }

  getCaseNames(name: string, serial: string, searchQuery?: SearchQuery): Observable<any> {
    return this.http.post<any>('/api/case/getCaseNames', searchQuery, {name: name, serial: serial});
  }

  getCaseRelationList(searchQuery: SearchInDocIdList): Observable<SearchResultAndRelation> {
    return this.http.post<SearchResultAndRelation>('/api/case/caseTree/list', searchQuery);
  }

  getCaseRelations(id: string): Observable<CaseRelations> {
    return this.http.get<CaseRelations>('/api/case/caseTree', {id: id});
  }

  getCasesByJudge(name: string, serial: string, searchQuery?: SearchQuery, title?: string, page?: number, size?: number): Observable<SearchResult> {
    return this.http.post<SearchResult>('/api/case/casesByJudge', searchQuery, {name: name, serial: serial, title: title, page: page, size: size});
  }

  getCasesCitedByJudge(name: string, serial: string, referentialSearch?: ReferentialSearch, page?: number, size?: number): Observable<SearchResult> {
    return this.http.post<SearchResult>('/api/case/casesCitedByJudge', referentialSearch, {name: name, serial: serial, page: page, size: size});
  }

  getCourtNames(name: string, serial: string, searchQuery?: SearchQuery): Observable<any> {
    return this.http.post<any>('/api/case/getCourtNames', searchQuery, {name: name, serial: serial});
  }

  getHighlights(docId: string): Observable<TopHighlight> {
    return this.http.get<TopHighlight>('/api/case/highlights', {docId: docId});
  }

  getJudges(id: string): Observable<Judge[]> {
    return this.http.get<Judge[]>('/api/case/judges', {id: id});
  }

  getJudgesCitedByJudge(name: string, serial: string, searchQuery?: SearchQuery, page?: number, size?: number, judgeCourtType?: string, nameFiltered?: string): Observable<JudgeList> {
    return this.http.post<JudgeList>('/api/case/judgesCitedByJudge', searchQuery, {name: name, serial: serial, page: page, size: size, judgeCourtType: judgeCourtType, nameFiltered: nameFiltered});
  }

  getLawfirms(id: string): Observable<string[]> {
    return this.http.get<string[]>('/api/case/lawfirms', {id: id});
  }

  isIdValid(id: string): Observable<boolean> {
    return this.http.get<boolean>('/api/case/isIdValid', {id: id});
  }

  isIdValidAndNotSnippet(id: string): Observable<boolean> {
    return this.http.get<boolean>('/api/case/isIdValidAndNotSnippet', {id: id});
  }

  isRequestAble(id: string): Observable<boolean> {
    return this.http.get<boolean>('/api/case/isRequestAble', {id: id});
  }

  lawCompletion(query: string): Observable<string[]> {
    return this.http.get<string[]>('/api/case/completionLaw', {query: query});
  }

  search(searchQuery: SearchQuery): Observable<SearchResult> {
    return this.http.post<SearchResult>('/api/case/search', searchQuery);
  }

  searchForMap(searchQuery: SearchQuery): Observable<SearchResultAndRelationForMap> {
    return this.http.post<SearchResultAndRelationForMap>('/api/case/search/map', searchQuery);
  }

  searchForMapV2(searchMapParams: SearchMapParams): Observable<{data: SearchMapCase[]}> {
    return this.http.post<{data: SearchMapCase[]}>('/api/v2/case/search/map', searchMapParams)
  }

  searchForMapRelationsV2(searchMapRelationsParams: SearchMapRelationsParams): Observable<{ data: SearchMapRelationsResponse }> {
    return this.http.post<{ data: SearchMapRelationsResponse }>('/api/v2/case/search/map/relations', searchMapRelationsParams);
  }

  searchInBookmark(query: SearchQuery, page?: number, size?: number, highlightQuery?: string, folderId?: number): Observable<SearchResultInBookmark> {
    return this.http.post<SearchResultInBookmark>('/api/case/search/bookmark', query, {page: page, size: size, highlightQuery: highlightQuery, folderId: folderId});
  }

  searchInDocIdList(searchInDocIdList: SearchInDocIdList, page?: number, size?: number, highlightQuery?: string, includeSnippet?: boolean): Observable<SearchResult> {
    return this.http.post<SearchResult>('/api/case/search/docIdList', searchInDocIdList, {page: page, size: size, highlightQuery: highlightQuery, includeSnippet: includeSnippet});
  }

  searchInterpretation(searchQuery: SearchQuery): Observable<ReferenceResult> {
    return this.http.post<ReferenceResult>('/api/case/search/interpretation', searchQuery);
  }

  searchNews(searchQuery: SearchQuery): Observable<NewsResult> {
    return this.http.post<NewsResult>('/api/case/search/news', searchQuery);
  }

  searchRelatives(relativeSearch: RelativeSearch, page?: number, size?: number): Observable<SearchResult> {
    return this.http.post<SearchResult>('/api/case/search/relative', relativeSearch, {page: page, size: size});
  }

  searchRelativesTop3(relativeSearch: RelativeSearch): Observable<SearchResult> {
    return this.http.post<SearchResult>('/api/case/search/relativeTop3', relativeSearch);
  }

  searchTrialdecision(searchQuery: SearchQuery): Observable<ReferenceResult> {
    return this.http.post<ReferenceResult>('/api/case/search/trialdecision', searchQuery);
  }
}
