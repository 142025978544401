import { Injectable } from '@angular/core';
import { CompanyInfo } from '../../../ngxSpring/api.model';
import { ApiService } from '../../../ngxSpring/api.service';
import { firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class OrgService {
  company: CompanyInfo;

  constructor(private api: ApiService) {}

  async loadCompany() {
    this.company = await firstValueFrom(
      this.api.companyManager.getCompanyInfo(),
    );
    return this.company;
  }
}
