import { Validators } from '@angular/forms';

export const passwordValidators = [
  Validators.required,
  '비밀번호를 입력해주세요.',
  Validators.minLength(8),
  '비밀번호는 8자이상 이어야 합니다.',
  Validators.pattern(/(?=.*[A-Za-z])/g),
  '비밀번호에 영문자를 포함해주세요.',
  Validators.pattern(/(?=.*[0-9])/g),
  '비밀번호에 숫자를 포함해주세요.',
  Validators.pattern(/(?=.*[^A-Za-z0-9])/g),
  '비밀번호에 특수문자를 포함해주세요.',
];

export const emailValidators = [
  Validators.required,
  '이메일을 입력해주세요.',
  Validators.pattern(/[^@]+@[^\\.]+\..+/),
  '이메일이 잘못 입력되었습니다.',
];
