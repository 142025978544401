<div class="title">
  <h5>비밀번호 변경</h5>
</div>
<form class="text-left" [formGroup]="passwordRedefineForm">
  <div class="input-wrapper">
    <input
      [placeholder]="'새 비밀번호'"
      formControlName="password"
      type="password"
    />
    <div>
      <lb-err-for [group]="passwordRedefineForm" errFor="password"></lb-err-for>
    </div>
  </div>
  <div class="input-wrapper">
    <input
      placeholder="비밀번호 재입력"
      formControlName="passwordConfirm"
      type="password"
    />
    <div>
      <lb-err-for
        [group]="passwordRedefineForm"
        errFor="passwordConfirm"
      ></lb-err-for>
    </div>
  </div>
</form>
<div class="buttons">
  <button class="cancel" trackId="ba88" (click)="close()">취소</button>
  <button
    class="blue"
    trackId="f901"
    (click)="passwordRedefine()"
    [class.disabled]="passwordRedefineForm.error()"
  >
    비밀번호 변경하기
  </button>
</div>
