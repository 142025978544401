import { Injectable } from '@angular/core';
import { LocalStorageService } from './local-storage.service';

@Injectable({
  providedIn: 'root',
})
export class ReadService {
  constructor(private local: LocalStorageService) {}

  isRead(id: string) {
    return this.getRead()[id];
  }

  read(caseLink) {
    const read = this.getRead();
    read[caseLink] = true;
    try {
      this.local.setItem('read', JSON.stringify(read));
    } catch (e) {}
  }

  private getRead() {
    let read;
    try {
      read = JSON.parse(this.local.getItem('read'));
    } catch (e) {}
    if (!read) read = {};
    return read;
  }
}
