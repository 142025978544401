import {
  Component,
  HostListener,
  Input,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import { SearchFunction } from '../command';
import { SearchService } from '../../search/search.service';
import { NavigationEnd, Router } from '@angular/router';
import { ApiService } from '../../../../ngxSpring/api.service';
import { HighlightTag } from 'angular-text-input-highlight/highlight-tag.interface';
import { getParameterByName } from '../util';
import { TrackService } from '../../track/track.service';
import { AlertService } from '../../alert/alert.service';
import { TextInputHighlightComponent } from 'angular-text-input-highlight';
import { Subscription } from 'rxjs';
import { UserService } from '../../user/user.service';
import { JudgesService } from '../../judge/judges.service';
import {
  MAT_AUTOCOMPLETE_DEFAULT_OPTIONS,
  MatAutocompleteActivatedEvent,
} from '@angular/material/autocomplete';
import { PerfectScrollbarComponent } from 'ngx-perfect-scrollbar';

export interface HighlightTagWithQuery extends HighlightTag {
  name;
  query;
  key;
  value;
}

const space = ' ';

@Component({
  selector: 'lb-case-search',
  templateUrl: './case-search.component.html',
  styleUrls: ['./case-search.component.sass'],
  providers: [
    {
      provide: MAT_AUTOCOMPLETE_DEFAULT_OPTIONS,
      useValue: { overlayPanelClass: 'case-search-overlay-autocomplete' },
    },
  ],
})
export class CaseSearchComponent implements OnDestroy {
  @Input()
  withMaxHeight = false;

  @Input()
  label;

  @Input()
  placeholder;

  preventSearch;

  @ViewChild('input')
  input;

  hasValue;

  // 조합형 입력 후 Enter 눌러도 1번만 호출되도록 분기 처리
  handleKeyDownEnter(e, trigger) {
    if (!e.isComposing) {
      this.searchWithKeyword();
      trigger.closePanel()
    }
  }

  searchWithKeyword() {
    if (this.preventSearch) {
      this.preventSearch = false;
      return;
    }
    this.searchService.searchWithKeyword(this.query);
  }

  // 텍스트 에어리어 줄바꿈 금지
  preventEnter($event: KeyboardEvent) {
    if ($event.key === 'Enter') {
      $event.preventDefault();
      $event.stopPropagation();
      return;
    }
  }

  selectionChange(el: any, trigger?, value?) {
    this.preventSearch = true;
    this.functions = [];
    this.searchService.historyService.historyFiltering(value);
    this.find = el;
    setTimeout(() => {
      trigger?.openPanel();
    });
  }

  queryElements: HighlightTagWithQuery[] = [];
  qValue;
  beforeKeyword = '';

  find;
  functions = [];
  completions: any[];

  private extract() {
    if (!this.qValue) {
      return;
    }
    this.queryElements = this.searchService.extractQuery(
      this.qValue,
    ).queryElements;
  }

  get query() {
    if (!this.qValue) {
      return '';
    }
    return this.qValue;
  }

  @Input()
  set query($event: any) {
    this.qValue = $event;
    this.extract();
  }

  constructor(
    public searchService: SearchService,
    public router: Router,
    public api: ApiService,
    public alert: AlertService,
    public gaService: TrackService,
    private user: UserService,
    public judge: JudgesService,
  ) {}

  subscription: Subscription;

  ngOnInit(): void {
    if (this.qValue == undefined)
      this.query = this.searchService.searchQuery.query;
    this.setQuery();
    this.subscription = this.router.events.subscribe((e) => {
      if (e instanceof NavigationEnd) {
        this.query =
          this.searchService.searchQuery.query || getParameterByName('query');
        this.findCompletion(this.query);
        this.setQuery();
      }
    });
  }

  setQuery() {
    setTimeout(() => {
      this.extract();
      if (document.querySelector('textarea')) {
        document.querySelector('textarea').value = this.query;
      }
    }, 100);
  }

  @HostListener('paste', ['$event'])
  onPaste(e) {
    setTimeout(() => {
      e.target.value = e.target.value.replace(/\n/g, '');
      e.target.dispatchEvent(new Event('input'));
    });
  }

  showFunctions(value) {
    this.beforeKeyword = value ? value + ' ' : '';
    this.functions = this.searchService.commands;
    this.searchService.historyService.history = [];
    this.completions = [];
  }

  tagged(el) {
    if (!el) return el;
    const q = this.searchService.extractSearchQuery(el);
    q.queryElements.forEach(
      (it) =>
        (el = el.replace(
          it.query,
          `<span class="quick-tag">${it.query}</span>`,
        )),
    );
    return el;
  }

  @HostListener('click', ['$event'])
  onClick() {
    this.input.nativeElement.focus();
    this.findCompletion(this.input.nativeElement.value);
  }

  findCompletion(value: string, e?, judgeSearch = false) {
    // if (judgeSearch) {
    //   if (value) this.judge.find(value);
    //   else this.judge.judgeOptions = [];
    // }
    switch (e?.keyCode) {
      case 37:
      case 38:
      case 19:
      case 40:
        return;
      default:
        break;
    }

    this.hasValue = value;
    this.searchService.historyService.historyFiltering(value);
    const lastIndex = Math.max.apply(
      null,
      this.searchService.commands
        .map((it) => it.token || it.completion?.[0])
        .concat(space)
        .map((it) => value.lastIndexOf(it)),
    );
    let key = value.substring(lastIndex, value.length);
    if (key[key.length - 1] === ' ') {
      key = this.searchService.extractQuery(key).keyword;
    }

    // 마지막 토큰이 대시일 경우 스페이스 +2의 위치에서 비포키워드 짜름(대시유지)
    // 마지막 토큰이 @등일 경우 스페이스까지 유지
    this.beforeKeyword = value.substring(
      0,
      value.lastIndexOf(space) +
        (value[lastIndex] == '-' || value[lastIndex] == '"' ? 2 : 1),
    );
    this.functions = this.searchService.commands
      .filter((it) => !it.hide)
      .filter(
        (it) =>
          !value.trim() ||
          (value.trim() && it.isMatch(key, value, this.searchService.commands)),
      );
    if (this.find) {
      this.functions = [];
    }

    const completionKeyword = this.queryElements?.length
      ? value.substring(lastIndex + 1, value.length)
      : value;

    this.find = this.searchService.commands.find((it) => {
      if (this.beforeKeyword.endsWith(' "')) {
        return this.beforeKeyword
          .substring(0, this.beforeKeyword.length - 1)
          .endsWith(it.completion);
      }
      return this.beforeKeyword.endsWith(it.completion);
    });

    if (!this.find) {
      this.find = this.searchService.commands.find((it) =>
        this.beforeKeyword.endsWith(it.completion),
      );
    }

    if (this.find) {
      this.functions = [];
    }
    if (
      this.searchService.searchQuery.caseType == 'news' ||
      this.searchService.searchQuery.caseType == 'reference'
    ) {
      this.functions = [];
      this.find = null;
    }

    // 컴플리션과 완전히 일치하는 쿼리면 추천어 검색 안함
    if (
      this.functions.find(
        (it) =>
          it.completion.substring(0, completionKeyword.length) ===
          completionKeyword,
      )
    ) {
      this.completions = [];
      return;
    }
    const last = this.beforeKeyword
      .split(' ')
      .filter((it) => it)
      .last();
    if (last && last.startsWith('@법조문')) {
      this.completionName = '추천 법령';
      this.api.lbCase.lawCompletion(completionKeyword).subscribe((list) => {
        this.completions = list.map((it) => {
          return { view: it, value: it.replace(/ /g, '') };
        });
      });
      return;
    }
    if (last && last.startsWith('@')) {
      return;
    }
    if (!completionKeyword) return;
    this.completionName = '추천 검색어';
    this.api.lbCase
      .completion(completionKeyword, this.user.isLawyer)
      .subscribe((list) => {
        this.completions = list;
      });
  }

  getCompletionValue(c: any) {
    const completion = c.value || c;
    if (!this.queryElements?.length) return completion;
    if (completion.match(' ')) {
      return this.beforeKeyword + `"${completion.value || completion}"`;
    }
    return this.beforeKeyword + (completion.value || completion);
  }

  functionValue(el: SearchFunction) {
    if (el.preserveKeyword) {
      return this.query + el.completion;
    }
    return this.beforeKeyword + el.completion;
  }

  @ViewChild(TextInputHighlightComponent)
  high: TextInputHighlightComponent;
  completionName;

  ifJudge(j) {
    return j && j.name ? j.name : j;
  }

  setScroll() {
    document.querySelector('.text-highlight-element').scrollLeft =
      this.input.nativeElement.scrollLeft;
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

  ifJudgeMove($event) {
    if ($event.name) this.judge.moveTo($event);
  }

  log(
    $event: MatAutocompleteActivatedEvent,
    scroll: PerfectScrollbarComponent,
  ) {
    setTimeout(() => {
      const offset = (document.querySelector('.mat-active') as any).offsetTop;
      const height = (document.querySelector('.mat-active') as any)
        .offsetHeight;
      if (
        scroll.directiveRef.ps().scrollbarYTop < offset &&
        scroll.directiveRef.ps().scrollbarYTop +
          scroll.directiveRef.ps().containerHeight >
          offset + height
      )
        return;
      scroll.directiveRef.scrollTo(null, offset);
    });
  }
}
