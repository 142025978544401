import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ArchiveFolderWithSelect } from '../archive-dialog/archive-dialog.component';
import { FolderService } from '../../bookmark/folder.service';

@Component({
  selector: 'lb-select-folder',
  templateUrl: './select-folder.component.html',
  styleUrls: ['./select-folder.component.sass'],
})
export class SelectFolderComponent implements OnInit, OnDestroy {
  @Input()
  level = 0;

  @Input()
  folder: ArchiveFolderWithSelect;

  @Input()
  selectSingle = false;

  subscription;

  constructor(public folderService: FolderService) {}

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

  ngOnInit(): void {
    if (!this.selectSingle) return;
    this.subscription = this.folderService.selectionChange.subscribe((f) => {
      if (this.folder != f) this.folder.select = false;
    });
  }
}
