import {
  Directive,
  ElementRef,
  Inject,
  Optional,
  PLATFORM_ID,
} from '@angular/core';
import { NgModel } from '@angular/forms';
import { isPlatformServer } from '@angular/common';

@Directive({
  selector: 'input(:not([mask]))',
})
export class InputEventDirective {
  constructor(
    private elf: ElementRef,
    @Optional() private ngModel: NgModel,
    @Inject(PLATFORM_ID) platform,
  ) {
    if (isPlatformServer(platform)) return;
    this.elf.nativeElement.addEventListener('keyup', (e) => {
      this.ngModel?.viewToModelUpdate(e.target.value);
    });
    this.elf.nativeElement.addEventListener('keypress', (e) => {
      this.ngModel?.viewToModelUpdate(e.target.value);
    });
  }
}
