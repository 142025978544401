import {
  Directive,
  ElementRef,
  EventEmitter,
  HostListener,
  Output,
} from '@angular/core'

@Directive({
  selector: '[isInViewport]',
})
export class IsInViewport {
  @Output() onView = new EventEmitter()

  debounceTimer = null
  private MS_PER_FRAME = 16

  constructor(private elementRef: ElementRef) {}

  @HostListener('window:scroll', ['$event'])
  public onScroll() {
    if (this.debounceTimer) {
      clearTimeout(this.debounceTimer)
    }

    this.debounceTimer = setTimeout(() => {
      if (this.elementRef) {
        const rect = this.elementRef.nativeElement.getBoundingClientRect()
        const windowHeight = window.innerHeight

        if (rect.top >= 0 && rect.bottom < windowHeight + rect.height) {
          this.onView.emit()
        }
      }
    }, this.MS_PER_FRAME)
  }
}
