import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
  name: 'keywordBold',
})
export class KeywordBoldPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}

  transform(value: string, regex): any {
    if (!value?.length) return;
    return this.sanitizer.bypassSecurityTrustHtml(this.replace(value, regex));
  }

  replace(str, regex) {
    if (!regex) return str;
    return str.replace(new RegExp(`(${regex})`, 'gi'), '<b>$1</b>');
  }
}
