import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'preventNull',
})
export class PreventNullPipe implements PipeTransform {
  transform(value): string {
    if (!value) {
      return '';
    }
    if (value.includes('null')) {
      return '';
    }
    return value;
  }
}
