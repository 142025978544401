import { Component, ElementRef, HostListener, OnInit } from '@angular/core';
import { CaseSearchComponent } from '../../core/case-search/case-search.component';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { SearchService } from '../../search/search.service';
import { Router } from '@angular/router';
import { ApiService } from '../../../../ngxSpring/api.service';
import { TrackService } from '../../track/track.service';
import { AlertService } from '../../alert/alert.service';
import { UserService } from '../../user/user.service';
import { JudgesService } from '../../judge/judges.service';

@Component({
  selector: 'lb-case-search-compact',
  templateUrl: './case-search-compact.component.html',
  styleUrls: ['./case-search-compact.component.sass'],
})
export class CaseSearchCompactComponent
  extends CaseSearchComponent
  implements OnInit
{
  constructor(
    searchService: SearchService,
    router: Router,
    api: ApiService,
    dialog: AlertService,
    public host: ElementRef,
    ga: TrackService,
    user: UserService,
    judge: JudgesService,
  ) {
    super(searchService, router, api, dialog, ga, user, judge);
  }

  focusOn: boolean;
  mouseOn: boolean;
  autoOpen: boolean;

  updateFocus() {
    if (this.focusOn || this.mouseOn || this.autoOpen) {
      this.host.nativeElement.classList.add('focused');
      return;
    }
    this.host.nativeElement.classList.remove('focused');
  }

  ifFocusSearch(trigger: MatAutocompleteTrigger) {
    if (this.focusOn || this.mouseOn || this.autoOpen) {
      this.searchService.searchWithKeyword(this.query);
      trigger.closePanel();
      return;
    }
    this.input.nativeElement.focus();
  }

  @HostListener('mouseenter')
  mouseOver() {
    this.mouseOn = true;
    this.updateFocus();
  }

  @HostListener('mouseleave')
  mouseLeave() {
    this.mouseOn = false;
    this.updateFocus();
  }
}
