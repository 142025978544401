import { Pipe, PipeTransform } from '@angular/core';
import { Judge, JudgeProfile } from '../../../ngxSpring/api.model';
import { getLBoxNextClientURL } from '../core/util';

export function judgeLink(judge: JudgeProfile | Judge) {
  // React 판사 분석 URL로 연경
  return `${getLBoxNextClientURL()}/legal-analytics/judge-analytics/judge/${judge.name}/${judge.serial}`
  // return (
  //   '/judge/' + judge.name + (judge.serial != 'A' ? '_' + judge.serial : '')
  // );
}

@Pipe({
  name: 'judgeLink',
})
export class JudgeLinkPipe implements PipeTransform {
  transform(judge: JudgeProfile | Judge): string {
    return judgeLink(judge);
  }
}
