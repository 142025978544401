/* tslint:disable */
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ApiHttp} from '../api.http';

import {} from '../api.model';

@Injectable()
export class NewLawyerController {
  constructor(private http: ApiHttp) {
  }

  newLawyerAuthorize(idNumber: string): Observable<void> {
    return this.http.post<void>('/api/newLawyer', null, {idNumber: idNumber});
  }
}
