<ng-container *showItSizes="{ max: 959 }">
  <iframe
    type="text/html"
    width="360"
    height="250"
    [src]="url"
    frameborder="0"
  ></iframe>
</ng-container>

<ng-container *showItSizes="{ min: 960 }">
  <iframe
    type="text/html"
    width="800"
    height="450"
    [src]="url"
    frameborder="0"
  ></iframe>
</ng-container>
