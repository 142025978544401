import { Directive, HostListener } from '@angular/core';
import { AlertService } from '../alert/alert.service';

@Directive({
  selector: '[involveCaseHelp]',
})
export class DoCaseHelpDirective {
  constructor(private alert: AlertService) {}

  @HostListener('click')
  help() {
    this.alert.imageAlert(
      '/assets/selecttip.jpg',
      '수행여부를 표시해주시면, 판례 페이지의 ‘판사/변호사/로펌’ 섹션에 변호사님의 정보가 추가됩니다.',
    );
  }
}
