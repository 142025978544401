import { Component } from '@angular/core';
import { LoaderService } from '../loader.service';

@Component({
  selector: 'lb-loading-bar',
  templateUrl: './loading-bar.component.html',
  styleUrls: ['./loading-bar.component.sass'],
})
export class LoadingBarComponent {
  constructor(public loaderService: LoaderService) {}
}
