import { Injectable } from '@angular/core';
import { environment } from '../../../src/environments/environment'
import { UnleashClient } from 'unleash-proxy-client'

@Injectable({ providedIn: 'root' })
export class FeatureFlagService {
  unleashClient: UnleashClient
  constructor() {}

  initFeatureFlag() {
    this.unleashClient = new UnleashClient({
      url: environment.UNLEASH_URL,
      clientKey: environment.UNLEASH_CLIENT_KEY,
      refreshInterval: 15 ,
      appName: 'lbox-frontend',
    })
    
    this.unleashClient.start();

    /**
     * @description
     * packages/components에서 unleash의 url, client-key를 build 시점에 맞춰 주기 위해 window 객체 사용
     */
    window.lboxClient = window.lboxClient || {};

    window.lboxClient.unleash = {
      UNLEASH_URL: environment.UNLEASH_URL,
      UNLEASH_CLIENT_KEY: environment.UNLEASH_CLIENT_KEY
    };
  }

  getVariant(key: string) {
    return this.unleashClient.getVariant(key)
  }

  isEnabled(key: string) {
    return this.unleashClient.isEnabled(key)
  }

  isReady() {
    return this.unleashClient.isReady()
  }

  getError() {
    return this.unleashClient.getError()
  }
}
