<div class="outer">
  <div class="wrapper">
    <div class="input" mwlTextInputHighlightContainer>
      <textarea
        (focusin)="focusOn = true; updateFocus(); completionOnFind(input.value)"
        (focusout)="focusOn = false; updateFocus()"
        mwlTextInputElement
        [placeholder]="'결과 내 재검색'"
        [matAutocomplete]="auto"
        trackEnter="돋보기 클릭(엔터포함)"
        [(ngModel)]="query"
        #trigger="matAutocompleteTrigger"
        (keypress)="preventEnter($event)"
        (keyup.enter)="searchExecute(); trigger.closePanel()"
        (keyup)="findCompletion(input.value, $event); preventSearch = false"
        #input
      >
      </textarea>
      <mwl-text-input-highlight
        [tags]="queryElements"
        [textInputElement]="input"
      >
      </mwl-text-input-highlight>
    </div>
    <!--서치다이얼로그 / 컴팩트 / 서치-->

    <mat-autocomplete
      panelWidth="440px"
      class="autocomplete compact"
      #auto="matAutocomplete"
      (opened)="autoOpen = true; updateFocus()"
      (closed)="autoOpen = false; updateFocus()"
    >
      <span class="label" *ngIf="functions?.length || find"
        ><span class="icon3 small n-94 h-3 mr-2"></span>빠른 검색 명령어</span
      >
      <mat-option [disabled]="true" class="points-none" *ngIf="find">
        <span class="fn-desc" [innerHTML]="find.description"></span>
      </mat-option>
      <div class="quick-options" *ngIf="functions.length">
        <mat-option
          class="quick-button"
          [value]="functionValue(el)"
          *ngFor="let el of functions"
          (onSelectionChange)="selectionChange(el, trigger, input.value)"
        >
          {{ el.name }}
        </mat-option>
      </div>
      <span
        class="label"
        *ngIf="searchService.historyService.keywordHistory?.length"
        >최근 검색어</span
      >
      <mat-option
        [value]="el"
        *ngFor="let el of searchService.historyService.keywordHistory"
        (onSelectionChange)="searchExecute(el)"
      >
        <span class="icon3 n-14 h-5 mr-12"></span>
        <span class="keyword" [innerHTML]="tagged(el)"></span>
        <span
          trackId="1c23"
          (click)="
            searchService.historyService.removeHistory(el);
            $event.stopPropagation()
          "
          class="icon delete-icon"
        ></span>
      </mat-option>
      <span class="label" *ngIf="completions?.length">{{
        completionName
      }}</span>
      <mat-option
        [value]="getCompletionValue(el)"
        *ngFor="let el of completions; let i = index"
        (onSelectionChange)="searchExecute(getCompletionValue(el))"
      >
        <span>
          <span class="icon3 n-83 mr-12"></span>
          <span class="keyword" [innerHTML]="tagged(el.view || el)"></span>
        </span>
      </mat-option>
    </mat-autocomplete>
  </div>
  <span
    class="icon3 n-10 h-3 delete"
    *ngIf="query"
    trackId="8a46"
    (click)="query = ''; ifFocusSearch(trigger)"
  ></span>
  <button class="search" trackId="b269" (click)="ifFocusSearch(trigger)">
    <span class="icon2 n-83 mt--2 ml--2"></span>
  </button>
</div>
