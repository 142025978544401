<mat-menu
  #popover="matMenu"
  [xPosition]="xPosition"
  [yPosition]="yPosition"
  class="mat-popover"
>
  <ng-template matMenuContent>
    <div style="position: relative">
      <span class="edge"></span>
      <span class="edge-after"></span>
      <div
        trackId="930f"  (click)="$event.stopPropagation(); $event.preventDefault()"
        class="cursor-default"
      >
        <ng-container *ngTemplateOutlet="popoverContent"></ng-container>
      </div>
    </div>
  </ng-template>
</mat-menu>
<div trackId="76f0"  [matMenuTriggerFor]="popover">
  <ng-content></ng-content>
</div>
