import { Component, Input } from '@angular/core';
import { Reference } from '../../../../ngxSpring/api.model';

@Component({
  selector: 'lb-reference-summary',
  templateUrl: './reference-summary.component.html',
  styleUrls: ['./reference-summary.component.sass'],
})
export class ReferenceSummaryComponent {
  @Input()
  reference: Reference;
}
