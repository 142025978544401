import { Directive, OnDestroy, OnInit } from '@angular/core';

@Directive({
  selector: '[mountMonitor]',
})
export class MountMonitorDirective implements OnInit, OnDestroy {
  ngOnInit(): void {
    console.log('init');
  }

  ngOnDestroy(): void {
    console.log('destroy');
  }
}
