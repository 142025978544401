/* tslint:disable */
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ApiHttp} from '../api.http';

import {CompanyPlanUser} from '../api.model';

@Injectable()
export class CompanyPlanUserController {
  constructor(private http: ApiHttp) {
  }

  deleteCompanyPlanUser(id: number): Observable<void> {
    return this.http.delete<void>('/api/companyPlanUser', {id: id});
  }

  getCompanyPlanUser(id: number): Observable<CompanyPlanUser> {
    return this.http.get<CompanyPlanUser>('/api/companyPlanUser', {id: id});
  }

  getCompanyPlanUserList(contractId: number): Observable<CompanyPlanUser[]> {
    return this.http.get<CompanyPlanUser[]>('/api/companyPlanUser/list', {contractId: contractId});
  }

  saveCompanyPlanUser(companyPlanUser: CompanyPlanUser): Observable<CompanyPlanUser> {
    return this.http.post<CompanyPlanUser>('/api/companyPlanUser', companyPlanUser);
  }
}
