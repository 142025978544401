import { Component, HostListener, OnInit } from '@angular/core';

@Component({
  selector: 'lb-user-recommends',
  templateUrl: './user-recommends.component.html',
  styleUrls: ['./user-recommends.component.sass'],
})
export class UserRecommendsComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {
    this.customOptions = {
      loop: true,
      dots: false,
      autoplay: true,
      autoplaySpeed: 1500,
      autoplayTimeout: 3500,
      stagePadding: window.innerWidth > 959 ? 0 : 40,
      margin: 10,
      responsive: {
        0: {
          items: 1,
        },
        760: {
          items: 3,
        },
      },
    };
  }

  @HostListener('window:resize')
  onResize() {
    this.customOptions = {
      loop: true,
      dots: false,
      autoplay: true,
      autoplaySpeed: 1500,
      autoplayTimeout: 3500,
      stagePadding: window.innerWidth > 959 ? 0 : 40,
      margin: 10,
      responsive: {
        0: {
          items: 1,
        },
        760: {
          items: 3,
        },
      },
    };
  }

  customOptions;
}
