import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SearchService } from '../../search/search.service';
import { UserService } from '../../user/user.service';

@Component({
  selector: 'lb-case-search-opener',
  templateUrl: './case-search-opener.component.html',
  styleUrls: ['./case-search-opener.component.sass'],
})
export class CaseSearchOpenerComponent {
  query;

  constructor(
    public dialog: MatDialog,
    public search: SearchService,
    public user: UserService,
  ) {}
}
