<div class="title">{{ title }}</div>
<div class="labels" [style.height]="parsePx(max)">
  <div *ngFor="let label of labels" [style.bottom]="parsePx(label)">
    {{ label }}만
  </div>
</div>
<div fxLayout="row" fxLayoutAlign="start end" [style.height]="parsePx(max)">
  <div fxFlex="auto"></div>
  <div fxLayout="column" fxLayoutAlign="end center">
    <div class="label">약 {{ values[0] }}만건</div>
    <div class="bar" [style.height]="parsePx(values[0])"></div>
    <div class="mt-8">로**</div>
  </div>
  <div fxFlex="auto" class="mr-12"></div>
  <div fxLayout="column" fxLayoutAlign="end center">
    <div class="label active">약 {{ values[2] }}만건</div>
    <div class="bar active" [style.height]="parsePx(values[2])"></div>
    <div class="mt-8 font-weight-700">엘박스</div>
  </div>
  <div fxFlex="auto"></div>
</div>
