<div class="body">
  <div fxLayout.lt-md="column" fxLayout="row" class="row">
    <div class="name" *ngIf="!noName" style="padding-top: 2px" fxFlex="none">
      이용약관 동의<span class="required"></span>
    </div>
    <div fxFlex="auto">
      <div fxLayout="row">
        <div fxFlex="auto">
          <mat-checkbox class="font-weight-500" [(ngModel)]="agreeAll">
            전체 동의하기
          </mat-checkbox>
        </div>
        <span
          class="icon2 n-35 mt--2"
          [class.n-36]="!expand"
          trackId="a3e5"  (click)="expand = !expand"
        ></span>
      </div>
      <div class="subscription" *ngIf="!expand">
        엘박스 이용약관 동의(필수), 개인정보 수집 및 이용 동의(필수), 프로모션
        정보 수신 동의(선택), 14세 미만 가입 제한 확인(필수)
      </div>
      <div *ngIf="expand">
        <div fxLayout="row" class="rule">
          <div fxFlex="auto">
            •
            <a 
              trackId="9ce5"  
              lbNewTabLink="/rule" 
              class="color-primary"
            >
              엘박스 이용약관 동의
            </a>
          </div>
          <mat-checkbox [(ngModel)]="agrees[0]">
            동의함 <span class="color-gray">(필수)</span>
          </mat-checkbox>
        </div>
        <div fxLayout="row" class="rule">
          <div fxFlex="auto">
            <span trackId="a30e"  (click)="privateExpand = !privateExpand">
              • 개인정보 수집 및 이용 동의{{ jobName ? '(' + jobName + ')' : ''}}
              <span
                class="icon3 n-35"
                [class.n-36]="!privateExpand"
              ></span>
            </span>
          </div>
          <mat-checkbox [(ngModel)]="agrees[1]">
            동의함 <span class="color-gray">(필수)</span>
          </mat-checkbox>
        </div>
        <div *ngIf="privateExpand" class="personal-information-terms-and-conditions-wrapper">
          <div class="divider"></div>
          <lb-personal-information-collect-and-usage-info-table
            [contentType]="contentType"
          ></lb-personal-information-collect-and-usage-info-table>
        </div>
        <div fxLayout="row" class="rule">
          <div fxFlex="auto">
            <span trackId="0fdd"  (click)="promoExpand = !promoExpand">
              • 프로모션 정보 수신 동의
              <span class="icon3 n-35" [class.n-36]="!promoExpand"></span>
            </span>
          </div>
          <mat-checkbox [(ngModel)]="agrees[2]">
            동의함 <span class="color-gray">(선택)</span>
          </mat-checkbox>
        </div>
        <div class="promotion-sub-text">
          신규 서비스(법률 데이터분석 등) 출시 및 할인 혜택 정보
        </div>
        <div class="expand-desc" *ngIf="promoExpand">
          개인정보 보호를 위하여 아래의 개인정보 수집·이용에 대한 내용을 자세히
          읽어 보신 후 동의 여부를 결정하여 주시기 바랍니다.
          <h5 class="bold">개인정보 수집, 이용 내역</h5>
          <table *showItSizes="{ min: 960 }">
            <thead>
              <tr>
                <th>항목</th>
                <th>처리 목적</th>
                <th>보유 기간</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>이름, 전화번호, 생년월일, 이메일 주소, 패스워드</td>
                <td>신규 서비스 출시 등 마케팅 및 광고에의 활용</td>
                <td>
                  서비스 이용 만료시 또는 개인정보 수집·이용 목적 달성시까지
                  또는 동의 철회시까지
                </td>
              </tr>
            </tbody>
          </table>
          <table *showItSizes="{ max: 959 }">
            <tbody>
              <tr>
                <th>항목</th>
                <td>이름, 전화번호, 생년월일, 이메일 주소, 패스워드</td>
              </tr>
              <tr>
                <th>처리 목적</th>
                <td>신규 서비스 출시 등 마케팅 및 광고에의 활용</td>
              </tr>
              <tr>
                <th>보유 기간</th>
                <td>
                  서비스 이용 만료시 또는 개인정보 수집·이용 목적 달성시까지
                  또는 동의 철회시까지
                </td>
              </tr>
            </tbody>
          </table>
          회사로부터 신규 기능 및 서비스, 각종 할인 및 마케팅 관련 정보를 받기
          원하신다면 위 항목에 동의하여 주시기 바랍니다. 귀하는 위의 개인정보
          제공에 동의하지 않을 권리가 있으나, 동의하지 않을 경우 일부 서비스
          제공에 제한이 있을 수 있습니다.
        </div>

        <div fxLayout="row" class="rule">
          <div fxFlex="auto">• 14세 미만 가입 제한 확인</div>
          <mat-checkbox [(ngModel)]="agrees[3]">
            확인함 <span class="color-gray">(필수)</span>
          </mat-checkbox>
        </div>
        <div class="mt-8 ml-8 font-12">
          엘박스는 만 14세부터 이용 가능합니다.
        </div>
      </div>
    </div>
  </div>
</div>
