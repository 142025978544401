<div class="verify-container">
  <div class="verify-header">
    <div class="verify-title">
      <h4>변호사 인증</h4>
    </div>
  </div>

  <div class="verify-body">
    <div class="verify-description">
      변호사 전용 서비스 이용을 위해 변호사 인증이 필요합니다. 대한변호사협회 신분증 정보를 입력해주세요.
    </div>

    <div class="verify-label-input-wrapper">
      <label for="registerNo" class="verify-label">대한변협 신분증 등록번호</label>
      <input id="registerNo" maxlength="100" placeholder="등록번호를 입력해주세요" class="verify-input"
        [class]="isVerifyingFailed ? 'failed' : ''" [(ngModel)]="registerNo" (ngModelChange)="handleChangeRegisterNo()"
        (keypress)="filterNumbers($event)" autocomplete="off" />
    </div>

    <div class="verify-label-input-wrapper">
      <label for="licenseNo" class="verify-label">대한변협 신분증 발급번호</label>
      <input id="licenseNo" placeholder="발급번호를 입력해주세요" class="verify-input" [class]="isVerifyingFailed ? 'failed' : ''"
        [(ngModel)]="licenseNo" (ngModelChange)="handleChangeLicenseNo()" (keypress)="filterNumbers($event)"
        autocomplete="off" />
      <div class="verify-failed-message" *ngIf="isVerifyingFailed">
        입력한 정보와 일치하는 정보가 없습니다.<br />
        아래 링크의 안내를 확인하신 후 재시도 해주세요.
      </div>
    </div>

    <div class="verify-wrapper-question">
      <button class="verify-button-question" (click)="handleClickFAQButton()">
        <img src="/assets/icon-question.png" />
        <span>변호사 인증에 어려움이 있으신가요?</span>
      </button>
    </div>

    <div class="verify-container-terms">
      <div class="verify-wrapper-terms-checkbox">
        <div class="verify-checkbox-wrapper">
          <mat-checkbox class="verify-custom-mat-checkbox" (change)="handleClickTermsCheckbox()"
            [checked]="isTermAgreed">
            개인정보 수집 및 이용에 동의합니다.
          </mat-checkbox>
        </div>
        <button class="verify-accordian-toggle-button" [class]="showsTermsDetail ? '' : 'rotate-180'"
          (click)="handleClickIconCaretUp()">
          <img src="/assets/icon-caret-up.png" />
        </button>
      </div>

      <div class="verify-wrapper-terms-detail" [class]="showsTermsDetail ? 'shown' : ''">
        <span>
          귀하는 위 개인정보 수집∙이용 등에 대한 동의를 거부하실 수 있으며, 동의하지 않을 경우
          서비스 이용에 제한이 있을 수 있습니다.
        </span>

        <h5>개인정보 수집 및 이용 내역</h5>
        <table class="verify-table-terms">
          <thead>
            <th>항목</th>
            <th>처리 목적</th>
            <th>보유 기간</th>
          </thead>
          <tbody>
            <td>대한변협 신분증 등록번호 및 발급번호</td>
            <td>변호사 전용 서비스 제공</td>
            <td class="verify-terms-period">
              서비스 이용 만료시 또는 개인정보 수집∙이용 목적 달성시까지
            </td>
          </tbody>
        </table>
      </div>
    </div>
  </div>

  <div class="verify-footer">
    <button class="verify-button-confirm" [class]="isConfirmButtonActive ? '' : 'disabled'"
      (click)="handleClickConfirmButton()" [disabled]="!isConfirmButtonActive">확인</button>
    <button class="verify-button-cancel" (click)="handleClickCancelButton()">다음에 하기</button>
  </div>
</div>