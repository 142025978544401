<div class="form-table">
  <div class="title text-center">
    <span class="icon2 n-34 invert" trackId="ee1d" routerLink="../"></span>
    회원정보<span *ngIf="registerService.isTransfer"> 추가</span> 입력 (2/2)
  </div>
  <div class="body">
    <div fxLayout.lt-md="column" fxLayout="row" class="row" *ngIf="!cert">
      <div class="name">본인 확인<span class="required"></span></div>
      <div fxFlex="auto">
        <button
          mat-stroked-button
          color="primary"
          trackId="a7d8"
          (click)="doAuth()"
          class="single"
        >
          휴대폰 인증하기
        </button>
        <div class="subscription">엘박스는 만 14세부터 이용 가능합니다.</div>
      </div>
    </div>
    <div fxLayout.lt-md="column" fxLayout="row" class="row" *ngIf="cert">
      <div class="name">이름</div>
      <div fxFlex="auto" class="name">
        {{ cert.name }}
      </div>
    </div>
    <div fxLayout.lt-md="column" fxLayout="row" class="row" *ngIf="cert">
      <div class="name">휴대폰 번호</div>
      <div fxFlex="auto" class="name">
        {{ cert.phone }}
      </div>
    </div>
    <form
      *ngIf="!registerService.isNormalTransfer"
      [formGroup]="registerGroup"
      trackEnter="회원가입 (엔터로 가입)"
    >
      <div fxLayout.lt-md="column" fxLayout="row" class="row">
        <div class="name">이메일 주소<span class="required"></span></div>
        <div fxFlex="auto">
          <input
            placeholder="이메일"
            formControlName="email"
            (focus)="registerGroup.stopCheck('email')"
            (blur)="checkEmail()"
            (change)="handleChangeEmailInput()"
          />
          <lb-err-for [group]="registerGroup" errFor="email"></lb-err-for>
          <div class="subscription">
            원활한 서비스 제공을 위해 현재 사용 중인 이메일을 입력해주세요.
          </div>
        </div>
      </div>
      <div
        fxLayout.lt-md="column"
        fxLayout="row"
        class="row"
        *ngIf="!snsRegister"
      >
        <div class="name">비밀번호<span class="required"></span></div>
        <div fxFlex="auto">
          <input
            placeholder="비밀번호 (8~12자 영문, 숫자, 특수기호)"
            formControlName="password"
            type="password"
            (focus)="registerGroup.stopCheck('password')"
            (blur)="registerGroup.startCheck('password')"
          />
          <lb-err-for [group]="registerGroup" errFor="password"></lb-err-for>
        </div>
      </div>
      <div
        fxLayout.lt-md="column"
        fxLayout="row"
        class="row"
        *ngIf="!snsRegister"
      >
        <div class="name">비밀번호 확인<span class="required"></span></div>
        <div fxFlex="auto">
          <input
            placeholder="비밀번호 재입력"
            formControlName="passwordConfirm"
            type="password"
            (focus)="registerGroup.stopCheck('passwordConfirm')"
            (blur)="registerGroup.startCheck('passwordConfirm')"
          />
          <lb-err-for
            [group]="registerGroup"
            errFor="passwordConfirm"
          ></lb-err-for>
        </div>
      </div>
    </form>
  </div>
  <div class="divider"></div>
  <div class="body">
    <div fxLayout.lt-md="column" fxLayout="row" class="row">
      <div class="name" fxFlex="none">
        회원유형<span class="required"></span>
      </div>
      <lb-lbox-user-type
        fxFlex="auto"
        userType="etc"
        #userType
      ></lb-lbox-user-type>
    </div>
  </div>
  <div *ngIf="!registerService.isTransfer">
    <div class="divider"></div>
    <lb-agree-rules></lb-agree-rules>
  </div>
</div>
<div class="bottom-buttons">
  <button
    mat-flat-button
    color="primary"
    [trackData]="{job:userType?.job?.[0]}"
    [class.disabled]="!registerAble"
    trackId="08df"
    (click)="register()"
  >
    <span *ngIf="!registerService.isTransfer"> 회원가입 </span>
    <span *ngIf="registerService.isTransfer"> 완료 </span>
  </button>
</div>
