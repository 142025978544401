import { NgModule } from '@angular/core';
import {
  TrackDirective,
  TrackEnterDirective,
  TrackPrefixDirective,
  TrackProto,
  TrackValueDirective,
} from './track.directive';
import { CommonModule } from '@angular/common';

@NgModule({
  declarations: [
    TrackEnterDirective,
    TrackDirective,
    TrackValueDirective,
    TrackProto,
    TrackPrefixDirective,
  ],
  exports: [
    TrackDirective,
    TrackValueDirective,
    TrackPrefixDirective,
    TrackEnterDirective,
  ],
  imports: [CommonModule],
})
export class TrackModule {}
