import { Component, OnInit } from '@angular/core';
import { DialogOrBottomSheetProto } from '../alert/alert.service';

@Component({
  selector: 'lb-warning-law-school-authorization',
  templateUrl: './warning-law-school-authorization.component.html',
  styleUrls: ['./warning-law-school-authorization.component.sass'],
})
export class WarningLawSchoolAuthorizationComponent extends DialogOrBottomSheetProto<{
  fileRef: any;
  draggedFileList: FileList | null;
}> {
  handleClickConfirmButton() {
    this.close(true);
    if (!this.data.draggedFileList) {
      this.data.fileRef.nativeElement.click();
    }
  }
}
