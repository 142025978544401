/* tslint:disable */
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ApiHttp} from '../api.http';

import {UserToken} from '../api.model';

@Injectable()
export class UserTokenController {
  constructor(private http: ApiHttp) {
  }

  changeName(id: number, name: string): Observable<UserToken> {
    return this.http.post<UserToken>('/api/userToken/changeName', null, {id: id, name: name});
  }

  deleteToken(id: number): Observable<number> {
    return this.http.delete<number>('/api/userToken', {id: id});
  }

  getTokens(deviceId: string): Observable<UserToken[]> {
    return this.http.get<UserToken[]>('/api/userToken', {deviceId: deviceId});
  }

  resolveError(deviceId: string, resolvedDeviceId: string): Observable<void> {
    return this.http.post<void>('/api/userToken/resolveError', null, {deviceId: deviceId, resolvedDeviceId: resolvedDeviceId});
  }

  tokenDeleteAdmin(id: number): Observable<number> {
    return this.http.delete<number>('/api/userToken/admin', {id: id});
  }

  tokenListAdmin(userId: number): Observable<UserToken[]> {
    return this.http.get<UserToken[]>('/api/userToken/admin', {userId: userId});
  }

  validateDevice(deviceId: string): Observable<void> {
    return this.http.post<void>('/api/userToken', null, {deviceId: removePercentEncoding(deviceId)});
  }
}

function removePercentEncoding(str: string): string {
  if (!str) return str
  if (str.startsWith('%22') && str.endsWith('%22')) {
    return str.slice(3, -3);
  } else {
    return str;
  }
}
