<div fxLayout class="title">
  <div fxLayout class="title-left">
    <div>
      <div trackId="11e4" (click)="move()">
        {{ title || id }}
      </div>
      <div
        class="font-14 mt-12"
        *ngIf="folder?.name"
        trackId="41c5"
        [routerLink]="'/bookmark/' + folder?.id"
        style="opacity: 0.6"
      >
        <span
          class="icon3 small n-80 gray-tone mr-6"
          [class.n-95]="folderService.isSharedFolder(folder)"
        ></span>
        {{ folder?.name }}
      </div>
    </div>
  </div>
  <span class="icon icon-delete mt-4" trackId="82e5" (click)="close()"></span>
</div>
<div class="tabs" fxLayout="row" *ngIf="loaded">
  <div
    [class.active]="caseSelectService.tab == caseSelectService.tabIndex.HIGHLIGHT"
    trackId="23c3"
    (click)="caseSelectService.tab = 1"
  >
    하이라이트<span style="color: #aaaaaa" *ngIf="highlights">
      ({{ highlights?.length || 0 }})</span
    >
  </div>
  <div
    *ngIf="!userService.isRestrictedUser"
    [class.active]="caseSelectService.tab == caseSelectService.tabIndex.MEMO"
    trackId="3faf"
    (click)="caseSelectService.tab = 0"
  >
    메모<span style="color: #aaaaaa" *ngIf="memos">
      ({{ memos?.length || 0 }})</span
    >
  </div>
</div>
<lb-day-log-list
  class="highlight-list"
  *ngIf="caseSelectService.tab == 0"
  [logs]="memos"
  [itemTemplate]="itemTempl"
>
  <ng-template let-record="record" #itemTempl>
    <span
      class="color-badge"
      [ngClass]="record.className"
      *ngIf="record.className"
    ></span>
    <div class="bold mb-12">
      {{ record.memo }}
    </div>
    <div>
      {{ record.text | removeReserveTags }}
    </div>
    <div fxLayout="row" class="mt-12">
      <span
        class="color-primary"
        trackId="2ead"
        (click)="scrollTo(record)"
        *ngIf="record.indexKey"
        >바로가기</span
      >
      <span fxFlex="auto"></span>
      <ng-container *ngIf="record.userId == userService.user?.id">
        <span trackId="ce16" (click)="modifyMemo(record)">수정</span>
        <span class="div"></span>
        <span
          class="color-warn"
          trackId="c3bc"
          (click)="deleteHighlight(record, 'memo')"
          >삭제</span
        >
      </ng-container>
      <ng-container *ngIf="record.userId != userService.user?.id">
        <span class="color-gray">{{ record.userName }}님 작성</span>
      </ng-container>
    </div>
  </ng-template>
</lb-day-log-list>

<lb-day-log-list
  class="highlight-list"
  *ngIf="caseSelectService.tab == 1"
  [logs]="highlights"
  [itemTemplate]="highTempl"
>
  <ng-template let-record="record" #highTempl>
    <span
      class="color-badge"
      [ngClass]="record.className"
      *ngIf="record.className"
    ></span>
    {{ record.text | removeReserveTags }}
    <div fxLayout="row" class="mt-12">
      <span class="color-primary" trackId="d541" (click)="scrollTo(record)"
        >바로가기</span
      >
      <span fxFlex="auto"></span>
      <span
        *ngIf="record.userId == userService.user?.id"
        class="color-warn"
        trackId="bf66"
        (click)="deleteHighlight(record, 'highlight')"
        >삭제</span
      >
      <ng-container *ngIf="record.userId != userService.user?.id">
        <span class="color-gray">{{ record.userName }}님 표시</span>
      </ng-container>
    </div>
  </ng-template>
</lb-day-log-list>
