import { Component, HostBinding, HostListener, Input } from '@angular/core';
import { DragScrollDirective } from '../drag-scroll.directive';

@Component({
  selector: 'lb-scroll-handle',
  templateUrl: './scroll-handle.component.html',
  styleUrls: ['./scroll-handle.component.sass'],
})
export class ScrollHandleComponent {
  @Input()
  type;

  @Input()
  clazz = 'icon2 n-33';

  @Input()
  tickSelector;

  constructor(private elf: DragScrollDirective) {}

  @Input()
  offset = 0;

  @HostListener('click', ['$event'])
  click() {
    if (this.tickSelector != null) {
      const offsets = [];
      document.querySelectorAll(this.tickSelector).forEach((it) => {
        offsets.push(it.offsetLeft);
      });
      let left;
      if (this.type == 'left') {
        left =
          offsets[
            offsets.indexOf(
              offsets.find(
                (it, index) =>
                  this.elf.el.scrollLeft - this.offset >= it &&
                  this.elf.el.scrollLeft - this.offset < offsets[index + 1],
              ),
            ) - 1
          ];
      } else {
        left =
          offsets[
            offsets.indexOf(
              offsets.find(
                (it, index) =>
                  this.elf.el.scrollLeft - this.offset >= it &&
                  this.elf.el.scrollLeft - this.offset < offsets[index + 1],
              ),
            ) + 1
          ];
      }
      this.elf.el.scrollTo({ left: left + this.offset, behavior: 'smooth' });
      return;
    }
    if (this.type == 'left') {
      this.elf.el.scrollTo({ left: 0, behavior: 'smooth' });
    } else {
      this.elf.el.scrollTo({
        left: this.elf.el.scrollWidth - this.elf.el.clientWidth,
        behavior: 'smooth',
      });
    }
  }

  @HostBinding('style.opacity')
  get display() {
    if (this.type == 'left') return this.elf.el.scrollLeft > 2 ? 1 : 0;
    return this.elf.el.scrollLeft <
      this.elf.el.scrollWidth - this.elf.el.clientWidth - 2
      ? 1
      : 0;
  }

  @HostBinding('style.z-index')
  get zIndex() {
    if (this.type == 'left') return this.elf.el.scrollLeft > 2 ? 1 : -1;
    return this.elf.el.scrollLeft <
      this.elf.el.scrollWidth - this.elf.el.clientWidth - 2
      ? 1
      : -1;
  }
}
