import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'lb-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.sass'],
})
export class CheckboxComponent {
  @Input()
  value;

  @Output()
  valueChange = new EventEmitter();
}
