import {
  Directive,
  EventEmitter,
  HostListener,
  Input,
  Output,
} from '@angular/core';
import { CaseDetailService } from '../core/case-detail.service';
import { ReadService } from '../core/read.service';

@Directive({
  selector: '[caseLink]',
})
export class CaseLinkDirective {
  @Input()
  caseLink;

  @Input()
  query;

  @Input()
  newTabOnly;

  @Input()
  caseData;

  @Output()
  moved = new EventEmitter();

  constructor(private cd: CaseDetailService, private read: ReadService) {}

  @HostListener('click', ['$event'])
  onClick() {
    this.read.read(this.caseLink);
    this.cd
      .moveOrRequest(this.caseLink, this.query, this.newTabOnly, this.caseData)
      .then((v) => {
        if (!v) this.moved.emit();
      });
  }
}
