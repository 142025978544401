/* tslint:disable */
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiHttp } from '../api.http';

import {
  AlarmMethodType,
  AlarmType,
  LboxUser,
  LqaData,
  Page,
  VerifyLawyerRequest,
} from '../api.model';

@Injectable()
export class LboxUserController {
  constructor(private http: ApiHttp) {}

  checkLicense(
    name: string,
    birthDay: string,
    cardnum: string,
    inputnum: string,
  ): Observable<boolean> {
    return this.http.post<boolean>('/api/lboxUser/checkLicense', null, {
      name: name,
      birthDay: birthDay,
      cardnum: cardnum,
      inputnum: inputnum,
    });
  }

  getLboxUser(): Observable<LboxUser> {
    return this.http.get<LboxUser>('/api/lboxUser');
  }

  getLboxUserByUserId(userId: number): Observable<LboxUser> {
    return this.http.get<LboxUser>('/api/lboxUser/byUserId', {
      userId: userId,
    });
  }

  getLboxUserList(
    page?: number,
    size?: number,
    query?: string,
  ): Observable<Page<LboxUser>> {
    return this.http.get<Page<LboxUser>>('/api/lboxUser/list', {
      page: page,
      size: size,
      query: query,
    });
  }

  lqaOpen(): Observable<LboxUser> {
    return this.http.post<LboxUser>('/api/lboxUser/update/lqaOpen', null);
  }

  setDistricts(districts: any[]): Observable<LboxUser> {
    return this.http.post<LboxUser>('/api/lboxUser/districts', districts);
  }

  toggleLqaEnable(): Observable<LboxUser> {
    return this.http.post<LboxUser>('/api/lboxUser/toggleLqaEnable', null);
  }

  update(job?: any[], pointAutoUse?: boolean): Observable<LboxUser> {
    return this.http.post<LboxUser>('/api/lboxUser/update', job, {
      pointAutoUse: pointAutoUse,
    });
  }

  updateAlarm(
    type: AlarmType,
    method: AlarmMethodType,
    value: boolean,
  ): Observable<LboxUser> {
    return this.http.post<LboxUser>('/api/lboxUser/update/alarm', null, {
      type: type,
      method: method,
      value: value,
    });
  }

  updateForLqa(lqaData: LqaData): Observable<LboxUser> {
    return this.http.post<LboxUser>('/api/lboxUser/updateForLqa', lqaData);
  }

  updateForLqaDistricts(lqaDistricts: any[]): Observable<LboxUser> {
    return this.http.post<LboxUser>(
      '/api/lboxUser/updateLqaDistricts',
      lqaDistricts,
    );
  }

  updateForSubstitute(lboxUser: LboxUser): Observable<LboxUser> {
    return this.http.post<LboxUser>(
      '/api/lboxUser/updateForSubstitute',
      lboxUser,
    );
  }

  updateLicense(cardnum: string, inputnum: string): Observable<boolean> {
    return this.http.post<boolean>('/api/lboxUser/updateLicense', null, {
      cardnum: cardnum,
      inputnum: inputnum,
    });
  }

  updateMemoByUserId(userId: number, memo: string): Observable<LboxUser> {
    return this.http.post<LboxUser>('/api/lboxUser/updateMemo', null, {
      userId: userId,
      memo: memo,
    });
  }

  getLegalAnalyticsCanUse(): Observable<{ canUse: boolean }> {
    return this.http.get('/api/legal-analytics/can-use');
  }

  /**
   * 변호사 인증
   * - 성공하는 경우에만 200 응답을 반환
   * - 실패할 경우 에러 처리
   */
  verifyLawyer(req: VerifyLawyerRequest) {
    return this.http.post<{ id: string; userId: string }>(
      '/api/lboxUser/verifyLawyer',
      req,
    );
  }

  /** 로스쿨 학생 인증 모달 표시 여부 확인 */
  shouldDisplayLawSchoolAuthorizationModal() {
    return this.http.get<{ shouldDisplay: boolean }>(
      '/api/lawSchoolAuthorization/should-display-modal',
    );
  }
}
