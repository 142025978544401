import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
  PLATFORM_ID,
} from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';
import { SuggestionService } from './suggestion.service';
import { isPlatformServer } from '@angular/common';
import { LocalStorageService } from '../../core/local-storage.service';

@Component({
  selector: 'lb-suggestion',
  templateUrl: './suggestion.component.html',
  styleUrls: ['./suggestion.component.sass'],
  animations: [
    trigger('enterAnimation', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('.0s .2s', style({ opacity: 1 })),
      ]),
      transition(':leave', [
        style({ opacity: 1 }),
        animate('.0s .2s', style({ opacity: 0 })),
      ]),
    ]),
  ],
})
export class SuggestionComponent implements OnInit {
  popoverClosed = true;

  @Input()
  gaName;

  @Output()
  ok = new EventEmitter();

  @Input()
  popoverKey;

  @Input()
  timeout = 2000;

  @Input()
  message;

  @Input()
  okButton;

  @Input()
  style;

  index: number;

  constructor(
    public suggestion: SuggestionService,
    @Inject(PLATFORM_ID) protected platform,
    private localStorage: LocalStorageService,
  ) {}

  ngOnInit(): void {
    if (isPlatformServer(this.platform)) {
      return;
    }
    setTimeout(() => {
      this.popoverClosed = this.localStorage.getItem(this.popoverKey) == 'true';
      if (!this.popoverClosed) this.index = this.suggestion.getIndex();
    }, this.timeout);
  }

  closePopover() {
    this.localStorage.setItem(this.popoverKey, 'true');
    this.suggestion.nowIndex++;
    this.popoverClosed = true;
  }

  clickOk() {
    this.ok.emit();
    this.closePopover();
  }
}
