export const tracks = {
  '1064': { name: 'click_substitute_request', data: null },
  '1864': { name: 'click_opencase_popup', data: null },
  '2618': { name: 'click_membership_none', data: null },
  '3891': { name: 'click_login_forgotpassword', data: null },
  '4357': { name: 'click_statute_list', data: null },
  '4647': { name: 'click_gnb_mypage', data: {} },
  '4926': { name: 'click_gnb_helpcenter', data: null },
  '5245': { name: 'click_case_statute', data: null },
  '5566': { name: 'click_reference_download', data: null },
  '5695': { name: 'click_gnb_library_librarycase', data: null },
  '5921': { name: 'click_search_statute_periodfilter', data: null },
  '6293': { name: 'click_case_drag_similarsearch', data: null },
  '7333': { name: 'click_case_judge', data: { type: 'judge' } },
  '/user/invitee34d': { name: 'click_mypage_invite_copycode', data: null },
  '/user/substitute/historye34d': {
    name: 'click_mypage_substitute_tab',
    data: null,
  },
  '0439': { name: 'click_search_nav', data: null },
  '7b4c': { name: 'click_search_nav', data: null },
  '0674': { name: 'click_judge_sort', data: null },
  '07ec': { name: 'click_main_searchnoti', data: null },
  main07ec: { name: 'click_main_searchnoti', data: null },
  '09f1': { name: 'click_login', data: { type: 'EMAIL' } },
  '2f47': { name: 'click_login', data: { type: 'KAKAO' } },
  '69b2': { name: 'click_login', data: { type: 'NAVER' } },
  e4b0: { name: 'click_login', data: { type: 'GOOGLE' } },
  '0e92': { name: 'click_schedule_register_complete', data: null },
  '0f99': { name: 'click_gnb_schedule', data: null },
  ae8c: { name: 'click_substitute_request_register', data: null },
  '1e51': { name: 'click_substitute_register', data: null },
  '26b4': { name: 'click_gnb_mypage_list', data: null },
  '30bb': { name: 'click_gnb_library', data: null },
  '373e': { name: 'click_case_btn_save', data: null },
  '3b83': { name: 'click_judge_quote_more', data: null },
  '3f5c': { name: 'click_mypage_substitute_region', data: null },
  '478b': { name: 'click_schedule_register', data: null },
  '49bf': { name: 'click_membership_company_inquryregister', data: null },
  '4a19': { name: 'click_library_unshare', data: null },
  '54d9': { name: 'click_judge_news_more', data: null },
  '56bf': { name: 'click_substitute_sort', data: { type: '기한 임박순' } },
  '1cc9': { name: 'click_substitute_sort', data: { type: '등록일순' } },
  statute77bb: {
    name: 'click_search_statute_periodfilter',
    data: { type: 'statute' },
  },
  statute90e4: { name: 'click_search_statute_periodfilter', data: null },
  statute029c: { name: 'click_search_statute_periodfilter', data: null },
  statute994b: { name: 'click_search_statute_periodfilter', data: null },
  statute5921: { name: 'click_search_statute_periodfilter', data: null },
  '5ae1': { name: 'click_search_statute_sortfilter', data: null },
  '6b64': { name: 'click_gnb_logo', data: null },
  '6e30': { name: 'click_case_signup', data: { type: '판례, 다운로드/인쇄' } },
  '6fad': { name: 'click_gnb_membership', data: null },
  bbc6: { name: 'click_case_judge', data: { type: 'lawfirm' } },
  '77bb': { name: 'click_search_statute_rangefilter', data: null },
  검색범위988d: { name: 'click_search_statute_rangefilter', data: null },
  '79bc': { name: 'click_search_case_judge', data: null },
  '97dd': { name: 'click_gnb_alarm_tab', data: null },
  '988d': { name: 'click_search_case_courtfilter', data: null },
  법원988d: { name: 'click_search_case_courtfilter', data: null },
  '98df': { name: 'click_judge_courtfilter', data: { type: '지방법원' } },
  b65e: { name: 'click_judge_courtfilter', data: { type: '대법원' } },
  f5b7: { name: 'click_judge_courtfilter', data: { type: '고등법원' } },
  a1ff: { name: 'click_gnb_signup', data: null },
  a2ab: { name: 'click_search_case_sort', data: null },
  a2ea: { name: 'click_judge_casefilter', data: null },
  a32f: { name: 'click_case_btn_setting', data: null },
  a4e2: { name: 'click_login_findaccount', data: null },
  a8c4: { name: 'click_search_case_wholestatute', data: null },
  ab7a: { name: 'click_gnb_login', data: null },
  b342: { name: 'click_signup_complete', data: null },
  '667e': { name: 'click_signup_complete', data: null },
  '08df': { name: 'click_signup_complete', data: null },
  b461: { name: 'click_login_signup', data: null },
  b76e: { name: 'click_mypage_searchnoti_register', data: null },
  a70b: { name: 'click_mypage_searchnoti_register', data: null },
  bf32: { name: 'click_gnb_substitute', data: null },
  caseview_map: { name: 'click_search_case_mapview', data: null },
  cbf9: { name: 'click_schedule_tab', data: null },
  '19ac': { name: 'click_schedule_tab', data: { type: '사건 목록' } },
  '8d92': { name: 'click_schedule_tab', data: { type: '사건 캘린더' } },
  d066: { name: 'click_schedule_google', data: null },
  d1dd: { name: 'click_substitute_tab', data: null },
  d6e6: { name: 'click_membership_company', data: null },
  d992: { name: 'click_case_btn_highlightshare', data: null },
  deaf: { name: 'click_library_tab', data: null },
  f21f: { name: 'click_library_tab', data: null },
  dfab: { name: 'click_judge_judgefilter', data: null },
  e092: { name: 'click_library_share', data: null },
  e34d: { name: 'click_mypage_requestcase_tab', data: null },
  e369: { name: 'click_search_case_searchnoti', data: { type: '목록보기' } },
  fdd4: {
    name: 'click_search_case_searchnoti',
    data: { type: '현재조건 등록하기' },
  },
  e948: { name: 'click_gnb_alarm', data: null },
  '76f0': { name: 'click_gnb_alarm', data: null },
  f061: { name: 'click_gnb_caseregister', data: null },
  f14a: { name: 'click_mypage_substitute_board', data: null },
  f51c: { name: 'click_judge_news_new', data: null },
  fe3c: { name: 'click_signup_login', data: null },
  judge1591: { name: 'click_judge_page', data: null },
  judgerange: { name: 'click_judge_periodfilter', data: null },
  mainb4ed: { name: 'click_judge_search', data: null },
  '55f8': { name: 'click_judge_search', data: null },
  dd2d: { name: 'click_main_membership', data: null },
  사건유형988d: { name: 'click_search_case_casefilter', data: null },
  재판유형988d: { name: 'click_search_case_trialfilter', data: null },
  case77bb: { name: 'click_search_case_periodfilter', data: null },
  case90e4: { name: 'click_search_case_periodfilter', data: null },
  case029c: { name: 'click_search_case_periodfilter', data: null },
  case994b: { name: 'click_search_case_periodfilter', data: null },
  case5921: { name: 'click_search_case_periodfilter', data: null },
  '749d': { name: 'click_search_statute', data: null },
  suggestde18: { name: 'click_search_case_statutename', data: null },
  autopoint: { name: 'click_mypage_payment_autopoint', data: null },
  '7f98': { name: 'click_opencase_popup', data: null },
  '63cb': { name: 'click_opencase_popup', data: null },
  c4aa: { name: 'click_opencase_tree', data: null },
  '/user/subscribee34d': { name: 'click_mypage_searchnoti_modify', data: null },
  '086f': { name: 'click_mypage_searchnoti_modify', data: { type: '수정' } },
  '0360': { name: 'click_mypage_searchnoti_modify', data: { type: '삭제' } },
  '0ef5': { name: 'click_library_search', data: null },
  c84f: { name: 'click_case_btn_index', data: null },
  truef4a3: {
    name: 'click_case_freeMembership',
    data: { type: '판례, 다운로드/인쇄' },
  },
  falsef4a3: { name: 'click_case_membership', data: null },
  f4a3: {
    name: 'click_case_membership',
    data: { type: '판례, 다운로드/인쇄' },
  },
  '8d82': { name: 'click_signup', data: null },
  c58b: { name: 'click_membership_invite', data: null },
  '295c': { name: 'click_membership_complete', data: null },
  '7a14': { name: 'click_substitute_request_register', data: null },
  '3c41': { name: 'click_substitute_substitute', data: null },
  '9a65': { name: 'click_question_register', data: null },
  de18: { name: 'click_search_statute_name', data: null },
  d07f: { name: 'click_search_wholestatutename', data: null },
  '1d18': { name: 'click_statute_history', data: null },
  '25db': { name: 'click_statute_history', data: null },
  fd36: { name: 'click_statute_reference', data: null },
  '06d4': { name: 'click_schedule_calendar_detail', data: null },
  법령종류988d: { name: 'click_search_statute_statutefilter', data: null },
  c4fb: { name: 'click_schedule_list_detail', data: null },
  maine915: { name: 'click_search', data: null },
  e915: { name: 'click_search', data: null },
  mainaacc: { name: 'click_fastsearch', data: null },
  aacc: { name: 'click_fastsearch', data: null },
  b0f8: { name: 'click_search_reference', data: null },
  출처988d: { name: 'click_search_reference_sourcefilter', data: null },
  '3e23': { name: 'click_reference_share', data: null },
  a300: { name: 'click_search_commentary_wholeList', data: null },
  e386: { name: 'click_search_commentary', data: null },
  '주석서 종류988d': {
    name: 'click_search_commentary_commentaryfilter',
    data: null,
  },
  '00e1': { name: 'click_commentary_list', data: null },
  '87d7': { name: 'click_nudgingMembership', data: null },
  '828c': { name: 'click_nudgingMembership_cancel', data: null },
  b736: { name: 'click_gnb_library_librarycase', data: null },
  opencases: { name: 'click_gnb_library_opencase', data: null },
  '77b9': { name: 'click_library_case', data: null },
  '466c': { name: 'click_library_case', data: null },
  '2b96': { name: 'click_library_case', data: null },
  retitle: { name: 'click_library_opencase', data: null },
  '660d': { name: 'click_gnb_helpcenter', data: null },
  '216b': { name: 'click_case_btn_textrequest', data: null },
  '5cc7': { name: 'click_case_btn_unregisteredplz', data: null },

  // 요금제 화면 > '견적 보기' 버튼 클릭
  cme1: { name: 'click_membership_estimate', data: null },
  // 견적 화면 > '비즈니스 플랜 문의하기' 버튼 클릭
  cme2: { name: 'click_membership_estimate_inquiryregister', data: null },
  // 견적 화면 > '내 서비스 소개서' 버튼 클릭
  cme3: { name: 'click_membership_estimate_brochure', data: null },
  // 요금제 화면 > 비영리단체 '더 알아보기' 버튼 클릭
  cme4: { name: 'click_membership_nonprofit', data: null },

  // 메인페이지 상단 배너 > '요금제 보기' 버튼 클릭
  cbp1: { name: 'click_banner_pricing', data: null },
  // 메인페이지 상단 배너 > '더 알아보기' 버튼 클릭
  cbp2: { name: 'click_banner_nonprofit', data: null },
  // (non-profit.lbox.kr 로그인 시) 메인페이지 상단 배너 > '자세히' 버튼 클릭
  cbp3: { name: 'click_banner_detail', data: null },
  // 메인페이지 상단 배너 > '로그쿨생 혜택 보기' 버튼 클릭
  cbp4: { name: 'click_banner_lawschool', data: null },
  // 메인페이지 > 주석서 상세 페이지 배너 이미지 클릭
  maincmcw: { name: 'click_main_commentary_wholelist', data: null },
  // 메인페이지 > LBox 다큐멘터리 배너 이미지 클릭
  maincmld: { name: 'click_main_lbox_documentary', data: null },

  // 비영리단체 플랜 랜딩페이지 > '이용 신청하기' 버튼 클릭
  cna1: { name: 'click_nonprofit_apply', data: null },
  // 비영리단체 플랜 랜딩페이지 > '자세히 보기' 버튼 클릭
  cna2: { name: 'click_nonprofit_detail', data: null },
  // 비영리단체 플랜 랜딩페이지 > '서비스 소개서' 버튼 클릭
  cna3: { name: 'click_nonprofit_brochure', data: null },

  // 헤더 > '이용 가이드' 버튼 클릭
  cgg1: { name: 'click_gnb_guide', data: null },
  // 헤더 > '법률분석' 버튼 클릭 (대상자에게만 표시하는 버튼)
  cgla: { name: 'click_gnb_legal_analytics', data: null },

  // 변호사 인증 팝업
  claf: { name: 'click_lawyerauth_faq', data: null },
};
