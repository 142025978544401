<div class="title">
  <h5>결제취소 완료</h5>
</div>

<div class="refund-info-wrapper">
  <div class="gray-box">
    <div class="row">
      <span class="label">반환된 포인트</span>
      <span class="value">{{ data.refundPoint | number }}P</span>
    </div>
  
    <div class="row" *ngIf="data.amount">
      <span class="label">환불 금액</span>
      <span class="value">{{ data.amount | number }}원</span>
    </div>
  </div>
  
  <ng-container *ngIf="data.amount">
    <div class="gray-box mt-16">
      <div class="row">
        <span class="label">환불 결제수단</span>
        <span class="value">[{{ data.cardName }}]{{ data.cardNumber }}</span>
      </div>
    </div>
  </ng-container>
</div>

<div class="misc-info-wrapper">
  <p class="info-text">
    취소금액은 카드사의 정책에 따라 3-5영업일 내로 환불처리될 
    예정이며, 구체적인 환불 시기는 카드사로 확인 부탁드립니다.
  </p>
  <p class="info-text">
    계속 멤버십을 이용하고자 하는 경우, 언제든지 다시 가입해주세요.
  </p>
</div>

<div class="buttons">
  <button class="confirm-button" trackId="c0a9"  (click)="close()">확인</button>
</div>
