<div class="title">
  <h5 [innerHTML]="data.title"></h5>
  <div
    [innerHTML]="data.description"
    *ngIf="data.description"
    class="desc"
  ></div>
</div>
<div>
  <div class="input-wrapper">
    <mat-select
      panelClass="multiline"
      [(ngModel)]="option"
      [placeholder]="data.placeholder || '선택'"
    >
      <mat-option *ngFor="let option of data.options" [value]="option">{{
        option.name || option
      }}</mat-option>
    </mat-select>
  </div>
  <div class="input-wrapper mt-16" *ngIf="option?.etc">
    <textarea
      [placeholder]="option.placeholder || ''"
      [(ngModel)]="option.value"
    ></textarea>
  </div>
</div>
<div class="mt-24"></div>
<div class="buttons">
  <button class="cancel" trackId="c4d7"  (click)="close(null)" *ngIf="data.cancel != false">
    {{ data.cancel || '취소' }}
  </button>
  <button class="blue" trackId="e052"  (click)="select(option?.value || option)">
    {{ data.ok || '확인' }}
  </button>
</div>
