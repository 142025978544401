import { Injectable } from '@angular/core';
import { datadogLogs } from '@datadog/browser-logs';

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {
  clear() {
    localStorage.clear();
  }

  /**
   * 인자로 주어진 key 들을 제외하고 LocalStorage 를 clear 한다
   */
  clearExcept(exceptKeys: string[]): void {
    if (typeof window === 'undefined') {
      return;
    }

    // clear 하지 않을 key, value 쌍 임시 저장
    const exceptKeyValuePairs: Record<string, string> = {};
    exceptKeys.forEach((exceptKey) => {
      const value = window.localStorage.getItem(exceptKey);
      if (value) {
        exceptKeyValuePairs[exceptKey] = value;
      }
    });

    window.localStorage.clear();

    Object.entries(exceptKeyValuePairs).forEach(([key, value]) =>
      window.localStorage.setItem(key, value),
    );
  }

  getItem(key: string): any {
    try {
      return localStorage.getItem(key);
    } catch (e) {
      datadogLogs.logger.error(`LocalStorage getItem() error: ${e}`);
    }
  }

  setItem(key: string, value: string) {
    try {
      localStorage.setItem(key, value);
    } catch (e) {
      datadogLogs.logger.error(`LocalStorage setItem() error: ${e}`);
    }
  }

  removeItem(key: string) {
    localStorage.removeItem(key);
  }

  getBooleanItem(key: string): boolean | null {
    try {
      return JSON.parse(localStorage.getItem(key));
    } catch (e) {
      datadogLogs.logger.error(`JSON parse error: ${e}`);
      return null;
    }
  }

  setBooleanItem(key: string, value: boolean) {
    try {
      localStorage.setItem(key, JSON.stringify(value));
    } catch (e) {
      datadogLogs.logger.error(`LocalStorage setBooleanItem() error: ${e}`);
    }
  }
}
