import { EventEmitter, Inject, Injectable, PLATFORM_ID } from '@angular/core';
import FingerprintJS from '@fingerprintjs/fingerprintjs-pro';
import { isPlatformServer } from '@angular/common';
import { firstValueFrom } from 'rxjs';
import { ApiService } from '../../../ngxSpring/api.service';
import { LocalStorageService } from './local-storage.service';
import { log } from '../../log';
import { uuid } from './util';
import { datadogLogs } from '@datadog/browser-logs';

@Injectable({
  providedIn: 'root',
})
export class DeviceService {
  constructor(
    @Inject(PLATFORM_ID) private platform,
    private api: ApiService,
    private localStorageService: LocalStorageService,
  ) {
    if (isPlatformServer(this.platform)) {
      return;
    }
    const deviceId = this.localStorageService.getItem('deviceId');
    if (deviceId && !deviceId.match('not allowed')) {
      this.device = deviceId;
      log(this.device, 'device');
      this.deviceUpdated.emit();
      return;
    }
    // NOTE: fingerprint의 proxy 서버를 거쳐서 실제 npm package를 호출함
    const fpPromise = FingerprintJS.load({
      apiKey: '3yEe1ZJyMobBlEXBJEH0',
      scriptUrlPattern: [
        'https://fp-metrics.lbox.kr/web/v3/3yEe1ZJyMobBlEXBJEH0/loader_v3.9.0.js',
        FingerprintJS.defaultScriptUrlPattern, // The default endpoint as fallback
      ],
      endpoint: [
        'https://fp-metrics.lbox.kr',
        FingerprintJS.defaultEndpoint, // The default endpoint as fallback
      ],
    });

    fpPromise
      .then((fp) => fp.get())
      .then((result) => {
        if (deviceId) {
          this.api.userToken
            .resolveError(deviceId, result.visitorId)
            .subscribe();
        }
        this.device = result.visitorId;
        log(this.device, 'device, api loaded');
        this.localStorageService.setItem('deviceId', this.device);
        this.deviceUpdated.emit();
      })
      .catch((error) => {
        datadogLogs.logger.error('fingerprintjs error', {}, error);
        if (deviceId) this.device = deviceId;
        else this.device = 'not allowed ' + uuid();

        log(this.device, 'device load fail');
        this.localStorageService.setItem('deviceId', this.device);
        this.deviceUpdated.emit();
      });
  }

  private deviceUpdated = new EventEmitter();

  device: string;

  async deviceId() {
    if (this.device) return this.device;
    await firstValueFrom(this.deviceUpdated);
    return this.device;
  }

  async verify() {
    this.api.userToken.validateDevice(await this.deviceId()).subscribe();
  }
}
