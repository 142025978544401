import { Component, OnDestroy, OnInit } from '@angular/core';
import { AlarmService } from '../../core/alarm.service';
import { UserService } from '../../user/user.service';

@Component({
  selector: 'lb-alarm',
  templateUrl: './alarm.component.html',
  styleUrls: ['./alarm.component.sass'],
})
export class AlarmComponent implements OnInit, OnDestroy {
  constructor(public alarmService: AlarmService, public user: UserService) {}

  interval;

  ngOnDestroy(): void {
    if (this.interval) {
      clearInterval(this.interval);
    }
  }

  ngOnInit(): void {
    this.alarmService.refreshAlarm();
    this.alarmService.refreshCount();
    this.interval = setInterval(() => {
      this.alarmService.refreshCount();
    }, 30000);
  }
}
