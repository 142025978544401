/* tslint:disable */
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ApiHttp} from '../api.http';

import {CompanyPlanInquiry} from '../api.model';

interface PublicPlanInquiry {
  organizationName: string;
  managerName: string;
  managerPhoneNumber: string;
  managerEmail: string;
  ipAddress?: string;
  contact?: string;
}

@Injectable()
export class ZendeskController {
  constructor(private http: ApiHttp) {
  }

  createTicketForCompanyPlanInquiry(inquiry: CompanyPlanInquiry): Observable<void> {
    return this.http.post<void>('/api/zendesk/company-plan-inquiry', inquiry);
  }

  createTicketForPublicPlanInquiry(inquiry: PublicPlanInquiry): Observable<void> {
    return this.http.post<void>('/api/zendesk/public-plan-inquiry', inquiry)
  }
}
