import {
  Component,
  HostListener,
  Input,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { firstValueFrom, forkJoin } from 'rxjs';
import { ArchiveCaseInfo } from '../../../../../ngxSpring/api.model';
import { FolderUpdate } from './folder.update';

@Component({
  selector: 'lb-scrap-menu',
  templateUrl: './scrap-menu.component.html',
  styleUrls: ['./scrap-menu.component.sass'],
})
export class ScrapMenuComponent extends FolderUpdate {
  @ViewChild('folderContents', { static: true })
  menu;

  @ViewChildren('menu')
  children: QueryList<ScrapMenuComponent>;

  @Input()
  level = 0;

  loaded = false;

  cases: ArchiveCaseInfo[];

  get hasItems() {
    if (!this.cases || !this.folders) {
      return false;
    }
    return this.cases.length || this.folders.length;
  }

  folderLink(folder) {
    if (folder) {
      return '/bookmark/' + folder.id;
    }
    return '/bookmark/';
  }

  @HostListener('mouseenter')
  hoverThenLoad() {
    this.children.forEach((it) => {
      it.load(false);
    });
  }

  load(reload = true) {
    if (this.loaded && !reload) return;
    firstValueFrom(
      forkJoin([
        this.api.archiveCase.getArchiveCaseListAll(this.folder?.id),
        this.api.archiveFolder.getArchiveFolderList(this.folder?.id),
      ]),
    ).then((result) => {
      this.loaded = true;
      this.folders = result[1];
      this.cases = result[0];
    });
  }
}
