import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'dateUndefined',
})
export class DateUndefinedPipe implements PipeTransform {
  limit = moment('2200', 'YYYY');

  transform(date): unknown {
    if (this.limit.isBefore(date)) return '미정';
    return moment(date).format('YYYY. M. D.');
  }
}
