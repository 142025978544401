/* tslint:disable */
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ApiHttp} from '../api.http';

import {Certificate, IamportCertificate} from '../api.model';

@Injectable()
export class IamportCertificateController {
  constructor(private http: ApiHttp) {
  }

  getIamportCertificate(uid: string): Observable<IamportCertificate> {
    return this.http.get<IamportCertificate>('/api/iamportCertificate', {uid: uid});
  }

  getLboxIamportCertificate(uid: string): Observable<Certificate> {
    return this.http.get<Certificate>('/api/iamportCertificate/lbox', {uid: uid});
  }

  removeDeviceAndLogin(uid: string, replaceTokenId: number, deviceId: string): Observable<void> {
    return this.http.post<void>('/api/iamportCertificate/removeDeviceAndLogin', null, {uid: uid, replaceTokenId: replaceTokenId, deviceId: deviceId});
  }
}
