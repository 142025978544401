/* tslint:disable */
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ApiHttp} from '../api.http';

import {LqaQuestion, Page} from '../api.model';

@Injectable()
export class LqaQuestionController {
  constructor(private http: ApiHttp) {
  }

  getLqaLawyerPermission(): Observable<boolean> {
    return this.http.get<boolean>('/api/lqaQuestion/permission/lawyer');
  }

  getLqaQuestionByClient(id: number): Observable<LqaQuestion> {
    return this.http.get<LqaQuestion>('/api/lqaQuestion', {id: id});
  }

  getLqaQuestionList(): Observable<LqaQuestion[]> {
    return this.http.get<LqaQuestion[]>('/api/lqaQuestion/list');
  }

  getLqaQuestionListAdmin(page?: number, size?: number, keyword?: string): Observable<Page<LqaQuestion>> {
    return this.http.get<Page<LqaQuestion>>('/api/lqaQuestion/list/admin', {page: page, size: size, keyword: keyword});
  }

  saveLqaQuestion(lqaQuestion: LqaQuestion): Observable<LqaQuestion> {
    return this.http.post<LqaQuestion>('/api/lqaQuestion', lqaQuestion);
  }

  saveLqaQuestionAdmin(lqaQuestion: LqaQuestion): Observable<LqaQuestion> {
    return this.http.post<LqaQuestion>('/api/lqaQuestion/admin', lqaQuestion);
  }
}
