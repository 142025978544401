export function selectCourt(c) {
  if (c === '서울민사지방법원' || c === '서울형사지방법원') {
    return '서울중앙지방법원';
  }
  return c;
}

export const courts = [
  '대법원',
  '서울고등법원',
  '서울고등법원 (춘천)',
  '서울고등법원 (인천)',
  '서울중앙지방법원',
  '서울동부지방법원',
  '서울남부지방법원',
  '서울북부지방법원',
  '서울민사지방법원',
  '의정부지방법원',
  '서울서부지방법원',
  '서울행정법원',
  '인천지방법원',
  '인천지방법원 부천지원',
  '수원지방법원',
  '수원지방법원 성남지원',
  '수원지방법원 여주지원',
  '수원지방법원 평택지원',
  '수원지방법원 안양지원',
  '춘천지방법원',
  '춘천지방법원 강릉지원',
  '춘천지방법원 원주지원',
  '춘천지방법원 속초지원',
  '춘천지방법원 영월지원',
  '청주지방법원',
  '청주지방법원 충주지원',
  '청주지방법원 제천지원',
  '청주지방법원 영동지원',
  '대전지방법원',
  '대전지방법원 홍성지원',
  '대전지방법원 논산지원',
  '대전지방법원 천안지원',
  '대전지방법원 공주지원',
  '대전지방법원 서산지원',
  '대구고등법원',
  '대구지방법원',
  '대구지방법원 안동지원',
  '대구지방법원 경주지원',
  '대구지방법원 김천지원',
  '대구지방법원 상주지원',
  '대구지방법원 의성지원',
  '대구지방법원 영덕지원',
  '대구지방법원 포항지원',
  '대구지방법원 서부지원',
  '부산고등법원',
  '부산고등법원 (창원)',
  '부산고등법원 (울산)',
  '부산지방법원',
  '울산지방법원',
  '부산지방법원 동부지원',
  '부산지방법원 서부지원',
  '창원지방법원',
  '창원지방법원 진주지원',
  '창원지방법원 통영지원',
  '창원지방법원 밀양지원',
  '창원지방법원 거창지원',
  '창원지방법원 마산지원',
  '광주고등법원',
  '광주고등법원 (제주)',
  '광주고등법원 (전주)',
  '광주지방법원',
  '광주지방법원 목포지원',
  '광주지방법원 장흥지원',
  '광주지방법원 순천지원',
  '광주지방법원 해남지원',
  '전주지방법원',
  '전주지방법원 군산지원',
  '전주지방법원 정읍지원',
  '전주지방법원 남원지원',
  '제주지방법원',
  '대전고등법원',
  '대전고등법원 (청주)',
  '특허법원',
  '수원고등법원',
  '의정부지방법원 고양지원 파주시법원',
  '의정부지방법원 포천시법원',
  '의정부지방법원 남양주지원 가평군법원',
  '의정부지방법원 남양주지원',
  '의정부지방법원 연천군법원',
  '의정부지방법원 철원군법원',
  '의정부지방법원 고양지원',
  '의정부지방법원 동두천시법원',
  '인천지방법원 강화군법원',
  '인천지방법원 부천지원 김포시법원',
  '수원지방법원 평택지원 안성시법원',
  '수원지방법원 용인시법원',
  '수원지방법원 오산시법원',
  '수원지방법원 안산지원 광명시법원',
  '수원지방법원 안산지원',
  '수원지방법원 성남지원 광주시법원',
  '수원지방법원 여주지원 양평군법원',
  '수원지방법원 여주지원 이천시법원',
  '춘천지방법원 인제군법원',
  '춘천지방법원 홍천군법원',
  '춘천지방법원 양구군법원',
  '춘천지방법원 화천군법원',
  '춘천지방법원 강릉지원 삼척시법원',
  '춘천지방법원 강릉지원 동해시법원',
  '춘천지방법원 원주지원 횡성군법원',
  '춘천지방법원 속초지원 고성군법원',
  '춘천지방법원 속초지원 양양군법원',
  '춘천지방법원 영월지원 정선군법원',
  '춘천지방법원 영월지원 태백시법원',
  '춘천지방법원 영월지원 평창군법원',
  '청주지방법원 보은군법원',
  '청주지방법원 괴산군법원',
  '청주지방법원 진천군법원',
  '청주지방법원 충주지원 음성군법원',
  '청주지방법원 제천지원 단양군법원',
  '청주지방법원 영동지원 옥천군법원',
  '대전지방법원 세종특별자치시법원',
  '대전지방법원 금산군법원',
  '대전지방법원 홍성지원 서천군법원',
  '대전지방법원 홍성지원 보령시법원',
  '대전지방법원 홍성지원 예산군법원',
  '대전지방법원 논산지원 부여군법원',
  '대전지방법원 천안지원 아산시법원',
  '대전지방법원 공주지원 청양군법원',
  '대전지방법원 서산지원 태안군법원',
  '대전지방법원 서산지원 당진시법원',
  '대구지방법원 청도군법원',
  '대구지방법원 영천시법원',
  '대구지방법원 칠곡군법원',
  '대구지방법원 서부지원 성주군법원',
  '대구지방법원 경산시법원',
  '대구지방법원 서부지원 고령군법원',
  '대구지방법원 안동지원 영주시법원',
  '대구지방법원 안동지원 봉화군법원',
  '대구지방법원 김천지원 구미시법원',
  '대구지방법원 상주지원 예천군법원',
  '대구지방법원 상주지원 문경시법원',
  '대구지방법원 의성지원 청송군법원',
  '대구지방법원 의성지원 군위군법원',
  '대구지방법원 영덕지원 울진군법원',
  '대구지방법원 영덕지원 영양군법원',
  '울산지방법원 양산시법원',
  '창원지방법원 마산지원 함안군법원',
  '창원지방법원 창원남부시법원',
  '창원지방법원 김해시법원',
  '창원지방법원 마산지원 의령군법원',
  '창원지방법원 진주지원 하동군법원',
  '창원지방법원 진주지원 사천시법원',
  '창원지방법원 진주지원 남해군법원',
  '창원지방법원 진주지원 산청군법원',
  '창원지방법원 통영지원 거제시법원',
  '창원지방법원 통영지원 고성군법원',
  '창원지방법원 밀양지원 창녕군법원',
  '창원지방법원 거창지원 합천군법원',
  '창원지방법원 거창지원 함양군법원',
  '광주지방법원 곡성군법원',
  '광주지방법원 영광군법원',
  '광주지방법원 나주시법원',
  '광주지방법원 장성군법원',
  '광주지방법원 화순군법원',
  '광주지방법원 담양군법원',
  '광주지방법원 목포지원 함평군법원',
  '광주지방법원 목포지원 영암군법원',
  '광주지방법원 목포지원 무안군법원',
  '광주지방법원 장흥지원 강진군법원',
  '광주지방법원 순천지원 보성군법원',
  '광주지방법원 순천지원 고흥군법원',
  '광주지방법원 순천지원 여수시법원',
  '광주지방법원 순천지원 구례군법원',
  '광주지방법원 순천지원 광양시법원',
  '광주지방법원 해남지원 완도군법원',
  '광주지방법원 해남지원 진도군법원',
  '전주지방법원 진안군법원',
  '전주지방법원 김제시법원',
  '전주지방법원 무주군법원',
  '전주지방법원 임실군법원',
  '전주지방법원 군산지원 익산시법원',
  '전주지방법원 정읍지원 부안군법원',
  '전주지방법원 정읍지원 고창군법원',
  '전주지방법원 남원지원 장수군법원',
  '전주지방법원 남원지원 순창군법원',
  '제주지방법원 서귀포시법원',
  '서울가정법원',
  '서울회생법원',
  '인천가정법원',
  '수원가정법원',
  '대전가정법원',
  '대구가정법원',
  '부산가정법원',
  '광주가정법원',
  // '고등군사법원',
  // '해군작전사령부 보통군사법원',
  '수원회생법원',
  '부산회생법원',
  '의정부지방법원 남양주시법원', // 현재 의정부지방법원 남양주지원으로 변경됨
];

export const courtsForMyCase = [
  '대법원',
  '서울고등법원',
  '서울고등법원(춘천)',
  '서울고등법원(인천)',
  '대전고등법원',
  '대전고등법원(청주)',
  '대구고등법원',
  '부산고등법원',
  '부산고등법원(창원)',
  '부산고등법원(울산)',
  '광주고등법원',
  '광주고등법원(제주)',
  '광주고등법원(전주)',
  '수원고등법원',
  '특허법원',
  '서울가정법원',
  '서울행정법원',
  '서울회생법원',
  '서울중앙지방법원',
  '서울지방법원',
  '서울동부지방법원',
  '서울남부지방법원',
  '서울북부지방법원',
  '서울서부지방법원',
  '의정부지방법원',
  '의정부지방법원 고양지원',
  '의정부지방법원 고양지원 파주시법원',
  '의정부지방법원 포천시법원',
  '의정부지방법원 남양주지원',
  '의정부지방법원 동두천시법원',
  '의정부지방법원 남양주지원 가평군법원',
  '의정부지방법원 연천군법원',
  '의정부지방법원 철원군법원',
  '인천지방법원',
  '인천지방법원 부천지원',
  '인천지방법원 부천지원 김포시법원',
  '인천지방법원 강화군법원',
  '인천가정법원',
  '인천가정법원 부천지원',
  '수원지방법원',
  '수원지방법원 성남지원',
  '수원지방법원 여주지원',
  '수원지방법원 평택지원',
  '수원지방법원 안산지원',
  '수원지방법원 안양지원',
  '수원지방법원 용인시법원',
  '수원지방법원 오산시법원',
  '수원지방법원 안산지원 광명시법원',
  '수원지방법원 평택지원 안성시법원',
  '수원지방법원 성남지원 광주시법원',
  '수원지방법원 여주지원 양평군법원',
  '수원지방법원 여주지원 이천시법원',
  '수원가정법원',
  '수원가정법원 성남지원',
  '수원가정법원 여주지원',
  '수원가정법원 평택지원',
  '수원가정법원 안산지원',
  '수원가정법원 안양지원',
  '춘천지방법원',
  '춘천지방법원 강릉지원',
  '춘천지방법원 원주지원',
  '춘천지방법원 속초지원',
  '춘천지방법원 영월지원',
  '춘천지방법원 홍천군법원',
  '춘천지방법원 속초지원 양구군법원',
  '춘천지방법원 강릉지원 삼척시법원',
  '춘천지방법원 강릉지원 동해시법원',
  '춘천지방법원 영월지원 정선군법원',
  '춘천지방법원 영월지원 평창군법원',
  '춘천지방법원 영월지원 태백시법원',
  '춘천지방법원 원주지원 횡성군법원',
  '춘천지방법원 인제군법원',
  '춘천지방법원 화천군법원',
  '춘천지방법원 속초지원 고성군법원',
  '춘천지방법원 속초지원 양양군법원',
  '대전지방법원',
  '대전지방법원 홍성지원',
  '대전지방법원 공주지원',
  '대전지방법원 논산지원',
  '대전지방법원 서산지원',
  '대전지방법원 천안지원',
  '대전지방법원 금산군법원',
  '대전지방법원 세종특별자치시법원',
  '대전지방법원 홍성지원 보령시법원',
  '대전지방법원 홍성지원 서천군법원',
  '대전지방법원 홍성지원 예산군법원',
  '대전지방법원 천안지원 아산시법원',
  '대전지방법원 서산지원 태안군법원',
  '대전지방법원 서산지원 당진시법원',
  '대전지방법원 논산지원 부여군법원',
  '대전지방법원 공주지원 청양군법원',
  '대전가정법원',
  '대전가정법원 홍성지원',
  '대전가정법원 공주지원',
  '대전가정법원 논산지원',
  '대전가정법원 서산지원',
  '대전가정법원 천안지원',
  '청주지방법원',
  '청주지방법원 충주지원',
  '청주지방법원 제천지원',
  '청주지방법원 영동지원',
  '청주지방법원 진천군법원',
  '청주지방법원 보은군법원',
  '청주지방법원 제천지원 단양군법원',
  '청주지방법원 충주지원 음성군법원',
  '청주지방법원 영동지원 옥천군법원',
  '청주지방법원 괴산군법원',
  '대구지방법원',
  '대구지방법원 서부지원',
  '대구지방법원 안동지원',
  '대구지방법원 경주지원',
  '대구지방법원 포항지원',
  '대구지방법원 김천지원',
  '대구지방법원 상주지원',
  '대구지방법원 의성지원',
  '대구지방법원 영덕지원',
  '대구지방법원 경산시법원',
  '대구지방법원 칠곡군법원',
  '대구지방법원 청도군법원',
  '대구지방법원 영천시법원',
  '대구지방법원 서부지원 성주군법원',
  '대구지방법원 서부지원 고령군법원',
  '대구지방법원 안동지원 영주시법원',
  '대구지방법원 안동지원 봉화군법원',
  '대구지방법원 김천지원 구미시법원',
  '대구지방법원 상주지원 문경시법원',
  '대구지방법원 상주지원 예천군법원',
  '대구지방법원 의성지원 청송군법원',
  '대구지방법원 의성지원 군위군법원',
  '대구지방법원 영덕지원 울진군법원',
  '대구지방법원 영덕지원 영양군법원',
  '대구가정법원',
  '대구가정법원 안동지원',
  '대구가정법원 경주지원',
  '대구가정법원 포항지원',
  '대구가정법원 김천지원',
  '대구가정법원 상주지원',
  '대구가정법원 의성지원',
  '대구가정법원 영덕지원',
  '부산지방법원',
  '부산지방법원 동부지원',
  '부산지방법원 서부지원',
  '부산가정법원',
  '울산지방법원',
  '울산지방법원 양산시법원',
  '울산가정법원',
  '창원지방법원',
  '창원지방법원 마산지원',
  '창원지방법원 진주지원',
  '창원지방법원 통영지원',
  '창원지방법원 밀양지원',
  '창원지방법원 거창지원',
  '창원지방법원 창원남부시법원',
  '창원지방법원 김해시법원',
  '창원지방법원 마산지원 함안군법원',
  '창원지방법원 마산지원 의령군법원',
  '창원지방법원 진주지원 사천시법원',
  '창원지방법원 진주지원 남해군법원',
  '창원지방법원 진주지원 하동군법원',
  '창원지방법원 통영지원 거제시법원',
  '창원지방법원 통영지원 고성군법원',
  '창원지방법원 밀양지원 창녕군법원',
  '창원지방법원 거창지원 합천군법원',
  '창원지방법원 거창지원 함양군법원',
  '창원지방법원 진주지원 산청군법원',
  '광주지방법원',
  '광주지방법원 목포지원',
  '광주지방법원 장흥지원',
  '광주지방법원 순천지원',
  '광주지방법원 해남지원',
  '광주지방법원 담양군법원',
  '광주지방법원 목포지원 함평군법원',
  '광주지방법원 장흥지원 강진군법원',
  '광주지방법원 순천지원 구례군법원',
  '광주지방법원 순천지원 영광군법원',
  '광주지방법원 나주시법원',
  '광주지방법원 장성군법원',
  '광주지방법원 화순군법원',
  '광주지방법원 곡성군법원',
  '광주지방법원 순천지원 광양시법원',
  '광주지방법원 순천지원 고흥군법원',
  '광주지방법원 순천지원 여수시법원',
  '광주지방법원 순천지원 보성군법원',
  '광주지방법원 목포지원 무안군법원',
  '광주지방법원 목포지원 영암군법원',
  '광주지방법원 해남지원 완도군법원',
  '광주지방법원 해남지원 진도군법원',
  '광주가정법원',
  '광주가정법원 목포지원',
  '광주가정법원 장흥지원',
  '광주가정법원 순천지원',
  '광주가정법원 해남지원',
  '전주지방법원',
  '전주지방법원 군산지원',
  '전주지방법원 정읍지원',
  '전주지방법원 남원지원',
  '전주지방법원 진안군법원',
  '전주지방법원 김제시법원',
  '전주지방법원 무주군법원',
  '전주지방법원 임실군법원',
  '전주지방법원 군산지원 익산시법원',
  '전주지방법원 정읍지원 부안군법원',
  '전주지방법원 정읍지원 고창군법원',
  '전주지방법원 남원지원 장수군법원',
  '전주지방법원 남원지원 순창군법원',
  '제주지방법원',
  '제주지방법원 서귀포시법원',
  '법원 행정처',
  // '고등군사법원',
  // '해군작전사령부 보통군사법원',
  '수원회생법원',
  '부산회생법원',
  '의정부지방법원 남양주시법원', // 현재 의정부지방법원 남양주지원으로 변경됨
];

export const courtsForJudgeEdit = [
  '광주가정법원',
  '광주가정법원 목포지원',
  '광주가정법원 순천지원',
  '광주가정법원 장흥지원',
  '광주가정법원 해남지원',
  '광주고등법원',
  '광주고등법원 (전주)',
  '광주고등법원 (제주)',
  '광주지방법원',
  '광주지방법원 곡성군법원',
  '광주지방법원 나주시법원',
  '광주지방법원 담양군법원',
  '광주지방법원 목포지원',
  '광주지방법원 목포지원 무안군법원',
  '광주지방법원 목포지원 영암군법원',
  '광주지방법원 목포지원 함평군법원',
  '광주지방법원 순천지원',
  '광주지방법원 순천지원 고흥군법원',
  '광주지방법원 순천지원 광양시법원',
  '광주지방법원 순천지원 구례군법원',
  '광주지방법원 순천지원 보성군법원',
  '광주지방법원 순천지원 여수시법원',
  '광주지방법원 영광군법원',
  '광주지방법원 장성군법원',
  '광주지방법원 장흥지원',
  '광주지방법원 장흥지원 강진군법원',
  '광주지방법원 해남지원',
  '광주지방법원 해남지원 완도군법원',
  '광주지방법원 해남지원 진도군법원',
  '광주지방법원 화순군법원',
  '광주지방법원·광주가정법원 목포지원',
  '광주지방법원·광주가정법원 순천지원',
  '광주지방법원·광주가정법원 장흥지원',
  '광주지방법원·광주가정법원 해남지원',
  '대구가정법원',
  '대구가정법원 경주지원',
  '대구가정법원 김천지원',
  '대구가정법원 상주지원',
  '대구가정법원 안동지원',
  '대구가정법원 영덕지원',
  '대구가정법원 의성지원',
  '대구가정법원 포항지원',
  '대구고등법원',
  '대구지방법원',
  '대구지방법원 가정지원',
  '대구지방법원 경산시법원',
  '대구지방법원 경주지원',
  '대구지방법원 김천지원',
  '대구지방법원 김천지원 구미시법원',
  '대구지방법원 상주지원',
  '대구지방법원 상주지원 문경시법원',
  '대구지방법원 상주지원 예천군법원',
  '대구지방법원 서부지원',
  '대구지방법원 서부지원 고령군법원',
  '대구지방법원 서부지원 성주군법원',
  '대구지방법원 안동지원',
  '대구지방법원 안동지원 봉화군법원',
  '대구지방법원 안동지원 영주시법원',
  '대구지방법원 영덕지원',
  '대구지방법원 영덕지원 영양군법원',
  '대구지방법원 영덕지원 울진군법원',
  '대구지방법원 영천시법원',
  '대구지방법원 의성지원',
  '대구지방법원 의성지원 군위군법원',
  '대구지방법원 의성지원 청송군법원',
  '대구지방법원 청도군법원',
  '대구지방법원 칠곡군법원',
  '대구지방법원 포항지원',
  '대구지방법원·대구가정법원 경주지원',
  '대구지방법원·대구가정법원 김천지원',
  '대구지방법원·대구가정법원 상주지원',
  '대구지방법원·대구가정법원 안동지원',
  '대구지방법원·대구가정법원 영덕지원',
  '대구지방법원·대구가정법원 포항지원',
  '대법원',
  '대전가정법원',
  '대전가정법원 공주지원',
  '대전가정법원 논산지원',
  '대전가정법원 서산지원',
  '대전가정법원 천안지원',
  '대전가정법원 홍성지원',
  '대전고등법원',
  '대전고등법원 (청주)',
  '대전지방법원',
  '대전지방법원 공주지원',
  '대전지방법원 공주지원 청양군법원',
  '대전지방법원 금산군법원',
  '대전지방법원 논산지원',
  '대전지방법원 논산지원 부여군법원',
  '대전지방법원 서산지원',
  '대전지방법원 서산지원 당진시법원',
  '대전지방법원 서산지원 태안군법원',
  '대전지방법원 세종특별자치시법원',
  '대전지방법원 천안지원',
  '대전지방법원 천안지원 아산시법원',
  '대전지방법원 홍성지원',
  '대전지방법원 홍성지원 보령시법원',
  '대전지방법원 홍성지원 서천군법원',
  '대전지방법원 홍성지원 예산군법원',
  '대전지방법원·대전가정법원 공주지원',
  '대전지방법원·대전가정법원 논산지원',
  '대전지방법원·대전가정법원 서산지원',
  '대전지방법원·대전가정법원 천안지원',
  '대전지방법원·대전가정법원 홍성지원',
  '마산지방법원',
  '마산지방법원 거창지원',
  '부산가정법원',
  '부산고등법원',
  '부산고등법원 (울산)',
  '부산고등법원 (창원)',
  '부산지방법원',
  '부산지방법원 가정지원',
  '부산지방법원 동부지원',
  '부산지방법원 서부지원',
  '서울가정법원',
  '서울고등법원',
  '서울고등법원 (인천)',
  '서울고등법원 (춘천)',
  '서울남부지방법원',
  '서울동부지방법원',
  '서울민사지방법원',
  '서울북부지방법원',
  '서울서부지방법원',
  '서울중앙지방법원',
  '서울지방법원',
  '서울지방법원 남부지원',
  '서울지방법원 동부지원',
  '서울지방법원 북부지원',
  '서울지방법원 서부지원',
  '서울행정법원',
  '서울형사지방법원',
  '서울회생법원',
  '수원가정법원',
  '수원가정법원 성남지원',
  '수원가정법원 안산지원',
  '수원가정법원 안양지원',
  '수원가정법원 여주지원',
  '수원가정법원 평택지원',
  '수원고등법원',
  '수원지방법원',
  '수원지방법원 성남지원',
  '수원지방법원 성남지원 광주시법원',
  '수원지방법원 안산지원',
  '수원지방법원 안산지원 광명시법원',
  '수원지방법원 안양지원',
  '수원지방법원 여주지원',
  '수원지방법원 여주지원 양평군법원',
  '수원지방법원 여주지원 이천시법원',
  '수원지방법원 오산시법원',
  '수원지방법원 용인시법원',
  '수원지방법원 평택지원',
  '수원지방법원 평택지원 안성시법원',
  '수원지방법원·수원가정법원 성남지원',
  '수원지방법원·수원가정법원 안산지원',
  '수원지방법원·수원가정법원 안양지원',
  '수원지방법원·수원가정법원 평택지원',
  '수원지방법원․수원가정법원',
  '울산가정법원',
  '울산지방법원',
  '울산지방법원 양산시법원',
  '의정부지방법원',
  '의정부지방법원 고양지원',
  '의정부지방법원 고양지원 파주시법원',
  '의정부지방법원 남양주지원',
  '의정부지방법원 남양주지원 가평군법원',
  '의정부지방법원 동두천시법원',
  '의정부지방법원 연천군법원',
  '의정부지방법원 철원군법원',
  '의정부지방법원 포천시법원',
  '인천가정법원',
  '인천가정법원 부천지원',
  '인천지방법원',
  '인천지방법원 강화군법원',
  '인천지방법원 부천지원',
  '인천지방법원 부천지원 김포시법원',
  '인천지방법원·인천가정법원 부천지원',
  '전주지방법원',
  '전주지방법원 군산지원',
  '전주지방법원 군산지원 익산시법원',
  '전주지방법원 김제시법원',
  '전주지방법원 남원지원',
  '전주지방법원 남원지원 순창군법원',
  '전주지방법원 남원지원 장수군법원',
  '전주지방법원 무주군법원',
  '전주지방법원 임실군법원',
  '전주지방법원 정읍지원',
  '전주지방법원 정읍지원 고창군법원',
  '전주지방법원 정읍지원 부안군법원',
  '전주지방법원 진안군법원',
  '제주지방법원',
  '제주지방법원 서귀포시법원',
  '창원지방법원',
  '창원지방법원 거창지원',
  '창원지방법원 거창지원 함양군법원',
  '창원지방법원 거창지원 합천군법원',
  '창원지방법원 김해시법원',
  '창원지방법원 마산지원',
  '창원지방법원 마산지원 의령군법원',
  '창원지방법원 마산지원 함안군법원',
  '창원지방법원 밀양지원',
  '창원지방법원 밀양지원 창녕군법원',
  '창원지방법원 진주지원',
  '창원지방법원 진주지원 남해군법원',
  '창원지방법원 진주지원 사천시법원',
  '창원지방법원 진주지원 산청군법원',
  '창원지방법원 진주지원 하동군법원',
  '창원지방법원 창원남부시법원',
  '창원지방법원 통영지원',
  '창원지방법원 통영지원 거제시법원',
  '창원지방법원 통영지원 고성군법원',
  '청주지방법원',
  '청주지방법원 괴산군법원',
  '청주지방법원 보은군법원',
  '청주지방법원 영동지원',
  '청주지방법원 영동지원 옥천군법원',
  '청주지방법원 제천지원',
  '청주지방법원 제천지원 단양군법원',
  '청주지방법원 진천군법원',
  '청주지방법원 충주지원',
  '청주지방법원 충주지원 음성군법원',
  '춘천지방법원',
  '춘천지방법원 강릉지원',
  '춘천지방법원 강릉지원 동해시법원',
  '춘천지방법원 강릉지원 삼척시법원',
  '춘천지방법원 속초지원',
  '춘천지방법원 속초지원 고성군법원',
  '춘천지방법원 속초지원 양양군법원',
  '춘천지방법원 양구군법원',
  '춘천지방법원 영월지원',
  '춘천지방법원 영월지원 정선군법원',
  '춘천지방법원 영월지원 태백시법원',
  '춘천지방법원 영월지원 평창군법원',
  '춘천지방법원 원주지원',
  '춘천지방법원 원주지원 횡성군법원',
  '춘천지방법원 인제군법원',
  '춘천지방법원 홍천군법원',
  '춘천지방법원 화천군법원',
  '특허법원',
  '헌법재판소',
  '수원회생법원',
  '부산회생법원',
  '의정부지방법원 남양주시법원', // 현재 의정부지방법원 남양주지원으로 변경됨
];

const militaryCourts = [
  '고등군사법원',
  '국방부 보통군사법원',
  '중앙지역군사법원 ',
  '제1지역군사법원 ',
  '제2지역군사법원 ',
  '제3지역군사법원',
  '제4지역군사법원',
  '육군본부 보통군사법원',
  '육군교육사령부 보통군사법원 ',
  '육군군수사령부 보통군사법원 ',
  '수도방위사령부 보통군사법원',
  '수도군단 보통군사법원 ',
  '특수전사령부 보통군사법원 ',
  '지상작전사령부 보통군사법원',
  '제1야전군사령부 보통군사법원 ',
  '제1군단 보통군사법원',
  '제2군단 보통군사법원',
  '제3군단 보통군사법원',
  '제5군단 보통군사법원 ',
  '제6군단 보통군사법원',
  '제7군단 보통군사법원',
  '제8군단 보통군사법원',
  '제2작전사령부 보통군사법원 ',
  '제3야전군사령부 보통군사법원',
  '해군본부 보통군사법원',
  '해군작전사령부 보통군사법원',
  '제1함대 보통군사법원',
  '제2함대 보통군사법원',
  '해병대사령부 보통군사법원',
  '해병제1사단 보통군사법원',
  '해병제2사단 보통군사법원',
  '공군본부 보통군사법원 ',
  '공군작전사령부 보통군사법원 ',
  '공군교육사령부 보통군사법원 ',
  '기동정찰사령부 보통군사법원 ',
  '방공관제사령부 보통군사법원 ',
  '공중전투사령부 보통군사법원',
  '방공유도탄사령부 보통군사법원',
  '공군미사일방어사령부 보통군사법원',
];

/** LBK-1592 [어드민 > 내 판례등록(/admin/myCase) > 신청완료 > 등록하기 버튼 탭]에서만 값 필터링 적용 요청 */
const EXCEPTIONS_ON_ENROLLMENT = [
  '서울고등법원(춘천)',
  '서울고등법원(인천)',
  '대전고등법원(청주)',
  '부산고등법원(창원)',
  '부산고등법원(울산)',
  '광주고등법원(제주)',
  '광주고등법원(전주)',
];

export const courtsForMyCaseEnrollment = courtsForMyCase
  .filter((c) => !EXCEPTIONS_ON_ENROLLMENT.includes(c))
  .concat(militaryCourts);

/** 고등법원 */
export const highCourts = courts.filter(
  (it) =>
    it == '서울행정법원' ||
    it == '서울회생법원' ||
    it.includes('고등법원') ||
    it.includes('특허법원'),
);
highCourts.sort((a, b) => a.localeCompare(b));
export const civilCourts = courts.filter((it) => it.includes('지방법원'));
civilCourts.sort((a, b) => a.localeCompare(b));

/** LBK-1598 가정법원 판례는 입수 불가능하기 때문에 미등록판례 요청 시 옵션에서 제거 */
export const courtsExceptFamilyCourts = courts.filter(c => !c.includes('가정법원'));

/**
 * 지방법원 필터 데이터를 간소화 함에따라 사용될 지방법원 리스트
 */
export const civilCourtsForCaseSearchFilterOnly = [
  "서울중앙지방법원",
  "서울남부지방법원",
  "서울동부지방법원",
  "서울북부지방법원",
  "서울서부지방법원",
  "수원지방법원",
  "인천지방법원",
  "의정부지방법원",
  "춘천지방법원",
  "대전지방법원",
  "청주지방법원",
  "대구지방법원",
  "부산지방법원",
  "울산지방법원",
  "창원지방법원",
  "광주지방법원",
  "전주지방법원",
  "제주지방법원"
]