import { Component, EventEmitter, OnDestroy } from '@angular/core';
import { debounceTime, map } from 'rxjs/operators';
import * as Hangul from 'hangul-js';
import { ApiService } from '../../../../../ngxSpring/api.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'lb-law-select',
  templateUrl: './law-select.component.html',
  styleUrls: ['./law-select.component.sass'],
})
export class LawSelectComponent implements OnDestroy {
  searchJomuns: any[] = [{ jomuns: [''] }];
  errors = {};
  private subscription: Subscription;

  get hasError() {
    return Object.keys(this.errors)
      .map((it) => this.errors[it])
      .find((it) => it);
  }

  getOptionText(o) {
    if (!o) return;
    return o.statute_fullname;
  }

  filteredJomuns(jomuns, jomun) {
    return (jomuns || []).filter((it) => it.match(this.extract(jomun || '')));
  }

  extract(s) {
    const extract = s.match(/(\d+)([^\d]*)(\d*)/);
    if (!extract || !extract[1]) return '';
    let result = extract[1];
    if (extract[3]) result += '-' + extract[3];
    return result;
  }

  trackByIndex(index: number): any {
    return index;
  }

  inputUpdated = new EventEmitter();

  checkValidStatute(input, i) {
    this.inputUpdated.emit({ input, i });
  }

  constructor(private api: ApiService) {
    this.subscription = this.inputUpdated
      .pipe(debounceTime(100))
      .subscribe((value) => {
        this.api.lawSubscribe
          .isValidStatute(value.input.value)
          .subscribe((v) => {
            this.errors[value.i] = !v;
          });
        this.setLaw(value.input.value, value.i);
      });
  }

  findOption(search, value, i) {
    if (search.inputValue == value) return;
    search.inputValue = value;
    this.searchJomuns[i].law = value;
    if (!value || value.length < 1) return;
    if (!Hangul.isCompleteAll(value.replace(' ', ''))) return;
    this.api.lbCase
      .lawCompletion(value)
      .pipe(
        map((it) => {
          return it.map((it) => {
            return {
              jomuns: [''],
              law: it,
              statute_fullname: it,
            };
          });
        }),
      )
      .subscribe((v) => {
        search.filteredOptions = v;
      });
  }

  setLaw(law, i) {
    this.errors[i] = false;
    this.searchJomuns[i].law = law;
  }

  joName(jo) {
    return joName(jo);
  }

  deletable() {
    return this.searchJomuns.map((it) => it.jomuns.length).sum() > 1;
  }

  delete(i: number, j: number) {
    if (this.searchJomuns[i].jomuns.length > 1) {
      this.searchJomuns[i].jomuns.remove(this.searchJomuns[i].jomuns[j]);
      return;
    }
    this.searchJomuns.remove(this.searchJomuns[i]);
  }

  extractAsKeyword(prefix = ' @법조문 ') {
    let query = '';
    this.searchJomuns
      .filter((it: any) => it.law)
      .forEach((it: any) => {
        query +=
          `${prefix}${it.law.replace(/ /g, '')}` +
          it.jomuns.map((it) => this.extract(it || '')).join(',');
      });
    return query;
  }

  checkValidJomun(i: number, j: number) {
    if (
      !this.searchJomuns[i].jomuns[j] ||
      !String(this.searchJomuns[i].jomuns[j]).trim()
    ) {
      delete this.errors[i + ',' + j];
      return;
    }
    const group = String(this.searchJomuns[i].jomuns[j]).match(
      /제?\s*(?<num>\d+)조?\s*([-의]\s*(?<subnum>\d+)\s*)?((?<hang>\d+)항)?/,
    );
    if (!group) {
      this.errors[i + ',' + j] =
        '조문을 정확하게 입력해주세요. (예: 10조, 16조의2)';
      return;
    }
    if (group.groups.hang) {
      this.errors[i + ',' + j] =
        '조 단위까지만 입력 가능합니다.' + '(예: 10조, 16조의2)';
      return;
    }
    delete this.errors[i + ',' + j];
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }
}

export function joName(jo: any) {
  if (!jo) return '';
  if (jo.match('-')) return '제' + jo.replace(/\-/, '조의 ');
  return '제' + jo + '조';
}
