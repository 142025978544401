import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '../../user/user.service';

@Component({
  selector: 'lb-header-and-footer-template',
  templateUrl: './header-and-footer-template.component.html',
  styleUrls: ['./header-and-footer-template.component.sass'],
})
export class HeaderAndFooterTemplateComponent {
  @Input()
  showSuggest;

  @Input()
  showsFooter = true;

  constructor(public userService: UserService, private router: Router) {}

  resolveLink(url: string) {
    if (url.startsWith('/')) {
      this.router.navigateByUrl(url);
    } else {
      window.open(url, '_blank');
    }
  }
}
