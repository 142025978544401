<div class="title">
  <h5>다른 폴더로 이동</h5>
</div>
<div class="divider"></div>
<div class="scroller">
  <div
    class="line-button"
    style="padding-left: 12px"
    [class.active]="folderService?.rootSelected"
    trackId="791d"
    (click)="selectRoot()"
  >
    <span
      class="icon2 n-48 mr-8"
      [class.h-1]="folderService?.rootSelected"
    ></span
    ><span class="vertical-middle">판결문 보관함</span>
    <span class="icon icon-check"></span>
  </div>
  <div class="line-button placeholder" *ngIf="!parents.length">
    폴더가 없습니다.
  </div>
  <lb-select-folder
    *ngFor="let folder of parents"
    [selectSingle]="true"
    [folder]="folder"
  ></lb-select-folder>
</div>
<div class="divider"></div>
<div class="buttons">
  <button class="cancel" trackId="4ee0" (click)="close()">취소</button>
  <button class="blue" trackId="fa28" (click)="select()">이동하기</button>
</div>
