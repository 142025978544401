import { Component, ViewChild } from '@angular/core';
import { DialogOrBottomSheetProto } from '../../alert/alert.service';
import { LawSelectComponent } from './law-select/law-select.component';

@Component({
  selector: 'lb-search-with-law',
  templateUrl: './search-with-law.component.html',
  styleUrls: ['./search-with-law.component.sass'],
})
export class SearchWithLawComponent extends DialogOrBottomSheetProto<any> {
  @ViewChild(LawSelectComponent)
  lawSelect: LawSelectComponent;

  ok() {
    if (this.lawSelect.hasError) {
      this.alert.pop('법령/조문을 확인해주세요.');
      return;
    }
    this.close(this.lawSelect.extractAsKeyword());
  }
}
