import {
  BrowserModule,
  EventManager,
  HammerModule,
} from '@angular/platform-browser';
import { ErrorHandler, NgModule } from '@angular/core';
import { TransferHttpCacheModule } from '@nguniversal/common';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxSpringModule } from '../../ngxSpring/ngx.spring.module';
import { PointerEventManager } from './pointer-event-manager';
import { CoreModule } from './core/core.module';
import { MatDialogModule } from '@angular/material/dialog';
import { NotFoundComponent } from './not-found/not-found.component';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { DatePipe, Location } from '@angular/common';
import { MatNativeDateModule } from '@angular/material/core';
import { NgxMaskModule } from 'ngx-mask';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { ChunkErrorHandlerService } from './chunk-error-handler.service';
import { MatSidenavModule } from '@angular/material/sidenav';
import { HeaderModuleModule } from './core/header-drawer/header-module.module';
import { LinkDirectivesModule } from './core/link-directives/link-directives.module';
import { environment } from 'src/environments/environment';
import { LboxComponentsService } from './shared/lbox-components.service';

@NgModule({
  declarations: [AppComponent, NotFoundComponent],
  imports: [
    MatDatepickerModule,
    MatNativeDateModule,
    TransferHttpCacheModule,
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    BrowserAnimationsModule,
    NgxSpringModule,
    MatDialogModule,
    MatBottomSheetModule,
    CoreModule,
    HammerModule,
    NgxMaskModule.forRoot(),
    MatSidenavModule,
    HeaderModuleModule,
    LinkDirectivesModule,
  ],
  providers: [
    Location,
    DatePipe,
    LboxComponentsService,
    { provide: EventManager, useClass: PointerEventManager },
    { provide: ErrorHandler, useClass: ChunkErrorHandlerService },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

/* tslint:disable */
if (typeof Element !== 'undefined') {
  if (!('remove' in Element.prototype)) {
    // @ts-ignore
    Element.prototype.remove = function () {
      if (this.parentNode) {
        this.parentNode.removeChild(this);
      }
    };
  }

  if (!Element.prototype.matches) {
    Element.prototype.matches =
      (Element.prototype as any).msMatchesSelector ||
      Element.prototype.webkitMatchesSelector;
  }

  if (!Element.prototype.closest) {
    Element.prototype.closest = function (s) {
      let el = this;

      do {
        if (el.matches(s)) {
          return el;
        }
        el = el.parentElement || el.parentNode;
      } while (el !== null && el.nodeType === 1);
      return null;
    };
  }
}

declare global {
  interface Array<T> {
    last(): T;

    remove(el: T): void;

    removeAll(el: T[]): void;

    removeIf(callback): void;

    pushAll(array: T[]): void;

    random(): T;

    distinct(condition?): Array<T>;

    average(): number;

    sum(): number;

    flatten(): Array<any>;
  }

  interface Window {
    naverCallback(token: string);
  }
}

Array.prototype.remove = function (el) {
  if (!this.includes(el)) {
    return;
  }
  this.splice(this.indexOf(el), 1);
};

Array.prototype.sum = function () {
  return this.reduce((a, b) => a + b, 0);
};

Array.prototype.removeAll = function (el: any[]) {
  el.forEach((e) => this.remove(e));
};

Array.prototype.distinct = function (condition?) {
  const arr = [];
  if (!condition) {
    for (const n of this) {
      if (!arr.includes(n)) {
        arr.push(n);
      }
    }
    return arr;
  }
  for (const n of this) {
    if (!arr.find((it) => condition(it, n))) {
      arr.push(n);
    }
  }
  return arr;
};

Array.prototype.removeIf = function (callback) {
  let i = this.length;
  while (i--) {
    if (callback(this[i], i)) {
      this.splice(i, 1);
    }
  }
};
Array.prototype.average = function () {
  return this.sum() / this.length;
};

Array.prototype.pushAll = function (array: any[]) {
  if (!array || !array.forEach) {
    return;
  }
  array.forEach((each) => {
    this.push(each);
  });
};

Array.prototype.last = function () {
  return this[this.length - 1];
};

Array.prototype.random = function () {
  return this[Math.floor(Math.random() * this.length)];
};
Array.prototype.flatten = function () {
  return this.reduce((a, b) => a.concat(b), []);
};

/* tslint:disable */
if (!Array.prototype.includes) {
  Object.defineProperty(Array.prototype, 'includes', {
    value: function (searchElement, fromIndex) {
      if (this == null) {
        throw new TypeError('"this" is null or not defined');
      }

      // 1. Let O be ? ToObject(this value).
      const o = Object(this);

      // 2. Let len be ? ToLength(? Get(O, "length")).
      const len = o.length >>> 0;

      // 3. If len is 0, return false.
      if (len === 0) {
        return false;
      }

      // 4. Let n be ? ToInteger(fromIndex).
      //    (If fromIndex is undefined, this step produces the value 0.)
      const n = fromIndex | 0;

      // 5. If n ≥ 0, then
      //  a. Let k be n.
      // 6. Else n < 0,
      //  a. Let k be len + n.
      //  b. If k < 0, let k be 0.
      let k = Math.max(n >= 0 ? n : len - Math.abs(n), 0);

      function sameValueZero(x, y) {
        return (
          x === y ||
          (typeof x === 'number' &&
            typeof y === 'number' &&
            isNaN(x) &&
            isNaN(y))
        );
      }

      // 7. Repeat, while k < len
      while (k < len) {
        // a. Let elementK be the result of ? Get(O, ! ToString(k)).
        // b. If SameValueZero(searchElement, elementK) is true, return true.
        if (sameValueZero(o[k], searchElement)) {
          return true;
        }
        // c. Increase k by 1.
        k++;
      }

      // 8. Return false
      return false;
    },
  });
}

if (typeof window !== 'undefined') {
  (window as any).zendesk = () => {
    window.open('https://lboxhelp.zendesk.com/hc/ko', '_blank');
  };
}
