/* tslint:disable */
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ApiHttp} from '../api.http';

import {CaseInfo, Page, RequestCase, RequestCaseState, RequestCountStats} from '../api.model';

@Injectable()
export class RequestCaseController {
  constructor(private http: ApiHttp) {
  }

  deleteRequestCase(id: number): Observable<void> {
    return this.http.delete<void>('/api/requestCase', {id: id});
  }

  getDayList(from: number, to: number): Observable<string[]> {
    return this.http.get<string[]>('/api/requestCase/dayList', {from: from, to: to});
  }

  getMonthList(): Observable<string[]> {
    return this.http.get<string[]>('/api/requestCase/monthList');
  }

  getMyRequestCaseList(states: any[], page?: number, size?: number, from?: number, to?: number): Observable<Page<CaseInfo>> {
    return this.http.post<Page<CaseInfo>>('/api/requestCase/list/my', states, {page: page, size: size, from: from, to: to});
  }

  getRequestCaseList(page?: number, size?: number, state?: RequestCaseState, query?: string, orderBy?: number): Observable<Page<RequestCase>> {
    return this.http.get<Page<RequestCase>>('/api/requestCase/list', {page: page, size: size, state: state, query: query, orderBy: orderBy});
  }

  getRequestCaseListByCourtAndRequestCaseId(docId?: string): Observable<RequestCase[]> {
    return this.http.get<RequestCase[]>('/api/requestCase/list/courtAndId', {docId: docId});
  }

  getRequestedRecord(requestCase: RequestCase): Observable<RequestCase> {
    return this.http.post<RequestCase>('/api/requestCase/requested', requestCase);
  }

  hideRequestCase(id: number): Observable<void> {
    return this.http.delete<void>('/api/requestCase/hide', {id: id});
  }

  requestCountGetStats(fromDate?: string, toDate?: string): Observable<RequestCountStats[]> {
    return this.http.get<RequestCountStats[]>('/api/requestCase/count/daily', {fromDate: fromDate, toDate: toDate});
  }

  requestRegister(email: string): Observable<void> {
    return this.http.post<void>('/api/requestCase/requestRegister', null, {email: email});
  }

  saveRequestCase(requestCase: RequestCase): Observable<RequestCase> {
    return this.http.post<RequestCase>('/api/requestCase', requestCase);
  }

  sendEmail(requestCase: RequestCase): Observable<void> {
    return this.http.post<void>('/api/requestCase/sendEmail', requestCase);
  }

  updateRequestCase(requestCase: RequestCase): Observable<RequestCase> {
    return this.http.post<RequestCase>('/api/requestCase/update', requestCase);
  }
}
