import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { CanActivateChild } from '@angular/router';
import { isPlatformServer } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class KakaoGuard implements CanActivateChild {
  constructor(@Inject(PLATFORM_ID) private platform) {}

  canActivateChild() {
    if (isPlatformServer(this.platform)) {
      return true;
    }

    const userAgent = navigator.userAgent.toLowerCase();

    if (userAgent.match(/kakaotalk/i)) {
      location.href =
        'kakaotalk://web/openExternal?url=' + encodeURIComponent(location.href);
      return false;
    }
    return true;
  }
}
