/* tslint:disable */
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ApiHttp} from '../api.http';

import {CaseManage, CaseManageData} from '../api.model';

@Injectable()
export class CaseManageController {
  constructor(private http: ApiHttp) {
  }

  deleteCaseManage(id: number): Observable<void> {
    return this.http.delete<void>('/api/caseManage', {id: id});
  }

  getCaseEvents(): Observable<CaseManage[]> {
    return this.http.get<CaseManage[]>('/api/caseManage/caseEvents');
  }

  getCaseManage(id: number): Observable<CaseManage> {
    return this.http.get<CaseManage>('/api/caseManage', {id: id});
  }

  getCaseManageByInfo(court: string, caseNo: string, name: string): Observable<CaseManage> {
    return this.http.get<CaseManage>('/api/caseManage/byInfo', {court: court, caseNo: caseNo, name: name});
  }

  saveCaseManage(caseManage: CaseManage): Observable<CaseManage> {
    return this.http.post<CaseManage>('/api/caseManage', caseManage);
  }

  updateName(id: number, name: string): Observable<CaseManageData> {
    return this.http.post<CaseManageData>('/api/caseManage/updateName', null, {id: id, name: name});
  }
}
