/* tslint:disable */
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ApiHttp} from '../api.http';

import {LawyerVerify, Page} from '../api.model';

@Injectable()
export class LawyerVerifyController {
  constructor(private http: ApiHttp) {
  }

  authorize(id: number): Observable<void> {
    return this.http.post<void>('/api/lawyerVerify/authorize', null, {id: id});
  }

  getLawyerVerifyList(page?: number, size?: number, includeDone?: boolean): Observable<Page<LawyerVerify>> {
    return this.http.get<Page<LawyerVerify>>('/api/lawyerVerify/list', {page: page, size: size, includeDone: includeDone});
  }

  reject(id: number, reason: string): Observable<void> {
    return this.http.post<void>('/api/lawyerVerify/reject', null, {id: id, reason: reason});
  }

  saveLawyerVerify(lawyerVerify: LawyerVerify): Observable<LawyerVerify> {
    return this.http.post<LawyerVerify>('/api/lawyerVerify', lawyerVerify);
  }
}
