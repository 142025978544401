<div *ngIf="!prevent">
  <div class="title">
    <h5>미등록 판례 요청</h5>
    <p>
      <b>{{ data.caseId }}</b> 사건의 법원명을 입력해주세요.
      <br />
      등록이 완료되면 설정하신 알림 수단으로 안내해드리겠습니다.
    </p>

    <div [ngSwitch]="type" class="font-12">
      <div class="alert" *ngSwitchCase="0">
        <div>
          <span class="icon3 n-63 h-5 mr-8"></span>가압류, 가처분 결정문은
          법원별 제공시점에 따라 등록이 지연될 수 있습니다.
        </div>
        <div>
          <span class="icon3 n-63 h-5 mr-8"></span>보관기간이 지나 폐기되었거나
          열람제한 신청된 결정문은 등록이 불가합니다.
        </div>
        <div>
          <span class="icon3 n-63 h-5 mr-8"></span>아래 법원명 항목에서 검색되지
          않는 법원의 판례는 등록이 불가한 점 양해 부탁드립니다.
        </div>
        <div>
          <span class="icon3 n-63 h-5 mr-8"></span>미등록 판례 요청 시 취소가
          불가하며, 요청에 따라 등록된 판결문은 삭제할 수 없습니다.
        </div>
      </div>

      <div class="alert" *ngSwitchCase="2">
        <div>
          <span class="icon3 n-63 h-5 mr-8"></span>오래된 판례는 법원별 제공시점에
          따라 등록이 지연될 수 있습니다.
        </div>
        <div>
          <span class="icon3 n-63 h-5 mr-8"></span>보관기간이 지나 폐기되었거나
          열람제한 신청된 판례는 등록이 불가합니다.
        </div>
        <div>
          <span class="icon3 n-63 h-5 mr-8"></span>아래 법원명 항목에서 검색되지
          않는 법원의 판례는 등록이 불가한 점 양해 부탁드립니다.
        </div>
        <div>
          <span class="icon3 n-63 h-5 mr-8"></span>미등록 판례 요청 시 취소가
          불가하며, 요청에 따라 등록된 판결문은 삭제할 수 없습니다.
        </div>
      </div>

      <div class="alert" *ngSwitchDefault>
        <div>
          <span class="icon3 n-63 h-5 mr-8"></span>비실명화 여부에 따라 입수
          소요 기간이 상이합니다.
        </div>
        <div>
          <span class="icon3 n-63 h-5 mr-8"></span>판결이 아직 확정되지 않았거나
          열람제한 신청된 판례는 등록이 불가합니다.
        </div>
        <div>
          <span class="icon3 n-63 h-5 mr-8"></span>아래 법원명 항목에서 검색되지
          않는 법원의 판례는 등록이 불가한 점 양해 부탁드립니다.
        </div>
        <div>
          <span class="icon3 n-63 h-5 mr-8"></span>미등록 판례 요청 시 취소가
          불가하며, 요청에 따라 등록된 판결문은 삭제할 수 없습니다.
        </div>
      </div>
    </div>
  </div>

  <div class="input-wrapper">
    <label class="mb-4"> 법원명 (필수) </label>
    <lb-court-input [(court)]="court"></lb-court-input>
  </div>

  <div class="buttons">
    <button class="cancel" trackId="173b" (click)="close()">취소</button>
    <button class="blue" trackId="7677" (click)="request()">등록요청</button>
  </div>
</div>

<div *ngIf="prevent">
  <div class="title">
    <h5>입수불가 판례</h5>
    <p [innerHTML]="prevent"></p>
  </div>

  <div class="buttons">
    <button class="cancel" trackId="153e" (click)="close()" style="opacity: 0">
      취소
    </button>
    <button class="blue" trackId="f2d1" (click)="close()">확인</button>
  </div>
</div>
