<div class="title alert-dialog">
    <h5 [innerHTML]="data.message"></h5>
    <div
            [innerHTML]="data.description | safeHtml"
            *ngIf="data.description"
            class="desc"
    ></div>
</div>
<div class="buttons">
    <button
        *ngIf="data.cancel != false && cancelButtonTrackId"
        [trackId]="cancelButtonTrackId"
        class="cancel"
        (click)="close(null)"
    >
        {{ data.cancel || '취소' }}
    </button>
    <button 
        class="button-primary" 
        [trackId]="okButtonTrackId" 
        (click)="close(true)" 
        *ngIf="data.ok != false"
    >
        {{ data.ok || '확인'}}
    </button>
</div>
