import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filePath',
})
export class FilePathPipe implements PipeTransform {
  transform(value: any, privateType?): any {
    if (privateType == 'lqaImage')
      return '/api/matchedLawyer/file?path=' + encodeURI(value);
    if (!privateType) return `https://image.lbox.kr/` + value;
    return '/api/upload?path=' + encodeURI(value);
  }
}
