import { Component, HostBinding, Input } from '@angular/core';
import { ValidFormControl, ValidFormGroup } from '../../core/valid-form-group';

@Component({
  selector: 'lb-err-for',
  templateUrl: './err-for.component.html',
  styleUrls: ['./err-for.component.sass'],
})
export class ErrForComponent {
  @Input()
  check: any = true;

  @Input()
  group: ValidFormGroup;

  @Input()
  errFor: string;

  @Input()
  control;

  get ctrl(): ValidFormControl {
    return this.control ? this.control : this.group.controls[this.errFor];
  }

  @HostBinding('hidden')
  get hidden() {
    return !(this.ctrl.invalid && this.ctrl.touched);
  }
}
