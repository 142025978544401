<ng-container *ngIf="!data">
  <h3 class="mb-12">법률 Q&A 프로필 등록</h3>
  <div class="mb-32">
    법률 Q&A 질문자에게 공개될 프로필 정보를 입력해주세요.
    <br />
    입력하신 정보는 ‘법률 Q&A 설정’ 페이지에서 수정하실 수 있습니다.
  </div>
</ng-container>

<ng-container *ngIf="!data || data == 1">
  <h4>
    기본정보
    <ng-container *ngIf="data == 1"> 수정</ng-container>
  </h4>
  <h5>이름</h5>
  <input readonly [value]="user.user.name" />

  <h5 class="required">소속 법인/사무소명</h5>
  <input
    [(ngModel)]="lboxUserData.lqaData.work"
    [class.errored]="touched && !lboxUserData.lqaData.work"
    placeholder="예) 법무법인(유한) 엘박스, 엘박스 법률사무소"
    maxlength="25"
  />

  <h5 class="required">소속 법인/사무소 주소</h5>
  <div
    fxLayout="row"
    fxLayoutAlign="start center"
    fxLayout.lt-md="column"
    class="row"
  >
    <div fxFlex="auto" trackId="70b7" (click)="address()">
      <input
        readonly
        style="background: white"
        [class.errored]="touched && !lboxUserData.lqaData.workAddress"
        placeholder="지번/도로명/건물명을 입력해주세요"
        [value]="lboxUserData.lqaData.workAddress"
      />
    </div>
    <div fxFlex="auto">
      <input
        id="workAddressDetail"
        [readOnly]="!lboxUserData.lqaData.workAddress"
        placeholder="상세주소를 입력해주세요"
        [(ngModel)]="lboxUserData.lqaData.workAddressDetail"
      />
    </div>
  </div>

  <h5 class="required">전화</h5>
  <div
    fxLayout="row"
    fxLayoutAlign="start center"
    class="input-child"
    *ngFor="
      let phone of lboxUserData.lqaData.phones;
      trackBy: index;
      let i = index
    "
  >
    <div fxFlex="auto">
      <input
        mask="N*"
        [specialCharacters]="[]"
        [patterns]="pattern"
        [class.errored]="touched && !hasPhoneValue()"
        [(ngModel)]="lboxUserData.lqaData.phones[i]"
        placeholder="Q&A 진행 후, 후속 상담진행을 원하는 질문자를 위해 전화번호를 입력해주세요"
      />
    </div>
    <span
      fxFlex="none"
      class="ml-6"
      *ngIf="lboxUserData.lqaData.phones.length > 1"
    >
      <span
        class="i delete mt-2"
        trackId="3cf2"
        (click)="lboxUserData.lqaData.phones.remove(phone)"
      ></span>
    </span>
  </div>
  <div class="add">
    <span trackId="9405" (click)="lboxUserData.lqaData.phones.push('')">
      <span class="icon3 n-42 h-6 small"></span
      ><span class="vertical-middle">전화 추가</span>
    </span>
  </div>

  <h5 class="required">이메일 주소</h5>
  <input
    [class.errored]="
      touched &&
      (!lboxUserData.lqaData.workEmail ||
        !lboxUserData.lqaData.workEmail.match('@'))
    "
    placeholder="Q&A 진행 후, 후속 상담진행을 원하는 질문자를 위해 이메일을 입력해주세요"
    [(ngModel)]="lboxUserData.lqaData.workEmail"
  />
</ng-container>
<ng-container *ngIf="!data">
  <div class="div"></div>
</ng-container>
<ng-container *ngIf="!data || data == 2">
  <h4>
    이력
    <ng-container *ngIf="data == 2"> 수정</ng-container>
  </h4>
  <h5 class="required">자격 정보</h5>
  <div fxLayout="row">
    <span
      class="relative-position mr-12"
      fxFlex="auto"
      trackId="419a"
      (click)="changeType()"
    >
      <input
        readonly
        [value]="lboxUser.job[2] + ' ' + lboxUser.job[3]"
        class="not-styled"
      />
      <span
        class="color-primary font-14"
        style="position: absolute; right: 18px; top: 12px; font-weight: 500"
        >변경</span
      >
    </span>
    <div class="year" style="width: 200px" fxFlex="auto">
      <lb-lqa-select-year
        [(value)]="lboxUserData.lqaData.licenseYear"
        [includeNow]="false"
        placeholder="자격 취득 연도"
      ></lb-lqa-select-year>
    </div>
  </div>

  <h5 class="optional">경력</h5>
  <div
    fxLayout="row"
    fxLayout.lt-md="column"
    fxLayoutAlign="start center"
    class="input-child row"
    *ngFor="
      let career of lboxUserData.lqaData.careers;
      trackBy: index;
      let i = index
    "
  >
    <div fxFlex="auto">
      <input [(ngModel)]="career.name" maxlength="20" placeholder="회사명" />
    </div>
    <div fxLayout="row" fxLayoutAlign="start center">
      <div class="year" fxFlex="auto">
        <lb-lqa-select-year
          [(value)]="career.startYear"
          placeholder="시작 연도"
          [includeNow]="false"
        ></lb-lqa-select-year>
      </div>
      <div class="ml-12 mr-12 color-gray">~</div>
      <div class="year" fxFlex="auto">
        <lb-lqa-select-year
          [(value)]="career.endYear"
          placeholder="종료 연도"
        ></lb-lqa-select-year>
      </div>

      <span
        fxFlex="none"
        class="ml-6"
        *ngIf="lboxUserData.lqaData.careers.length > 1"
      >
        <span
          class="i delete mt-2"
          trackId="a7c4"
          (click)="lboxUserData.lqaData.careers.remove(career)"
        ></span>
      </span>
    </div>
  </div>
  <div class="add">
    <span
      trackId="9ae5"
      (click)="lboxUserData.lqaData.careers.push(rangeData())"
    >
      <span class="icon3 n-42 h-6 small"></span
      ><span class="vertical-middle">경력 추가</span>
    </span>
  </div>

  <h5 class="optional">학력</h5>
  <div
    fxLayout="row"
    fxLayout.lt-md="column"
    fxLayoutAlign="start center"
    class="input-child row"
    *ngFor="
      let school of lboxUserData.lqaData.schools;
      trackBy: index;
      let i = index
    "
  >
    <div fxFlex="auto">
      <input [(ngModel)]="school.name" maxlength="20" placeholder="학교명" />
    </div>
    <div fxLayout="row" fxLayoutAlign="start center">
      <div class="year" fxFlex="auto">
        <lb-lqa-select-year
          [(value)]="school.startYear"
          placeholder="시작 연도"
          [includeNow]="false"
        ></lb-lqa-select-year>
      </div>
      <div class="ml-12 mr-12 color-gray">~</div>
      <div class="year" fxFlex="auto">
        <lb-lqa-select-year
          [(value)]="school.endYear"
          placeholder="종료 연도"
        ></lb-lqa-select-year>
      </div>
      <span
        fxFlex="none"
        class="ml-6"
        *ngIf="lboxUserData.lqaData.schools.length > 1"
      >
        <span
          class="i delete mt-2"
          trackId="c0cc"
          (click)="lboxUserData.lqaData.schools.remove(school)"
        ></span>
      </span>
    </div>
  </div>
  <div class="add">
    <span
      trackId="7c27"
      (click)="lboxUserData.lqaData.schools.push(rangeData())"
    >
      <span class="icon3 n-42 h-6 small"></span
      ><span class="vertical-middle">학력 추가</span>
    </span>
  </div>
</ng-container>
<ng-container *ngIf="!data">
  <div class="font-weight-500 mt-48 pb-24">
    <mat-checkbox [(ngModel)]="check" [class.errored]="touched && !check"
      ><span
        class="color-primary"
        lbNewTabLink="/rule/lqa"
        trackId="4d15"
        (click)="$event.stopPropagation(); $event.preventDefault()"
        >법률 Q&A 서비스 운영정책</span
      >에 동의합니다.
    </mat-checkbox>
  </div>
  <div class="buttons pt-0 pl-0 pr-0 mt-24">
    <button class="cancel" trackId="52d6" (click)="close()">취소</button>
    <button
      class="blue"
      [class.disabled]="!check || !dataFilled"
      trackId="d47a"
      (click)="register()"
    >
      등록하기
    </button>
  </div>
</ng-container>
<ng-container *ngIf="data">
  <div class="buttons pt-0 pl-0 pr-0 mt-24">
    <button class="cancel" trackId="8540" (click)="close()">닫기</button>
    <button
      class="blue"
      [class.disabled]="!dataFilled"
      trackId="caa7"
      (click)="update()"
    >
      적용
    </button>
  </div>
</ng-container>
