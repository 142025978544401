import { Component, EventEmitter, Output } from '@angular/core';
import { CaseSearchCompactComponent } from '../case-search-compact/case-search-compact.component';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';

@Component({
  selector: 'lb-dialog-case-search',
  templateUrl: './dialog-case-search.component.html',
  styleUrls: ['./dialog-case-search.component.sass'],
})
export class DialogCaseSearchComponent extends CaseSearchCompactComponent {
  @Output()
  search = new EventEmitter();

  updateFocus() {
    if (this.focusOn || this.mouseOn) {
      this.host.nativeElement.classList.add('focused');
      return;
    }
    this.host.nativeElement.classList.remove('focused');
  }

  searchExecute(q?) {
    if (!q) {
      q = this.query;
    }
    this.search.emit(q);
  }

  ifFocusSearch(trigger: MatAutocompleteTrigger) {
    if (this.focusOn || this.mouseOn || this.autoOpen) {
      this.searchExecute(this.query);
      trigger.closePanel();
      return;
    }
    this.input.nativeElement.focus();
  }

  completionOnFind(value) {
    setTimeout(() => {
      this.findCompletion(value);
    }, 300);
  }
}
