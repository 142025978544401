import { Component, ViewChild } from '@angular/core';
import { ScriptService } from 'ngx-script-loader';
import { ApiService } from '../../../../ngxSpring/api.service';
import {
  IamportCertificate,
  UserRegisterInfo,
} from '../../../../ngxSpring/api.model';
import { AlertService } from '../../alert/alert.service';
import { ValidFormGroup } from '../../core/valid-form-group';
import { Validators } from '@angular/forms';
import { emailValidators, passwordValidators } from '../../core/validators';
import { UserService } from '../../user/user.service';
import { Router } from '@angular/router';
import { AgreeRulesComponent } from '../agree-rules/agree-rules.component';
import { RegisterService } from '../register.service';
import {
  existDesc,
  getParameterByName,
  navigateToFromOr,
} from '../../core/util';
import { LoginRecommendComponent } from '../../user/dialogs/login-recommend/login-recommend.component';
import { LocalStorageService } from '../../core/local-storage.service';

@Component({
  selector: 'lb-lbox-user',
  templateUrl: './lbox-user.component.html',
  styleUrls: ['./lbox-user.component.sass'],
})
export class LboxUserComponent {
  @ViewChild(AgreeRulesComponent, { static: false })
  agreeRules: AgreeRulesComponent;

  @ViewChild('userType', { static: false })
  type;

  snsRegister;
  snsType;

  registerGroup;
  emailExist = false;

  /** 회원가입 관련 인증 정보 임시 저장용 변수 */
  cert: IamportCertificate;

  /**
   * 휴대폰 본인인증 관련 로직
   */
  doAuth() {
    void this.user.getCertificate().then((c) => {
      if (!c) {
        return;
      }

      if (c.exists) {
        void this.alert
          .confirm(
            '알림',
            '로그인하기',
            existDesc(
              this.registerService.isTransfer
                ? '이미 본인인증을 마친 계정이 있습니다.'
                : '이미 가입한 계정이 있습니다.',
              c.snsAuthList.length ? c.snsAuthList[0].type : null,
              c.email,
              null,
              c.snsAuthList[0]?.email,
            ),
          )
          .then((v) => {
            /** 로그인하기 버튼 누른 경우 */
            if (v) {
              void this.alert.open(LoginRecommendComponent, c, {
                width: '450px',
              });
              return;
            }
          });

        return;
      }

      /** 인증 정보 변수에 할당 */
      this.cert = c.certificate;
    });
  }

  constructor(
    protected script: ScriptService,
    protected api: ApiService,
    protected alert: AlertService,
    public user: UserService,
    public registerService: RegisterService,
    protected router: Router,
    protected localStorage: LocalStorageService,
  ) {
    if (this.registerService.verifiedKey) {
      this.api.iamportCertificate
        .getLboxIamportCertificate(this.registerService.verifiedKey)
        .subscribe((v) => {
          this.cert = v.certificate;
        });
    }

    this.snsType = getParameterByName('sns');
    this.snsRegister =
      getParameterByName('sns') || this.registerService.isSnsTransfer;

    const param: any = {
      email: emailValidators.concat([
        () => {
          if (this.emailExist) {
            return true;
          }
        },
        '이미 가입한 이메일입니다.',
      ] as any),
    };

    if (!this.snsRegister) {
      param.password = passwordValidators;
      param.passwordConfirm = [
        Validators.required,
        '비밀번호를 확인해주세요.',
        () => {
          if (!this.registerGroup) {
            return;
          }

          return (
            this.registerGroup.getFormValue('password') !==
            this.registerGroup.getFormValue('passwordConfirm')
          );
        },
        '비밀번호가 일치하지 않습니다.',
      ];
    }

    this.registerGroup = new ValidFormGroup(param);
  }

  get registerAble() {
    if (this.registerGroup.error()) {
      return false;
    }

    return this.cert && this.agree && this.type?.selected;
  }

  get agree() {
    return this.agreeRules?.requireAgreed || this.registerService.agree;
  }

  get marketingAgree() {
    return this.agreeRules?.agrees?.[2] ?? false;
  }

  /**
   * 회원가입 로직
   */
  async register() {
    if (!this.cert) {
      void this.alert.alert('본인 인증을 완료해주세요.');
      return;
    }

    if (!this.user.user) {
      const error = this.registerGroup.error();

      if (error) {
        this.alert.pop(error);
        return;
      }
    }

    if (this.type.error) {
      void this.alert.alert(this.type.error);
      return;
    }

    if (!this.agree) {
      void this.alert.alert('필수 항목에 동의해주세요.');
      return;
    }

    /** 회원가입 API call */
    this.api.user
      .register(
        {
          user: this.registerGroup.value,
          job: this.type.job,
          snsToken: this.localStorage.getItem('snsToken'),
          snsType: this.snsType,
        } as UserRegisterInfo,
        this.cert.imp_uid,
        await this.user.deviceService.deviceId(),
        this.marketingAgree,
      )
      .subscribe((u) => {
        this.user.updateUser(u);
        navigateToFromOr(this.router);
      });
  }

  checkEmail() {
    this.api.user
      .existCheck(this.registerGroup.getFormValue('email'))
      .subscribe((v) => {
        this.emailExist = v;
        this.registerGroup.startCheck('email');
      });
  }

  handleChangeEmailInput() {
    const emailValue = this.registerGroup.getFormValue('email');
    this.registerGroup.setFormValue(
      'email',
      emailValue.replaceAll(' ', '').replace(/\.$/, ''),
    );
  }
}

export function getNumberOptions(number: number, number2: number, suffix) {
  const options = [];
  if (number2 < number) {
    for (let i = number2; i <= number; i++) {
      options.unshift(i + suffix);
    }
  }
  for (let i = number; i <= number2; i++) {
    options.push(i + suffix);
  }
  return options;
}
