import { caseTypes } from '../../case/case.service';
import { interpretFilters } from './interpret-types';
import { courtOption, trialTypeOption } from '../../core/util';
import { SearchService } from '../search.service';

const caseTypeOption = {
  type: {
    name: '사건유형',
    value: 'caseType',
    icon: 'n-88',
  },
  types: caseTypes,
};
const interpretOption = {
  type: {
    name: '유권해석',
    value: 'interpretation',
    icon: 'n-4',
    paramName: 'reference',
  },
  types: [
    {
      value: null,
      name: '전체',
    },
    {
      value: '법제처',
      name: '법제처',
      link: 'http://www.law.go.kr/LSW/main.html',
      subOptions: [
        { name: '소관기관', options: interpretFilters['법제처'][0] },
      ],
    },
  ],
};

const trialOption = {
  type: {
    name: '심결례',
    value: 'trialdecision',
    icon: 'n-4',
    paramName: 'reference',
  },
  types: [
    {
      value: null,
      name: '전체',
    },
    {
      value: '공정거래위원회',
      name: '공정거래위원회',
      link: 'https://case.ftc.go.kr/ocp/co/ltfr.do',
      subOptions: [
        {
          name: '대표조치유형',
          options: interpretFilters['공정거래위원회'][0],
        },
        {
          name: '대표위반유형',
          options: interpretFilters['공정거래위원회'][1],
        },
      ],
    },
    {
      value: '조세심판원',
      name: '조세심판원',
      link: 'https://www.tt.go.kr/main/main.do',
      subOptions: [
        { name: '세목구분', options: interpretFilters['조세심판원'][0] },
        { name: '세목분류', options: interpretFilters['조세심판원'][1] },
        { name: '결정', options: interpretFilters['조세심판원'][2] },
      ],
    },
  ],
};
const newOption = {
  type: {
    name: '출처',
    value: 'data',
    icon: 'n-28',
  },
  types: [
    { value: null, name: '전체' },
    { value: 'lawtimes', name: '법률신문' },
  ],
};
const refOption = {
  type: {
    name: '출처',
    value: 'reference',
    icon: 'n-28',
  },
  types: [
    { value: 'dbpia', name: 'DBpia' },
    { value: 'kci', name: 'KCI' },
    { value: 'naver', name: '네이버 학술정보' },
  ],
};

export type CategoryType =
  | 'case'
  | 'statute'
  | 'trialdecision'
  | 'interpretation'
  | 'news'
  | 'commentary'
  | 'reference';

export const typePath = (type) => (type == 'case' ? '' : type);

const statuteTypeOption = {
  multiple: true,
  ga: 'click_search_statute_statutefilter',
  type: {
    name: '법령종류',
    value: 'bubjongString',
    icon: 'n-88',
  },
  types: [
    {
      value: 'LAW',
      name: '법률',
    },
    {
      value: 'PRECEDENT',
      name: '대통령령',
    },
    {
      value: 'PRIME_MINISTER',
      name: '총리령/부령',
    },
    {
      value: 'ETC',
      name: '기타',
    },
  ],
};
const statuteTypeMobileOption = JSON.parse(JSON.stringify(statuteTypeOption));
statuteTypeMobileOption.types.unshift({ value: null, name: '전체' });

const nowFilter = {
  mobileName: '검색범위',
  ga: 'click_search_statute_rangefilter',
  type: {
    name: '검색범위',
    value: 'onlyCurrent',
    icon: 'n-89',
  },
  types: [
    {
      value: null,
      name: '현행',
    },
    {
      value: 'false',
      name: '전체',
    },
  ],
};

export const categories = [
  {
    name: '판례',
    type: 'case',
    subscribe: true,
    mobileOptions: () => [trialTypeOption, courtOption],
    mobileSubOption: caseTypeOption,
    options: [caseTypeOption, trialTypeOption, courtOption],
  },
  {
    name: '법령',
    type: 'statute',
    mobileOptions: () => [nowFilter],
    orders: ['SCORE_DESC', 'EFFECT_DESC', 'PROCLAMATION_DESC'],
    mobileSubOption: statuteTypeMobileOption,
    options: [statuteTypeOption, nowFilter],
  },
  {
    name: '주석서',
    type: 'commentary',
    hidePage: true,
    hideCount: true,
    hideOrder: true,
    hideRange: true,
    options: [
      {
        multiple: true,
        type: {
          name: '주석서 종류',
          value: 'commentaryTitle',
          icon: 'n-88',
        },
        types: [
          { name: '민사소송법', value: '민사소송법' },
          { name: '민사집행법', value: '민사집행법' },
          {
            name: '상법',
            value: '상법',
            subOptions: [
              {
                name: '상법',
                hideInput: true,
                options: ['회사편', '총칙 상행위'],
              },
            ],
          },
          { name: '채무자회생법', value: '채무자회생법' },
          {
            name: '형법',
            value: '형법',
            subOptions: [
              {
                name: '형법',
                hideInput: true,
                options: ['각칙', '총칙'],
              },
            ],
          },
        ],
      },
    ],
    mobileSubOption: {
      multiple: true,
      type: {
        name: '주석서 종류',
        value: 'commentaryTitle',
        icon: 'n-88',
      },
      types: [
        { name: '전체', value: null },
        { name: '민사소송법', value: '민사소송법' },
        { name: '민사집행법', value: '민사집행법' },
        {
          name: '상법',
          value: '상법',
          subOptions: [
            {
              name: '상법',
              hideInput: true,
              options: ['회사편', '총칙 상행위'],
            },
          ],
        },
        { name: '채무자회생법', value: '채무자회생법' },
        {
          name: '형법',
          value: '형법',
          subOptions: [
            {
              name: '형법',
              hideInput: true,
              options: ['각칙', '총칙'],
            },
          ],
        },
      ],
    },
  },
  {
    name: '참고문헌',
    type: 'reference',
    orders: ['SCORE_DESC', 'DATE_DESC'],
    hidePage: true,
    hideCount: true,
    hideRange: true,
    mobileSubOption: refOption,
    options: [refOption],
  },
  {
    orders: ['SCORE_DESC', 'RECENT_DESC'],
    name: '심결례',
    type: 'trialdecision',
    mobileSubOption: trialOption,
    mobileOptions: (searchService: SearchService) => {
      switch (searchService.searchQuery['reference']) {
        case '공정거래위원회':
          return [
            {
              name: '유형 필터',
              type: {
                name: '유형',
                icon: 'n-51',
              },
              types: [
                {
                  name: '유형 필터',
                  subOptions: [
                    {
                      name: '대표조치유형',
                      options: interpretFilters['공정거래위원회'][0],
                    },
                    {
                      name: '대표위반유형',
                      options: interpretFilters['공정거래위원회'][1],
                    },
                  ],
                },
              ],
            },
          ];
        case '조세심판원':
          return [
            {
              name: '세목 필터',
              type: {
                name: '세목',
                icon: 'n-51',
              },
              types: [
                {
                  name: '세목 필터',
                  subOptions: [
                    {
                      name: '세목구분',
                      options: interpretFilters['법제처'][0],
                    },
                    {
                      name: '세목분류',
                      options: interpretFilters['법제처'][0],
                    },
                    {
                      name: '결정',
                      options: interpretFilters['법제처'][0],
                    },
                  ],
                },
              ],
            },
          ];
      }
    },
    mobileParam: 'reference',
    options: [trialOption],
  },
  {
    name: '유권해석',
    orders: ['SCORE_DESC', 'RECENT_DESC'],
    type: 'interpretation',
    mobileSubOption: interpretOption,
    mobileOptions: (searchService: SearchService) => {
      switch (searchService.searchQuery['reference']) {
        case '법제처':
          return [
            {
              name: '소관 기관',
              type: {
                name: '소관 기관',
                icon: 'n-51',
              },
              types: [
                {
                  name: '소관 기관',
                  subOptions: [
                    {
                      name: '소관 기관',
                      options: interpretFilters['법제처'][0],
                    },
                  ],
                },
              ],
            },
          ];
      }
    },
    options: [interpretOption],
  },
  {
    name: '뉴스',
    type: 'news',
    mobileSubOption: newOption,
    options: [newOption],
    hideOrder: true,
    hideRange: true,
  },
];
