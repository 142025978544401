import {
  EventEmitter,
  Inject,
  Injectable,
  OnDestroy,
  PLATFORM_ID,
} from '@angular/core';
import {
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router,
} from '@angular/router';
import { isPlatformServer } from '@angular/common';
import { TrackingService } from './tracking.service';
import { Subscription } from 'rxjs';
import { UserpilotService } from '../shared/userpilot.service'

@Injectable({ providedIn: 'root' })
export class LoaderService implements OnDestroy {
  loadValue

  set load(value) {
    this.loadValue = value
    const el = document.querySelector('.loading-bar.loading-bar--active')
    if (!el) return
    if (value) {
      el.classList.add('loading')
    } else {
      el.classList.remove('loading')
    }
  }

  get load() {
    return this.loadValue
  }

  dataLoaded = new EventEmitter()
  subscription: Subscription

  constructor(
    private router: Router,
    private tracking: TrackingService,
    private userpilotService: UserpilotService,
    @Inject(PLATFORM_ID) private platform,
  ) {
    if (isPlatformServer(this.platform)) {
      return
    }
    this.subscription = this.router.events.subscribe((e) => {
      if (e instanceof NavigationStart) {
        this.load = true
      }
      if (e instanceof NavigationEnd) {
        this.load = false
        setTimeout(() => {
          this.userpilotService.instance?.reload()
          document.scrollingElement.scrollTop = 0
          document.body.scrollTop = 0

          try {
            const target = document.querySelector(
              '#' + location.href.split('#')[1],
            )
            if (location.href.includes('#') && target) {
              setTimeout(() => {
                target.scrollIntoView({ behavior: 'smooth', block: 'center' })
              }, 500)
            }
          } catch {}
        })
      }
      if (e instanceof NavigationError || e instanceof NavigationCancel) {
        this.load = false
        setTimeout(() => {
          document.scrollingElement.scrollTop = 0
          document.body.scrollTop = 0
        })
      }
    })
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe()
  }
}
