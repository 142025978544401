import { EventEmitter, Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { TrackingService } from '../core/tracking.service';
import { ApiService } from '../../../ngxSpring/api.service';
import { isProduction } from '../core/util';
import { isPlatformServer } from '@angular/common';
import { tracks } from './track';
import { UserpilotService } from '../shared/userpilot.service'

declare var gtag

@Injectable({ providedIn: 'root' })
export class TrackService {
  showTrack = new EventEmitter()
  showTracks = []

  constructor(
    public tracking: TrackingService,
    public api: ApiService,
    private userpilotService: UserpilotService,
    @Inject(PLATFORM_ID) private platform,
  ) {
    if (isPlatformServer(this.platform)) {
      return
    }
    if (!this.trackMap || !isProduction()) {
      this.api.trackId.getTrackIdMap().subscribe((v) => {
        this.trackMap = v
      })
    }
  }

  trackMap: { [key: string]: { name; data } } = tracks
  nowTrackKey

  sendEvent(name, value?, d?) {
    if (isPlatformServer(this.platform)) {
      return
    }
    if (!name) return
    const data = {
      type: name,
      ...value,
      ...d,
    } as any

    if (typeof gtag !== 'undefined') {
      gtag('event', name, data)
    }
    this.userpilotService.instance?.track(name, data)
    this.tracking.tracking(name, data).then()
  }

  trackName(trackKey: string) {
    return this.trackMap?.[trackKey]?.name
  }

  trackData(trackKey: string) {
    return this.trackMap?.[trackKey]?.data
  }
}
