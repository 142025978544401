import { Component, Input } from '@angular/core';
import { JudgeProfile } from '../../../../../ngxSpring/api.model';

@Component({
  selector: 'lb-info-desc',
  templateUrl: './info-desc.component.html',
  styleUrls: ['./info-desc.component.sass'],
})
export class InfoDescComponent {
  @Input()
  judge: JudgeProfile;

  get infoList() {
    return [
      (this.judge.retired == true ? '전) ' : '') + this.judge.court,
      this.judge.bar_type,
    ].filter((it) => it);
  }
}
