import {
  AfterViewInit,
  Directive,
  ElementRef,
  HostListener,
  Renderer2,
} from '@angular/core';
import { isMobileOrTablet } from '../core/util';

@Directive({
  selector: '[lbDragScrollX]',
})
export class DragScrollDirective implements AfterViewInit {
  lastClientX;
  el;
  pushed = false;

  constructor(public elf: ElementRef, private renderer: Renderer2) {
    this.el = elf.nativeElement;
    if (isMobileOrTablet()) {
      this.renderer.setStyle(this.el, 'overflow-x', 'auto');
      this.renderer.setStyle(this.el, 'overflow-y', 'hidden');
    } else {
      this.renderer.setStyle(this.el, 'overflow', 'hidden');
    }
  }

  @HostListener('mousedown', ['$event'])
  mousedown(e) {
    if (isMobileOrTablet()) {
      return;
    }
    this.pushed = true;
    this.lastClientX = e.clientX;
  }

  @HostListener('mouseup')
  @HostListener('window:mouseup')
  mouseup() {
    if (isMobileOrTablet()) {
      return;
    }
    this.pushed = false;
  }

  @HostListener('mousemove', ['$event'])
  mousemove(e) {
    if (isMobileOrTablet()) {
      return;
    }
    if (this.pushed) {
      this.el.scrollLeft -= -this.lastClientX + (this.lastClientX = e.clientX);
    }
  }

  @HostListener('wheel', ['$event'])
  wheel(e) {
    this.el.scrollLeft += +(e.deltaY / 4);
  }

  @HostListener('window:resize')
  updateHandleView() {
    if (this.el.clientWidth > this.el.children[0]?.scrollWidth)
      this.el.classList.add('has-handle');
    else this.el.classList.remove('has-handle');
  }

  ngAfterViewInit(): void {
    this.updateHandleView();
  }
}
