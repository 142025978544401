/* tslint:disable */
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ApiHttp} from '../api.http';

import {ClientTracking, CopyLog, Page} from '../api.model';

@Injectable()
export class ClientTrackingController {
  constructor(private http: ApiHttp) {
  }

  copyLog(copyLog: CopyLog): Observable<void> {
    return this.http.post<void>('/api/clientTracking/copyLog', copyLog);
  }

  getClientTrackingList(page?: number, size?: number, userId?: number): Observable<Page<ClientTracking>> {
    return this.http.get<Page<ClientTracking>>('/api/clientTracking/list', {page: page, size: size, userId: userId});
  }

  saveClientTracking(url: string, pageId: string, eventId: string, prevEventId?: string, prevPageId?: string, event?: any): Observable<ClientTracking> {
    return this.http.post<ClientTracking>('/api/clientTracking', event, {url: url, pageId: pageId, eventId: eventId, prevEventId: prevEventId, prevPageId: prevPageId});
  }
}
