/* tslint:disable */
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ApiHttp} from '../api.http';

import {Page, RequestRecord} from '../api.model';

@Injectable()
export class RequestRecordController {
  constructor(private http: ApiHttp) {
  }

  getRequestRecordList(page?: number, size?: number): Observable<Page<RequestRecord>> {
    return this.http.get<Page<RequestRecord>>('/api/requestRecord/list', {page: page, size: size});
  }

  refreshRecord(token: string): Observable<number> {
    return this.http.post<number>('/api/requestRecord/refresh', null, {token: token});
  }
}
