/* tslint:disable */
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ApiHttp} from '../api.http';

import {LawSubscribe, Page, SearchQuery, SearchResult} from '../api.model';

@Injectable()
export class LawSubscribeController {
  constructor(private http: ApiHttp) {
  }

  deleteLawSubscribe(id: number): Observable<void> {
    return this.http.delete<void>('/api/lawSubscribe', {id: id});
  }

  getLawSubscribeList(page?: number, size?: number): Observable<Page<LawSubscribe>> {
    return this.http.get<Page<LawSubscribe>>('/api/lawSubscribe/list', {page: page, size: size});
  }

  getMyLawSubscribeList(): Observable<LawSubscribe[]> {
    return this.http.get<LawSubscribe[]>('/api/lawSubscribe/list/my');
  }

  isValidStatute(name: string): Observable<boolean> {
    return this.http.get<boolean>('/api/lawSubscribe/isValid', {name: name});
  }

  save(lawSubscribe: LawSubscribe): Observable<LawSubscribe> {
    return this.http.post<LawSubscribe>('/api/lawSubscribe/admin', lawSubscribe);
  }

  saveLawSubscribe(lawSubscribe: LawSubscribe): Observable<LawSubscribe> {
    return this.http.post<LawSubscribe>('/api/lawSubscribe', lawSubscribe);
  }

  searchByLawSubscribe(id: number, searchQuery?: SearchQuery, page?: number, size?: number): Observable<SearchResult> {
    return this.http.post<SearchResult>('/api/lawSubscribe/searchByLawSubscribe', searchQuery, {id: id, page: page, size: size});
  }

  sendEmail(id: number): Observable<void> {
    return this.http.post<void>('/api/lawSubscribe/sendEmail', null, {id: id});
  }
}
