import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class UrlService {
  /**
   * 개발 환경에 맞는 URL authority 반환
   * - https://developer.mozilla.org/en-US/docs/Learn/Common_questions/Web_mechanics/What_is_a_URL#authority
   */
  getAuthority(): string {
    if (typeof window === 'undefined') {
      return '';
    }

    if (window.location.host === 'localhost:4200') {
      return 'http://localhost:3000';
    } else {
      return window.location.origin;
    }
  }
}
