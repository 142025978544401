<div class="alarms">
  <div class="title">
    <span class="vertical-middle"> 알림 </span>
    <span
      class="icon3 n-58 mt--2 small"
      (click)="close()"
      trackId="a788"
      routerLink="/user/alarm"
    ></span>
    <span
      class="icon icon-delete close"
      trackId="44db"
      (click)="close()"
    ></span>
  </div>
  <div class="categories-wrapper" lbDragScrollX>
    <div class="categories" style="user-select: none">
      <div
        *ngFor="
          let category of user.isLawyer
            ? alarmService.lawyerCategories
            : alarmService.categories
        "
        [class.active]="category == alarmService.selectedCategory"
        trackId="97dd"
        (click)="alarmService.refreshAlarm(category)"
        [trackData]="{ type: category.logType }"
        [innerHTML]="category.name"
      ></div>
      <div style="width: 6px"></div>
    </div>
  </div>
  <div class="content" (scroll)="checkMore($event)">
    <div class="alarm" *ngIf="!alarmService.alarms?.length">
      <div class="message">새로운 알림이 없습니다.</div>
    </div>
    <div
      *ngFor="let alarm of alarmService.alarms; let last = last"
      class="wrapper"
    >
      <a
        class="alarm"
        trackId="c7e9"
        (click)="alarm.readAt = date()"
        [class.cursor-default]="!alarm.link && alarm.readAt"
        [class.unread]="!alarm.readAt"
      >
        <div class="message">
          <span [innerHTML]="alarm.message | newLine: true"></span>
          <div
            *ngIf="alarm.link || alarm.action"
            class="mt-6 color-primary"
            fxLayout="row"
          >
            <span trackId="3e62" (click)="moveOrAction(alarm)">
              {{ alarm.linkText || '판례 보러가기' }}
            </span>
            <span fxFlex="auto"></span>
            <span
              *ngIf="alarm.link2 || alarm.action2"
              trackId="da57"
              (click)="moveOrAction(alarm, '2')"
            >
              {{ alarm.linkText2 || '판례 보러가기' }}
            </span>
          </div>

          <div class="date" *ngIf="alarm.updatedAt">
            {{ alarm.updatedAt | amTimeAgo
            }}<span class="new mt--2" *ngIf="!alarm.readAt"></span>
          </div>
        </div>
      </a>
      <div class="divider" *ngIf="!last"></div>
    </div>
  </div>
</div>
