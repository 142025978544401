import { Component, EventEmitter, Input, Output } from '@angular/core';
import { associations } from '../../substitute/districtsAndCourts';

@Component({
  selector: 'lb-lawyer-association-input',
  templateUrl: './lawyer-association-input.component.html',
  styleUrls: ['./lawyer-association-input.component.sass'],
})
export class LawyerAssosiationInputComponent {
  @Input()
  association;

  @Output()
  associationChange = new EventEmitter();
  associations = associations;
}
