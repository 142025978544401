import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'lb-input-for-etc',
  templateUrl: './input-for-etc.component.html',
  styleUrls: ['./input-for-etc.component.sass'],
})
export class InputForEtcComponent {
  @Input()
  value;

  @Output()
  valueChange = new EventEmitter();

  @Input()
  placeholder = '직접 입력';

  textError(value) {
    if (!value) return '내용을 입력해주세요.';
    if (value.length < 2) return '두 글자 이상 입력해주세요.';
  }
}
