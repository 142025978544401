/* tslint:disable */
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ApiHttp} from '../api.http';

import {CompanyContract} from '../api.model';

@Injectable()
export class CompanyContractController {
  constructor(private http: ApiHttp) {
  }

  addPeople(id: number, startDate: string, size: number): Observable<CompanyContract> {
    return this.http.post<CompanyContract>('/api/companyContract/add', null, {id: id, startDate: startDate, size: size});
  }

  deleteCompanyContract(id: number): Observable<void> {
    return this.http.delete<void>('/api/companyContract', {id: id});
  }

  getCompanyContract(id: number): Observable<CompanyContract> {
    return this.http.get<CompanyContract>('/api/companyContract', {id: id});
  }

  getCompanyContractList(companyId: number): Observable<CompanyContract[]> {
    return this.http.get<CompanyContract[]>('/api/companyContract/list', {companyId: companyId});
  }

  saveCompanyContract(companyContract: CompanyContract): Observable<CompanyContract> {
    return this.http.post<CompanyContract>('/api/companyContract', companyContract);
  }
}
