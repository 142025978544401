import { Component, Input } from '@angular/core';
import { AlarmService } from '../../../core/alarm.service';
import { UserService } from '../../../user/user.service';
import { AlertService } from '../../../alert/alert.service';

@Component({
  selector: 'lb-alarm-board',
  templateUrl: './alarm-board.component.html',
  styleUrls: ['./alarm-board.component.sass'],
})
export class AlarmBoardComponent {
  @Input()
  popover;

  constructor(
    public alarmService: AlarmService,
    public user: UserService,
    private alert: AlertService,
  ) {}

  checkMore($event) {
    const element = $event.target;
    if (element.scrollHeight - 100 < element.clientHeight + element.scrollTop)
      this.alarmService.more();
  }

  moveOrAction(alarm, suffix = '') {
    if (alarm['link' + suffix]) {
      const path = alarm['link' + suffix];
      if (path.startsWith('http'))
        window.open(alarm['link' + suffix], '_blank');
      else window.open(location.origin + alarm['link' + suffix], '_blank');
    }
    if (alarm['action' + suffix]) alarm['action' + suffix]();
  }

  date() {
    return new Date();
  }

  close() {
    if (this.popover) this.popover.close();
    else this.alert.close();
  }
}
