import { Component, OnInit } from '@angular/core';
import { ValidFormGroup } from '../../../core/valid-form-group';
import { Validators } from '@angular/forms';
import { DialogOrBottomSheetProto } from '../../../alert/alert.service';
import { passwordValidators } from '../../../core/validators';
import { UserService } from '../../user.service';

@Component({
  selector: 'lb-password-reset-dialog',
  templateUrl: './password-reset-dialog.component.html',
  styleUrls: ['./password-reset-dialog.component.sass'],
})
export class PasswordResetDialogComponent
  extends DialogOrBottomSheetProto<any>
  implements OnInit
{
  passwordRedefineForm;

  passwordRedefine() {
    const error = this.passwordRedefineForm.error();
    if (error) {
      this.alert.alert(error);
      return;
    }
    if (
      this.data.hasPassword &&
      this.passwordRedefineForm.getFormValue('oldPassword') ==
        this.passwordRedefineForm.getFormValue('password')
    ) {
      this.alert.pop('기존 비밀번호와 새 비밀번호가 같습니다.');
      return;
    }
    this.api.user
      .updatePassword({
        newPassword: this.passwordRedefineForm.getFormValue('password'),
        code: this.data.hasPassword
          ? this.passwordRedefineForm.getFormValue('oldPassword')
          : null,
      })
      .subscribe(() => {
        this.close();
        if (this.data.hasPassword) this.alert.pop('비밀번호가 변경되었습니다.');
        else {
          this.alert.pop('비밀번호가 설정되었습니다.');
          this.injector.get(UserService).updateFromServer();
        }
      });
  }

  ngOnInit(): void {
    if (!this.data) this.data = {};
    const params = {
      password: passwordValidators,
      passwordConfirm: [
        Validators.required,
        '비밀번호를 확인해주세요.',
        () => {
          if (!this.passwordRedefineForm) {
            return;
          }
          return (
            this.passwordRedefineForm.getFormValue('password') !==
            this.passwordRedefineForm.getFormValue('passwordConfirm')
          );
        },
        '비밀번호가 일치하지 않습니다.',
      ],
    } as any;
    if (this.data.hasPassword) {
      params.oldPassword = passwordValidators;
    }
    this.passwordRedefineForm = new ValidFormGroup(params);
  }
}
