import { Component, Input } from '@angular/core';

export type ContentType =
  | 'lawyer'
  | 'lawSchoolStudent'
  | 'businessPlanInquiry'
  | 'publicPlanInquiry';

export interface TermsAndConditionsItem {
  label: '항목' | '처리 목적' | '보유 기간';
  value: string;
  bold: boolean;
}

@Component({
  selector: 'lb-personal-information-collect-and-usage-info-table',
  templateUrl: './personal-information-collect-and-usage-info-table.html',
  styleUrls: ['./personal-information-collect-and-usage-info-table.sass'],
})
export class PersonalInformationCollectAndUsageInfoTable {
  @Input()
  contentType: ContentType = 'lawyer';

  get termsAndConditionsByContentType(): TermsAndConditionsItem[] {
    if (this.contentType === 'lawyer') {
      return [
        {
          label: '항목',
          value:
            '이름, 전화번호, 생년월일, 이메일 주소, 패스워드, 응시 시험, 시험 회차, 소속 변호사회, (변호사 인증 시 추가적으로 수집되는 정보) 대한변협 신분증 등록번호, 대한변협 신분증 발급번호',
          bold: false,
        },
        {
          label: '처리 목적',
          value: '회원 관리, 회원 불만 사항 처리, 재화 또는 서비스 제공, 상품/서비스 개선 및 추천, 고지/안내사항 전달, 상품/서비스 이용 실적 통계/분석, 변호사 전용 서비스 제공',
          bold: false,
        },
        {
          label: '보유 기간',
          value: '서비스 이용 만료시 또는 개인정보 수집·이용 목적 달성시까지',
          bold: true,
        },
      ];
    }

    if (this.contentType === 'lawSchoolStudent') {
      return [
        {
          label: '항목',
          value:
            '이름, 전화번호, 생년월일, 이메일 주소, 패스워드, 학교명, 입학연도, 졸업 예정 연도, (로스쿨 학생 인증 시 추가적으로 수집되는 정보) 학과명, 학번, 입학연월일, 졸업예정연월일, 인물사진, 발급일자, 재학학기',
          bold: false,
        },
        {
          label: '처리 목적',
          value:
            '회원 관리, 회원 불만 사항 처리, 재화 또는 서비스 제공, 상품/서비스 개선 및 추천, 고지/안내사항 전달, 상품/서비스 이용 실적 통계/분석, 로스쿨 학생 전용 서비스 제공',
          bold: false,
        },
        {
          label: '보유 기간',
          value: '서비스 이용 만료시 또는 개인정보 수집·이용 목적 달성시까지',
          bold: true,
        },
      ];
    }

    if (this.contentType === 'businessPlanInquiry') {
      return [
        {
          label: '항목',
          value: '(필수) 이름, 소속, 담당자 이메일, 담당자 전화번호, 희망 이용인원, (선택) 문의사항',
          bold: false,
        },
        {
          label: '처리 목적',
          value: '고객 문의 응대, 견적 및 구매 상담, 서비스 소개 및 이용 안내',
          bold: false,
        },
        {
          label: '보유 기간',
          value: '서비스 이용 만료시 또는 개인정보 수집·이용 목적 달성시까지',
          bold: true,
        },
      ];
    }

    if (this.contentType === 'publicPlanInquiry') {
      return [
        {
          label: '항목',
          value:
            '(필수) 단체명, 담당자 이름, 담당자 전화번호, 담당자 이메일 주소, (선택) IP 주소, 문의사항',
          bold: false,
        },
        {
          label: '처리 목적',
          value: '고객 문의 응대, 견적 및 구매 상담, 서비스 소개 및 이용 안내',
          bold: false,
        },
        {
          label: '보유 기간',
          value: '서비스 이용 만료시 또는 개인정보 수집·이용 목적 달성시까지',
          bold: true,
        },
      ];
    }

    return [
      {
        label: '항목',
        value: `
          <div>
            <p>이름, 전화번호, 생년월일, 이메일 주소, 패스워드</p>
            <ol class="sub-text-list">
              <li>법조인(판사, 검사, 법무관 등): 직역 유형, 직업, 응시 시험, 시험 회차, 자격 획득 연도</li>
              <li>법률전문직: 직역 유형, 직업, 자격 획득 연도</li>
              <li>공무원(법원, 검찰, 경찰 등): 직업 유형, 직업, 임용 연도</li>
              <li>대학생/대학원생: 직역 유형, 직업, 학교, 학과, 졸업예정연도</li>
              <li>교수: 직역 유형, 직업, 학교, 학과</li>
              <li>연구원: 직역 유형, 직업, 소속 기관</li>
              <li>기타: 직역 유형, 직업</li>
            </ol>
          </div>
        `,
        bold: false,
      },
      {
        label: '처리 목적',
        value:
          '회원 관리, 회원 불만 사항 처리, 재화 또는 서비스 제공, 상품/서비스 개선 및 추천, 고지/안내사항 전달, 상품/서비스 이용 실적 통계/분석',
        bold: false,
      },
      {
        label: '보유 기간',
        value: '서비스 이용 만료시 또는 개인정보 수집·이용 목적 달성시까지',
        bold: true,
      },
    ];
  }
}
