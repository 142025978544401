import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TrackingService } from 'src/app/core/tracking.service';

@Component({
  selector: 'lb-dialog-order',
  templateUrl: './dialog-order.component.html',
  styleUrls: ['./dialog-order.component.sass'],
})
export class DialogOrderComponent {
  @Input()
  orders;

  @Input()
  order;

  @Output()
  orderChange = new EventEmitter();

  constructor(private tracking: TrackingService) {}
  
  onClickOrder(orderType: string) {
    this.tracking.tracking('click_judge_quote_sort', {
      funnel_root_id: this.tracking.getCasePDPFunnelRootId(),
      type: orderType,
    })
  }
}
