<div
  class="popover"
  @enterAnimation
  *ngIf="!popoverClosed && suggestion.nowIndex == index"
  [ngStyle]="style"
>
  <div [innerHTML]="message"></div>
  <button
    mat-flat-button
    class="mt-24"
    trackId="92b3"
    (click)="clickOk()"
    [innerHTML]="okButton"
  ></button>
  <button mat-button class="mt-8" trackId="8288" (click)="closePopover()">
    나중에
  </button>
</div>
