import { Component, Input } from '@angular/core';

@Component({
  selector: 'lb-mobile-header-with-back-button',
  templateUrl: './mobile-header-with-back-button.component.html',
  styleUrls: ['./mobile-header-with-back-button.component.sass'],
})
export class MobileHeaderWithBackButtonComponent {
  @Input()
  name;

  back() {
    history.back();
  }
}
