<div class="title">
  <h5 fxLayout="row">
    <span fxFlex="auto">변호사 신분증 수기 인증</span>
  </h5>
  <div class="mb-12 word-break-keep-all">
    변호사임을 증명할 수 있는 사진을 제출해주세요
  </div>
  <div class="mb-32">
    <div fxLayout="row" fxLayoutAlign="start start" class="mb-6">
      <span class="icon3 mt-2 mr-6 n-49 h-4" fxFlex="none"></span>
      <div>
        인증할 수 있는 사진: 대한변호사협회 신분증, 변호사등록증명원, 변호사시험 합격증명서, 지방변호사회 신분증
      </div>
    </div>
    <div fxLayout="row" fxLayoutAlign="start start" class="mb-6">
      <span class="icon3 mt-2 mr-6 n-49 h-4" fxFlex="none"></span>
      <div>
        성명, 신분증 등록번호, 신분증 발급번호의 확인이 어렵거나 2011년 3월 이전에 발급된 신분증은 반려될 수 있습니다.
      </div>
    </div>
  </div>
  <div>
    <div class="file no-bottom-radius" *ngIf="validFiles?.length">
      <div fxLayout="row" fxLayoutAlign="center center">
        <div fxFlex="auto">
          총 {{ validFiles?.length }}개 항목을 업로드
          <span *ngIf="!done">하는 중 입니다.</span>
          <span *ngIf="done">하였습니다.</span>
        </div>
        <span
          class="icon icon-delete"
          trackId="302a"
          (click)="files = []"
        ></span>
      </div>
      <div class="progress">
        <span
          [style.right]="done ? 0 : ((sum - progressSum) / sum) * 100 + '%'"
        ></span>
      </div>
      <div class="font-12 color-gray">
        <ng-container *ngIf="!done">
          {{ progressSum | fileSize }}/{{ sum | fileSize }}
        </ng-container>
        <ng-container *ngIf="done">완료</ng-container>
      </div>
    </div>

    <lb-upload-area
      [fileType]="'신분증 이미지 파일(JPG, PNG)'"
      *ngIf="!files?.length"
      (filesChange)="fileUpload($event)"
      >업로드하기
    </lb-upload-area>

    <div
      class="files"
      *ngIf="files?.length"
      [class.top-radius]="!validFiles?.length"
    >
      <div
        class="file"
        fxLayout="row"
        *ngFor="let file of files"
        fxLayoutAlign="center center"
      >
        <img
          src="../assets/document.svg"
          style="width: 24px; margin-right: 16px"
        />
        <div fxFlex="auto">
          <div fxLayout="row" fxLayoutAlign="center center">
            <div fxFlex="auto">
              {{ file.file.name }}
            </div>
            <span
              class="icon icon-delete"
              fxFlex="none"
              trackId="3e36"
              (click)="files.remove(file)"
            ></span>
          </div>
          <div class="progress">
            <span
              [style.right]="
                file.done
                  ? 0
                  : ((file.file.size - file.progressSize) / file.file.size) *
                      100 +
                    '%'
              "
            ></span>
          </div>
          <div class="font-12 color-warn" *ngIf="file.error">
            {{ file.error }}
          </div>
          <div class="font-12 color-gray" *ngIf="!file.error">
            <ng-container *ngIf="!file.done">
              {{ file.progressSize | fileSize }}/{{
                file.file.size | fileSize
              }}>
            </ng-container>
            <ng-container *ngIf="file.done">완료</ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
  <mat-checkbox class="mt-24" [(ngModel)]="agree">
    엘박스 개인정보 처리방침에 동의합니다.
    <span
      class="color-primary ml-8"
      (click)="$event.preventDefault(); $event.stopPropagation()"
      trackId="fa02"
      lbNewTabLink="/rule/private"
      >내용보기</span
    >
  </mat-checkbox>
</div>
<div class="buttons mt-0">
  <button class="cancel" (click)="close(null)">취소</button>
  <button
    class="blue"
    (click)="verifyRequest()"
    [class.disabled]="!done || !validFiles.length || !agree"
  >
    변호사 인증하기
  </button>
</div>
