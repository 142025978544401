<div class="recommend">
  <div class="container">
    <h1>엘박스 회원들의 생생한 추천</h1>
    <div class="left-arrow" trackId="b48c"  (click)="carousel.prev()">
      <span class="icon2 n-32 invert"></span>
    </div>
    <div class="right-arrow" trackId="c860"  (click)="carousel.next()">
      <span class="icon2 n-32"></span>
    </div>
    <div class="carousel">
      <owl-carousel-o #carousel [options]="customOptions">
        <ng-template carouselSlide>
          <div class="review">
            <div>
              “판례 내용을 편하게 볼 수 있고 밑줄 치기 등 부가적인 기능들도 너무
              맘에 들어요! 그리고 알람기능도요 ㅠㅠ 최고입니다!”<br /><b
                >김○○ 대학생</b
              >
            </div>
          </div>
        </ng-template>
        <ng-template carouselSlide>
          <div class="review">
            <div>
              “하급심 판례가 아주 많고, 기일 관리 프로그램도 좋습니다. 기본적인
              업무 프로그램으로 활용하면 큰 도움이 될 것 같습니다.”<br /><b
                >장○○ 변호사</b
              >
            </div>
          </div>
        </ng-template>
        <ng-template carouselSlide>
          <div class="review">
            <div>
              “판결에 관한 한 현재 최고의 서비스라고 생각한다.”<br /><b
                >임○○ 법과대학 교수</b
              >
            </div>
          </div>
        </ng-template>
        <ng-template carouselSlide>
          <div class="review">
            <div>
              “하급심부터 상급심까지 손쉽게 접근 가능. 참조판례, 따름판례 확인이
              편함. 다양한 검색 옵션, 많은 판례, 미등록 판례 요청 기능 등.”<br /><b
                >류○○ 공인노무사</b
              >
            </div>
          </div>
        </ng-template>
        <ng-template carouselSlide>
          <div class="review">
            <div>“사용자 친화적, 디자인 깔끔.”<br /><b>김○○ 변호사</b></div>
          </div>
        </ng-template>
        <ng-template carouselSlide>
          <div class="review">
            <div>
              “탁월하고 간편한 검색방법과 다양한 판례보유가 으뜸입니다.”<br /><b
                >유○○ 손해사정사</b
              >
            </div>
          </div>
        </ng-template>
        <ng-template carouselSlide>
          <div class="review">
            <div>
              “지금껏 여러 싸이트를 사용했지만, 이렇게 사용자 중심에서
              자료제공을 해주는곳은 없었어요.. 최고.”<br /><b
                >지○○ 손해사정사</b
              >
            </div>
          </div>
        </ng-template>
        <ng-template carouselSlide>
          <div class="review">
            <div>
              “디자인이 깔끔하고 예쁘다 / 1심 판례들이 많다 / 유저들에게
              주기적으로 의견을 묻는 등 제작자들의 적극적인 모습이 보기
              좋다.”<br /><b>김○○ 변호사</b>
            </div>
          </div>
        </ng-template>
        <ng-template carouselSlide>
          <div class="review">
            <div>
              “따름 판례를 볼 수 있고, 심급별 결과 추적이 상대적으로 용이하며,
              필요한 판결문을 신속하게 입수해 줍니다.”<br /><b>안○○ 변호사</b>
            </div>
          </div>
        </ng-template>
      </owl-carousel-o>
    </div>
  </div>
</div>
