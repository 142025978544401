import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'maxLength',
})
export class MaxLengthPipe implements PipeTransform {
  transform(value: string, ...args: number[]): unknown {
    if (!value) {
      return;
    }
    if (value.length > args[0]) {
      return value.substring(0, args[0]) + '...';
    }
    return value;
  }
}
