import { AfterViewInit, Directive, ElementRef } from '@angular/core';
import { ScriptService } from 'ngx-script-loader';
import { firstValueFrom } from 'rxjs';

declare var autosize;

@Directive({
  selector: '[autosize]',
})
export class AutosizeDirective implements AfterViewInit {
  constructor(private elf: ElementRef, private scriptService: ScriptService) {}

  ngAfterViewInit(): void {
    setTimeout(() => {
      if (typeof autosize !== 'undefined') {
        autosize(this.elf.nativeElement);
        autosize.update(this.elf.nativeElement);
        return;
      }
      firstValueFrom(this.scriptService
        .loadScript('/assets/autosize.min.js'))
        .then(() => {
          autosize(this.elf.nativeElement);
          autosize.update(this.elf.nativeElement);
        });
    });
  }
}
