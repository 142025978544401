import { AfterViewInit, Component, NgZone, ViewChild } from '@angular/core';
import { ScriptService } from 'ngx-script-loader';
import { getParameterByName } from '../../core/util';
import { Router } from '@angular/router';
import { ApiService } from '../../../../ngxSpring/api.service';
import { firstValueFrom } from 'rxjs';

declare var grecaptcha;

@Component({
  selector: 'lb-recaptcha',
  templateUrl: './recaptcha.component.html',
  styleUrls: ['./recaptcha.component.sass'],
})
export class RecaptchaComponent implements AfterViewInit {
  @ViewChild('captcha')
  captcha;

  constructor(
    private scriptService: ScriptService,
    private router: Router,
    private zone: NgZone,
    private api: ApiService,
  ) {}

  ngAfterViewInit(): void {
    firstValueFrom(
      this.scriptService.loadScript('https://www.google.com/recaptcha/api.js'),
    ).then(() => {
      grecaptcha.ready(() => {
        grecaptcha.render(this.captcha.nativeElement, {
          sitekey: '6LegCKMZAAAAAMyE2QpZw_kxIy9pOgcPDnCJuEyG',
          callback: (res) => {
            const path = getParameterByName('from');
            const url =
              !path || path.includes('recaptcha')
                ? '/'
                : path.replace(location.origin, '');
            if (location.pathname.endsWith('robot')) {
              location.href = `/recaptcha/release?token=${res}&from=${url}`;
              return;
            }
            this.api.requestRecord.refreshRecord(res).subscribe(() => {
              this.zone.run(() => {
                this.router.navigateByUrl(url);
              });
            });
          },
        });
      });
    });
  }
}
