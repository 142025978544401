<div class="title">
  <h5 class="mb-24">내 판례등록 이용안내</h5>
  <div class="font-weight-500 mb-16">신청절차</div>
  <ol>
    <li>등록 신청할 판례의 pdf 파일을 업로드 해주세요.</li>
    <li>
      모든 개인정보에 대한 익명화가 진행되며, 익명화가 완료되면
      카카오톡/이메일로 알림을 드립니다. (최소 5영업일 이상 소요)
    </li>
    <li>
      ‘내 메뉴 > 내 판례등록’ 페이지를 통해
      <b>익명화 결과를 확인 후, 승인해주시면</b> 판례가 최종 등록됩니다.
    </li>
  </ol>
  <div class="divider mb-24 mt-24"></div>
  <div class="font-weight-500 mb-16">유의사항</div>
  <div fxLayout="row" class="mb-12">
    <div fxFlex="none">
      <span class="icon3 n-49 h-4 small mt--4 mr-4"></span>
    </div>
    <div>
      변호사님과 소송대리인/변호인 정보가 일치하는 경우만 등록 가능합니다.
    </div>
  </div>
  <div fxLayout="row" class="mb-12">
    <div fxFlex="none">
      <span class="icon3 n-49 h-4 small mt--4 mr-4"></span>
    </div>
    <div>
      판결문 정·등본이나 대법원 전자소송사이트에서 다운로드한 판결문만 등록
      가능합니다(지급명령 등록 불가).
    </div>
  </div>
  <div fxLayout="row">
    <div fxFlex="none">
      <span class="icon3 n-49 h-4 small mt--4 mr-4"></span>
    </div>
    <div>
      <div class="mb-8">
        아래의 경우에는 <span class="color-warn">등록 불가</span>합니다.
      </div>
      <div>
        <div class="mb-8">· 판결문 내용을 임의로 수정한 경우</div>
        <div class="mb-8">· 판결문에 워터마크가 있는 경우</div>
        · 판결문을 카메라로 찍어서 올린 경우
      </div>
    </div>
  </div>
  <div class="mb-12 mt-48">
    기타 궁금한 점은 <span zendesk class="color-primary">고객센터</span>를 통해
    실시간으로 문의하실 수 있습니다.
  </div>
</div>
<div class="buttons">
  <button class="blue" trackId="a079" (click)="close(true)">확인</button>
</div>
