import { Directive, HostListener, Input } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { BottomSheetTemplateComponent } from './bottom-sheet-template/bottom-sheet-template.component';

@Directive({
  exportAs: 'trigger',
  selector: '[menuOrBottomSheet]',
})
export class MenuOrBottomSheetDirective extends MatMenuTrigger {
  @Input()
  set menuOrBottomSheet(m) {
    this.menu = m;
  }

  _handleClick(event: MouseEvent) {}

  // openMenu() {

  // }
}

@Directive({
  selector: '[opener]',
})
export class OpenerDirective {
  constructor(
    private matMenu: MenuOrBottomSheetDirective,
    private bottomSheet: MatBottomSheet,
  ) {}

  @HostListener('click')
  click() {
    if (window.innerWidth > 768) {
      this.matMenu.toggleMenu();
      return;
    }
    this.bottomSheet?.open(BottomSheetTemplateComponent, {
      panelClass: 'bottomMenu',
      data: this.matMenu.menu.templateRef,
    });
  }
}
