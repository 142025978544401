import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterBy',
})
export class FilterByPipe implements PipeTransform {
  transform(values: any[], keyword, prop): any[] {
    if (!keyword || !values) return values;
    return values.filter((it) => it[prop]?.match(keyword));
  }
}
