const PAGE_PATHNAME_WITHOUT_HEADER = [
  '/user/login',
  '/',
  '/user/login/naver',
  '/user/register',
  '/not_found',
  '/register',
  '/casemapwrapper',
  '/admin',
  '/recaptcha',
  '/org',
];

const PAGE_PATHNAME_WITHOUT_MY_REGISTER_MODAL = ['/user/login/naver', '/'];

export const PAGE_PATHNAME_WITHOUT_REACT_COMPONENT = Array.from(
  new Set([
    ...PAGE_PATHNAME_WITHOUT_HEADER,
    ...PAGE_PATHNAME_WITHOUT_MY_REGISTER_MODAL,
  ]),
);

export const CUSTOM_EVENT_NAME = {
  openMembershipNudgingModal: 'open-membership-nudging-modal',
  closeMembershipNudgingModal: 'close-membership-nudging-modal',
};
