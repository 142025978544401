import { Directive, HostListener, Input } from '@angular/core';
import { AlertService } from '../alert/alert.service';

@Directive({
  selector: '[lbMyCaseRegisterEventAlert]',
})
export class MyCaseRegisterEventAlertDirective {
  constructor(private alert: AlertService) {}

  @Input()
  tip;

  @HostListener('click')
  click() {
    this.tip?.hide();
    this.alert
      .alert(
        '내 판례등록 이벤트',
        null,
        `<div class="mb-32">직접 담당했던 판례를 등록해주실 때마다 1건 당 5,000 포인트를 지급해드립니다.</div>
<h5 class="mb-16">참여방법</h5>
<div class="mb-16 flex-row"><span class="mr-4">1.</span> <span>등록 신청할 판례의 pdf 파일을 업로드 해주세요.</span></div>
<div class="mb-16 flex-row"><span class="mr-4">2.</span> <span>모든 개인정보에 대한 익명화가 진행되며, 익명화가 완료되면 카카오톡/이메일로 알림을 드립니다.</span></div>
<div class="mb-16 flex-row"><span class="mr-4">3.</span> <span>‘내 메뉴 > 내 판례등록’ 페이지를 통해 익명화 결과를 확인 후 최종 승인해주세요.</span></div>
<div class="mb-24 flex-row"><span class="mr-4">4.</span> <span>판례가 최종 등록되면 포인트가 지급됩니다.</span></div>
<div style="border-bottom: 1px solid #d8d8d8;margin-bottom: 24px"></div>
<h5 class="mb-16">유의사항</h5>
<div class="mb-16"><span class="icon3 n-49 h-4 small mt--4 mr-4"></span> 변호사님과 소송대리인/변호인 정보가 일치하는 경우만 등록 가능합니다.</div>
<div class="mb-16"><span class="icon3 n-49 h-4 small mt--4 mr-4"></span> 판결문 정본이나 대법원 전자소송사이트에서 다운로드한 판결문만 등록 가능합니다.</div>
<div class="mb-16"><span class="icon3 n-49 h-4 small mt--4 mr-4"></span> 아래의 경우에는 <span class="color-warn">등록 불가</span>합니다.
 <div class="ml-16">
 <div class="mb-8 mt-8">· 판결문 내용을 임의로 수정한 경우</div>
 <div class="mb-8">· 판결문에 워터마크가 있는 경우</div>
         · 판결문을 카메라로 찍어서 올린 경우
 </div>
</div>
<div class="mb-24"><span class="icon3 n-49 h-4 small mt--4 mr-4"></span> 본 이벤트는 당사 사정에 따라 변경 또는 조기 종료될 수 있습니다.</div>`,
        null,
        { maxWidth: '620px' },
        true,
      )
      .then(() => this.tip?.show());
  }
}
