/* tslint:disable */
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ApiHttp} from '../api.http';

import {TrackId} from '../api.model';

@Injectable()
export class TrackIdController {
  constructor(private http: ApiHttp) {
  }

  deleteId(id: string, name: string): Observable<TrackId> {
    return this.http.delete<TrackId>('/api/trackId', {id: id, name: name});
  }

  get(trackId: string): Observable<TrackId> {
    return this.http.get<TrackId>('/api/trackId/detail', {trackId: trackId});
  }

  getTrackIdMap(): Observable<any> {
    return this.http.get<any>('/api/trackId');
  }

  getTrackNames(): Observable<string[]> {
    return this.http.get<string[]>('/api/trackId/names');
  }

  newTrack(name: string): Observable<void> {
    return this.http.post<void>('/api/trackId/name', null, {name: name});
  }

  saveTrackId(id: string, name: string, data?: any): Observable<TrackId> {
    return this.http.post<TrackId>('/api/trackId', data, {id: id, name: name});
  }
}
