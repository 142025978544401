import {
  Case,
  CaseAndRelations,
  OrderType,
  Section,
  SnsAuthType,
} from '../../../ngxSpring/api.model';
import {
  civilCourtsForCaseSearchFilterOnly,
  highCourts,
} from '../search/dialogs/case-request-dialog/courts';
import * as moment from 'moment';
import { NEW_LBOX_PREFIX } from 'src/consts';

export function docIdByCourtAndCaseNo(court, caseNo) {
  return (
    court.replace(/ /g, '').replace(/\(/g, '').replace(/\)/g, '') + '-' + caseNo
  );
}

export function mapOf(keyValues: { key: string; value: string }[]) {
  const result = {};
  keyValues.forEach((it) => {
    result[it.key] = it.value;
  });
  return result;
}

export function getParameterByName(name, url = location.href) {
  name = name.replace(/[\[\]]/g, '\\$&');

  const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)');
  const results = regex.exec(url);

  if (!results) {
    return null;
  }

  if (!results[2]) {
    return '';
  }

  return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

/**
 * 'from' query param 값을 보고, 해당 url 로 돌아가도록 하는 로직
 */
export function navigateToFromOr(router) {
  const from = getParameterByName('from');
  if (from && !from?.match('user/login')) {
    try {
      // 이전 페이지가 React 앱인 경우 예외 처리
      const LBOX_REACT_DEV_ADDRESS = 'localhost:3000/';
      const LBOX_REACT_PROD_ADDRESS = `lbox.kr/${NEW_LBOX_PREFIX}`;
      if (
        from.includes(LBOX_REACT_DEV_ADDRESS) ||
        from.includes(LBOX_REACT_PROD_ADDRESS)
      ) {
        // React 앱으로 이동하는 경우, Angular 로그인 후처리에 필요한 시간 확보
        setTimeout(() => {
          window.location.assign(from);
        }, 500);
        return;
      }
      // 이전 페이지가 Angular 앱인 경우
      // 페이지를 replace 하는 경우 header component를 불러오지 못해서 location을 할당하도록 수정
      window.location.assign(from);
    } catch (e) {
      router.navigateByUrl('/' + (isLworks() ? '/work/main' : ''));
    }
    return;
  }
  router.navigateByUrl('/' + (isLworks() ? '/work/main' : ''));
}

export function extractCaseNum(caseId): string {
  return caseId.split('-').slice(1).join('-');
}

export function extractCourt(caseId): string {
  return caseId.split('-')[0];
}

export function toQueryString(query: {
  keyword: string;
  excludes: string[];
}): string {
  if (!query.excludes || !query.excludes.length) {
    return query.keyword;
  }
  return query.keyword + ' ' + query.excludes.map((it) => '-' + it).join(' ');
}

export function isWhale() {
  return navigator.userAgent?.toLowerCase()?.match('whale');
}

export function isInAppBrowser() {
  return navigator.userAgent.match(
    /inapp|NAVER|KAKAOTALK|Snapchat|Line|WirtschaftsWoche|Thunderbird|Instagram|everytimeApp|WhatsApp|Electron|wadiz|AliApp|zumapp|iPhone(.*)Whale|Android(.*)Whale|kakaostory|band|twitter|DaumApps|DaumDevice\/mobile|FB_IAB|FB4A|FBAN|FBIOS|FBSS|SamsungBrowser\/[^1]/i,
  );
}

export function isSafari() {
  return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
}

export function isEdge() {
  return (navigator as any)?.userAgentData?.brands?.find(
    (it) => it.brand == 'Microsoft Edge',
  );
}

export function isChrome() {
  return (
    /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor)
  );
}

export function isIOS() {
  return (
    [
      'iPad Simulator',
      'iPhone Simulator',
      'iPod Simulator',
      'iPad',
      'iPhone',
      'iPod',
    ].includes(navigator.platform) ||
    (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
  );
}

export function isMobile() {
  let check = false;
  (function (a) {
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
        a,
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
        a?.substring(0, 4),
      )
    ) {
      check = true;
    }
  })(navigator.userAgent || navigator.vendor || (window as any).opera);
  return check;
}

export function isMobileOrTablet() {
  return isMobile() || isTablet();
}

export function isTablet() {
  if (
    navigator.userAgent.match(/Mac/) &&
    navigator.maxTouchPoints &&
    navigator.maxTouchPoints > 2
  ) {
    return true;
  }
  const userAgent = navigator.userAgent.toLowerCase();
  return /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(
    userAgent,
  );
}

export function splitSections(sections: Section[]): {
  beforeList: Section[];
  mainList: Section[];
  afterList: Section[];
} {
  const sectionList = [];
  sectionList.pushAll(sections);
  let sectionStart = sectionList.find((it) => it.type === 'start');
  if (!sectionStart) {
    sectionStart = sectionList.find((it) => it.title === '주문');
  }
  if (!sectionStart) {
    sectionStart = sectionList.find((it) => it.title === '이유');
  }
  if (!sectionStart) {
    return { beforeList: [], mainList: sectionList, afterList: [] };
  }
  const beforeList = sectionList.splice(0, sectionList.indexOf(sectionStart));
  let lastSection = sectionList.find((it) => it.type == 'last');
  if (!lastSection) {
    lastSection = sectionList.find((it) => it.title == '이유');
  }
  const mainList = sectionList.splice(
    0,
    lastSection ? sectionList.indexOf(lastSection) + 1 : sectionList.length,
  );
  const afterList = sectionList;
  return { beforeList, mainList, afterList };
}

export function extractListData(caseData: Case, key: 'footnote' | 'images') {
  const footnotes = caseData[key] ? caseData[key] : {};
  return Object.keys(footnotes).map((key) => {
    return { key: key, value: footnotes[key] };
  });
}

export function wait(m?) {
  return new Promise<void>((r) => {
    setTimeout(() => r(), m);
  });
}

export function getFormatted(text) {
  const formats = [];
  ['아래첨자', '위첨자', '강조점', '취소선', '밑줄', '굵게'].forEach((it) => {
    function extractRegex(r) {
      const regex = new RegExp(`${it}${r}`, 'g');
      let result;
      while ((result = regex.exec(text)) !== null) {
        const startIndex = result.index;
        formats.push({
          span: [startIndex, startIndex + it.length + 1],
          type: 'hidden',
        });
        formats.push({
          span: [
            startIndex + it.length + 1 + result[1].length,
            startIndex + it.length + 2 + result[1].length,
          ],
          type: 'hidden',
        });
        formats.push({
          span: [
            startIndex + it.length + 1,
            startIndex + it.length + 1 + result[1].length,
          ],
          type: it,
        });
      }
    }

    extractRegex('\\[(.+?)\\]');
    extractRegex('\\((.+?)\\)');
    extractRegex('\\{(.+?)\\}');
  });
  return formats;
}

let index = 0;

export function getCaseTextByIndex(hl, text) {
  index++;
  hl.tempIndex = index;
  const start = hl.span[0];
  const end = hl.span[1];
  setTimeout(() => {
    hl.index = hl.tempIndex;
  });
  return { text: text.slice(start, end), index: index };
}

export function getHighlightTexts(lbCase: Case, highlights: any) {
  index = 0;
  const { beforeList, mainList, afterList } = splitSections(
    lbCase.section_list,
  );
  return [
    ['summary', 'issue'].map((it) => {
      return highlights[it].map((hl) => {
        return { origin: lbCase[it], highlight: hl };
      });
    }),
    sectionListToHighlightTexts(beforeList),
    sectionListToHighlightTexts(mainList),
    ['judges'].map((it) => {
      return highlights[it].map((hl) => {
        return { origin: lbCase[it], highlight: hl };
      });
    }),
    sectionListToHighlightTexts(afterList),
  ]
    .flatten()
    .flatten();

  function sectionListToHighlightTexts(list: Section[]) {
    return list
      .map((section) =>
        section.refList
          .filter((it) => it.type == 'myHighlight')
          .sort((a, b) => a.span[0] - b.span[0])
          .map((it) => {
            return { origin: section.text, highlight: it };
          }),
      )
      .filter((it) => it.length);
  }
}

export function extractMatchDataFromQuery(query) {
  if (!query) {
    return;
  }
  let matcher = query.match(getCourtAndCaseNoRegex());
  if (matcher) {
    return { caseId: matcher[2], court: matcher[1] };
  }
  matcher = query.match(getCaseNoRegex());
  if (matcher) {
    return { caseId: matcher[1] + (matcher[4] || '') };
  }
}

export function existDesc(
  message,
  type?: SnsAuthType,
  email?,
  snsRecommend = true,
  info = null,
) {
  let desc;
  switch (type) {
    case 'KAKAO':
      desc = '[소셜 가입: 카카오톡]';
      break;
    case 'NAVER':
      desc = '[소셜 가입: 네이버]';
      break;
    case 'GOOGLE':
      desc = '[소셜 가입: 구글]';
      break;
    default:
      desc = `[이메일 가입: ${email}]`;
      break;
  }
  if (info) desc += `<br>[계정: ${info}]`;

  let descText =
    "<div class='exist-desc'>" +
    message +
    '<br><b>' +
    desc +
    '</b>' +
    '<br><br>위 계정으로 로그인하시겠습니까?</div>';

  if (!snsRecommend)
    descText +=
      "<div class='exist-desc-desc'>엘박스 운영정책에 따라 계정은 이용자 1명당 1개만 허용됩니다." +
      '<br>계정 변경을 원하시면 회원탈퇴 후 원하는 계정으로 재가입해야 합니다.' +
      "<br>이와 관련하여 궁금한 사항은 <a onclick='zendesk()'>고객센터</a>를 통해 문의하여 주시기 바랍니다.</div>";
  return descText;
}

export function isLworks() {
  return (
    (isProduction() ||
      location.host == 'lworks.kr' ||
      location.host == 'dev.lworks.kr') &&
    location.pathname.startsWith('/work')
  );
}

export function isProduction() {
  return PRODUCTION_URLS.indexOf(location.host) !== -1;
}

export const PRODUCTION_URLS = [
  'lbox.kr',
  'non-profit.lbox.kr',
  'non-profit-police.lbox.kr',
  'public.lbox.kr',
];

export function loginUrl() {
  if (isLworks()) return '/work';
  return `/user/login?from=${encodeURIComponent(location.href)}`;
}

export function getCaseNoRegex() {
  return /(((?:20|42|43|49)\d{2}|([6-9]\d))(?!조의)[가-힣]{1,3}\d{1,7})(\-\d{1,2})?/;
}

export function getCourtAndCaseNoRegex() {
  return /([가-힣]{1,16})[- ](((?:20|42|43|49)\d{2}|([6-9]\d))(?!조의)[가-힣]{1,3}\d{1,7})(\-\d{1,2})?/;
}

export const defaultOrders: OrderType[] = [
  'SCORE_DESC',
  'RECENT_DESC',
  'VIEWS_DESC',
  'CITED_DESC',
];

const constitute: any = {
  value: '헌법재판소',
  name: '헌법재판소',
};

export const courtOption = {
  mobileName: '법원 필터',
  multiple: true,
  type: {
    name: '법원',
    value: 'courtType',
    icon: 'n-90',
  },
  types: [
    { value: '대법원', name: '대법원' },
    {
      value: '고등법원',
      name: '고등/특허법원',
      subOptions: [{ name: '고등/특허법원', options: highCourts }],
    },
    {
      value: '지방법원',
      name: '지방법원',
      subOptions: [
        { name: '지방법원', options: civilCourtsForCaseSearchFilterOnly },
      ],
    },
    constitute,
  ],
};

export const trialTypeOption = {
  mobileName: '재판유형',
  type: {
    name: '재판유형',
    value: 'trialType',
    icon: 'n-89',
  },
  types: [
    { name: '전체', value: null },
    { name: '판결', value: '판결' },
    { name: '결정', value: '결정' },
  ],
};

export const day = 24 * 1000 * 60 * 60;

export const later = 7;

export function deleteAllCookies() {
  if (!document.cookie) {
    return;
  }
  const cookies = document.cookie.split(';');
  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i];
    const eqPos = cookie.indexOf('=');
    const name = eqPos > -1 ? cookie.substring(0, eqPos) : cookie;
    document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/';
  }
}

export function getCookie(name) {
  if (typeof document === 'undefined') {
    return;
  }
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(';').shift();
}

export function toDownloadName(data: CaseAndRelations): string {
  if (data.case.doc_id) {
    return `${data.case.doc_id}.pdf`
  }
  
  if (data.case.title) {
    return `${data.case.title}.pdf`
  }

  if (data.case.sentence_type == '결정') {
    return `${data.case.court} ${moment(data.case.announce_date).format(
      'yyyy. M. d.',
    )}자 ${data.case.caseno_list[0]} 결정.pdf`;
  }

  return `${data.case.court} ${moment(data.case.announce_date).format(
    'yyyy. M. d.',
  )} 선고 ${data.case.caseno_list[0]} 판결.pdf`;
}

export function getIndicesOf(targetStr, searchStr) {
  const searchStrLen = searchStr.length;

  if (searchStrLen === 0) {
    return [];
  }

  let startIndex = 0;
  let index;

  const indices = [];
  while ((index = targetStr.indexOf(searchStr, startIndex)) > -1) {
    indices.push(index);
    startIndex = index + searchStrLen;
  }

  return indices;
}

export function caseLink(docId: string) {
  return '/case/' + docId.replace('-', '/');
}

export function uuid() {
  function s4() {
    return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
  }

  return (
    s4() +
    s4() +
    '-' +
    s4() +
    '-' +
    s4() +
    '-' +
    s4() +
    '-' +
    s4() +
    s4() +
    s4()
  );
}

export function getLBoxNextClientURL() {
  if (window.location.host === 'localhost:4200') {
    return `http://localhost:3000/${NEW_LBOX_PREFIX}`;
  } else {
    return window.location.origin + `/${NEW_LBOX_PREFIX}`;
  }
}
