import { Component, HostListener, Input, OnInit } from '@angular/core';

@Component({
  selector: 'lb-main-chart',
  templateUrl: './main-chart.component.html',
  styleUrls: ['./main-chart.component.sass'],
})
export class MainChartComponent implements OnInit {
  @Input()
  unit = 20;

  max;

  labels;

  height = 130;

  @Input()
  title;

  @Input()
  values = [];

  constructor() {}

  @HostListener('window:resize')
  onResize() {
    this.height = window.innerWidth > 959 ? 300 : 130;
  }

  parsePx(number: number) {
    return (number / this.max) * this.height + 'px';
  }

  ngOnInit(): void {
    this.labels = [];
    this.height = window.innerWidth > 959 ? 300 : 130;
    this.max = Math.max.apply(null, this.values);
    while (true) {
      if (this.labels.last() >= this.max) {
        this.max = this.labels.last();
        return;
      }
      this.labels.push((this.labels.last() || 0) + this.unit);
    }
  }
}
