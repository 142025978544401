export const tokenName = 'lboxToken';

// 판례 디테일 페이지
export const copyTextOptionLocalStorageKey = 'copyTextWithSource';

// Next.js base url prefix
export const NEW_LBOX_PREFIX = 'v2';

export const V2_PAGE_PATH = {
  root: `/${NEW_LBOX_PREFIX}`,
  legalAnalytics: {
    caseAnalytics: '/legal-analytics/case-analytics',
  },
  user: {
    myCase: '/user/myCase',
  },
  /** 변호사 프로필 */
  lawyerProfile: {
    /** 내 프로필 관리 */
    index: '/user/profile',
    /** 내 수행사건 추가 */
    myCases: '/user/profile/cases',
    /** 프로필 사전등록 이벤트 소개 */
    profileEvent: '/user/profile-event',
    /** 프로필 사전등록 온보딩 */
    profileOnboarding: '/user/profile-onboarding',
  },
  /** 요금제 */
  pricing: {
    /** 요금제 페이지 */
    index: '/pricing',
    standard: {
      /** 스탠다드 플랜 결제하기 */
      pay: '/pricing/standard/pay',
    },
    business: {
      /** 비즈니스 플랜 문의하기 */
      contact: '/pricing/business/contact',
      /** 비즈니스 플랜 견적 보기 */
      quotationContact: '/pricing/business/quotation-contact',
    },
    public: {
      /** 퍼블릭 플랜 문의하기 */
      contact: '/pricing/public/contact',
    },
  },
  /** 회원가입 */
  register: {
    index: '/register',
  },
  /** 로그인 */
  login: {
    index: '/login',
  },
} as const;
