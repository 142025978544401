import { EventEmitter, Injectable, Injector } from '@angular/core';
import { ArchiveFolder } from '../../../ngxSpring/api.model';
import { AlertService } from '../alert/alert.service';
import { ArchiveDialogComponent } from '../shared/archive-dialog/archive-dialog.component';
import { FolderService } from '../bookmark/folder.service';
import { ApiService } from '../../../ngxSpring/api.service';
import { Router } from '@angular/router';
import { CopyService } from '../core/copy.service';
import { firstValueFrom } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { toDownloadName } from '../core/util';

declare let saveAs;

export interface ITabIndex {
  MEMO: 0;
  HIGHLIGHT: 1;
}

export type TabIndexNum = ITabIndex[keyof ITabIndex];

@Injectable()
export class CaseSelectService {
  selectedCase: any;
  selectedFolder: ArchiveFolder;
  highlights;
  tab;
  tabIndex: ITabIndex;
  highlightChanged = new EventEmitter();
  caseMoved = new EventEmitter();

  constructor(
    private alert: AlertService,
    private api: ApiService,
    private injector: Injector,
    private http: HttpClient,
  ) {
    this.tabIndex = { MEMO: 0, HIGHLIGHT: 1 };
  }

  get empty() {
    return !this.selectedCase && !this.selectedFolder;
  }

  selectCase(aCase, tab: TabIndexNum = this.tabIndex.MEMO) {
    this.selectedFolder = null;
    this.selectedCase = aCase;
    this.tab = tab;
  }

  selectFolder(folder: ArchiveFolder) {
    this.selectedCase = null;
    this.selectedFolder = folder;
  }

  setEmpty() {
    this.selectedFolder = null;
    this.selectedCase = null;
  }

  moveTo(record: any) {
    void this.injector.get(Router).navigateByUrl('/' + record.caseId);
  }

  copyUrl(requestCase) {
    this.injector
      .get(CopyService)
      .copy(
        location.origin + '/' + requestCase.caseId,
        '판례 URL이 클립보드에 복사되었습니다.',
      );
  }

  async archive(requestCase) {
    if (!requestCase.caseData) {
      requestCase.caseData = await firstValueFrom(
        this.api.lbCase.get(requestCase.caseId),
      );
    }

    const folders = await firstValueFrom(
      this.api.archiveFolder.getArchiveFolderList(
        null,
        true,
        requestCase.caseId,
      ),
    );

    const containFolders = folders.filter((it) => it.contains);
    if (containFolders.length) {
      if (
        !(await this.alert.confirm(
          `<span color="primary">${containFolders
            .map((it) => it.name || '미분류')
            .join(
              ', ',
            )}</span> 폴더에 이미 저장된 판례입니다. 다른 폴더에 중복으로 저장하시겠습니까?`,
        ))
      ) {
        return;
      }
    }

    void this.alert.open(ArchiveDialogComponent, {
      folders: folders.filter((it) => it.id),
      case: requestCase.caseData.case,
      folderService: this.injector.get(FolderService),
    });
  }

  async savePdf(requestCase) {
    if (!requestCase.caseData) {
      requestCase.caseData = await firstValueFrom(
        this.api.lbCase.get(requestCase.caseId),
      );
    }
    const data = requestCase.caseData;

    this.api.readCase
      .setActionFlag(data.case.doc_id, false, false, false, true)
      .subscribe();
    const params = {
      key: requestCase.caseId,
      docId: data.case.doc_id,
    };

    this.http
      .post('/api/case/download', params, {
        params: { docId: requestCase.caseId },
        responseType: 'blob',
      })
      .subscribe((v) => {
        saveAs(v, toDownloadName(data));
      });
  }

  move(folderOrCase: any, folder: any) {
    if (folder !== null && !folder) return;
    switch (folderOrCase.type) {
      case 'folder':
        if (folderOrCase.folder?.parent == folder?.id) return;
        if (folderOrCase.folder?.id == folder?.id) return;
        this.api.archiveFolder
          .moveFolder(folderOrCase.folder.id, folder?.id)
          .subscribe(() => {
            this.injector
              .get(FolderService)
              .folderRemoved.emit(folderOrCase.folder);
            this.injector.get(FolderService).folderUpdated.emit(folder);
            const originFolderId = folderOrCase.folder.parent;
            folderOrCase.folder.parent = folder?.id;
            this.alert
              .pop('폴더를 이동했습니다.', 5000, '실행취소')
              .subscribe(() => {
                this.api.archiveFolder
                  .moveFolder(folderOrCase.folder.id, originFolderId)
                  .subscribe(() => {
                    this.injector
                      .get(FolderService)
                      .folderUpdated.emit(
                        originFolderId ? { id: originFolderId } : null,
                      );
                    this.injector
                      .get(FolderService)
                      .folderUpdated.emit(
                        folder?.id ? { id: folder?.id } : null,
                      );
                    folderOrCase.folder.parent = originFolderId;
                  });
              });
          });
        break;
      case 'case':
        if (folderOrCase.case?.folderId == folder?.id) return;
        this.api.archiveCase
          .updateFolder(folderOrCase.case.id, folder?.id)
          .subscribe(() => {
            this.caseMoved.emit({
              folder,
              case: folderOrCase.case,
              type: 'removed',
            });
            const originFolderId = folderOrCase.case.folderId;
            folderOrCase.case.folderId = folder?.id;
            this.alert
              .pop('판례를 이동했습니다.', 5000, '실행취소')
              .subscribe(() => {
                this.api.archiveCase
                  .updateFolder(folderOrCase.case.id, originFolderId)
                  .subscribe(() => {
                    this.caseMoved.emit({
                      folder,
                      case: folderOrCase.case,
                      type: 'added',
                    });
                    folderOrCase.case.folderId = originFolderId;
                  });
              });
          });
        break;
    }
  }
}
