import { EventEmitter, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AlertService } from '../alert/alert.service';
import { ApiService } from '../../../ngxSpring/api.service';
import { Location } from '@angular/common';
import { QueryElement, SearchQuery } from '../../../ngxSpring/api.model';
import { commands } from '../core/command';
import { SearchWithLawComponent } from '../shared/search-with-law/search-with-law.component';
import { UserService } from '../user/user.service';
import { SearchDialogComponent } from '../core/search-dialog/search-dialog.component';
import { queryCorrection } from '../core/error-quries';
import { HistoryService } from '../core/history.service';
import { CategoryType, typePath } from './search-result/categories';

@Injectable({ providedIn: 'root' })
export class SearchService {
  correctedQuery;
  searchResultScroll;
  searchQuery: any = {};
  type: CategoryType;
  queryElements: QueryElement[];
  isSearchingWithoutCorrection = false;
  typeChanged = new EventEmitter();

  get commands() {
    return commands.filter(
      (it) => !it.supports || it.supports?.includes(this.type || 'case'),
    );
  }

  reset() {
    this.searchQuery = {};
  }

  constructor(
    private router: Router,
    private alert: AlertService,
    private api: ApiService,
    private location: Location,
    private user: UserService,
    public historyService: HistoryService,
  ) {}

  setDataBySearchQuery(searchQuery, rawQuery) {
    searchQuery.pureQuery = searchQuery.query;
    searchQuery.query = rawQuery;
    this.queryElements = searchQuery.queryElements;
    searchQuery.queryElements = null;
    this.searchQuery = searchQuery;
  }

  search(
    page = 1,
    preventCorrection = null,
    type: CategoryType = this.type,
    searchQuery = this.searchQuery,
  ) {
    this.searchResultScroll = 0;
    if (!searchQuery.query || !searchQuery.query.trim()) {
      this.alert.pop('검색어를 입력해주세요.');
      return;
    }
    searchQuery.query = queryCorrection(searchQuery.query);
    searchQuery.page = page;
    const query = {} as any;
    Object.keys(searchQuery).forEach((it) => {
      if (!searchQuery[it]) return;
      if (it == 'page' && searchQuery[it] == 1) return;
      query[it] = searchQuery[it];
    });
    query.preventCorrection = preventCorrection ? preventCorrection : null;
    query.pureQuery = null;

    this.historyService.addHistory(searchQuery.query);
    const commands = ['/search'];
    const path = typePath(type);
    if (path) commands.push(path);
    return this.router.navigate(commands, {
      queryParams: query,
    });
  }

  searchWith(name, value?: string) {
    this.searchQuery[name] = value;
    return this.search();
  }

  searchWithKeyword(query: any) {
    query = query.trim();
    if (!query) {
      this.alert.pop('검색어를 입력해주세요');
      return;
    }
    
    this.searchQuery.query = query;
    this.isSearchingWithoutCorrection = false;
    return this.search();
  }

  searchWithRange($event: any) {
    if (
      $event == 1 ||
      $event == 3 ||
      $event == 5 ||
      !$event ||
      $event.match(/\d*-\d*/)
    ) {
      this.searchQuery.range = $event;
      this.search();
      return;
    }
    this.alert.pop('검색 기간을 정확히 입력해주세요');
  }

  searchWithLaw() {
    this.alert
      .open(SearchWithLawComponent, null, { width: '470px' })
      .then((v) => {
        if (!v) return;
        this.searchQuery.query = this.searchQuery.query + v;
        this.searchWithKeyword(this.searchQuery.query);
      });
  }

  upload(fileList) {
    if (!fileList.length) return;
    for (let i = 0; i < Array.from(fileList).length; i++) {
      const file: any = Array.from(fileList)[i];
      if (file.type != 'application/pdf') {
        (file as any).error =
          '업로드 실패: PDF 파일 형식만 업로드가 가능합니다.';
      }
    }
    // this.user.myCaseRegister(fileList);
  }

  openSearch(isFn?, tab?) {
    this.alert.dialog.open(SearchDialogComponent, {
      panelClass: 'search-dialog',
      width: '100%',
      height: '100%',
      maxWidth: '100vw',
      maxHeight: '100vh',
      data: { isFn, tab },
    });
  }

  changeType(type) {
    return this.search(1, null, type, {
      query: this.searchQuery.query,
    } as SearchQuery);
  }

  extractSearchQuery(v) {
    const r = this.extractQuery(v);
    return {
      query: r.keyword,
      queryElements: r.queryElements,
    };
  }

  extractQuery(v, type?) {
    if (!v) {
      return { queryElements: [], keyword: '' };
    }

    let value = v;

    const commandList = type
      ? commands.filter(
          (it) => !it.supports || it.supports?.includes(type || 'case'),
        )
      : this.commands;

    const elements = commandList
      .map((it) => {
        const result = [];
        it.regex.forEach((r) => {
          const regex = new RegExp(r, 'gm');
          let regexResult;
          while ((regexResult = regex.exec(value)) !== null) {
            value =
              value.substring(0, regexResult.index) +
              this.blank(regexResult[0].length) +
              value.substring(regexResult.index + regexResult[0].length);
            const highlightOffset = it.highlightOffset || 0;
            result.push({
              name: it.name,
              query: regexResult[0],
              type: it.type,
              value: regexResult[1],
              cssClass: it.type + ' function',
              indices: {
                start: regexResult.index + highlightOffset,
                end:
                  regexResult.index +
                  highlightOffset +
                  ((it.highlightLimit || regexResult[0].length) -
                    highlightOffset),
              },
            });
          }
        });
        return result;
      })
      .reduce((acc, val) => acc.concat(val), []);
      
    const keyword = value.replace ? value.replace(/ +/g, ' ') : '';

    if (!elements.find((it) => it.type != 'exclude') && !keyword.trim()) {
      return { queryElements: [], keyword: v };
    }

    return {
      queryElements: elements,
      keyword: keyword === ' ' ? '' : keyword,
    };
  }

  blank(length: any) {
    let result = '';
    for (let i = 0; i < length; i++) result += ' ';
    return result;
  }
}
