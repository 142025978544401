import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'lb-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.sass'],
})
export class HeaderWithCompactSearchComponent {
  @Input()
  break = 768;

  @Input()
  withSearch = true;

  @Input()
  mobileSearch = false;

  constructor(private router: Router) {}

  back() {
    if (window.opener) {
      this.router.navigateByUrl('/');
      return;
    }
    history.back();
  }
}
