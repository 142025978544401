import { Directive, HostBinding, HostListener, Input } from '@angular/core';
import { AlertService } from '../../alert/alert.service';
import { UserService } from '../../user/user.service';
import { ReadService } from '../read.service';

@Directive({
  selector: '[lbNewTabLink]',
})
export class NewTabLinkDirective {
  @Input()
  loginRequired;

  @Input()
  lbNewTabLink;

  @HostBinding('class.read')
  get readClass() {
    if (!this.lbNewTabLink) return;
    return this.read.isRead(this.lbNewTabLink);
  }

  @Input()
  target = '_blank';

  constructor(
    private alertService: AlertService,
    private user: UserService,
    private read: ReadService,
  ) {}

  @HostListener('click')
  async move() {
    if (this.loginRequired) {
      await this.user.loginCheck();
    }
    this.read.read(this.lbNewTabLink);
    await this.alertService.checkOpen(this.lbNewTabLink);
  }
}
