import { Injectable } from '@angular/core';

@Injectable()
export class SuggestionService {
  index = -1;
  nowIndex = 0;

  constructor() {}

  getIndex() {
    this.index++;
    return this.index;
  }
}
