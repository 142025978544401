<div class="verify-container">
  <div class="verify-header">
    <div class="verify-title">
      <h4>로스쿨 학생 인증</h4>
      <button (click)="handleClickCloseButton()">
        <img src="/assets/icon-close.png" />
      </button>
    </div>
  </div>

  <div class="verify-body">
    <div class="verify-description">
      로스쿨 플랜은 현재 로스쿨에 재학 중인 경우에만 이용할 수 있습니다.<br />
      로스쿨 재학 중임을 증명할 수 있는 학생증 또는 재학증명서 사본을 제출해 주세요.
    </div>

    <div class="verify-file-upload">
      <div class="file no-bottom-radius" *ngIf="validFiles?.length">
        <div fxLayout="row" fxLayoutAlign="center center">
          <div fxFlex="auto">
            총 {{ validFiles?.length }}개 항목을 업로드
            <span *ngIf="!done">하는 중 입니다.</span>
            <span *ngIf="done">하였습니다.</span>
          </div>
          <span class="icon icon-delete" trackId="302a" (click)="files = []"></span>
        </div>
        <div class="progress">
          <span [style.right]="done ? 0 : ((sum - progressSum) / sum) * 100 + '%'"></span>
        </div>
        <div class="font-12 color-gray">
          <ng-container *ngIf="!done">
            {{ progressSum | fileSize }}/{{ sum | fileSize }}
          </ng-container>
          <ng-container *ngIf="done">완료</ng-container>
        </div>
      </div>

      <lb-upload-area-for-law-school-authorization *ngIf="!files?.length" accept=".jpg,.jpeg,.png"
        (filesChange)="updateFiles($event)">업로드하기</lb-upload-area-for-law-school-authorization>

      <div class="files" *ngIf="files?.length" [class.top-radius]="!validFiles?.length">
        <div class="file" fxLayout="row" *ngFor="let file of files" fxLayoutAlign="center center">
          <img src="/assets/document.svg" style="width: 24px; margin-right: 16px" />
          <div fxFlex="auto">
            <div fxLayout="row" fxLayoutAlign="center center">
              <div fxFlex="auto">
                {{ file.file.name }}
              </div>
              <span class="icon icon-delete" fxFlex="none" trackId="3e36" (click)="files.remove(file)"></span>
            </div>
            <div class="progress">
              <span [style.right]="
                file.done
                  ? 0
                  : ((file.file.size - file.progressSize) / file.file.size) *
                      100 +
                    '%'
              "></span>
            </div>
            <div class="font-12 color-warn" *ngIf="file.error">
              {{ file.error }}
            </div>
            <div class="font-12 color-gray" *ngIf="!file.error">
              <ng-container *ngIf="!file.done">
                {{ file.progressSize | fileSize }}/{{
                file.file.size | fileSize
                }}>
              </ng-container>
              <ng-container *ngIf="file.done">완료</ng-container>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="verify-container-warnings">
      <h6>첨부 시 주의 사항</h6>
      <ul>
        <li>이름, 학교, 학과, 입학연도 확인이 어렵거나 유효하지 않은 학생증은 반려될 수 있습니다.</li>
        <li>체크카드 겸용 학생증인 경우 카드번호와 유효기간이 보이지 않도록 마스킹 처리 후 첨부해주세요.
          마스킹 처리를 하지 않은 경우 즉시 파기 되며 요청이 반려됩니다.</li>
      </ul>
    </div>

    <div class="verify-container-terms">
      <div class="verify-wrapper-terms-checkbox">
        <div class="verify-checkbox-wrapper">
          <mat-checkbox class="verify-custom-mat-checkbox" (change)="handleClickTermsCheckbox()"
            [checked]="isTermAgreed">
            개인정보 수집 및 이용에 동의합니다.
          </mat-checkbox>
        </div>
        <button class="verify-accordian-toggle-button" [class]="showsTermsDetail ? '' : 'rotate-180'"
          (click)="handleClickIconCaretUp()">
          <img src="/assets/icon-caret-up.png" />
        </button>
      </div>

      <div class="verify-wrapper-terms-detail" [class]="showsTermsDetail ? 'shown' : ''">
        <span>
          귀하는 위 개인정보 수집∙이용 등에 대한 동의를 거부하실 수 있으며, 동의하지 않을 경우
          서비스 이용에 제한이 있을 수 있습니다.
        </span>

        <h5>개인정보 수집 및 이용 내역</h5>
        <table class="verify-table-terms">
          <thead>
            <th>항목</th>
            <th>처리 목적</th>
            <th>보유 기간</th>
          </thead>
          <tbody>
            <td>학교명, 입학연도, 졸업예정연도, 학과명, 학번, 인물사진, 입학일자, 재학학기, 발급일자</td>
            <td>로스쿨 학생 전용 서비스 제공</td>
            <td class="verify-terms-period">
              서비스 이용 만료시 또는 개인정보 수집∙이용 목적 달성시까지
            </td>
          </tbody>
        </table>
      </div>
    </div>
  </div>

  <div class="verify-footer">
    <button class="verify-button-confirm" [class]="isConfirmButtonActive ? '' : 'disabled'"
      (click)="handleClickConfirmButton()" [disabled]="!isConfirmButtonActive">확인</button>
  </div>
</div>