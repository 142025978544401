<div
  *ngFor="let search of searchJomuns; let i = index; trackBy: trackByIndex"
  class="set"
>
  <div fxLayout="row" class="mb-4">
    <span class="name"> 법령 </span>
    <div class="full-width">
      <input
        [value]="search.law || ''"
        #input
        (keyup)="findOption(search, input.value, i)"
        (keyup.enter)="input.blur(); trigger.closePanel()"
        placeholder="예) 민법, 형법"
        (blur)="checkValidStatute(input, i)"
        [matAutocomplete]="auto"
        [class.error]="errors[i]"
        #trigger="matAutocompleteTrigger"
      />
      <div class="color-warn font-14" *ngIf="errors[i]">
        법령을 정확하게 입력해주세요. (예: 형법, 민사소송법)
      </div>
    </div>
    <mat-autocomplete
      #auto="matAutocomplete"
      class="default"
      (optionSelected)="checkValidStatute(input, i)"
      [displayWith]="getOptionText"
    >
      <mat-option
        *ngFor="let option of search.filteredOptions"
        [value]="option"
        class="law-option"
      >
        {{ option.statute_fullname }}
      </mat-option>
    </mat-autocomplete>
  </div>
  <div fxLayout="row">
    <span class="name"> 조문 </span>
    <div fxFlex="auto" class="full-width">
      <div
        *ngFor="
          let jomun of searchJomuns[i].jomuns;
          let j = index;
          trackBy: trackByIndex
        "
        fxLayout="row"
        fxLayoutAlign="center center"
        class="mb-4"
      >
        <div class="full-width">
          <input
            #input2
            [(ngModel)]="searchJomuns[i].jomuns[j]"
            [class.error]="errors[i + ',' + j]"
            (keyup.enter)="input2.blur(); trigger2.closePanel()"
            (blur)="checkValidJomun(i, j)"
            [readOnly]="errors[i]"
            placeholder="예) 536조, 347조의2"
            [matAutocomplete]="auto2"
            #trigger2="matAutocompleteTrigger"
          />
          <div
            class="color-warn font-14"
            *ngIf="errors[i + ',' + j]"
            [innerHTML]="errors[i + ',' + j]"
          ></div>
        </div>
        <mat-autocomplete #auto2="matAutocomplete" class="default">
          <mat-option
            *ngFor="
              let option of filteredJomuns(
                searchJomuns[i].jomunOptions,
                searchJomuns[i].jomuns[j]
              )
            "
            [value]="option"
            class="law-option"
          >
            {{ joName(option) }}
          </mat-option>
        </mat-autocomplete>
        <div
          fxFlex="none"
          *ngIf="deletable()"
          trackId="feed"
          (click)="delete(i, j)"
        >
          <span class="i delete ml-6"></span>
        </div>
      </div>
      <span
        trackId="13aa"
        (click)="search.jomuns.push('')"
        *ngIf="search.jomuns?.length < 5"
        class="inline-block mt-16"
      >
        <span class="icon3 n-42"></span
        ><span class="vertical-middle ml-4">조문추가</span>
      </span>
    </div>
  </div>
  <div class="full mt-20" *ngIf="search.jomuns?.length >= 5">
    조문은 한번에 최대 5개까지 추가할 수 있습니다.
  </div>
</div>
<span
  trackId="42a5"
  (click)="searchJomuns.push({ jomuns: [''] })"
  class="color-primary"
  *ngIf="searchJomuns.length < 3"
>
  <span class="icon3 h-6 n-42"></span
  ><span class="vertical-middle ml-4">법령추가</span>
</span>
<div class="full" *ngIf="searchJomuns.length >= 3">
  법령은 한번에 최대 3개까지 추가할 수 있습니다.
</div>
