/* tslint:disable */
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ApiHttp} from '../api.http';

import {AttorneyCaseCandidateRelation, UserInvolveCase} from '../api.model';

@Injectable()
export class UserInvolveCaseController {
  constructor(private http: ApiHttp) {
  }

  getCandidateCaseList(): Observable<AttorneyCaseCandidateRelation[]> {
    return this.http.get<AttorneyCaseCandidateRelation[]>('/api/userInvolveCase/list/candidate');
  }

  getInvolveCaseList(): Observable<AttorneyCaseCandidateRelation[]> {
    return this.http.get<AttorneyCaseCandidateRelation[]>('/api/userInvolveCase/list/involve');
  }

  marking(docAndTypes: any[]): Observable<UserInvolveCase[]> {
    return this.http.post<UserInvolveCase[]>('/api/userInvolveCase/marking', docAndTypes);
  }
}
