<div class="confirm-data" [class.confirmed]="info?.confirmed === true">
  <span *ngIf="info?.confirmed === true" class="icon3 n-103"></span>
  <ng-container *ngIf="!info || info?.confirmed === null">
    <span class="vertical-middle">
      현재 시점에서 확정 여부가 확인되지 않은 판결입니다
    </span>
  </ng-container>
  <ng-container *ngIf="info">
    <ng-container *ngIf="info.confirmed === true">
      <span class="vertical-middle font-weight-500"> 확정판결 </span>
      <span
        class="vertical-middle font-weight-400 ml-6 color-primary"
        *ngIf="info.confirmed_case.doc_id !== caseId"
        [caseLink]="'/' + info.confirmed_case.doc_id"
        >{{ info.confirmed_case.doc_id }}</span
      >
    </ng-container>
    <ng-container *ngIf="info.confirmed === false">
      <span class="vertical-middle color-mid-gray">
        <ng-container *ngIf="info.confirmed_case.announce_date"
          >상소기간 진행중</ng-container
        >
        <ng-container *ngIf="!info.confirmed_case.announce_date">
          <ng-container *ngIf="info.confirmed_case?.level === 4">
            파기환송심 진행중
          </ng-container>
          <ng-container *ngIf="info.confirmed_case?.level !== 4">
            상급심 진행중
          </ng-container>
        </ng-container>
      </span>
      <ng-container *ngIf="info.confirmed_case.doc_id !== caseId">
        <span
          *ngIf="info.confirmed_case.announce_date"
          class="vertical-middle font-weight-400 ml-6 color-primary"
           trackId="9d58" [lbNewTabLink]="'/' + info.confirmed_case.doc_id"
          >{{ info.confirmed_case.doc_id }}</span
        >
        <span
          *ngIf="!info.confirmed_case.announce_date"
          class="vertical-middle font-weight-400 ml-6"
          >{{ info.confirmed_case.doc_id }}</span
        >
      </ng-container>
    </ng-container>
  </ng-container>
</div>
