<div class="expand-desc">
  개인정보 보호를 위하여 아래의 개인정보 수집·이용에 대한 내용을 자세히 읽어
  보신 후 동의 여부를 결정하여 주시기 바랍니다.
  <table *showItSizes="{ min: 960 }">
    <thead>
      <tr>
        <th *ngFor="let item of termsAndConditionsByContentType">
          {{ item.label }}
        </th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td *ngFor="let item of termsAndConditionsByContentType" [class.personal-information-holding-duration-text]="item.bold" [innerHTML]="item.value"></td>
      </tr>
    </tbody>
  </table>
  <table *showItSizes="{ max: 959 }">
    <tbody>
      <tr *ngFor="let item of termsAndConditionsByContentType">
        <th>{{ item.label }}</th>
        <td [class.personal-information-holding-duration-text]="item.bold" [innerHTML]="item.value"></td>
      </tr>
    </tbody>
  </table>
  <div>
    귀하는 위 개인정보 수집∙이용에 대한 동의를 거부하실 수 있으며, 동의하지 않을 경우 서비스 이용에 제한이 있을 수 있습니다.
  </div>
</div>
