import { Component, Input } from '@angular/core';
import { getNumberOptions } from '../lbox-user/lbox-user.component';

@Component({
  selector: 'lb-lbox-user-type',
  templateUrl: './lbox-user-type.component.html',
  styleUrls: ['./lbox-user-type.component.sass'],
})
export class LboxUserTypeComponent {
  @Input()
  job = [];

  @Input()
  showsWarningText = true;

  @Input()
  isAuthorizedLawyerChangeJobDisabled = false;

  jobOptions = [
    '변호사',
    '법조인(판사, 검사, 법무관 등)',
    '법률전문직',
    '공무원(법원, 검찰, 경찰 등)',
    '법학전문대학원생',
    '대학생/대학원생',
    '교수',
    '연구원',
    '기타(직접 입력)',
    '선택 안 함(회원 유형별 맞춤 서비스 이용 불가)',
  ];

  get typeOptions() {
    switch (this.userType) {
      case 'all':
        return this.jobOptions;
      case 'etc':
        return [
          '법조인(판사, 검사, 법무관 등)',
          '법률전문직',
          '공무원(법원, 검찰, 경찰 등)',
          '대학생/대학원생',
          '교수',
          '연구원',
          '기타(직접 입력)',
          '선택 안 함(회원 유형별 맞춤 서비스 이용 불가)',
        ];
      case 'lawyer':
        return [];
      case 'lawSchool':
        return [];
    }
  }

  @Input()
  userType: 'etc' | 'lawyer' | 'lawSchool' | 'all' = 'all';

  options: any = {};
  years;
  optionCounts = {
    변호사: 4,
    '법조인(판사, 검사, 법무관 등)': 4,
    법률전문직: 3,
    '공무원(법원, 검찰, 경찰 등)': 3,
    법학전문대학원생: 4,
    '대학생/대학원생': 4,
    교수: 3,
    연구원: 2,
    '기타(직접 입력)': 1,
    '선택 안 함(회원 유형별 맞춤 서비스 이용 불가)': 1,
  };
  gYears;
  eYears;
  placeholders = {
    사법시험: '사법연수원 기수',
    변호사시험: '시험회차',
    '군법무관 임용시험': '군법무관 기수',
  };

  @Input()
  alert = true;

  @Input()
  yearOnly;

  get hasOptions() {
    return !('lawyer' == this.userType || 'lawSchool' == this.userType);
  }

  get error() {
    if (this.job.find((it) => it && it.length < 2)) {
      return '두 글자 이상 입력해주세요.';
    }
    if (!this.selected) {
      return '유형 정보를 모두 작성해주세요.';
    }
  }

  get selected() {
    if (this.job[0] == '변호사') {
      /** 변호사인 경우 시험 종류와 시험 차수 값이 있는지 체크 */
      return this.job[2] && this.job[3];
    }

    return (
      this.job.filter((it) => it?.length > 1 && it !== '미정').length ==
      this.optionCount
    );
  }

  get optionCount() {
    return (
      this.optionCounts[this.job[0]] +
      this.job.filter((it) => it == '기타(직접 입력)').length
    );
  }

  constructor() {
    this.years = getNumberOptions(1963, new Date().getFullYear(), '년');
    this.gYears = getNumberOptions(
      new Date().getFullYear(),
      new Date().getFullYear() + 10,
      '년',
    );
    this.eYears = getNumberOptions(
      new Date().getFullYear() + 1,
      new Date().getFullYear() - 10,
      '년',
    );
    this.years.sort((a, b) => b.localeCompare(a));
    this.options['시험 유형'] = ['사법시험', '변호사시험', '군법무관 임용시험'];
    this.options['사법시험'] = getNumberOptions(50, 1, '기');
    this.options['변호사시험'] = getNumberOptions(13, 1, '회');
    this.options['군법무관 임용시험'] = getNumberOptions(19, 1, '기');
    this.options['법조인(판사, 검사, 법무관 등)'] = [
      '판사',
      '검사',
      '재판연구원',
      '공익법무관',
      '군법무관',
      '기타(직접 입력)',
    ];
    this.options['법학전문대학원 선택'] = [
      '강원대학교',
      '건국대학교',
      '경북대학교',
      '경희대학교',
      '고려대학교',
      '동아대학교',
      '부산대학교',
      '서강대학교',
      '서울대학교',
      '서울시립대학교',
      '성균관대학교',
      '아주대학교',
      '연세대학교',
      '영남대학교',
      '원광대학교',
      '이화여자대학교',
      '인하대학교',
      '전남대학교',
      '전북대학교',
      '제주대학교',
      '중앙대학교',
      '충남대학교',
      '충북대학교',
      '한국외국어대학교',
      '한양대학교',
    ];
    this.options['법률전문직'] = [
      '손해사정사',
      '공인노무사',
      '변리사',
      '법무사',
      '세무사',
      '행정사',
      '공인회계사',
      '관세사',
      '감정평가사',
      '기타(직접 입력)',
    ];
    this.options['공무원(법원, 검찰, 경찰 등)'] = [
      '법원공무원',
      '검찰공무원',
      '경찰공무원',
      '기타(직접 입력)',
    ];
  }

  jobClear(i) {
    for (let index = 0; index < this.job.length; index++) {
      if (i < index) this.job[index] = null;
    }
  }

  setUndefinedIfNew() {
    if (
      this.job[2] == '변호사시험' &&
      this.job[3] == this.options['변호사시험'][0]
    ) {
      this.job[5] = '미정';
    }
  }
}
