import { Component, OnInit } from '@angular/core';
import { courtsExceptFamilyCourts, selectCourt } from './courts';
import { RequestCase } from '../../../../../ngxSpring/api.model';
import { UserService } from '../../../user/user.service';
import { docIdByCourtAndCaseNo, getCaseNoRegex } from '../../../core/util';
import { Router } from '@angular/router';
import { CaseRequestDialogWithoutDocIdComponent } from './case-request-dialog-without-doc-id.component';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'lb-case-request-dialog',
  templateUrl: './case-request-dialog.component.html',
  styleUrls: ['./case-request-dialog.component.sass'],
})
export class CaseRequestDialogComponent
  extends CaseRequestDialogWithoutDocIdComponent
  implements OnInit
{
  court;
  prevent = null;
  user;
  type;

  async request() {
    if (!this.user.logged) {
      this.alert.pop('로그인이 필요한 서비스입니다.');
      return;
    }
    if (!courtsExceptFamilyCourts.find((it) => it === this.court)) {
      this.alert.pop('법원을 선택해주세요.');
      return;
    }
    if (!this.data.caseId.match(getCaseNoRegex())) {
      this.alert.pop('판례 번호를 확인해주세요.');
      return;
    }
    const docId = docIdByCourtAndCaseNo(
      selectCourt(this.court),
      this.data.caseId,
    );
    if (await firstValueFrom(this.api.lbCase.isRequestAble(docId))) {
      void this.alert
        .confirm(
          '이미 등록된 판례',
          null,
          `등록요청하신 ${docId} 판례는 이미 등록된 판례입니다. 해당 판례로 이동하시겠습니까?`,
        )
        .then((v) => {
          if (!v) return;
          this.close();
          if (v) {
            if (window.innerWidth >= 768) {
              void this.alert.checkOpen(location.origin + '/' + docId);
            } else {
              return this.injector.get(Router).navigateByUrl('/' + docId);
            }
            return;
          }
        });
      return;
    }
    this.setType(this.data.caseId, this.court);
    if (this.prevent) {
      this.done = true;
      return;
    }
    this.api.requestCase
      .saveRequestCase({
        requestCaseId: this.data.caseId,
        court: selectCourt(this.court),
      } as RequestCase)
      .subscribe(() => {
        this.close();

        if (this.alert.snackBar._openedSnackBarRef) {
          return;
        }

        void this.trackingService.tracking(
          'complete_search_case_unregistered',
          {
            keyword: this.caseId,
            type: '판례디테일페이지',
          },
        );
        this.alert.pop('미등록 판례 요청이 접수되었습니다.');
      });
  }

  ngOnInit(): void {
    this.user = this.injector.get(UserService);
    const name = courtsExceptFamilyCourts.find(
      (it) => it.replace(/\(\) /g, '') === this.data.court,
    );
    if (name) {
      this.court = name;
    }
    this.setType(this.data.caseId, this.court);
  }
}
