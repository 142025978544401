<div class="title">
  <h5>비밀번호 변경</h5>
</div>
<form class="text-left" [formGroup]="passwordRedefineForm">
  <div *ngIf="data.hasPassword">
    <h5 class="ml-24 mb-12">기존 비밀번호</h5>
    <div class="input-wrapper">
      <input
        placeholder="기존 비밀번호"
        formControlName="oldPassword"
        type="password"
      />
      <div>
        <lb-err-for
          [group]="passwordRedefineForm"
          errFor="oldPassword"
        ></lb-err-for>
      </div>
    </div>
  </div>
  <h5 class="ml-24 mb-12" *ngIf="data.hasPassword">새 비밀번호</h5>
  <div class="input-wrapper">
    <input
      [placeholder]="(data.hasPassword ? '새 ' : '') + '비밀번호'"
      formControlName="password"
      type="password"
    />
    <div>
      <lb-err-for [group]="passwordRedefineForm" errFor="password"></lb-err-for>
    </div>
  </div>
  <div class="input-wrapper">
    <input
      placeholder="비밀번호 재입력"
      formControlName="passwordConfirm"
      type="password"
    />
    <div>
      <lb-err-for
        [group]="passwordRedefineForm"
        errFor="passwordConfirm"
      ></lb-err-for>
    </div>
  </div>
</form>
<div class="buttons">
  <button class="cancel" trackId="4fc6" (click)="close()">취소</button>
  <button
    class="blue"
    trackId="4bf2"
    (click)="passwordRedefine()"
    [class.disabled]="passwordRedefineForm.error()"
  >
    비밀번호 변경하기
  </button>
</div>
