export const interpretFilters = {
  공정거래위원회: [
    [
      '경고',
      '경고등',
      '경고무혐의',
      '경고에대한심의',
      '경고인정',
      '고발',
      '과징금',
      '과태료',
      '과태료등',
      '기각',
      '기업결합예외인정',
      '기타',
      '기타의 조치',
      '내부이첩',
      '동의의결',
      '동의의결기각',
      '동의의결인용',
      '무혐의',
      '무혐의,각하등',
      '미정',
      '법위반공표',
      '부속조치',
      '사건병합',
      '사건삭제',
      '수정(국제계약)',
      '수정의결',
      '시장권고이상조치',
      '시정권고',
      '시정명령',
      '시정요청(약관)',
      '시정조치감면',
      '심사불개시',
      '심사절차종료',
      '심의절차종료',
      '심의절차중지',
      '영업정지명령',
      '영업정지요청',
      '원안의결',
      '이첩',
      '인용',
      '일부인용',
      '임시중지명령',
      '입찰참가자격제한요청',
      '재결(이의신청)',
      '재결각하',
      '재결기각',
      '정정광고',
      '조사중지',
      '조정',
      '종결처리',
      '주의촉구',
      '질의회신',
      '취하',
      '타기관이첩',
    ],
    [
      '가맹사업거래의공정화에관한법령 위반관련',
      '경제력 집중억제',
      '기업결합',
      '기타',
      '대규모 유통업에서의 거래 공정화에 관한 법률 위반',
      '대리점거래의 공정화에 관한 법률 위반',
      '방문판매등에관한법령 위반관련',
      '부당한 공동행위',
      '부당한 국제계약체결',
      '불공정거래행위',
      '불공정약관',
      '불공정하도급거래행위',
      '사업자단체금지행위',
      '시장지배적지위 남용행위',
      '자료미제출등',
      '재판매가격유지행위',
      '전자상거래소비자보호법령 위반',
      '질의회신',
      '집행정지신청등',
      '할부거래에 관한 법령 위반관련',
      '해당없음',
    ],
  ],
  법제처: [
    [
      '감사원',
      '개인정보보호위원회',
      '경찰청',
      '고용노동부',
      '공정거래위원회',
      '과학기술정보통신부',
      '관세청',
      '교육부',
      '국가보훈처',
      '국가인권위원회',
      '국가정보원',
      '국무조정실',
      '국무총리',
      '국무총리비서실',
      '국민권익위원회',
      '국방부',
      '국세청',
      '국토교통부',
      '국회',
      '금융위원회',
      '기상청',
      '기획재정부',
      '농림축산식품부',
      '농촌진흥청',
      '대검찰청',
      '대법원',
      '대통령경호처',
      '대통령비서실',
      '문화재청',
      '문화체육관광부',
      '민주평화통일자문회의사무처',
      '방송통신위원회',
      '방위사업청',
      '법무부',
      '법제처',
      '병무청',
      '보건복지부',
      '산림청',
      '산업통상자원부',
      '새만금개발청',
      '소방청',
      '식품의약품안전처',
      '여성가족부',
      '외교부',
      '원자력안전위원회',
      '인사혁신처',
      '조달청',
      '중소벤쳐기업부',
      '중앙선거관리위원회',
      '질병관리청',
      '통계청',
      '통일부',
      '특허청',
      '해양경찰청',
      '해양수산부',
      '행정안전부',
      '행정중심복합도시건설청',
      '헌법재판소',
      '환경부',
    ],
  ],
  조세심판원: [
    ['내국세', '관세', '지방세'],
    ['법인', '소득', '부가', '양도', '상증', '관세', '기타', '지방'],
    ['인용', '취소', '경정', '기각', '각하', '재조사'],
  ],
};
