<span *ngIf="userService.logged" trackId="bb45" [matMenuTriggerFor]="services">
  <span
    class="icon2 n-87 mr-0"
    [class.h-4]="!colorRevert"
    [class.h-1]="colorRevert"
  ></span>
  <ng-container *ngIf="withLabel">
    <div>서비스</div>
  </ng-container>
</span>
<mat-menu #services class="mt-12">
  <ng-container *ngIf="userService.canUseLegalAnalytics && !userService.isRestrictedUser">
    <a mat-menu-item [href]="legalAnalyticsUrl" (click)="sendClickGnbAnalyticsEvent()">
      <img src="/assets/legal-analytics-menu-beta.png" style="width: 24px; height: 24px; margin-bottom: -7px; margin-right: 8px"
        trackId="cgla" />
      <span class="vertical-middle">법률분석</span>
    </a>
  </ng-container>

  <a mat-menu-item trackId="44ab" routerLink="/bookmark">
    <span class="icon2 n-80 h-1"></span>
    <span class="vertical-middle">판례보관함</span></a
  >
  <a mat-menu-item trackId="5910" routerLink="/bookmark/history">
    <span class="icon2 n-95 h-1"></span>
    <span class="vertical-middle">열어본 판례</span></a
  >
  <a mat-menu-item trackId="a806" routerLink="/user/calendar">
    <span class="icon2 n-79 h-4"></span>
    <span class="vertical-middle">사건일정관리</span></a
  >
  <a mat-menu-item trackId="1f7f" routerLink="/substitute">
    <img
      src="/assets/icon-substitute.png"
      style="width: 24px; height: 24px; display: inline-block; vertical-align: middle; margin-right: 8px;"
    />
    <span class="vertical-middle">복대리</span></a
  >
</mat-menu>
