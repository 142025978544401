/* tslint:disable */
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ApiHttp} from '../api.http';

import {NewsSearchQuery, NewsSearchResult} from '../api.model';

@Injectable()
export class NewsController {
  constructor(private http: ApiHttp) {
  }

  search(searchQuery: NewsSearchQuery): Observable<NewsSearchResult> {
    return this.http.post<NewsSearchResult>('/api/news/search', searchQuery);
  }
}
