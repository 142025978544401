<div class="title">
  <h5>결제수단 변경</h5>
</div>

<form class="payment-info-form" autocomplete="off">
  <div class="row col-1">
    <h5 class="label">카드번호</h5>
    <input
      placeholder="카드번호"
      [validation]="false"
      autocomplete="false"
      mask="0000-0000-0000-0000"
      #card
    />
  </div>

  <div class="row col-2">
    <div class="input-column">
      <h5 class="label">유효기간</h5>
      <div class="input-group-wrapper">
        <input
          autocomplete="false"
          placeholder="MM"
          mask="00"
          [validation]="false"
          #month
        />
        <input
          class="ml-4"
          autocomplete="false"
          placeholder="YY"
          mask="00"
          [validation]="false"
          #year
        />
      </div>
    </div>
    <div class="input-column">
      <h5 class="label">카드비밀번호 앞 2자리</h5>
      <input
        placeholder="비밀번호 2자리"
        autocomplete="false"
        mask="00"
        type="password"
        [validation]="false"
        #pw
      />
    </div>
  </div>

  <div class="row col-1">
    <h5 class="label">
      개인: 생년월일 6자리 / 법인: 사업자번호 10자리
    </h5>
    <input
      mask="0000000000"
      placeholder="123456"
      [validation]="false"
      #auth
      autocomplete="false"
    />
  </div>

  <p class="mt-24 info-text">
    결제수단 변경 시, 다음 결제일부터 변경된 결제수단으로 결제됩니다.
  </p>

</form>

<div class="button-group-wrapper">
  <button type="button" class="cancel-button" (click)="close()">
    취소
  </button>
  <button
    type="button"
    class="change-button"
    (click)="
      changePayment(
        card.value,
        '20' + year.value + '-' + month.value,
        pw.value,
        auth.value
      )
    "
  >
    변경
  </button>
</div>
