import {
  Component,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  OnDestroy,
  Optional,
  Output,
} from '@angular/core';
import { ApiService } from '../../../../ngxSpring/api.service';
import { Case, MyHighlight } from '../../../../ngxSpring/api.model';
import { Router } from '@angular/router';
import { AlertService } from '../../alert/alert.service';
import { CaseSelectService } from '../../user/case-select-service';
import { CaseService } from '../../case/case.service';
import { firstValueFrom, Subscription } from 'rxjs';
import { TrackService } from '../../track/track.service';
import { UserService } from '../../user/user.service';
import { FolderService } from '../../bookmark/folder.service';
import { caseLink } from '../../core/util';

@Component({
  selector: 'lb-case-interact-panel',
  templateUrl: './case-interact-panel.component.html',
  styleUrls: ['./case-interact-panel.component.sass'],
})
export class CaseInteractPanelComponent implements OnDestroy {
  id;
  title;
  highlights;
  memos;
  folder;
  sub: Subscription;

  @Input()
  set case(caseData) {
    this.id = caseData.caseId || caseData.docId;
    this.title = caseData.title;
    this.loaded = false;
    this.folder = caseData.folder;
    this.api.myHighlight
      .getMyHighlightByDocIdAsList(caseData.caseId)
      .subscribe((v) => {
        this.highlights = v.filter((it) => it.className);
        this.memos = v.filter((it) => it.memo);
        this.loaded = true;
      });
  }

  @HostListener('mouseup', ['$event'])
  mouseup(e) {
    e?.preventDefault();
    e?.stopPropagation();
  }

  @Input()
  set caseData(caseData: Case) {
    this.id = caseData.doc_id;
    this.title = caseData.title;
    this.loaded = false;
    this.api.myHighlight
      .getMyHighlightByDocIdAsList(caseData.doc_id)
      .subscribe((v) => {
        this.highlights = v.filter((it) => it.className);
        this.memos = v.filter((it) => it.memo);
        this.loaded = true;
      });
  }

  @Output()
  closeClick = new EventEmitter();

  constructor(
    private api: ApiService,
    private alert: AlertService,
    private router: Router,
    public caseSelectService: CaseSelectService,
    private ga: TrackService,
    public userService: UserService,
    public folderService: FolderService,
    @Optional() private caseService?: CaseService,
  ) {
    this.sub = this.caseService?.caseRefChange?.subscribe(() => {
      this.api.myHighlight
        .getMyHighlightByDocIdAsList(this.id)
        .subscribe((v) => {
          this.highlights = v.filter((it) => it.className);
          this.memos = v.filter((it) => it.memo);
          this.loaded = true;
        });
    });
  }

  ngOnDestroy(): void {
    this.sub?.unsubscribe();
  }

  @HostBinding('class.dialog')
  dialog;
  loaded;

  move() {
    this.router.navigateByUrl('/' + this.id).then(() => {
      this.alert.close();
    });
  }

  close() {
    this.alert.close();
    this.closeClick.emit();
  }

  modifyMemo(memo: MyHighlight) {
    this.alert
      .prompt(
        {
          placeholder: '메모 내용을 입력해주세요.',
          value: memo.memo,
          permitEmpty: true,
        },
        '메모 수정',
      )
      .then((v) => {
        if (!v && v != '') {
          return;
        }
        memo.memo = v;
        this.api.myHighlight.saveMyHighlight(memo).subscribe(() => {
          this.alert.pop('메모가 수정되었습니다.');
          this.caseService?.caseRefChange?.emit({ type: 'ref', ref: memo });
        });
      });
  }

  async deleteHighlight(highlight: MyHighlight, type: 'memo' | 'highlight') {
    const message =
      type == 'memo' ? '메모를 삭제합니다.' : '하이라이트를 삭제합니다.';
    if (!(await this.alert.deleteConfirm(message))) return;
    await firstValueFrom(this.api.myHighlight.deleteMyHighlight(highlight.id));
    this.memos.remove(highlight);
    this.highlights.remove(highlight);
    this.caseSelectService.highlightChanged.emit({
      highlight: highlight,
      id: highlight.docId,
      deleted: true,
    });
    if (type == 'memo') this.memos = JSON.parse(JSON.stringify(this.memos));
    else this.highlights = JSON.parse(JSON.stringify(this.highlights));
    this.caseService?.caseRefChange?.emit({
      type: 'ref',
      ref: highlight,
      deleted: true,
    });
  }

  scrollTo(record: any) {
    this.router.navigateByUrl(
      caseLink(record.docId) + '?scrollTo=' + record.id,
    );
  }
}
