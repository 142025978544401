import {
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  NgZone,
  OnInit,
  Output,
  PLATFORM_ID,
} from '@angular/core';
import { CaseInfo } from '../../../../../ngxSpring/api.model';
import { DatePipe, isPlatformServer } from '@angular/common';
import { SearchService } from '../../../search/search.service';
import { ActivatedRoute } from '@angular/router';
import { debounceTime, firstValueFrom } from 'rxjs';

@Component({
  selector: 'lb-daily-list',
  templateUrl: './daily-list.component.html',
  styleUrls: ['./daily-list.component.sass'],
})
export class DailyListComponent implements OnInit {
  @Input()
  label;

  @Input()
  tag;

  @Input()
  page;

  @Input()
  withTime = true;

  private list: any[];

  @Input()
  set data(data: any[]) {
    if (!data) {
      return;
    }
    this.list = data;
    this.setData();
  }

  setData() {
    this.sortByDay = {};
    this.list.forEach((it) => {
      const dayString = this.datePipe.transform(
        it[this.dateFieldName],
        'yyyy-MM-dd',
      );
      if (!this.sortByDay[dayString]) {
        this.sortByDay[dayString] = [];
      }
      this.sortByDay[dayString].push(it);
    });
    this.dateLabels = Object.keys(this.sortByDay).sort((a, b) =>
      b.localeCompare(a),
    );
    this.dateLabels.forEach((it) => {
      this.sortByDay[it].sort(
        (a, b) => Number(b[this.dateFieldName]) - Number(a[this.dateFieldName]),
      );
    });
  }

  public desc = false;

  sorted() {
    if (!this.desc) {
      this.dateLabels = Object.keys(this.sortByDay).sort((a, b) =>
        a.localeCompare(b),
      );
    } else {
      this.dateLabels = Object.keys(this.sortByDay).sort((a, b) =>
        b.localeCompare(a),
      );
    }
  }

  deleted(data) {
    this.list.remove(data);
    this.setData();
  }

  dateLabels = [];
  today = '';
  sortByDay: { [key: string]: CaseInfo[] } = {};

  hasScrolledIntoView = false;

  isServer = isPlatformServer(this.platform);

  @Input()
  template;

  @Input()
  titleTemplate;

  @Input()
  ifEmpty;

  @Input()
  dateFieldName = 'date';

  @Input()
  withSort = true;

  @Output()
  recordClick = new EventEmitter();

  constructor(
    @Inject(PLATFORM_ID) private platform,
    private datePipe: DatePipe,
    public search: SearchService,
    private route: ActivatedRoute,
    protected zone: NgZone,
    protected element: ElementRef,
  ) {}

  ngOnInit(): void {
    this.today = this.datePipe.transform(new Date(), 'yyyy-MM-dd');

    if (this.isServer) {
      return;
    }

    void this.scrollToTargetSeenCase();
  }

  /**
   * TODO:
   *   해당 기능을 당장은 사용하지 않음.
   *   추후에 필요해질 경우, 페이지 진입 시 `caseId` query param 만 같이 전달해주면 기능 동작 함.
   *   구현해 놓은거 지우기 아까워서 냄겨 둡니다...
   *
   * 'caseId' queryParam 에 해당하는 target 요소로 scroll 하고,
   * highlight animation 을 보여준다
   */
  async scrollToTargetSeenCase() {
    const { caseId } = await firstValueFrom(this.route.queryParams);

    // 화면 렌더링이 끝난 이후를 알기 위해 `onStable` EventEmitter 를 사용함.
    this.zone.onStable.pipe(debounceTime(500)).subscribe(() => {
      const targetElem = document.querySelector(`[data-case-id=${caseId}]`);

      if (!caseId || !targetElem || this.hasScrolledIntoView) {
        return;
      }

      // 화면에 target 요소가 들어올 때 class 를 추가해주기 위한 observer
      const observer = new IntersectionObserver(
        (entries) => {
          for (const { intersectionRatio } of entries) {
            if (intersectionRatio === 1) {
              targetElem.classList.add('scroll-target');
            }
          }
        },
        { threshold: 1 },
      );
      observer.observe(targetElem);

      // target 요소로 scroll
      targetElem.scrollIntoView({ behavior: 'smooth', block: 'center' });

      // 자동 scroll 을 1번만 실행하기 위한 flag
      this.hasScrolledIntoView = true;
    });
  }

  labelIncludes(token) {
    return this.label?.includes(token);
  }

  dayLabel(day: any) {
    if (this.today === day) {
      return '오늘';
    }
    return `${Number(day.split('-')[2])}일`;
  }

  monthLabel(day: any) {
    return `${Number(day?.split('-')[0])}년 ${Number(day?.split('-')[1])}월`;
  }
}
