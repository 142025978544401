/* tslint:disable */
import {Injectable} from '@angular/core';

import {ArchiveCaseController} from './controllers/archive.case.controller';
import {ArchiveFolderController} from './controllers/archive.folder.controller';
import {AttorneyProfileController} from './controllers/attorney.profile.controller';
import {CalendarMemoController} from './controllers/calendar.memo.controller';
import {CaseCountController} from './controllers/case.count.controller';
import {CaseManageController} from './controllers/case.manage.controller';
import {CaseNameGroupController} from './controllers/case.name.group.controller';
import {CaseReportController} from './controllers/case.report.controller';
import {CaseStatsController} from './controllers/case.stats.controller';
import {ClientTrackingController} from './controllers/client.tracking.controller';
import {CommentaryController} from './controllers/commentary.controller';
import {CompanyContractController} from './controllers/company.contract.controller';
import {CompanyController} from './controllers/company.controller';
import {CompanyManagerController} from './controllers/company.manager.controller';
import {CompanyPlanUserController} from './controllers/company.plan.user.controller';
import {EmailController} from './controllers/email.controller';
import {EmailVerifyController} from './controllers/email.verify.controller';
import {GoogleCalendarPermissionController} from './controllers/google.calendar.permission.controller';
import {HolidayListController} from './controllers/holiday.list.controller';
import {IamportCertificateController} from './controllers/iamport.certificate.controller';
import {IamportPaymentController} from './controllers/iamport.payment.controller';
import {JudgeEditCaseController} from './controllers/judge.edit.case.controller';
import {JudgeProfileController} from './controllers/judge.profile.controller';
import {LawSchoolAuthorizationController} from './controllers/law.school.authorization.controller';
import {LawSubscribeController} from './controllers/law.subscribe.controller';
import {LawyerVerifyController} from './controllers/lawyer.verify.controller';
import {LbCaseController} from './controllers/lb.case.controller';
import {LboxProductController} from './controllers/lbox.product.controller';
import {LboxUserController} from './controllers/lbox.user.controller';
import {LqaQuestionController} from './controllers/lqa.question.controller';
import {MatchedLawyerController} from './controllers/matched.lawyer.controller';
import {MatchedRecordReplyController} from './controllers/matched.record.reply.controller';
import {MyCaseRegisterController} from './controllers/my.case.register.controller';
import {MyHighlightController} from './controllers/my.highlight.controller';
import {NewLawyerController} from './controllers/new.lawyer.controller';
import {NewsController} from './controllers/news.controller';
import {PointLogController} from './controllers/point.log.controller';
import {PricePlanPaymentController} from './controllers/price.plan.payment.controller';
import {ReadCaseController} from './controllers/read.case.controller';
import {ReferenceController} from './controllers/reference.controller';
import {RequestCaseController} from './controllers/request.case.controller';
import {RequestRecordController} from './controllers/request.record.controller';
import {SearchLogController} from './controllers/search.log.controller';
import {SharedFolderController} from './controllers/shared.folder.controller';
import {SmsVerifyController} from './controllers/sms.verify.controller';
import {SnsAuthController} from './controllers/sns.auth.controller';
import {StatuteController} from './controllers/statute.controller';
import {SubstituteController} from './controllers/substitute.controller';
import {TrackIdController} from './controllers/track.id.controller';
import {UploadFileController} from './controllers/upload.file.controller';
import {UserAlarmController} from './controllers/user.alarm.controller';
import {UserController} from './controllers/user.controller';
import {UsersNotificationController} from './controllers/users.notification.controller';
import {UserHasPlanController} from './controllers/user.has.plan.controller';
import {UserInvolveCaseController} from './controllers/user.involve.case.controller';
import {UserTokenController} from './controllers/user.token.controller';
import {ZendeskController} from './controllers/zendesk.controller';

@Injectable()
export class ApiService {
  constructor(
    public archiveCase: ArchiveCaseController,
    public archiveFolder: ArchiveFolderController,
    public attorneyProfile: AttorneyProfileController,
    public calendarMemo: CalendarMemoController,
    public caseCount: CaseCountController,
    public caseManage: CaseManageController,
    public caseNameGroup: CaseNameGroupController,
    public caseReport: CaseReportController,
    public caseStats: CaseStatsController,
    public clientTracking: ClientTrackingController,
    public commentary: CommentaryController,
    public company: CompanyController,
    public companyContract: CompanyContractController,
    public companyManager: CompanyManagerController,
    public companyPlanUser: CompanyPlanUserController,
    public email: EmailController,
    public emailVerify: EmailVerifyController,
    public googleCalendarPermission: GoogleCalendarPermissionController,
    public holidayList: HolidayListController,
    public iamportCertificate: IamportCertificateController,
    public iamportPayment: IamportPaymentController,
    public judgeEditCase: JudgeEditCaseController,
    public judgeProfile: JudgeProfileController,
    public lawSchoolAuthorization: LawSchoolAuthorizationController,
    public lawSubscribe: LawSubscribeController,
    public lawyerVerify: LawyerVerifyController,
    public lbCase: LbCaseController,
    public lboxProduct: LboxProductController,
    public lboxUser: LboxUserController,
    public lqaQuestion: LqaQuestionController,
    public matchedLawyer: MatchedLawyerController,
    public matchedRecordReply: MatchedRecordReplyController,
    public myCaseRegister: MyCaseRegisterController,
    public myHighlight: MyHighlightController,
    public newLawyer: NewLawyerController,
    public news: NewsController,
    public pointLog: PointLogController,
    public pricePlanPayment: PricePlanPaymentController,
    public readCase: ReadCaseController,
    public reference: ReferenceController,
    public requestCase: RequestCaseController,
    public requestRecord: RequestRecordController,
    public searchLog: SearchLogController,
    public sharedFolder: SharedFolderController,
    public smsVerify: SmsVerifyController,
    public snsAuth: SnsAuthController,
    public statute: StatuteController,
    public substitute: SubstituteController,
    public trackId: TrackIdController,
    public uploadFile: UploadFileController,
    public user: UserController,
    public usersNotification: UsersNotificationController,
    public userAlarm: UserAlarmController,
    public userHasPlan: UserHasPlanController,
    public userInvolveCase: UserInvolveCaseController,
    public userToken: UserTokenController,
    public zendesk: ZendeskController,
  ) {}
}
