import { Pipe, PipeTransform } from '@angular/core';
import { ReadService } from '../core/read.service';

@Pipe({
  name: 'isRead',
})
export class IsReadPipe implements PipeTransform {
  constructor(public read: ReadService) {}

  transform(value) {
    return this.read.isRead(value);
  }
}
