<mat-select
  panelClass="fitContent"
  [ngModel]="association"
  (ngModelChange)="associationChange.emit($event)"
  placeholder="변호사회 선택"
>
  <mat-option *ngFor="let option of associations" [value]="option">{{
    option
  }}</mat-option>
</mat-select>
