<nav *ngIf="pages.length" fxLayout fxLayoutAlign="center center">
  <span class="mr-40 arrow-btn" trackId="72ad"  (click)="startMinus()" *ngIf="startMinusAble">
    <span
      class="icon2 n-34 reverse pagination-reverse-icon"
      [ngClass]="startMinusAble ? 'h-4' : 'h-0'"
    ></span>
  </span>
  <ul>
    <li
      *ngFor="let i of pages | slice: start:start + paginationLimit"
      trackId="601d"  (click)="change(i - 1)"
      [class.active]="i - 1 === pageable?.pageNumber"
    >
      {{ i }}
    </li>
  </ul>
  <span class="ml-40 arrow-btn" trackId="57a0"  (click)="startPlus()" *ngIf="startPlusAble">
    <span class="icon2 n-34 pagination-icon" [ngClass]="startPlusAble ? 'h-4': 'h-0'"></span>
  </span>
</nav>
