<ng-container *ngIf="phase == 0">
  <div class="title">
    <h5 fxLayout="row">
      <span fxFlex="auto">내 판례등록 신청</span>
      <lb-my-case-tip class="mt--6" [tip]="tipInstance"><span class="color-primary font-16">이용안내</span></lb-my-case-tip>
    </h5>
    <div class="mt-24 mb-24">
      개인정보에 대한 익명화 작업 후, 변호사님의 최종 승인을 거쳐 안전하게
      등록됩니다.
    </div>
    <div class="mb-32">
      <div fxLayout="row" fxLayoutAlign="start start" class="mb-6">
        <span class="icon3 mt-2 mr-6 n-49 h-4" fxFlex="none"></span>
        <div>
          변호사님과 소송대리인/변호인의 정보가 일치하는 경우만 등록 가능합니다.
        </div>
      </div>
      <div fxLayout="row" fxLayoutAlign="start start">
        <span class="icon3 mt-2 mr-6 n-49 h-4" fxFlex="none"></span>
        <div>
          개인정보에 대한 익명화 작업 후, 변호사님의 최종 승인을 거쳐 안전하게
          등록됩니다.
        </div>
      </div>
    </div>
    <div>
      <div class="file no-bottom-radius" *ngIf="validFiles?.length">
        <div fxLayout="row" fxLayoutAlign="center center">
          <div fxFlex="auto">
            총 {{ validFiles?.length }}개 항목을 업로드
            <span *ngIf="!done">하는 중 입니다.</span>
            <span *ngIf="done">하였습니다.</span>
          </div>
          <span class="icon icon-delete" trackId="92a5" (click)="files = []"></span>
        </div>
        <div class="progress">
          <span [style.right]="done ? 0 : ((sum - progressSum) / sum) * 100 + '%'"></span>
        </div>
        <div class="font-12 color-gray">
          <ng-container *ngIf="!done">
            {{ progressSum | fileSize }}/{{ sum | fileSize }}
          </ng-container>
          <ng-container *ngIf="done">완료</ng-container>
        </div>
      </div>
      <lb-upload-area *ngIf="!files?.length" (filesChange)="fileUpload($event)"
        (onDragOverChange)="$event ? tipInstance.hide() : tipInstance.show()">
        <span #tipInstance="tip" [lbTip]="{
            delay: 1000,
            tip: tip,
            trigger: 'manual',
            showOnCreate: true,
            theme: 'blue',
            hideOnClick: false,
            placement: 'bottom'
          }">판례 업로드하기
          <span #tip><span class="vertical-middle">1건 당 5,000포인트 지급</span><span lbMyCaseRegisterEventAlert
              [tip]="tipInstance" class="icon3 n-11 small vertical-middle ml-2 h-1"></span></span></span>
      </lb-upload-area>
      <div class="files" *ngIf="files?.length" [class.top-radius]="!validFiles?.length">
        <div class="file" fxLayout="row" *ngFor="let file of files" fxLayoutAlign="center center">
          <img src="../assets/document.svg" style="width: 24px; margin-right: 16px" />
          <div fxFlex="auto">
            <div fxLayout="row" fxLayoutAlign="center center">
              <div fxFlex="auto">
                {{ file.file.name }}
              </div>
              <span class="icon icon-delete" fxFlex="none" trackId="cd07" (click)="files.remove(file)"></span>
            </div>
            <div class="progress">
              <span [style.right]="
                  file.done
                    ? 0
                    : ((file.file.size - file.progressSize) / file.file.size) *
                        100 +
                      '%'
                "></span>
            </div>
            <div class="font-12 color-warn" *ngIf="file.error">
              {{ file.error }}
            </div>
            <div class="font-12 color-gray" *ngIf="!file.error">
              <ng-container *ngIf="!file.done">
                {{ file.progressSize | fileSize }}/{{
                file.file.size | fileSize
                }}>
              </ng-container>
              <ng-container *ngIf="file.done">완료</ng-container>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="anon-option">
      <div class="mb-16">
        <b>익명화 옵션</b>
      </div>
      <mat-radio-group [(ngModel)]="nameHide">
        <mat-radio-button class="mb-8" [value]="false">
          {{ name }} 변호사님의 성함을 판례에 표시합니다(변호사님의 성함이
          익명화되지 않음).
        </mat-radio-button>
        <mat-radio-button [value]="true">
          {{ name }} 변호사님의 성함도 익명화 합니다.
        </mat-radio-button>
      </mat-radio-group>
    </div>
  </div>
  <div class="buttons mt-0">
    <button class="cancel" trackId="ba1b" (click)="close(null)">취소</button>
    <button class="blue" trackId="9c28" (click)="phase = 1" [class.disabled]="!done || !validFiles.length">
      다음
    </button>
  </div>
</ng-container>
<ng-container *ngIf="phase == 1">
  <div class="title">
    <h5>내 판례등록 유의사항 확인</h5>
    <div>
      <div class="mb-16">아래의 경우에는 등록 불가합니다.</div>
      <div class="mb-6">· 판결문 내용을 임의로 수정한 경우</div>
      <div class="mb-6">· 판결문에 워터마크가 있는 경우</div>
      · 판결문을 카메라로 찍어서 올린 경우
    </div>
    <div class="anon-option">
      <div class="mb-12">
        <mat-checkbox class="bold" [(ngModel)]="agree2">
          유의사항을 확인하였습니다.
        </mat-checkbox>
      </div>
      <mat-checkbox class="bold" [(ngModel)]="agree">
        내 판례 등록 서비스 운영정책에 동의합니다.
      </mat-checkbox>
      <span class="color-primary vertical-middle ml-8 inline-block" trackId="cabc"
        lbNewTabLink="/rule/operation/register">내용보기</span>
    </div>
  </div>
  <div class="buttons mt-0">
    <button class="cancel" [class.disabled]="isRegistering" (click)="close(null)" trackId="a663">취소</button>
    <button *ngIf="!isRegistering" class="blue" [class.disabled]="!done || !validFiles.length || !agree || !agree2"
      (click)="registerAndClose()" trackId="8ce2">
      등록 신청하기
    </button>
    <button *ngIf="isRegistering" class="blue disabled">판례를 등록하는 중</button>
  </div>
</ng-container>