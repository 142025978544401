<ng-container *showItSizes="{ min: 960 }">
  <div class="title">
    <h5 class="mb-24">
      <span class="vertical-middle"
        >{{ user.user.name }} 변호사님이 수행한 사건을 알려주세요
      </span>
    </h5>
    <p>
      <b>‘{{ user.user.name }} 변호사’</b>님으로 검색되는 판례 중
      <b>수행여부가 확인되지 않은 판례가 {{ user.candidateCases?.length }}건</b>
      있습니다.<br />
      {{ user.user.name }} 변호사님의 전문성이 알려질 수 있도록 수행여부를
      표시한 후 완료버튼을 눌러주세요.
    </p>
  </div>
  <div class="table mt-8">
    <table>
      <tbody>
        <tr style="border-top: none; border-bottom: 1px solid #eeeeee">
          <td class="name">
            수행여부가 확인되지 않은 판례 {{ user.candidateCases?.length }}건
          </td>
          <td class="name" style="width: 104px">수행여부(Y/N)</td>
        </tr>
      </tbody>
    </table>
    <div class="scroller">
      <table>
        <tbody>
          <tr *ngFor="let item of user.candidateCases; let i = index">
            <td
              class="case"
              trackId="bb95"
              [lbNewTabLink]="'/' + item.doc_id + '#check'"
            >
              {{ item.doc_id }} {{ item.casename }}
            </td>
            <td style="width: 104px">
              <mat-radio-group [(ngModel)]="item.type">
                <mat-radio-button value="Y" class="mr-24">Y </mat-radio-button>
                <mat-radio-button value="N">N </mat-radio-button>
              </mat-radio-group>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <div class="divider mb-48"></div>
  <div class="ml-24 mb-24 mt-24">
    <b> 내가 수행한 사건이 엘박스에서 검색되지 않나요? </b>
    <a
      class="color-primary ml-12"
      trackId="0339"
      (click)="user.myCaseRegister()"
    >
      내 판례등록하기
    </a>
    <div class="color-gray mt-4">
      (내 판례등록을 통해 제공해주신 판례는 수행한 사건으로 자동 분류됩니다.)
    </div>
  </div>
  <div class="buttons">
    <button class="cancel" trackId="7091" (click)="close(); user.selectLater()">
      나중에
    </button>
    <button
      class="blue"
      [class.disabled]="!selected"
      trackId="89b5"
      (click)="selectDone()"
    >
      완료
    </button>
  </div>
</ng-container>

<ng-container *showItSizes="{ max: 959 }">
  <div style="height: 100%" fxLayout="column">
    <div class="title pb-12">
      <h5 class="mb-12">
        <span class="vertical-middle font-16"
          >{{ user.user.name }} 변호사님이 수행한 사건을 알려주세요
        </span>
      </h5>
      <p>
        <b>‘{{ user.user.name }} 변호사’</b>님으로 검색되는 판례 중
        <b
          >수행여부가 확인되지 않은 판례가
          {{ user.candidateCases?.length }}건</b
        >
        있습니다.<br />
        {{ user.user.name }} 변호사님의 전문성이 알려질 수 있도록 수행여부를
        표시한 후 완료버튼을 눌러주세요.
      </p>
    </div>
    <div class="table mt-8 scroller" fxFlex="auto">
      <table>
        <tbody>
          <tr *ngFor="let item of user.candidateCases; let i = index">
            <td
              class="case"
              trackId="f8e5"
              [lbNewTabLink]="'/' + item.doc_id + '#check'"
            >
              {{ item.doc_id }} {{ item.casename }}
            </td>
            <td style="width: 104px">
              <mat-radio-group [(ngModel)]="item.type">
                <mat-radio-button value="Y" class="mr-24">Y </mat-radio-button>
                <mat-radio-button value="N">N </mat-radio-button>
              </mat-radio-group>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="bottom">
      <div class="ml-24 mt-16 mr-24">
        <div class="color-gray">
          내 판례등록을 통해 제공해주신 판례는 수행한 사건으로 자동 분류됩니다.
        </div>
      </div>
      <div class="buttons">
        <button
          class="cancel"
          trackId="9458"
          (click)="close(); user.selectLater()"
        >
          나중에
        </button>
        <button
          class="blue"
          [class.disabled]="!selected"
          trackId="9186"
          (click)="selectDone()"
        >
          완료
        </button>
      </div>
    </div>
  </div>
</ng-container>
