<div class="title alert-dialog">
  <h5>알림</h5>
  <div [innerHTML]="data.replace('\n', '<br>')" class="desc"></div>
  <div *ngIf="user.hasMembershipChance">
    스탠다드 플랜을 7일동안 무료로 이용해보세요.
  </div>
</div>
<div class="buttons">
  <button
    class="cancel"
    trackId="828c"
    (click)="close(null)"
    [trackData]="{ text: data }"
    *ngIf="data.cancel != false"
  >
    닫기
  </button>
  <button
    class="blue"
    trackId="87d7"
    [trackData]="{ text: data }"
    (click)="close(true)"
  >
    스탠다드 플랜 혜택 보러가기
  </button>
</div>
