import { NgModule } from '@angular/core';
import { NewTabLinkDirective } from './new-tab-link.directive';
import { ZenDeskDirective } from './zen-desk.directive';
import { CommentaryLinkDirective } from '../../search/components/search-result-commentary/commentary-link.directive';
import { FileSizePipe } from '../../shared/file-upload-list/file-size.pipe';

@NgModule({
  declarations: [
    NewTabLinkDirective,
    ZenDeskDirective,
    CommentaryLinkDirective,
    FileSizePipe,
  ],
  exports: [
    NewTabLinkDirective,
    ZenDeskDirective,
    CommentaryLinkDirective,
    FileSizePipe,
  ],
})
export class LinkDirectivesModule {}
