/* tslint:disable */
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ApiHttp} from '../api.http';

import {UserAlarm} from '../api.model';

@Injectable()
export class UsersNotificationController {
  constructor(private http: ApiHttp) {}

  updateMarketingAgreementAll(userId: number, agree: boolean): Observable<UserAlarm> {
    return this.http.patch<UserAlarm>(`/api/users/${userId}/notifications/settings/marketing`, { agree });
  }
}
