import { Component } from '@angular/core';
import { FileUploadListComponent } from '../../shared/file-upload-list/file-upload-list.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { FormsModule } from '@angular/forms';
import { LinkDirectivesModule } from '../link-directives/link-directives.module';
import { FlexModule } from '@angular/flex-layout';
import { UploadAreaComponent } from '../../shared/upload-area/upload-area.component';
import { NgForOf, NgIf } from '@angular/common';
import {
  LawyerVerify,
  S3Signature,
  UploadMyCaseParams,
} from '../../../../ngxSpring/api.model';
import { Observable } from 'rxjs';

@Component({
  selector: 'lb-lawyer-verify-by-upload',
  templateUrl: './lawyer-verify-by-upload.component.html',
  styleUrls: ['./lawyer-verify-by-upload.component.sass'],
  standalone: true,
  imports: [
    MatCheckboxModule,
    FormsModule,
    LinkDirectivesModule,
    FlexModule,
    UploadAreaComponent,
    NgIf,
    NgForOf,
  ],
})
export class LawyerVerifyByUploadComponent extends FileUploadListComponent {
  getSignature(file: File): Observable<S3Signature> {
    const ext = file.name.split('.').pop().toLowerCase();
    if (ext !== 'jpg' && ext !== 'png') {
      this.alert.pop('JPG, PNG 파일만 업로드 가능합니다.');
      return;
    }
    return this.api.uploadFile.lawyerUploadSignature({
      filename: file.name,
      size: file.size,
    } as UploadMyCaseParams);
  }

  verifyRequest() {
    this.api.lawyerVerify
      .saveLawyerVerify({
        files: this.validFiles.map((it) => it.path),
        verifyType: (this.data as any).from,
      } as LawyerVerify)
      .subscribe(() => {
        this.alert
          .alert(
            '변호사 인증 신청 완료',
            null,
            (this.data as any).uploadDoneMessage ||
              '승인 절차는 1~2 영업일이 소요되며, 완료시\n엘박스 홈페이지에서 알림을 드립니다.',
          )
          .then(() => {
            this.close(true);
          });
      });
  }
}
