<div class="title">
  <h5>{{ data.yearOnly ? '자격 정보 변경' : '회원 유형 변경' }}</h5>
</div>
<div class="warning">
  <img src="/assets/icon-warning.png" alt="경고 아이콘" class="warning-icon">
  <p *ngIf="isAuthorizedLawyer" class="warning-text">
    변호사 인증 고객은 회원 유형 변경이 불가합니다.<br />
    관련 문의사항은 <a href="https://support.lbox.kr/hc/ko/requests/new?ticket_form_id=5237251656847" target="_blank" class="support-link">고객센터</a>로 문의해 주세요.
  </p>
  <p *ngIf="!isAuthorizedLawyer" class="warning-text">
    허위 정보 입력시 향후 서비스 이용이 제한될 수 있습니다.
  </p>
</div>
<div class="input-wrapper">
  <lb-lbox-user-type 
    [isAuthorizedLawyerChangeJobDisabled]="isAuthorizedLawyer"
    [showsWarningText]="false" 
    [yearOnly]="data.yearOnly" 
    [job]="job" 
  ></lb-lbox-user-type>
</div>
<div class="terms-and-conditions-wrapper">
  <div *ngIf="isTermsAndConditionsShown" class="heading">
    <p class="title-text" role="button" (click)="toggleTermsAndConditions()">
      개인정보 수집 및 이용 동의{{ jobName ? '(' + jobName + ')' : ''}}
      <img 
        src="/assets/icon-caret-up-bold.png" 
        alt="약관 펼치기/접기" 
        class="fold-expand-arrow-icon" 
        [ngClass]="{ expanded: isTermsAndConditionsExpanded, folded: !isTermsAndConditionsExpanded}"
      />
    </p>
    <mat-checkbox [(ngModel)]="agreesOnTermsAndConditions">동의(필수)</mat-checkbox>
  </div>
  <div *ngIf="isTermsAndConditionsShown" class="content" [ngClass]="{ expanded: isTermsAndConditionsExpanded, folded: !isTermsAndConditionsExpanded }">
    <p>
      개인정보 보호를 위하여 아래의 개인정보 수집∙이용에 대한 내용을 자세히 읽어 보신 후 동의 여부를 결정하여 주시기 바랍니다.
    </p>
    <ol class="terms-and-conditions-items">
      <li *ngFor="let termsAndConditionItem of termsAndConditionsItemsByJobName" class="terms-and-conditions-item">
        <span [class.bold]="termsAndConditionItem.bold">{{ termsAndConditionItem.text }}</span>
        <ol *ngIf="termsAndConditionItem.subTexts" class="terms-and-conditions-sub-items">
          <li *ngFor="let subText of termsAndConditionItem.subTexts">
            {{ subText }}
          </li>
        </ol>
      </li>
    </ol>
    <p>
      귀하는 위 개인정보 수집∙이용에 대한 동의를 거부하실 수 있으며, 동의하지 않을 경우 서비스 이용에 제한이 있을 수 있습니다.
    </p>
  </div>
</div>
<div class="buttons">
  <button class="cancel" trackId="4adc" (click)="close()">취소</button>
  <button
    class="blue"
    trackId="830c"
    (click)="update()"
    [class.disabled]="isModifyingButtonDisabled"
  >
    변경하기
  </button>
</div>
