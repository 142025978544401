import { AfterViewInit, Component, Input } from '@angular/core';
import { DailyListComponent } from '../../user/components/daily-list/daily-list.component';
import { debounceTime } from 'rxjs';

@Component({
  selector: 'lb-case-daily-list',
  templateUrl: './case-daily-list.component.html',
  styleUrls: ['./case-daily-list.component.sass'],
})
export class CaseDailyListComponent
  extends DailyListComponent
  implements AfterViewInit
{
  @Input()
  keyword;
  isServer = typeof window === 'undefined';

  ngAfterViewInit(): void {
    if (this.isServer) {
      return;
    }

    this.zone.onStable.pipe(debounceTime(500)).subscribe(() => {
      const thisElements = this.element.nativeElement as HTMLDivElement;
      const keywordElements = thisElements.querySelectorAll('.keyword');

      keywordElements.forEach((element: HTMLSpanElement) => {
        const { offsetHeight, scrollHeight } = element;
        if (offsetHeight < scrollHeight) {
          element.classList.add('ellipsis');
        }
      });
    });
  }
}
