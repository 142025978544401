/* tslint:disable */
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ApiHttp} from '../api.http';

import {AttorneyProfile} from '../api.model';

@Injectable()
export class AttorneyProfileController {
  constructor(private http: ApiHttp) {
  }

  getAttorneyProfile(): Observable<AttorneyProfile> {
    return this.http.get<AttorneyProfile>('/api/attorney/');
  }

  getAttorneyProfileDetail(name: string, serial: string): Observable<AttorneyProfile> {
    return this.http.get<AttorneyProfile>('/api/attorney/detail', {name: name, serial: serial});
  }

  save(attorneyProfile: AttorneyProfile): Observable<AttorneyProfile> {
    return this.http.post<AttorneyProfile>('/api/attorney/save', attorneyProfile);
  }

  updateAttorneyProfile(attorneyProfile: AttorneyProfile): Observable<AttorneyProfile> {
    return this.http.post<AttorneyProfile>('/api/attorney/update', attorneyProfile);
  }
}
