import { Component, OnInit } from '@angular/core';
import { DialogOrBottomSheetProto } from '../../../alert/alert.service';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'lb-youtube-embed',
  templateUrl: './youtube-embed.component.html',
  styleUrls: ['./youtube-embed.component.sass'],
})
export class YoutubeEmbedComponent
  extends DialogOrBottomSheetProto<string>
  implements OnInit
{
  url;

  ngOnInit(): void {
    this.url = this.injector
      .get(DomSanitizer)
      .bypassSecurityTrustResourceUrl(
        this.data.includes('?')
          ? this.data + '&autoplay=1&origin=https://lbox.kr'
          : this.data + '?autoplay=1&origin=https://lbox.kr',
      );
  }
}
