<div class="title">
  <h5>회원탈퇴</h5>
</div>
<div class="divider"></div>
<div [ngSwitch]="state">
  <div *ngSwitchDefault>
    <div class="wrap form">
      <div class="input-group text-left">
        <mat-select placeholder="계정 삭제 이유" [(ngModel)]="reason">
          <mat-option value="더이상 서비스가 필요하지않음"
            >더이상 서비스가 필요하지 않음</mat-option
          >
          <mat-option value="더 좋은 타사 서비스를 찾음"
            >더 좋은 타사 서비스를 찾음</mat-option
          >
          <mat-option value="기술적인 문제">기술적인 문제</mat-option>
          <mat-option value="개인정보 보호에 대한 불안감"
            >개인정보 보호에 대한 불안감</mat-option
          >
          <mat-option value="재가입">재가입</mat-option>
          <mat-option value="기타">기타</mat-option>
        </mat-select>
      </div>
      <textarea
        [(ngModel)]="reasonDetail"
        [placeholder]="'자세한 설명' + (reason === '기타' ? '' : '(선택사항)')"
        style="min-height: 100px"
        autosize
      ></textarea>
      <p class="guide-message">
        비즈니스 플랜으로 전환하는 경우, 이메일주소 변경만으로도 이용할 수
        있습니다.
      </p>
    </div>
    <div class="buttons">
      <button class="cancel" trackId="188f" (click)="close()">취소</button>
      <button class="blue" trackId="41e8" (click)="statePassword()">
        다음
      </button>
    </div>
  </div>
  <div *ngSwitchCase="'passwordConfirm'">
    <div class="wrap form">
      <input
        [(ngModel)]="password"
        type="password"
        placeholder="비밀번호 확인"
      />
    </div>
    <div class="buttons">
      <button class="cancel" trackId="cd5c" (click)="close()">취소</button>
      <button class="blue" trackId="593f" (click)="checkPassword()">
        다음
      </button>
    </div>
  </div>
  <div *ngSwitchCase="'confirm'">
    <div class="wrap form text-left">
      <ng-container *ngIf="!user.plan">
        엘박스 회원 탈퇴 시 저장된 모든 데이터 및 포인트는 영구 삭제되며, 더
        이상 {{ user.counts || 47 }}만 건 이상의 판례와 다양한 사건관리 기능을
        이용할 수 없습니다. 정말로 탈퇴하시겠습니까?
      </ng-container>
      <ng-container *ngIf="user.plan">
        엘박스 회원 탈퇴 시 저장된 모든 데이터 및 포인트는 영구 삭제되며, 잔여 이용기간은 소멸됩니다. 정말로 탈퇴하시겠습니까?
        <div class="mt-24">
          <div *ngIf="user.plan">
            이용기간: ~ {{ planExpiredDate | date: 'yyyy.M.d' }}까지
          </div>
          <div *ngIf="user.lboxUser?.point">
            보유 포인트: {{ user.lboxUser?.point | number }} 포인트
          </div>
        </div>
      </ng-container>

      <div *ngIf="user.plan?.cancelable" class="font-12 mt-12 color-gray">
        * 결제일로부터 7일 이내 해지 시 결제금액은 환불됩니다. (단, 스탠다드
        플랜 기능을 일절 사용하지 않은 경우에 한함)
      </div>
      <div class="font-12 mt-12 color-gray">
        * 7일 무료 이용 혜택은 실명 기준 1회만 이용 가능하며, 탈퇴 후 재가입
        하시는 경우 다시 적용되지 않습니다.
      </div>

      <mat-checkbox class="font-14 mt-24" [(ngModel)]="withdrawOk"
        >회원탈퇴 안내를 모두 확인하였으며 회원탈퇴에 동의합니다.
      </mat-checkbox>
    </div>
    <div class="buttons">
      <button class="cancel" trackId="4855" (click)="close()">취소</button>
      <button class="blue red" trackId="45b7" (click)="withdraw()">탈퇴</button>
    </div>
  </div>
</div>
