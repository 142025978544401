import { Injectable } from '@angular/core';
import { UserService } from '../user/user.service';

@Injectable()
export class RegisterService {
  agree;
  marketingAgree;
  isNormalTransfer;
  isSnsTransfer;
  verifiedKey;

  get isTransfer() {
    return this.isNormalTransfer || this.isSnsTransfer;
  }

  get agreeRequired() {
    return this.userService.user && !this.agree;
  }

  constructor(private userService: UserService) {}

  setType(verifiedKey: any) {
    this.verifiedKey = verifiedKey;
    this.isSnsTransfer =
      !!this.userService.user && !!this.userService.user.snsEmail;
    if (this.isSnsTransfer) {
      return;
    }
    this.isNormalTransfer = !!this.userService.user;
  }
}
