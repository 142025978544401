import { isPlatformBrowser } from '@angular/common';
import { ErrorHandler, Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { datadogLogs } from '@datadog/browser-logs';
import { error as errorLog } from 'src/log';

@Injectable()
export class ChunkErrorHandlerService implements ErrorHandler {
  constructor(@Inject(PLATFORM_ID) private platform) {}

  handleError(error: any): void {
    if (!error) {
      return;
    }

    if (isPlatformBrowser(this.platform)) {
      datadogLogs.logger.error(
        'angular 최상단에서 발생한 에러',
        { errorInfo: error },
        error,
      );

      errorLog(error);
    }

    const chunkFailedMessage = /Loading chunk [\d]+ failed/;
    if (error.message && chunkFailedMessage.test(error.message)) {
      window.location.reload();
    }
  }
}
