<!-- FileTrackOFF -->
<input [(ngModel)]="keyword" />

<button (click)="toggleBody()">보기</button>
<button (click)="notRegistered = !notRegistered">
  <ng-container *ngIf="notRegistered">등록포함</ng-container
  ><ng-container *ngIf="!notRegistered">미등록</ng-container>
</button>
<button
  (click)="viewOnly = !viewOnly"
  [disabled]="!gaService.showTracks?.length"
>
  화면에서
</button>
<button (click)="new()">새로등록</button>

<div *ngFor="let id of remains" (mouseover)="find(id.name)">
  <input [value]="id.name" (click)="input.select()" #input />
  <span> {{ id.key }}</span>
  <span *ngIf="gaService.showTracks.includes(id.name)"> o</span>
</div>
