/* tslint:disable */
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ApiHttp} from '../api.http';

import {InteractType, PageImpl, ReadCase} from '../api.model';

@Injectable()
export class ReadCaseController {
  constructor(private http: ApiHttp) {
  }

  deleteReadCase(id: number): Observable<void> {
    return this.http.delete<void>('/api/seenCase', {id: id});
  }

  getDayList(from: number, to: number): Observable<string[]> {
    return this.http.get<string[]>('/api/seenCase/dayList', {from: from, to: to});
  }

  getList(page?: number, size?: number, from?: number, to?: number, type?: InteractType): Observable<PageImpl<ReadCase>> {
    return this.http.get<PageImpl<ReadCase>>('/api/seenCase/list', {page: page, size: size, from: from, to: to, type: type});
  }

  getMonthList(): Observable<string[]> {
    return this.http.get<string[]>('/api/seenCase/monthList');
  }

  getReadCase(id: number): Observable<ReadCase> {
    return this.http.get<ReadCase>('/api/seenCase', {id: id});
  }

  getRecentList(): Observable<ReadCase[]> {
    return this.http.get<ReadCase[]>('/api/seenCase/list/recent');
  }

  setActionFlag(docId: string, memo?: boolean, highlight?: boolean, shared?: boolean, printed?: boolean): Observable<ReadCase> {
    return this.http.post<ReadCase>('/api/seenCase/actionFlag', null, {docId: docId, memo: memo, highlight: highlight, shared: shared, printed: printed});
  }
}
