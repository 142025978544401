<div
  class="line-button"
  trackId="c773"
  (click)="
    folder.select = !folder.select;
    folderService.selectionChange.emit(folder);
    folderService.rootSelected = false
  "
  [ngStyle]="{ paddingLeft: (level + 1) * 24 + 'px' }"
  [class.active]="folder.select"
>
  <span
    class="icon2 n-80"
    [class.h-2]="folder.select"
    [class.n-95]="folderService.isSharedFolder(folder) && !folder.select"
  ></span>
  <span class="folder-name">{{ folder.name }}</span>
  <span class="icon icon-check"></span>
</div>
<div *ngIf="folder.folders">
  <lb-select-folder
    [selectSingle]="selectSingle"
    [level]="level + 1"
    *ngFor="let f of folder.folders"
    [folder]="f"
  ></lb-select-folder>
</div>
