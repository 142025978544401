/* tslint:disable */
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ApiHttp} from '../api.http';

import {GoogleCalendarPermission} from '../api.model';

@Injectable()
export class GoogleCalendarPermissionController {
  constructor(private http: ApiHttp) {
  }

  getGoogleCalendarPermission(): Observable<GoogleCalendarPermission> {
    return this.http.get<GoogleCalendarPermission>('/api/googleCalendarPermission');
  }

  registerToken(code: string, redirectUri: string): Observable<GoogleCalendarPermission> {
    return this.http.post<GoogleCalendarPermission>('/api/googleCalendarPermission/registerToken', null, {code: code, redirectUri: redirectUri});
  }

  revokePermission(): Observable<void> {
    return this.http.delete<void>('/api/googleCalendarPermission');
  }
}
