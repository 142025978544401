/* tslint:disable */
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ApiHttp} from '../api.http';

import {ArchiveCase, ArchiveCaseInfo, Page} from '../api.model';

@Injectable()
export class ArchiveCaseController {
  constructor(private http: ApiHttp) {}

  deleteArchiveCase(id: number): Observable<void> {
    return this.http.delete<void>('/api/archiveCase', { id: id });
  }

  getArchiveCaseList(
    page?: number,
    size?: number,
    folderId?: number,
  ): Observable<Page<ArchiveCaseInfo>> {
    return this.http.get<Page<ArchiveCaseInfo>>('/api/archiveCase/list', {
      page: page,
      size: size,
      folderId: folderId,
    });
  }

  getArchiveCaseListAll(folderId?: number): Observable<ArchiveCaseInfo[]> {
    return this.http.get<ArchiveCaseInfo[]>('/api/archiveCase/list/all', {
      folderId: folderId,
    });
  }

  getRecentArchiveCaseList(): Observable<ArchiveCaseInfo[]> {
    return this.http.get<ArchiveCaseInfo[]>('/api/archiveCase/recent');
  }

  saveArchiveCase(
    folderIdList: any[],
    archiveCaseId: string,
  ): Observable<ArchiveCase[]> {
    return this.http.post<ArchiveCase[]>('/api/archiveCase', folderIdList, {
      archiveCaseId: archiveCaseId,
    });
  }

  updateFolder(id: number, folderId: number): Observable<void> {
    return this.http.post<void>('/api/archiveCase/updateFolder', null, {
      id: id,
      folderId: folderId,
    });
  }

  updateOrders(orders: any[]): Observable<void> {
    return this.http.post<void>('/api/archiveCase/updateOrders', orders);
  }

  /** 나중에 읽을 판결문 조회 */
  getArchiveCaseWatchLaterList(
    page: number,
    size: number,
  ): Observable<Page<ArchiveCaseInfo>> {
    return this.http.get<Page<ArchiveCaseInfo>>(
      '/api/archiveCase/watch-later',
      { page, size },
    );
  }
}
