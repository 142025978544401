import { Component } from '@angular/core';
import { DialogOrBottomSheetProto } from '../../../alert/alert.service';
import { UserService } from '../../../user/user.service';
import { courts, selectCourt } from './courts';
import { docIdByCourtAndCaseNo, getCaseNoRegex } from '../../../core/util';
import { Router } from '@angular/router';
import { RequestCase } from '../../../../../ngxSpring/api.model';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'lb-case-request-dialog-without-doc-id',
  templateUrl: './case-request-dialog-without-doc-id.component.html',
  styleUrls: ['./case-request-dialog.component.sass'],
})
export class CaseRequestDialogWithoutDocIdComponent extends DialogOrBottomSheetProto<any> {
  done;

  court;

  ngOnInit() {
    this.user = this.injector.get(UserService);
  }

  prevent;
  type;
  user;
  caseId: any;

  private typeByYear(caseId: string, number: number) {
    if (caseId.startsWith('9') || caseId.startsWith('4')) {
      return 2; // 사본신청
    }
    if (Number(caseId.substring(0, 4)) >= number) {
      return 1; // 인터넷 열람
    }
    return 2; // 사본신청
  }

  setType(caseId, court) {
    if (
      [
        '드합',
        '드단',
        '르',
        '므',
        '브',
        '스',
        '으',
        '너',
        '츠',
        '즈합',
        '즈단',
        '즈기',
        '느합',
        '느단',
        '푸',
        '크',
        '트',
        '푸초',
        '푸집',
        '버',
        '서',
        '어',
        '저',
        '처',
        '커',
        '터',
        '동버',
        '동서',
        '동어',
        '동저',
        '동버집',
        '동처',
        '동커',
        '동터',
        '동처집',
        '성',
        '성로',
        '성모',
        '성초',
      ].find((it) => caseId.includes(it))
    ) {
      this.prevent = `요청하신 '${caseId}' 사건은 판결문 제공에 관한 예규에 따라<br> 판결문 제공 대상에서 제외되어 입수가 어려운 점 양해 바랍니다.`;
      return;
    }
    if (['고약', '재고약'].find((it) => caseId.includes(it))) {
      this.prevent = `요청하신 '${caseId}' 사건은 약식명령사건으로 확정되어 사건기록이 검찰청으로 송부된 후에는 법원이 판결문을 보유하고 있지 않아 입수가 어려운 점 양해 바랍니다. `;
      return;
    }
    if (
      [
        '하',
        '회',
        '화',
        '하합',
        '하단',
        '하면',
        '재하단',
        '재하면',
        '개회',
        '개확',
        '개보',
        '화보',
        '회보',
        '개기',
        '회단',
        '회합',
        '회확',
        '회기',
        '하확',
        '하기',
        '국승',
        '국지',
        '간회단',
        '간회합',
      ].find((it) => caseId.includes(it)) &&
      court == '서울중앙지방법원'
    ) {
      this.prevent = `서울중앙지방법원에서는 회생·파산 사건을 처리하지 않습니다.<br>회생·파산 사건은 서울회생법원에 신청하시기 바랍니다.`;
      return;
    }
    if (['카합', '카단', '즈합', '즈단'].find((it) => caseId.includes(it)))
      this.type = 0;
    //가압류가처분
    else if (
      [
        '고합',
        '고단',
        '고약',
        '고정',
        '노',
        '도',
        '로',
        '모',
        '오',
        '보',
        '코',
        '조',
        '토',
        '초적',
        '초보',
        '초재',
        '초사',
        '초기',
        '감고',
        '감노',
        '감도',
        '감로',
        '감모',
        '감오',
        '감토',
        '감초',
        '전고',
        '전노',
        '전도',
        '전오',
        '전초',
        '전로',
        '전모',
        '보고',
        '보노',
        '보도',
        '보오',
        '보초',
        '보로',
        '보모',
        '치고',
        '치노',
        '치도',
        '치오',
        '치초',
        '초치',
        '치로',
        '치모',
        '동고',
        '동노',
        '동도',
        '동오',
        '동초',
        '푸',
        '크',
        '트',
        '푸초',
        '푸집',
        '버',
        '서',
        '어',
        '저',
        '버집',
        '처',
        '커',
        '터',
        '처집',
        '동버',
        '동서',
        '동어',
        '동저',
        '동버집',
        '동처',
        '동커',
        '동터',
        '성',
        '성로',
        '성모',
        '성초',
      ].find((it) => caseId.includes(it))
    )
      this.type = this.typeByYear(caseId, 2013);
    else this.type = this.typeByYear(caseId, 2015);
  }

  async request() {
    if (!this.user.logged) {
      this.alert.pop('로그인이 필요한 서비스입니다.');
      return;
    }
    if (!courts.find((it) => it === this.court)) {
      this.alert.pop('법원을 선택해주세요.');
      return;
    }
    if (!this.caseId.match(getCaseNoRegex())) {
      this.alert.pop('판례 번호를 확인해주세요.');
      return;
    }
    const docId = docIdByCourtAndCaseNo(selectCourt(this.court), this.caseId);
    if (await firstValueFrom(this.api.lbCase.isRequestAble(docId))) {
      this.alert
        .confirm(
          '이미 등록된 판례',
          null,
          `등록요청하신 ${docId} 판례는 이미 등록된 판례입니다. 해당 판례로 이동하시겠습니까?`,
        )
        .then((v) => {
          if (!v) return;
          this.close();
          if (v) {
            if (window.innerWidth >= 768) {
              this.alert.checkOpen(location.origin + '/' + docId);
            } else {
              return this.injector.get(Router).navigateByUrl('/' + docId);
            }
            return;
          }
        });
      return;
    }
    this.setType(this.caseId, this.court);
    if (this.prevent) {
      this.done = true;
      return;
    }
    this.api.requestCase
      .saveRequestCase({
        requestCaseId: this.caseId,
        court: selectCourt(this.court),
      } as RequestCase)
      .subscribe(() => {
        this.done = true;
      });
  }
}
