import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TrackingService } from 'src/app/core/tracking.service';

@Component({
  selector: 'lb-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.sass'],
})
export class PaginationComponent {
  @Input()
  pageNotChangeUntilPut;

  // 전체 항목 개수
  count: number = 0;

  @Input()
  set itemCount(count: number) {
    this.count = count;
    this.pageStart = 0;
    this.setPages();
  }

  // 전체 개수, 페이지당 개수에 따라 페이지 번호 배열 생성
  setPages() {
    // this.pages = [1, 2, 3, ...]
    this.pages = Array.from(
      { length: Math.ceil(this.count / this.itemPerPage) },
      (_, i) => i + 1
    );
  }

  @Output()
  pageChange = new EventEmitter<number>();

  pageStart = 0;

  pages = [];

  @Input()
  itemPerPage = 10;

  // 페이지당 개수 변경
  @Input()
  set setItemPerPage(itemPerPage: number) {
    this.itemPerPage = itemPerPage;
    this.setPages();
  }

  pageValue;

  @Input()
  pageMax = 5;

  @Input()
  pageLimit = 10000;

  constructor(private tracking: TrackingService) {}
  
  pageStartPlus() {
    if (this.pagePlusAble()) {
      this.pageStart += this.pageMax;
      this.setPage(this.pages.slice(this.pageStart, this.pageStart + 1)[0]);
    }
  }

  pagePlusAble() {
    return this.pageStart + this.pageMax < this.pages.last();
  }

  pageStartMinus() {
    if (this.pageMinusAble()) {
      this.pageStart -= this.pageMax;
      this.setPage(this.pages.slice(this.pageStart, this.pageStart + 1)[0]);
    }
  }

  pageMinusAble() {
    return this.pageStart > 0;
  }

  @Input()
  set page(p: number) {
    this.pageValue = p;
    if (
      !this.pages
        .slice(this.pageStart, this.pageStart + this.pageMax)
        .includes(this.pageValue)
    ) {
      this.pageStart =
        Math.floor((this.pageValue - 1) / this.pageMax) * this.pageMax;
    }
  }

  setPage(value) {
    if (!this.pageNotChangeUntilPut) this.page = value;
    this.trackingSearchPageInCasePDP(this.page)
    this.pageChange.emit(value);
  }

  trackingSearchPageInCasePDP(page: number) {
    if (window.location.pathname.includes('/case/'))  {
      this.tracking.tracking('click_search_page', {
        funnel_root_id: this.tracking.getCasePDPFunnelRootId(),
        pageno: page || 1,
      })
    }
  }
}
