/* tslint:disable */
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiHttp } from '../api.http';

import {
  LboxPermission,
  NonProfitOrganizationInfo,
  Page,
  PayInfo,
  PricePlan,
  PromotionType,
  SnsAuthType,
  UserBenefitApiResponse,
  UserHasPlan,
  UserRole
} from '../api.model';

@Injectable()
export class UserHasPlanController {
  constructor(private http: ApiHttp) {
  }

  checkTargetEventUser(): Observable<boolean> {
    return this.http.get<boolean>('/api/userHasPlan/checkTargetEventUser');
  }

  codeStatus(): Observable<any> {
    return this.http.get<any>('/api/userHasPlan/codeStatus');
  }

  benefits(): Observable<UserBenefitApiResponse> {
    return this.http.get<UserBenefitApiResponse>('/api/userHasPlan/benefits')
  }

  getMembershipInfo(referrerCode?: string): Observable<PayInfo> {
    return this.http.get<PayInfo>('/api/userHasPlan/membershipInfo', { referrerCode: referrerCode });
  }

  getPlanType(plan?: PricePlan): Observable<UserHasPlan> {
    return this.http.get<UserHasPlan>('/api/userHasPlan', { plan: plan });
  }

  getPromotionMembershipInfo(promotionType: PromotionType): Observable<PayInfo> {
    return this.http.get<PayInfo>('/api/userHasPlan/membershipInfo/promotion', { promotionType: promotionType });
  }

  getReferrerName(referrerCode: string): Observable<string> {
    return this.http.get<string>('/api/userHasPlan/referrerName', { referrerCode: referrerCode }, 'text');
  }

  getUserHasPlanList(page?: number, size?: number, keyword?: string): Observable<Page<any>> {
    return this.http.get<Page<any>>('/api/userHasPlan/list', { page: page, size: size, keyword: keyword });
  }

  getNonProfitOrganizationInfo(): Observable<NonProfitOrganizationInfo> {
    return this.http.get<NonProfitOrganizationInfo>('/api/non-profit/organization/current')
  }

  lastFailedChecked(): Observable<UserHasPlan> {
    return this.http.post<UserHasPlan>('/api/userHasPlan/lastFailedChecked', null);
  }

  lastInfo(plan?: PricePlan): Observable<UserHasPlan> {
    return this.http.get<UserHasPlan>('/api/userHasPlan/last', { plan: plan });
  }

  pointToReferrers(time: number, userId: number): Observable<void> {
    return this.http.post<void>('/api/userHasPlan/pointToReferrers', null, { time: time, userId: userId });
  }

  recommendCode(): Observable<string> {
    return this.http.get<string>('/api/userHasPlan/code', null, 'text');
  }

  releaseMembership(time: number, userId: number): Observable<void> {
    return this.http.post<void>('/api/userHasPlan/releaseMembership', null, { time: time, userId: userId });
  }

  savePlan(plan: UserHasPlan): Observable<UserHasPlan> {
    return this.http.post<UserHasPlan>('/api/userHasPlan/save', plan);
  }

  startMembership(cardNumber: string, expiry: string, birth: string, pw: string, referrerCode?: string, promotionType?: PromotionType): Observable<UserHasPlan> {
    return this.http.post<UserHasPlan>('/api/userHasPlan', null, { cardNumber: cardNumber, expiry: expiry, birth: birth, pw: pw, referrerCode: referrerCode, promotionType: promotionType });
  }

  startMembershipWithRegistered(referrerCode?: string, promotionType?: PromotionType): Observable<UserHasPlan> {
    return this.http.post<UserHasPlan>('/api/userHasPlan/registered', null, { referrerCode: referrerCode, promotionType: promotionType });
  }

  unsubscribe(): Observable<UserHasPlan> {
    return this.http.post<UserHasPlan>('/api/userHasPlan/unsubscribe', null);
  }

  unsubscribeWithReason({ reason, subReason, reasonDetail }: UnsubscribeWithReasonApiRequestPayload): Observable<UnsubscribeWithReasonApiResponse> {
    return this.http.post<UnsubscribeWithReasonApiResponse>('/api/userHasPlan/unsubscribe/reason', { reason, subReason, reasonDetail })
  }
}

interface UnsubscribeWithReasonApiRequestPayload {
  reason: string
  subReason: string
  reasonDetail: string
}

interface UnsubscribeWithReasonApiResponse {
  id: number
  createdAt: string
  withdrawAt: string
  snsEmail: string
  snsType: SnsAuthType
  email: string
  role: UserRole
  password: string
  name: string
  lboxUserData: boolean
  loggedAt: string
  data: Record<string, unknown>
  permissions: LboxPermission[]
}
