import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import {
  MatMenuTrigger,
  MenuPositionX,
  MenuPositionY,
} from '@angular/material/menu';

@Component({
  selector: 'lb-popover',
  templateUrl: './popover.component.html',
  styleUrls: ['./popover.component.sass'],
})
export class PopoverComponent implements AfterViewInit {
  @Input() xPosition: MenuPositionX = 'after';
  @Input() yPosition: MenuPositionY = 'below';
  @Input() popoverContent: TemplateRef<any>;
  @ViewChild(MatMenuTrigger) private matMenuTrigger: MatMenuTrigger;

  right;

  open() {
    this.matMenuTrigger.openMenu();
  }

  close() {
    this.matMenuTrigger.closeMenu();
  }

  constructor(private elf: ElementRef) {}

  ngAfterViewInit(): void {
    this.right = this.elf.nativeElement.offsetWidth / 2;
  }
}
