<div
  class="popover"
  @enterAnimation
  *ngIf="!popoverClosed && suggestion.nowIndex == index"
  [ngStyle]="style"
  fxLayout="row"
>
  <div [innerHTML]="message" fxFlex="auto"></div>
  <span class="icon3 n-8 h-2" trackId="c2ca"  (click)="closePopover()"></span>
</div>
