/* tslint:disable */
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ApiHttp} from '../api.http';

import {CaseReport, CaseReportState, Page} from '../api.model';

@Injectable()
export class CaseReportController {
  constructor(private http: ApiHttp) {
  }

  getCaseReport(id: number): Observable<CaseReport> {
    return this.http.get<CaseReport>('/api/caseReport', {id: id});
  }

  getCaseReportList(page?: number, size?: number, state?: CaseReportState, query?: string): Observable<Page<CaseReport>> {
    return this.http.get<Page<CaseReport>>('/api/caseReport/list', {page: page, size: size, state: state, query: query});
  }

  getRequestedCaseReport(caseId: string): Observable<boolean> {
    return this.http.get<boolean>('/api/caseReport/requested', {caseId: caseId});
  }

  saveCaseReport(caseReport: CaseReport): Observable<CaseReport> {
    return this.http.post<CaseReport>('/api/caseReport', caseReport);
  }

  sendDoneEmail(caseReport: CaseReport): Observable<void> {
    return this.http.post<void>('/api/caseReport/sendDoneEmail', caseReport);
  }

  updateCaseReport(caseReport: CaseReport): Observable<CaseReport> {
    return this.http.post<CaseReport>('/api/caseReport/update', caseReport);
  }
}
