import { Component, Input } from '@angular/core';
import { UserService } from '../../../user/user.service';
import { getLBoxNextClientURL } from '../../../core/util';
import { TrackService } from 'src/app/track/track.service';
import { V2_PAGE_PATH } from '../../../../consts';

@Component({
  selector: 'lb-lbox-services',
  templateUrl: './lbox-services.component.html',
  styleUrls: ['./lbox-services.component.sass'],
})
export class LboxServicesComponent {
  @Input()
  colorRevert;

  @Input()
  withLabel = false;

  legalAnalyticsUrl = getLBoxNextClientURL() + V2_PAGE_PATH.legalAnalytics.caseAnalytics;

  public sendClickGnbAnalyticsEvent() {
    this.trackService.sendEvent('click_gnb_analytics');
  }

  constructor(public userService: UserService, public trackService: TrackService) {}
}
