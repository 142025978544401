import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/user/user.service';

@Component({
  selector: 'lb-company-logo-slider',
  templateUrl: './company-logo-slider.component.html',
  styleUrls: ['./company-logo-slider.component.sass'],
})
export class CompanyLogoSliderComponent implements OnInit {
  constructor(
    public userService: UserService,
  ) {}

  ngOnInit(): void {
    this.customOptions = {
      loop: true,
      dots: false,
      responsive: {
        0: {
          items: 1,
        },
      },
    };
  }

  customOptions;
}
