/* tslint:disable */
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ApiHttp} from '../api.http';

import {CaseStats} from '../api.model';

@Injectable()
export class CaseStatsController {
  constructor(private http: ApiHttp) {
  }

  getCount(): Observable<CaseStats[]> {
    return this.http.get<CaseStats[]>('/api/caseStats/count');
  }
}
