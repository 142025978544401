/* tslint:disable */
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ApiHttp} from '../api.http';

import {HighlightObject, Iterable, LinkData, MyHighlight, Page} from '../api.model';

@Injectable()
export class MyHighlightController {
  constructor(private http: ApiHttp) {
  }

  deleteMyHighlight(id: number): Observable<void> {
    return this.http.delete<void>('/api/myHighlight', {id: id});
  }

  doneReported(id: number): Observable<void> {
    return this.http.post<void>('/api/myHighlight/done/reported', null, {id: id});
  }

  getMyHighlightByDocId(docId: string): Observable<HighlightObject> {
    return this.http.get<HighlightObject>('/api/myHighlight/byDocId', {docId: docId});
  }

  getMyHighlightByDocIdAsList(docId: string): Observable<MyHighlight[]> {
    return this.http.get<MyHighlight[]>('/api/myHighlight/byDocId/asList', {docId: docId});
  }

  getMyHighlightList(page?: number, size?: number, query?: string, folder?: number): Observable<Page<MyHighlight>> {
    return this.http.get<Page<MyHighlight>>('/api/myHighlight/list/highlight', {page: page, size: size, query: query, folder: folder});
  }

  getMyMemoList(page?: number, size?: number, query?: string, folder?: number): Observable<Page<MyHighlight>> {
    return this.http.get<Page<MyHighlight>>('/api/myHighlight/list/memo', {page: page, size: size, query: query, folder: folder});
  }

  getReportedList(page?: number, size?: number, includeDone?: boolean): Observable<Page<MyHighlight>> {
    return this.http.get<Page<MyHighlight>>('/api/myHighlight/list/reported', {page: page, size: size, includeDone: includeDone});
  }

  getShareLink(docId: string, link: LinkData): Observable<string> {
    return this.http.post<string>('/api/myHighlight/link', link, {docId: docId}, 'text');
  }

  getSharedHighlight(highlight: string): Observable<HighlightObject> {
    return this.http.get<HighlightObject>('/api/myHighlight/shared', {highlight: highlight});
  }

  rejectReported(id: number, reason: string): Observable<void> {
    return this.http.post<void>('/api/myHighlight/reject/reported', null, {id: id, reason: reason});
  }

  replaceHighlights(docId: string, highlights: HighlightObject): Observable<Iterable<MyHighlight>> {
    return this.http.post<Iterable<MyHighlight>>('/api/myHighlight/replaceHighlights', highlights, {docId: docId});
  }

  saveMyHighlight(myHighlight: MyHighlight): Observable<MyHighlight> {
    return this.http.post<MyHighlight>('/api/myHighlight', myHighlight);
  }
}
