<div fxFlex="auto" class="form text-left" style="max-width: 100%">
  <div *ngIf="hasOptions && !yearOnly">
    <mat-select
      panelClass="fitContent"
      [(ngModel)]="job[0]"
      (ngModelChange)="jobClear(0)"
      placeholder="회원 유형 선택"
      [disabled]="isAuthorizedLawyerChangeJobDisabled"
    >
      <mat-option *ngFor="let jobOption of typeOptions" [value]="jobOption">{{
        jobOption
      }}</mat-option>
    </mat-select>
  </div>
  <div [ngSwitch]="job[0]" class="detail">
    <ng-container
      *ngSwitchCase="
        job[0] === jobOptions[0] || job[0] === jobOptions[1] ? job[0] : ''
      "
    >
      <!-- 선택 직업 = 법조인(판사, 검사, 법무관 등) 인 경우 -->
      <div *ngIf="job[0] == jobOptions[1]">
        <mat-select
          panelClass="fitContent"
          [(ngModel)]="job[1]"
          placeholder="상세 선택"
          (ngModelChange)="jobClear(1)"
        >
          <mat-option
            *ngFor="let option of options[jobOptions[1]]"
            [value]="option"
            >{{ option }}</mat-option
          >
        </mat-select>
      </div>
      <div *ngIf="job[1] || job[0] == jobOptions[0]">
        <div *ngIf="job[1] == '기타(직접 입력)'">
          <lb-input-for-etc [(value)]="job[5]"></lb-input-for-etc>
        </div>
        <div>
          <mat-select
            [class.mt-0]="!hasOptions"
            panelClass="fitContent"
            [(ngModel)]="job[2]"
            placeholder="시험유형 선택"
            (ngModelChange)="jobClear(2)"
            class="mr-16"
          >
            <mat-option
              *ngFor="let option of options['시험 유형']"
              [value]="option"
              >{{ option }}</mat-option
            >
          </mat-select>
        </div>
        <div *ngIf="job[2]">
          <mat-select
            panelClass="fitContent"
            [(ngModel)]="job[3]"
            (ngModelChange)="setUndefinedIfNew()"
            [placeholder]="placeholders[job[2]]"
          >
            <mat-option
              *ngFor="let option of options[job[2]]"
              [value]="option"
              >{{ option }}</mat-option
            >
          </mat-select>
        </div>
        <lb-lawyer-association-input
          *ngIf="job[0] !== jobOptions[1]"
          [(association)]="job[5]"
        ></lb-lawyer-association-input>
      </div>
    </ng-container>
    <ng-container *ngSwitchCase="jobOptions[2]">
      <div>
        <mat-select
          panelClass="fitContent"
          [(ngModel)]="job[1]"
          placeholder="상세 선택"
          (ngModelChange)="jobClear(1)"
        >
          <mat-option
            *ngFor="let option of options['법률전문직']"
            [value]="option"
            >{{ option }}</mat-option
          >
        </mat-select>
      </div>
      <div *ngIf="job[1]">
        <div *ngIf="job[1] == '기타(직접 입력)'">
          <lb-input-for-etc [(value)]="job[5]"></lb-input-for-etc>
        </div>
        <div>
          <mat-select
            panelClass="fitContent"
            [(ngModel)]="job[2]"
            placeholder="자격 획득 연도 선택"
          >
            <mat-option *ngFor="let option of years" [value]="option">{{
              option
            }}</mat-option>
          </mat-select>
        </div>
      </div>
    </ng-container>
    <ng-container *ngSwitchCase="jobOptions[3]">
      <div>
        <mat-select
          panelClass="fitContent"
          [(ngModel)]="job[1]"
          placeholder="상세 선택"
          (ngModelChange)="jobClear(1)"
        >
          <mat-option
            *ngFor="let option of options[jobOptions[3]]"
            [value]="option"
            >{{ option }}</mat-option
          >
        </mat-select>
      </div>
      <div *ngIf="job[1]">
        <div *ngIf="job[1] == '기타(직접 입력)'">
          <lb-input-for-etc [(value)]="job[3]"></lb-input-for-etc>
        </div>
        <div>
          <mat-select
            panelClass="fitContent"
            [(ngModel)]="job[2]"
            [placeholder]="
              job[1] == '경찰공무원' ? '배명 연도 선택' : '임용 연도 선택'
            "
          >
            <mat-option *ngFor="let option of years" [value]="option">{{
              option
            }}</mat-option>
          </mat-select>
        </div>
      </div>
    </ng-container>
    <ng-container *ngSwitchCase="jobOptions[4]">
      <div>
        <mat-select
          [class.mt-0]="!hasOptions"
          panelClass="fitContent"
          [(ngModel)]="job[1]"
          placeholder="학교 선택"
        >
          <mat-option
            *ngFor="let option of options['법학전문대학원 선택']"
            [value]="option"
            >{{ option }}</mat-option
          >
        </mat-select>
      </div>
      <div>
        <mat-select
          panelClass="fitContent"
          [(ngModel)]="job[3]"
          placeholder="입학 연도 선택"
        >
          <mat-option *ngFor="let option of eYears" [value]="option">{{
            option
          }}</mat-option>
        </mat-select>
      </div>
      <div>
        <mat-select
          panelClass="fitContent"
          [(ngModel)]="job[2]"
          placeholder="졸업 예정 연도 선택"
        >
          <mat-option *ngFor="let option of gYears" [value]="option">{{
            option
          }}</mat-option>
        </mat-select>
      </div>
    </ng-container>
    <ng-container *ngSwitchCase="jobOptions[5]">
      <div fxLayout="row" fxLayout.lt-md="column">
        <lb-input-for-etc
          [(value)]="job[1]"
          placeholder="학교 입력"
          class="mr-16"
        ></lb-input-for-etc>
        <lb-input-for-etc
          [(value)]="job[2]"
          placeholder="학과 입력"
        ></lb-input-for-etc>
      </div>
      <div>
        <mat-select
          panelClass="fitContent"
          [(ngModel)]="job[3]"
          placeholder="졸업 예정 연도 선택"
        >
          <mat-option *ngFor="let option of gYears" [value]="option">{{
            option
          }}</mat-option>
        </mat-select>
      </div>
    </ng-container>
    <ng-container *ngSwitchCase="jobOptions[6]">
      <div fxLayout="row" fxLayout.lt-md="column">
        <lb-input-for-etc
          [(value)]="job[1]"
          placeholder="학교 입력"
          class="mr-16"
        ></lb-input-for-etc>
        <lb-input-for-etc
          [(value)]="job[2]"
          placeholder="학과 입력"
        ></lb-input-for-etc>
      </div>
    </ng-container>
    <ng-container *ngSwitchCase="jobOptions[7]">
      <lb-input-for-etc
        [(value)]="job[1]"
        placeholder="소속 기관 입력"
      ></lb-input-for-etc>
    </ng-container>
    <ng-container *ngSwitchCase="jobOptions[8]">
      <div>
        <lb-input-for-etc [(value)]="job[3]"></lb-input-for-etc>
      </div>
    </ng-container>
  </div>
  <ng-container *showItSizes="{ min: 600 }">
    <div class="subscription mt-12" *ngIf="showsWarningText && job[0] != jobOptions[9] && alert">
      허위 정보 입력 시 향후 서비스 이용이 제한될 수 있습니다.
    </div>
  </ng-container>
</div>
