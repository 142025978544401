import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { firstValueFrom, Observable } from 'rxjs';
import { ScriptService } from 'ngx-script-loader';
import { isPlatformServer } from '@angular/common';
import { TrackService } from '../track/track.service';
import { isProduction } from './util';
import { UserService } from '../user/user.service';
import {
  ZENDESK_SCRIPT_SRC,
  ZENDESK_CHAT_IFRAME_SELECTOR,
} from '../../utils/zendesk';

@Injectable({
  providedIn: 'root',
})
export class ZendeskLoaderGuard implements CanActivate {
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    if (isPlatformServer(this.platform)) {
      return true;
    }

    if (isProduction() && !this.userService.isRestrictedUser) {
      void firstValueFrom(
        this.scriptService.loadScript(ZENDESK_SCRIPT_SRC, {
          id: 'ze-snippet',
        }),
      ).then(() => {
        setTimeout(() => {
          const iframeElement = document?.querySelector<HTMLIFrameElement>(
            ZENDESK_CHAT_IFRAME_SELECTOR,
          );

          iframeElement?.contentWindow?.document
            .querySelector('button')
            ?.addEventListener('click', () => {
              this.track.sendEvent('click_zendesk', null, {
                page: this.track.tracking.pageName,
              });
            });
        }, 2000);
      });
    }

    return true;
  }

  constructor(
    private scriptService: ScriptService,
    @Inject(PLATFORM_ID) private platform,
    private track: TrackService,
    private userService: UserService,
  ) {}
}
