<div class="title">
  <h5 class="mb-24">등록된 기기 해제</h5>
  <div class="desc">
    {{ data | slice: 0:3 }}-{{ data | slice: 3:7 }}-**** 카카오톡으로 발송된
    인증번호를 입력해주세요. 카카오톡 메세지를 받지 못했다면 알람톡 차단 여부를
    확인해주세요.
  </div>
  <div class="color-mid-gray font-14">
    카카오톡 인증이 어려울시
    <span class="color-primary" zendesk>고객센터</span>로 문의해주세요.
  </div>
  <div class="relative-position mt-24">
    <input
      [(ngModel)]="code"
      placeholder="인증번호를 입력해주세요."
      maxlength="6"
      mask="000000"
    />
    <countdown [config]="{ format: 'mm:ss', leftTime: 180 }"></countdown>
  </div>
</div>
<div class="buttons">
  <button class="cancel" (click)="close()">취소</button>
  <button class="blue" [class.disabled]="code?.length != 6" (click)="verify()">
    인증
  </button>
</div>
