import { Component, Input } from '@angular/core';
import { AlertService } from '../../../alert/alert.service';
import { MyCaseTipDialogComponent } from './my-case-tip-dialog/my-case-tip-dialog.component';

@Component({
  selector: 'lb-my-case-tip',
  templateUrl: './my-case-tip.component.html',
  styleUrls: ['./my-case-tip.component.sass'],
})
export class MyCaseTipComponent {
  constructor(private alert: AlertService) {}

  @Input()
  tip;

  open() {
    this.tip?.hide();
    this.alert
      .open(MyCaseTipDialogComponent, null, { width: '530px' }, null, true)
      .then(() => this.tip?.show());
  }
}
