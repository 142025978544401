import { Injectable } from '@angular/core';
import { CanActivate, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { V2_PAGE_PATH } from 'src/consts';
import { UrlService } from '../shared/url.service';

@Injectable({
  providedIn: 'root',
})
export class RedirectHomeGuard implements CanActivate {
  constructor(private urlService: UrlService) {}

  canActivate():
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    window.location.href = `${this.urlService.getAuthority()}${
      V2_PAGE_PATH.root
    }`;

    return false;
  }
}
