<div class="title">
  <h5 class="mb-12">내 기기 관리</h5>
  <div class="desc">
    <ng-container *ngIf="data[0].deviceType == 'NORMAL'">
      현재 3개의 PC/웹브라우저가 모두 등록되어 있습니다.
    </ng-container>
    <ng-container *ngIf="data[0].deviceType != 'NORMAL'">
      현재 2개의 모바일 기기가 모두 등록되어 있습니다.
    </ng-container>
    <br />
    아래 항목 중 하나를 해제해주세요. 해제된 기기에서는 자동 로그아웃 됩니다.
    <br />
    기기 해제는 회원가입 시 인증된 본인 명의의 휴대폰 번호를 통해서만 가능합니다.
  </div>
</div>
<div class="input-wrapper">
  <h3 *ngIf="data[0].deviceType == 'NORMAL'">등록된 PC({{ data.length }}/3)</h3>
  <h3 *ngIf="data[0].deviceType != 'NORMAL'">
    등록된 모바일 기기({{ data.length }}/2)
  </h3>
  <lb-device
    [withNameEdit]="false"
    *ngFor="let device of data; let i = index"
    [index]="i"
    [device]="device"
    (removeDevice)="authorize($event)"
  ></lb-device>
</div>

<div class="buttons">
  <button class="cancel" trackId="fd58" (click)="close(null)">닫기</button>
</div>
