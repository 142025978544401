<div
  class="area"
  fxLayout="row"
  fxLayoutAlign="center center"
  (drop)="drop($event)"
  trackId="cb74"  (click)="file.click()"
  (dragover)="dragover($event)"
  (dragleave)="dragleave($event)"
>
  <img
    src="../assets/document.svg"
    style="width: 43px; margin-right: 20px"
    *showItSizes="{ min: 600 }"
  />
  <div>
    <ng-container *showItSizes="{ min: 600 }">
      <b class="drag"
        ><span>{{ fileType }}을 이곳에 드래그</span></b
      >하거나<br
    /></ng-container>
    <b class="link"> <ng-content></ng-content> </b>를 클릭해주세요
  </div>
</div>
<input
  type="file"
  [accept]="accept"
  (change)="filesChange.emit(file.files)"
  #file
  hidden
  [multiple]="multiple"
/>
