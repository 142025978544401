import { NgModule } from '@angular/core';
import { HeaderDrawerComponent } from './header-drawer.component';
import { MatMenuModule } from '@angular/material/menu';
import { TrackModule } from '../../track/track.module';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { LinkDirectivesModule } from '../link-directives/link-directives.module';
import { MatButtonModule } from '@angular/material/button';
import { MatRippleModule } from '@angular/material/core';
import { CaseSearchComponent } from '../case-search/case-search.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { TextInputHighlightModule } from 'angular-text-input-highlight';
import { FlexModule } from '@angular/flex-layout';
import { FormsModule } from '@angular/forms';
import { InfoDescComponent } from '../../judge/components/info-desc/info-desc.component';
import { JudgeLinkPipe } from '../../shared/judge-link.pipe';
import { SearchDialogComponent } from '../search-dialog/search-dialog.component';
import { MatSelectModule } from '@angular/material/select';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';

@NgModule({
  declarations: [
    HeaderDrawerComponent,
    CaseSearchComponent,
    InfoDescComponent,
    JudgeLinkPipe,
    SearchDialogComponent,
  ],
  imports: [
    MatMenuModule,
    RouterModule,
    CommonModule,
    LinkDirectivesModule,
    TrackModule,
    MatButtonModule,
    MatRippleModule,
    MatAutocompleteModule,
    TextInputHighlightModule,
    FlexModule,
    FormsModule,
    MatSelectModule,
    PerfectScrollbarModule,
  ],
  exports: [
    HeaderDrawerComponent,
    CaseSearchComponent,
    InfoDescComponent,
    JudgeLinkPipe,
  ],
})
export class HeaderModuleModule {}
