<ng-container [ngSwitch]="data.payInfo.paymentType">
  <ng-container *ngSwitchCase="'FREE_TRIAL'">
    <div class="title">
      <h5>스탠다드 플랜 가입</h5>
    </div>
    <div class="info">
      <div fxLayout="row" fxLayoutAlign="center center">
        <b fxFlex="auto" class="label">이용기간</b>
        <p class="value">
          <lb-membership-duration
            [isFree]="true"
            [plan]="data.payInfo.plan"
          ></lb-membership-duration>
        </p>
      </div>
    </div>
    <div class="info mt-16">
      <lb-payment-price [payment]="data.payInfo.payment"></lb-payment-price>
    </div>
  </ng-container>
  <ng-container *ngSwitchCase="'EXTEND'">
    <div class="title">
      <h5>스탠다드 플랜 재개</h5>
    </div>
    <div class="info">
      <div fxLayout="row">
        <b fxFlex="auto">이용기간</b>
        <lb-membership-duration
          [isFree]="false"
          [plan]="data.payInfo.plan"
        ></lb-membership-duration>
      </div>
    </div>
    <div class="info-subscription">
      · 기존 스탠다드 플랜 종료 후 결제 됩니다.
    </div>
    <div class="info">
      <div fxLayout="row" class="mb-12">
        <b fxFlex="auto">다음 결제일</b>
        <span>{{ data.payInfo.plan.nextPayDate | date: 'yyyy.M.d' }}</span>
      </div>
      <lb-payment-price [payment]="data.payInfo.payment"></lb-payment-price>
    </div>
  </ng-container>
  <ng-container *ngSwitchCase="'PAY_NOW'">
    <div class="title">
      <h5>스탠다드 플랜 가입</h5>
    </div>
    <div class="info">
      <div fxLayout="row">
        <b fxFlex="auto" class="mr-16">이용기간</b>
        <lb-membership-duration
          [isFree]="false"
          [plan]="data.payInfo.payment"
        ></lb-membership-duration>
      </div>
    </div>
    <div class="info mt-16">
      <lb-payment-price [payment]="data.payInfo.payment"></lb-payment-price>
    </div>
  </ng-container>
</ng-container>
<ng-container *ngIf="user?.payment">
  <div class="info mt-16">
    <div fxLayout="row">
      <p fxFlex="none" class="mr-16 label">결제수단</p>
      <span fxFlex="auto"></span>
      <span class="value"
        >[{{ user.payment.cardName }}] {{ user.payment.cardNumber }}
      </span>
      <span
        fxFlex="none"
        class="color-primary ml-16"
        trackId="fb18"
        (click)="user.payment = null"
        >변경하기</span
      >
    </div>
  </div>
</ng-container>
<ng-container *ngIf="!user?.payment">
  <div class="divider"></div>
  <h4>카드정보 입력</h4>
  <form class="text-left" autocomplete="off">
    <div
      fxLayout="row"
      fxLayoutAlign="start center"
      fxLayout.lt-md="column"
      fxLayoutAlign.lt-md="start start"
    >
      <h5 class="ml-24 mb-12 names">카드번호</h5>
      <div class="input-wrapper" fxFlex>
        <input
          autocomplete="false"
          placeholder="카드번호"
          [validation]="false"
          mask="0000-0000-0000-0000"
          #cardValue
          (keyup)="card = cardValue.value"
        />
      </div>
    </div>
    <div
      fxLayout="row"
      fxLayoutAlign="start center"
      fxLayout.lt-md="column"
      fxLayoutAlign.lt-md="start start"
    >
      <h5 class="ml-24 mb-12 names">유효기간</h5>
      <div class="input-wrapper" fxFlex>
        <input
          autocomplete="false"
          [validation]="false"
          placeholder="월"
          mask="00"
          style="width: 65px"
          [(ngModel)]="month"
          [ngModelOptions]="{ standalone: true }"
        /><span class="inline-block ml-12 mr-12">/</span
        ><input
          autocomplete="false"
          style="width: 65px"
          placeholder="연도"
          [validation]="false"
          mask="00"
          [(ngModel)]="year"
          [ngModelOptions]="{ standalone: true }"
        />
      </div>
    </div>
    <div
      fxLayout="row"
      fxLayoutAlign="start center"
      fxLayout.lt-md="column"
      fxLayoutAlign.lt-md="start start"
    >
      <h5 class="ml-24 mb-12 names">카드비밀번호 앞 2자리</h5>
      <div class="input-wrapper" fxFlex>
        <input
          autocomplete="false"
          [validation]="false"
          style="width: 54px"
          mask="00"
          type="password"
          [(ngModel)]="pw"
          [ngModelOptions]="{ standalone: true }"
        />
        <span class="inline-block ml-12 mr-12">**</span>
      </div>
    </div>
    <div
      fxLayout="row"
      fxLayoutAlign="start center"
      fxLayout.lt-md="column"
      fxLayoutAlign.lt-md="start start"
    >
      <h5 class="ml-24 mb-12 names">
        생년월일 6자리(개인) /<br *showItSizes="{ min: 959 }" />
        사업자 번호 10자리(법인)
      </h5>
      <div class="input-wrapper" fxFlex>
        <input
          [validation]="false"
          mask="0000000000"
          autocomplete="false"
          [(ngModel)]="auth"
          [ngModelOptions]="{ standalone: true }"
        />
        <p *ngIf="auth.length !== 6 && auth.length !== 10" class="validation-error">*지정된 자릿수에 맞게 입력해주세요.</p>
      </div>
    </div>
  </form>
</ng-container>
<ng-container *ngIf="user.hasMembershipChance">
  <div class="divider"></div>
  <h4>초대코드 입력</h4>
  <div
    fxLayout="row"
    fxLayoutAlign="start center"
    fxLayout.lt-md="column"
    fxLayoutAlign.lt-md="start start"
  >
    <h5 class="ml-24 mb-12 names">
      초대코드<span class="color-gray font-weight-400">(선택)</span>
    </h5>
    <div class="input-wrapper" fxFlex>
      <input [(ngModel)]="data.code" placeholder="초대코드를 입력해주세요" />
    </div>
  </div>
  <div class="sub-info ml-24">
    · 첫 결제 후 7일이 지난 시점에 초대인과 본인에게 포인트가 지급됩니다.
  </div>
</ng-container>
<div class="divider"></div>
<div class="mg-24">
  <div class="sub-info">
    <div fxLayout="row" *ngIf="data.payInfo.paymentType == 'FREE_TRIAL'">
      <div class="mr-4">·</div>
      <div>
        <ng-container *ngIf="data.payInfo.plan.promotionType == 'Extend'"
          >14
        </ng-container>
        <ng-container *ngIf="data.payInfo.plan.promotionType != 'Extend'"
          >7
        </ng-container>
        일 무료 이용기간 종료 전까지 어떠한 요금도 청구되지 않으며, 언제든지
        해지할 수 있습니다.
      </div>
    </div>
    <div fxLayout="row">
      <div class="mr-4">·</div>
      <div>이용기간은 정기 결제에 따라 자동 갱신됩니다.</div>
    </div>
    <div fxLayout="row">
      <div class="mr-4">·</div>
      <div>
        더 이상 이용을 원하지 않는다면 이용기간 종료 하루 전까지 해지하셔야
        합니다.
      </div>
    </div>
  </div>
  <mat-checkbox class="mb-12" [(ngModel)]="checked1">
    <a
      class="color-primary"
      trackId="5f48"
      href="https://lbox.notion.site/eddc0dae5ec041458e1405e28da57710"
      target="_blank"
      >엘박스 유료서비스 운영정책</a
    >에 동의(필수)
  </mat-checkbox>
  <br />
  <mat-checkbox [(ngModel)]="checked2">
    월 정기 자동결제 상품임을 인지하였습니다.
  </mat-checkbox>
</div>
<div class="buttons">
  <button class="cancel" trackId="04d2" (click)="close()">취소</button>
  <button
    class="blue"
    trackId="295c"
    (click)="pay()"
    [class.disabled]="isLoading || error"
  >
    <ng-container [ngSwitch]="data.payInfo.paymentType">
      <ng-container *ngSwitchCase="'FREE_TRIAL'">
        <ng-container *ngIf="!data.payInfo.plan.promotionType">
          7일 무료 이용하기
        </ng-container>
        <ng-container *ngIf="data.payInfo.plan.promotionType == 'Extend'">
          14일 무료 이용하기
        </ng-container>
        <ng-container *ngIf="data.payInfo.plan.promotionType == 'Discount'">
          할인받고 가입하기
        </ng-container>
      </ng-container>
      <ng-container *ngSwitchCase="'EXTEND'">
        스탠다드 플랜 다시 시작하기
      </ng-container>
      <ng-container *ngSwitchCase="'PAY_NOW'">
        스탠다드 플랜 가입하기
      </ng-container>
    </ng-container>
  </button>
</div>
