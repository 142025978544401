/* tslint:disable */
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ApiHttp} from '../api.http';

import {CommentarySearchQuery, CommentarySearchResult, ResponseEntity, StreamingResponseBody} from '../api.model';

@Injectable()
export class CommentaryController {
  constructor(private http: ApiHttp) {
  }

  commentaryMembership(key: string): Observable<boolean> {
    return this.http.get<boolean>('/api/commentary/membership', {key: key});
  }

  downloadFile(key: string): Observable<ResponseEntity<StreamingResponseBody>> {
    return this.http.get<ResponseEntity<StreamingResponseBody>>(`/api/commentary${key}`);
  }

  search(searchQuery: CommentarySearchQuery): Observable<CommentarySearchResult> {
    return this.http.post<CommentarySearchResult>('/api/commentary/search', searchQuery);
  }
}
