import { Pipe, PipeTransform } from '@angular/core';

const enums = {
  male: '남자',
  female: '여자',
  NAVER: '네이버',
  GOOGLE: '구글',
  KAKAO: '카카오톡',
  Modifying: '수정중',
  Done: '완료',
  Unset: '미할당',
  Set: '할당',
  Fixed: '수정완료',
  Approved: '검토완료',
  WrongData: '수정불가',
  Reject: '반려',
  Admin: '관리자',
  Alba: '알바',
  Skilled: '숙련(급행가능)',
  Trainee: '교육생',
  Normal: '일반',
  RejectByUser: '수정 진행 중',
  Registered: '등록완료',
  Upload: '익명화 대기 중',
  Anonymization: '익명화 진행 중',
  AnonymizationDone: '익명화완료',
  AnonymizationRetryDone: '수정 완료',
  RejectByLbox: '등록실패',
  RejectByLboxRegistering: '등록실패',
  RejectByLboxExist: '등록실패',
  SUBSTITUTE: '복대리',
  RECORD_COPY: '기록등사',
  MY: '의뢰 내역',
  CANDIDATE: '수임 내역',
  MY_CASE_REGISTER: '내 판례등록',
  MEMBERSHIP_REFUND: '스탠다드 플랜 결제 취소',
  MEMBERSHIP: '스탠다드 플랜 결제',
  REFERRER: '친구 초대 이벤트 참여',
  INVITER: '친구 초대 이벤트 참여',
  NEW_LAWYER_CONGRATS: '변호사시험 합격을 진심으로 축하드립니다.',

  // RequestCaseState
  Requested: '신규',
  Undecided: '미확정',
  Limited: '열람제한',
  WrongCaseNo: '부존재',
  OnRemoveProcess: '비실명 작업중',
  Get: '전송완료',
  Wasted: '폐기',
  Waiting: '납부',
  Unreadable: '판독불가',
  CounterClaim: '반소',
  UnAble: '검찰송부',
  Merged: '병합',
  Delayed: '입수지연',
  Cancel: '소취하',
};

@Pipe({
  name: 'enumValues',
})
export class EnumValuesPipe implements PipeTransform {
  transform(value): unknown {
    return enums[value] || value;
  }
}
