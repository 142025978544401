<div fxLayout="row" fxLayoutAlign="start center">
  <div fxFlex="auto">
    <h1>
      <span class="vertical-middle">{{ device.deviceName }}</span>
      <span
        *ngIf="withNameEdit && !userService.isRestrictedUser"
        trackId="7087"
        class="ml-4 mt--2 icon3 n-60 h-4"
        (click)="changeName()"
      ></span>
    </h1>
    <div class="date">
      <span *ngIf="browser">{{ browser }}<span class="div"></span></span
      >{{ device.createdAt | date: 'yyyy.M.d HH:mm' }} 등록됨
    </div>
  </div>
  <div>
    <button
      mat-stroked-button
      trackId="5494"  (click)="removeDevice.emit(device)"
      [disabled]="device.deviceToken"
    >
      <ng-container *ngIf="!device.deviceToken"> 해제 </ng-container>
      <ng-container *ngIf="device.deviceToken"> 사용중 </ng-container>
    </button>
  </div>
</div>
