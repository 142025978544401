import { Component } from '@angular/core'
import { DialogOrBottomSheetProto } from '../../../alert/alert.service'
import { PayInfo } from '../../../../../ngxSpring/api.model'
import { UserService } from '../../user.service'
import { Router } from '@angular/router'
import { finalize } from 'rxjs'

@Component({
  selector: 'lb-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.sass'],
})
export class PaymentComponent extends DialogOrBottomSheetProto<{
  payInfo: PayInfo
  code: string
}> {
  checked1
  checked2
  now = new Date()
  user = this.injector.get(UserService)
  card = ''
  month = ''
  year = ''
  pw = ''
  auth = ''
  moveToInfo = () => {
    this.injector.get(Router).navigateByUrl('/user/myPage/info')
  }

  isLoading = false
  setIsLoading(isLoading: boolean) {
    this.isLoading = isLoading
  }

  payment(card: string, expiry: string, pw: string, auth: string) {
    const error = this.error
    if (error) {
      this.alert.pop(error)
      return
    }

    this.setIsLoading(true)
    this.api.userHasPlan
      .startMembership(
        card,
        expiry,
        auth,
        pw,
        this.referrerCode,
        this.data.payInfo.plan.promotionType,
      )
      .pipe(finalize(() => this.setIsLoading(false)))
      .subscribe((v) => {
        if (v.failMessage) {
          this.alert.alert('결제 실패', null, `사유: ${v.failMessage}`)
          return
        }
        this.close(v)
        if (!v.referrerCode)
          this.alert
            .alert(
              `스탠다드 플랜 가입 완료`,
              null,
              '스탠다드 플랜에 가입해주셔서 감사합니다.\n' +
                '이제 엘박스의 모든 기능을 무제한으로 이용해보세요!',
              { id: 'membership_complete' },
            )
            .then(() => this.moveToInfo())
        else
          this.alert
            .alert(
              `스탠다드 플랜 가입 완료`,
              null,
              '친구 초대를 통해 스탠다드 플랜에 가입해주셔서 감사합니다. 이제 엘박스의 모든 기능을 무제한으로 이용해보세요!' +
                '<div class="font-14 mt-16 color-gray">친구초대 이벤트 3만 포인트는 첫 결제 후 7일 간 스탠다드 플랜을 유지한 경우 지급됩니다(무료 이용기간 제외).</div>',
              { id: 'membership_complete' },
            )
            .then(() => this.moveToInfo())
      })
  }

  get error() {
    if (!this.checked1) {
      return '엘박스 유료서비스 운영정책에 동의해주세요.'
    }
    if (!this.checked2) {
      return '월 정기결제 상품임을 확인해주세요.'
    }
    if (this.user.payment) return false
    if (this.card?.length < 18) {
      return '카드 번호를 확인해주세요.'
    }
    if (('20' + this.getYear() + '-' + this.getMonth()).length != 7) {
      return '유효기간을 확인해주세요.'
    }
    if (this.pw.length != 2) {
      return '비밀번호 앞 두자리를 확인해주세요.'
    }
    if (this.auth.length != 6 && this.auth.length != 10) {
      return '생년월일/사업자번호를 확인해주세요.'
    }
  }

  paymentWithoutCard() {
    if (!this.checked1) {
      this.alert.pop('엘박스 유료서비스 운영정책에 동의해주세요.')
      return
    }
    if (!this.checked2) {
      this.alert.pop('월 정기결제 상품임을 확인해주세요.')
      return
    }
    this.setIsLoading(true)
    this.api.userHasPlan
      .startMembershipWithRegistered(
        this.referrerCode,
        this.data.payInfo.plan.promotionType,
      )
      .pipe(finalize(() => this.setIsLoading(false)))
      .subscribe((v) => {
        if (v.failMessage) {
          this.alert.alert('결제 실패', null, `사유: ${v.failMessage}`)
          return
        }
        this.close(v)
        if (!v.referrerCode)
          this.alert
            .alert(
              `스탠다드 플랜 가입 완료`,
              null,
              '스탠다드 플랜에 가입해주셔서 감사합니다.\n' +
                '이제 엘박스의 모든 기능을 무제한으로 이용해보세요!',
              { id: 'membership_complete' },
            )
            .then(() => {
              this.moveToInfo()
            })
        else
          this.alert
            .alert(
              `스탠다드 플랜 가입 완료`,
              null,
              '친구 초대를 통해 스탠다드 플랜에 가입해주셔서 감사합니다. 이제 엘박스의 모든 기능을 무제한으로 이용해보세요!' +
                '<div class="font-14 mt-16 color-gray">친구초대 이벤트 3만 포인트는 첫 결제 후 7일 간 스탠다드 플랜을 유지한 경우 지급됩니다(무료 이용기간 제외).</div>',
              { id: 'membership_complete' },
            )
            .then(() => {
              this.moveToInfo()
            })
      })
  }

  /** 초대코드 */
  get referrerCode(): string | null {
    if (!this.data?.code) {
      return null
    }

    const trimmedCode = this.data.code.trim()
    if (trimmedCode.length === 0) {
      return null
    }
    return trimmedCode
  }

  pay() {
    if (this.user.payment) this.paymentWithoutCard()
    else
      this.payment(
        this.card,
        '20' + this.getYear() + '-' + this.getMonth(),
        this.pw,
        this.auth,
      )
  }

  getYear() {
    if (this.year.length == 1 && Number(this.year) < 10) return '0' + this.year
    return this.year
  }

  getMonth() {
    if (this.month.length == 1 && Number(this.month) < 10)
      return '0' + this.month
    return this.month
  }
}
