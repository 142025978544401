/* tslint:disable */
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ApiHttp} from '../api.http';

import {Statute, StatuteSearchQuery, StatuteSearchResult} from '../api.model';

@Injectable()
export class StatuteController {
  constructor(private http: ApiHttp) {
  }

  get(statuteName: string, date: number): Observable<Statute> {
    return this.http.get<Statute>('/api/statute', {statuteName: statuteName, date: date});
  }

  getAllList(statuteName: string): Observable<any[]> {
    return this.http.get<any[]>('/api/statute/allList', {statuteName: statuteName});
  }

  getList(statuteName: string, jomunKey: string): Observable<any[]> {
    return this.http.get<any[]>('/api/statute/list', {statuteName: statuteName, jomunKey: jomunKey});
  }

  search(searchQuery: StatuteSearchQuery): Observable<StatuteSearchResult> {
    return this.http.post<StatuteSearchResult>('/api/statute/search', searchQuery);
  }
}
