import { Directive, OnInit } from '@angular/core';
import { NgIf } from '@angular/common';
import { isMobileOrTablet } from '../core/util';

@Directive({
  selector: '[lbShowWhenMobile]',
})
export class ShowWhenMobileDirective extends NgIf implements OnInit {
  ngOnInit() {
    this.ngIf = isMobileOrTablet();
  }
}
