import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'lb-lqa-select-year',
  templateUrl: './lqa-select-year.component.html',
  styleUrls: ['./lqa-select-year.component.sass'],
})
export class LqaSelectYearComponent implements OnInit {
  @Input()
  value;

  @Output()
  valueChange = new EventEmitter();

  @Input()
  placeholder;

  @Input()
  includeNow = true;

  years;

  ngOnInit(): void {
    this.years = this.includeNow ? [0] : [];
    let year = new Date().getFullYear();
    for (let i = 0; i < 60; i++) {
      this.years.push(year);
      year -= 1;
    }
  }
}
