import { environment } from './environments/environment';

export const log =
  !environment.production ||
  (typeof localStorage !== 'undefined' &&
    localStorage?.getItem('amplitudeEditMode'))
    ? console.log
    : () => {};

export const warn = !environment.production ? console.warn : () => {}
export const error = !environment.production ? console.error :  () => {}