import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UserToken } from '../../../../../ngxSpring/api.model';
import { ApiService } from '../../../../../ngxSpring/api.service';
import { AlertService } from '../../../alert/alert.service';
import Bowser from 'bowser';
import { UserService } from '../../user.service';

@Component({
  selector: 'lb-device',
  templateUrl: './device.component.html',
  styleUrls: ['./device.component.sass'],
})
export class DeviceComponent implements OnInit {
  constructor(
    private api: ApiService, 
    private alert: AlertService,
    public userService: UserService,
  ) {}

  @Input()
  index;

  @Input()
  withNameEdit = true;

  @Input()
  device: UserToken;

  @Output()
  removeDevice = new EventEmitter<UserToken>();

  get browser() {
    if (this.device.userAgent) {
      return Bowser.getParser(this.device.userAgent).getBrowserName();
    }
    return this.device.browser;
  }

  get platform() {
    if (!this.device) return;
    switch (this.device.deviceType) {
      case 'NORMAL':
        return 'PC';
      case 'MOBILE':
        return '모바일';
      case 'TABLET':
        return '태블릿';
    }
  }

  get os() {
    if (!this.device) return;
    switch (this.device.devicePlatform) {
      case 'IOS':
        return 'IOS';
      case 'ANDROID':
        return 'Android';
      case 'UNKNOWN':
        return;
    }
  }

  changeName() {
    this.alert
      .prompt(
        { placeholder: '기기명', value: this.device.deviceName },
        '기기명 변경',
      )
      .then((v) => {
        if (!v) return;
        this.api.userToken.changeName(this.device.id, v).subscribe(() => {
          this.device.deviceName = v;
          this.alert.pop('기기명이 변경되었습니다.');
        });
      });
  }

  ngOnInit(): void {
    if (this.device.deviceName) return;
    this.device.deviceName = this.platform + (this.index + 1);
    if (this.os) this.device.deviceName += `(${this.os})`;
  }
}
