<span class="options" *showItSizes="{ min: 600 }">
  <span
    *ngFor="let o of orders"
    (click)="order = o; orderChange.emit(order); onClickOrder(order)"
    [class.active]="o == (order || orders[0])"
    >{{ o | optionName }}
  </span>
</span>
<span class="options" *showItSizes="{ max: 599 }" style="padding: 0">
  <mat-select [(ngModel)]="order" (ngModelChange)="orderChange.emit(order)">
    <mat-option *ngFor="let o of orders" [value]="o"
      >{{ o | optionName }}
    </mat-option>
  </mat-select>
</span>
