import { Component } from '@angular/core';
import { DialogOrBottomSheetProto } from '../../../alert/alert.service';
import { RefundData } from '../../../../../ngxSpring/api.model';

@Component({
  selector: 'lb-refund-data',
  templateUrl: './refund-data.component.html',
  styleUrls: ['./refund-data.component.sass'],
})
export class RefundDataComponent extends DialogOrBottomSheetProto<RefundData> {}
