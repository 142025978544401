<div *showItSizes="{ min: break }" class="header-button">
  <div class="buttons" *ngIf="userService.logged">
    <ng-container *ngIf="userService.isLawyer && !userService.isRestrictedUser">
      <a
        class="button transparent my-case-register"
        trackId="f061"
        (click)="userService.myCaseRegister()"
      >
        내 판결문 등록
      </a>
      <div class="divider"></div>
    </ng-container>
    <ng-container *showItSizes="{ max: folderBreak - 1 }">
      <lb-lbox-services
        [withLabel]="true"
        [colorRevert]="colorRevert"
        *ngIf="!userService.isRestrictedUser"
      ></lb-lbox-services>
      <div class="divider"></div>
    </ng-container>
    <ng-container *showItSizes="{ min: folderBreak }">
      <ng-container *ngIf="userService.canUseLegalAnalytics && !userService.isRestrictedUser">
        <a class="button transparent" [href]="legalAnalyticsUrl" (click)="sendClickGnbAnalyticsEvent()">
          <img src="/assets/legal-analytics-menu-beta.png" style="width: 24px; height: 24px; margin-bottom: -4px;" />
          <br />
          법률분석
        </a>
      </ng-container>
      <a 
        *ngIf="!userService.isRestrictedUser"
        class="button transparent" 
        trackId="bf32" 
        routerLink="/substitute"
      >
        <img
          src="/assets/icon-substitute.png"
          style="width: 24px; height: 24px; display: inline-block; vertical-align: middle;"
        />
        <br />
        복대리
      </a>

      <a 
        *ngIf="!userService.isRestrictedUser"
        class="button transparent" 
        trackId="0f99" 
        routerLink="/user/calendar"
      >
        <span class="icon2 n-79 h-4"></span>
        <br />
        사건일정관리
      </a>
      <lb-scrap-menu-folder-button #menu *ngIf="!userService.isRestrictedUser">
        <a
          class="button transparent"
          (mouseover)="menu.hasItems ? menu.open() : ''"
          trackId="30bb"
          routerLink="/bookmark"
        >
          <span class="icon2 n-80 h-1"></span><br />판결문 보관함
        </a>
        <div class="divider mr-34"></div>
      </lb-scrap-menu-folder-button>
    </ng-container>
    <lb-alarm *ngIf="userService.user && !userService.isRestrictedUser" class="mr-36">
      <a class="button transparent relative-position mr-0">
        <span class="icon2 n-81 h-4"></span>
        <span class="count" *ngIf="alarmService.count">{{
          alarmService.count
        }}</span>
        <br />
        <div>알림</div>
      </a>
    </lb-alarm>
    <a
      class="button transparent mr-0"
      trackId="4647"
      [matMenuTriggerFor]="appMenu"
    >
      <span class="icon2 n-82 h-4"></span>
      <div class="name">{{ userService.user.name }}</div>
    </a>
    <mat-menu #appMenu="matMenu" class="user-menu">
      <ng-container *ngIf="userService.logged">
        <ng-container *ngFor="let menu of userService._menuList">
          <div class="menu-name" *ngIf="!menu.name">
            {{ menu }}
          </div>
          <ng-container *ngIf="menu.name">
            <a
              mat-menu-item
              trackId="26b4"
              [trackData]="{ type: menu.logType || menu.name }"
              [routerLink]="menu.path"
              >{{ menu.name }}
              <span
                *ngIf="menu.sub"
                class="float-right"
                [innerHTML]="menu.sub"
              ></span>
            </a>
          </ng-container>
        </ng-container>

        <div class="menu-name">기타</div>
        <a
          mat-menu-item
          trackId="26b4"
          [trackData]="{ type: '이용 가이드' }"
          (click)="openUseGuideOnBlank()"
          >이용 가이드</a
        >
        <a
          *ngIf="!userService.isRestrictedUser"
          mat-menu-item
          zendesk
          trackId="26b4"
          [trackData]="{ type: '고객센터' }"
          >고객센터</a
        >
        <a
          mat-menu-item
          trackId="26b4"
          [trackData]="{ type: '이벤트' }"
          routerLink="/event"
          >이벤트</a
        >
        <a
          class="logout"
          mat-menu-item
          trackId="26b4"
          [trackData]="{ type: '로그아웃' }"
          (click)="userService.logout()"
          >로그아웃</a
        >
      </ng-container>
      <ng-container *ngIf="!userService.logged">
        <a mat-menu-item trackId="1227" routerLink="/user/login">로그인</a>
        <a mat-menu-item trackId="c69e" routerLink="/user/register">회원가입</a>
      </ng-container>
    </mat-menu>
  </div>

  <div class="header-buttons" *ngIf="!userService.logged">
    <a
      *ngIf="!userService.isRestrictedUser"
      class="header-menu-button"
      trackId="6fad"
      routerLink="/user/pricing"
    >
      요금제
    </a>
    <a
      *ngIf="!userService.isRestrictedUser"
      class="header-menu-button"
      trackId="cgg1"
      (click)="openUseGuideOnBlank()"
    >
      이용 가이드
    </a>
    <div class="header-divider"></div>
    <a
      class="header-menu-button login"
      [queryParams]="{ from: href }"
      trackId="ab7a"
      routerLink="/user/login"
    >
      로그인
    </a>
    <a
      class="header-menu-button join"
      [queryParams]="{ from: href }"
      trackId="a1ff"
      routerLink="/user/register"
      >회원가입</a
    >
  </div>
</div>

<ng-container *showItSizes="{ max: break - 1 }">
  <a
    *ngIf="
      !userService.plan &&
      !userService.logged &&
      membershipSuggestion &&
      !userService.isRestrictedUser
    "
    trackId="6fad"
    routerLink="/user/pricing"
    class="vertical-middle"
  >
    요금제
  </a>
  <lb-alarm-mobile *ngIf="showsMobileAlarm">
    <a class="button transparent relative-position">
      <span class="icon2 n-81 h-4"></span>
      <span class="count" *ngIf="alarmService.count">{{
        alarmService.count
      }}</span>
    </a>
  </lb-alarm-mobile>
  <span
    *ngIf="mobileSearch"
    class="icon2 n-83 mr-24"
    trackId="1bb0"
    (click)="search.openSearch()"
  ></span>
  <lb-lbox-services *ngIf="!userService.isRestrictedUser" [colorRevert]="colorRevert"></lb-lbox-services>
  <span
    class="icon2 n-82"
    [class.h-4]="!colorRevert"
    [class.h-1]="colorRevert"
    trackId="4206"
    (click)="userService.menuOpen.emit(true)"
  ></span>
</ng-container>
