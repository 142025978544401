import { Component, Input } from '@angular/core';

@Component({
  selector: 'lb-content-loader',
  templateUrl: './content-loader.component.html',
  styleUrls: ['./content-loader.component.sass'],
})
export class ContentLoaderComponent {
  loading = true;

  @Input()
  set load(data) {
    this.loading = false;
  }
}
