/* tslint:disable */
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ApiHttp} from '../api.http';

import {LboxProduct, Page} from '../api.model';

@Injectable()
export class LboxProductController {
  constructor(private http: ApiHttp) {
  }

  getLboxProduct(id: number): Observable<LboxProduct> {
    return this.http.get<LboxProduct>('/api/lboxProduct', {id: id});
  }

  getLboxProductList(page?: number, size?: number): Observable<Page<LboxProduct>> {
    return this.http.get<Page<LboxProduct>>('/api/lboxProduct/list', {page: page, size: size});
  }

  payment(id: number, card_number: string, expiry: string, birth: string): Observable<LboxProduct> {
    return this.http.post<LboxProduct>('/api/lboxProduct/payment', null, {id: id, card_number: card_number, expiry: expiry, birth: birth});
  }

  saveLboxProduct(lboxProduct: LboxProduct): Observable<LboxProduct> {
    return this.http.post<LboxProduct>('/api/lboxProduct', lboxProduct);
  }
}
