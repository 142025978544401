<div class="title" [class.read]="read">
  <span class="link" trackId="4dbe" [newsLink]="news.link"
    >{{ news.title }}
  </span>
</div>
<div class="sub">
  {{ news.originName }}
  <span class="date">| {{ news.time | slice: 0:10 }}</span>
</div>
<p [innerHTML]="news.content"></p>
<div class="relatives" *ngIf="news.cases?.length">
  <a *ngFor="let lbCase of news.cases" [caseLink]="lbCase.doc_id">
    <span class="icon icon-news-ref"></span> {{ lbCase.title }}</a
  >
</div>
