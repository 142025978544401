import { Component, OnInit, ViewChild } from '@angular/core';
import { DialogOrBottomSheetProto } from '../../../alert/alert.service';
import { LboxUserTypeComponent } from '../../../register/lbox-user-type/lbox-user-type.component';
import { UserService } from '../../user.service';

interface TermsAndConditionsItem {
  text: string;
  subTexts?: string[];
  bold: boolean;
}

interface DialogData {
  job: string[];
  yearOnly: boolean;
}

@Component({
  selector: 'lb-change-type',
  templateUrl: './change-type.component.html',
  styleUrls: ['./change-type.component.sass'],
})
export class ChangeTypeComponent
  extends DialogOrBottomSheetProto<DialogData>
  implements OnInit
{
  private userService = this.injector.get(UserService);

  @ViewChild(LboxUserTypeComponent, { static: false })
  type: LboxUserTypeComponent;
  job: string[];

  agreesOnTermsAndConditions = false;
  isOpenTermsAndConditions = false;

  /** 변호사, 로스쿨 학생, 기타(직접 입력) 인 경우에만 약관 헤더에 직업 노출 */
  get jobName() {
    const job = this.job?.[0];

    if (!job) {
      return '';
    }

    if (job === '변호사' || job === '법학전문대학원생') {
      return job;
    }

    return '';
  }

  get isModifyingButtonDisabled() {
    const isJobSelected = this.type?.selected;
    const 약관보임and약관동의안함 =
      this.isTermsAndConditionsShown && !this.agreesOnTermsAndConditions;

    return !isJobSelected || 약관보임and약관동의안함;
  }

  get isAuthorizedLawyer() {
    return Boolean(this.userService.authorized);
  }

  get isTermsAndConditionsShown() {
    return this.job?.[0] !== '선택 안 함(회원 유형별 맞춤 서비스 이용 불가)';
  }

  get isTermsAndConditionsExpanded() {
    return this.isOpenTermsAndConditions;
  }

  get termsAndConditionsItemsByJobName(): TermsAndConditionsItem[] {
    if (this.jobName === '변호사') {
      return [
        {
          text: '수집∙이용 항목: 응시 시험, 시험 회차, 소속 변호사회, (변호사 인증 시 추가적으로 수집되는 정보) 대한변협 신분증 등록번호, 대한변협 신분증 발급번호',
          bold: false,
        },
        {
          text: '처리 목적: 회원 관리, 회원 불만 사항 처리, 재화 또는 서비스 제공, 상품/서비스 개선 및 추천, 고지/안내사항 전달, 상품/서비스 이용 실적 통계/분석, 변호사 전용 서비스 제공',
          bold: false,
        },
        {
          text: '보유기간: 서비스 이용 만료시 또는 개인정보 수집 이용 목적 달성시까지',
          bold: true,
        },
      ];
    }

    if (this.jobName === '법학전문대학원생') {
      return [
        {
          text: '수집∙이용 항목: 학교명, 입학연도, 졸업 예정 연도, (로스쿨 학생 인증 시 추가적으로 수집되는 정보) 학과명, 학번, 입학연월일, 졸업예정연월일, 인물사진, 발급일자, 재학학기',
          bold: false,
        },
        {
          text: '처리 목적: 회원 관리, 회원 불만 사항 처리, 재화 또는 서비스 제공, 상품/서비스 개선 및 추천, 고지/안내사항 전달, 상품/서비스 이용 실적 통계/분석, 로스쿨 학생 전용 서비스 제공',
          bold: false,
        },
        {
          text: '보유기간: 서비스 이용 만료시 또는 개인정보 수집 이용 목적 달성시까지',
          bold: true,
        },
      ];
    }

    return [
      {
        text: '수집∙이용 항목:',
        subTexts: [
          '법조인(판사, 검사, 법무관 등): 직역 유형, 직업, 응시 시험, 시험 회차, 자격 획득 연도',
          '법률전문직: 직역 유형, 직업, 자격 획득 연도',
          '공무원(법원, 검찰, 경찰 등): 직업 유형, 직업, 임용 연도',
          '대학생/대학원생: 직역 유형, 직업, 학교, 학과, 졸업예정연도',
          '교수: 직역 유형, 직업, 학교, 학과',
          '연구원: 직역 유형, 직업, 소속 기관',
          '기타: 직역 유형, 직업',
        ],
        bold: false,
      },
      {
        text: '처리 목적: 회원 관리, 회원 불만 사항 처리, 재화 또는 서비스 제공, 상품/서비스 개선 및 추천, 고지/안내사항 전달, 상품/서비스 이용 실적 통계/분석',
        bold: false,
      },
      {
        text: '보유기간: 서비스 이용 만료시 또는 개인정보 수집 이용 목적 달성시까지',
        bold: true,
      },
    ];
  }

  ngOnInit() {
    this.job = JSON.parse(JSON.stringify(this.data.job));
  }

  update() {
    if (this.type.error) {
      return;
    }

    this.api.lboxUser.update(this.type.job).subscribe((v) => {
      this.close(v);
    });
  }

  toggleTermsAndConditions() {
    this.isOpenTermsAndConditions = !this.isOpenTermsAndConditions;
  }
}
