import { Injectable } from '@angular/core';
import { UserAlarm } from '../../../ngxSpring/api.model';
import { ApiService } from '../../../ngxSpring/api.service';

@Injectable({
  providedIn: 'root',
})
export class AlarmService {
  get alarms(): any[] {
    return this.events.concat(this._alarms);
  }

  count: number;

  private _alarms: UserAlarm[];

  categories = [
    { name: '전체', logType: '전체', values: [] },
    { name: '사건일정관리', values: ['caseManage'] },
    {
      name: '검색조건 알림',
      logType: '검색조건 알림',
      values: ['caseSubscribe'],
    },
    {
      name: '내 복대리',
      logType: '내 복대리',
      values: ['SUBSTITUTE'],
    },
    {
      name: '판례요청',
      logType: '판례요청',
      values: ['requestCase', 'caseReport'],
    },
    {
      name: '기타',
      logType: '기타',
      values: ['notice'],
    },
  ];
  selectedCategory = this.categories[0];
  end;
  loading;
  allowed;
  lawyerCategories = [...this.categories];
  events = [];

  constructor(private api: ApiService) {
    this.lawyerCategories.splice(4, 0, {
      name: '내 판결문 등록',
      logType: '내 판례등록',
      values: ['myCaseRegister'],
    });
  }

  public refreshAlarm(c?) {
    if (this.loading) return;
    const category = c || this.selectedCategory;
    if (c) this.end = false;
    this.loading = true;
    this.api.userAlarm
      .getUserAlarmList(category?.values || [], 0, 10)
      .subscribe((alarms) => {
        if (category) this.selectedCategory = category;
        this.loading = false;
        this._alarms = alarms.content;
      });
  }

  public refreshCount() {
    this.api.userAlarm.getNewCount().subscribe((c) => {
      this.count = c;
    });
  }

  read() {
    this.api.userAlarm.readUserAlarm().subscribe(() => {
      this.count = 0;
    });
  }

  page;

  more() {
    const page = Math.ceil(this._alarms.length / 10);
    if (this.page == page) return;
    this.page = page;
    if (this.end) return;
    this.api.userAlarm
      .getUserAlarmList(this.selectedCategory?.values || [], page, 10)
      .subscribe((alarms) => {
        this._alarms.pushAll(alarms.content);
        this.end = alarms.totalElements >= this._alarms.length;
      });
  }
}
